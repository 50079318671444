export default {
  'upgadeAccount.title': '升级',
  'upgadeAccount.p1': '选择您的套餐',
  'upgadeAccount.p2': '填入您的账单详情',
  'upgadeAccount.p3': '填入您的支付信息',
  'upgadeAccount.step1.title': '升级服务',
  'upgadeAccount.step1.columns.title1': '月度计划',
  'upgadeAccount.step1.columns.title2': '月度价格',
  'upgadeAccount.step1.p1': '全球加速，不受限制的节点',
  'upgadeAccount.step1.p2': '所有功能',
  'upgadeAccount.step1.p3': '24/7在线售后支持',
  'upgadeAccount.step1.p4': '无限HTTP（S）请求',
  'upgadeAccount.step1.p5': '统一的全球定价，无论地理位置',
  'upgadeAccount.step1.Choose.title': '选择购买周期',
  'upgadeAccount.step1.p6': '您可以最多购买12个月。一个月我们将以30天计算。',
  'upgadeAccount.step1.p7': '购买周期',
  'upgadeAccount.step1.p8': '服务时间',
  'upgadeAccount.step1.Next': '下一步',
  'upgadeAccount.step1.months': '月',
  'upgadeAccount.step1.month': '月',
  'upgadeAccount.step2.validatorEnglish_number.message': '使用3-10个字符',
  'upgadeAccount.step3.memuList.title1': '信用卡',
  'upgadeAccount.step3.memuList.title2': '支付宝',
  'upgadeAccount.step3.columns.title2': '支付详情',
  'upgadeAccount.step3.columns.title4': '每月可使用的流量',
  'upgadeAccount.step3.columns.title5': '总计需支付',
  'upgadeAccount.step3.PaymentSucceeded': '支付成功',
  'upgadeAccount.step3.PaymentFailed': '支付失败',
  'upgadeAccount.step3.title': '支付',
  'upgadeAccount.step3.postCreditCard.message': '添加成功',
};
