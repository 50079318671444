export default {
  'login.success.message': '登录成功',
  'login.validatorEmail.message': '请输入正确的邮箱地址',
  'login.Forget_Password': '忘记密码？',
  'login.WELCOME_TO_LightCDN': '欢迎来到LightCDN',
  'login.BEST_CDN_FOR_YOU': '为您提供最好用的CDN',
  'login.login': '登录',
  'login.form.email.name': '邮箱',
  'login.form.email.message': '请输入您的邮箱地址！',
  'login.form.password.name': '密码',
  'login.form.password.message': '请输入密码！',
  'login.Sign_up_for_free_trial': '注册获得免费试用',
  // 公共组件内容
  'login.components.infomation.title': '通知',
  'login.components.infomation.text1': 'New PoP:Dubai',
  'login.components.infomation.text2': "We've just launched a new PoP in Dubai!",
  'login.components.infomation.text3': 'New Tool For Free',
  'login.components.infomation.text4':
    'You can now easily generate Signed URLs straight from the UI if you have the Secure token enabled for your CDN Resource.Find it in your Client Panel,section Access protection',
  'login.components.infomation.text5': '帮助文档',
  'login.text1': '欢迎来到LightCDN',
  'login.text2': '为您提供最好的CDN',
  'login.FactorAuth.title': '验证您的登录',
  'login.FactorAuth.label': '在下面输入您的双因验证码。',
};
