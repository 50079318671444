/**
 * 充值弹窗
 */
import { useEffect, useState } from 'react';
import { FormattedMessage, useModel } from 'umi';
import { Modal, Form, Button, InputNumber, Radio, Select, Spin } from 'antd';
import { WarningOutlined, PlusOutlined } from '@ant-design/icons';
import { useFomatter } from '@/hooks';
import { useRequest } from 'ahooks';
import { dollarFormat } from '@/utils/utils';
import { getCreditCard_api } from '@/api/stripe';
import { postCalcRecharge_api } from '@/api/upgrade';
import { postOrder_api, postPaymentOrder_api } from '@/api/prod';
import AddCard from '../AddCard';
import { renderNetworkTitle } from '@/pages/cdnResources/components/NetworkList';
import cardVisa from '@/assets/images/card-visa.png';
import cardMastercard from '@/assets/images/card-Mastercard.png';
import cardUnionPay from '@/assets/images/card-unionPay.png';
import cardAmerican from '@/assets/images/card-American.png';
import './style.less';
interface P {
  visible: boolean; //弹窗状态
  onCallback: any; //返回函数
  backUrl?: string; //充值接口返回路径
}
const amountNumbers = [
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  },
  {
    text: '50',
    value: 50,
  },
  {
    text: '100',
    value: 100,
  },
  {
    text: '250',
    value: 250,
  },
  {
    text: '500',
    value: 500,
  },
  {
    text: '1000',
    value: 1000,
  },
  {
    text: '2000',
    value: 2000,
  },
  {
    text: 'Other',
    value: 0,
  },
];
const RechargeModal = (props: P) => {
  const { visible, onCallback, backUrl = '/billing?' } = props;
  const { initialState } = useModel('@@initialState');
  const { trafficData, getTrafficInfo } = initialState || {};
  const [amountVal, setAmountVal] = useState<number | null>(10);
  const [method, setMethod] = useState('CARD');
  const [addVisible, setAddVisible] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const { formatter } = useFomatter();
  const [form] = Form.useForm();
  //获取信用卡列表
  const {
    run: getCreditList,
    data: creditCardList,
    loading: creditLoading,
    refresh,
  } = useRequest(getCreditCard_api, {
    manual: true,
    initialData: [],
    formatResult: (res: any) => {
      return (
        res?.list?.map?.((v: any) => {
          return { ...v, label: v.number, value: v.id };
        }) || []
      );
    },
  });
  //支付订单
  const { run: postPaymentOrder, loading: payLoading } = useRequest(postPaymentOrder_api, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success && res.thirdPartyPayUrl) {
        window.location.href = res.thirdPartyPayUrl;
      }
      //清除处理
      setAmountVal(10);
      setIsOther(false);
      form.setFieldsValue({ orderAmt: 10 });
      onCallback(true);
      //先存储用户是否首充
      sessionStorage.setItem(
        'lightcdn_firstRecharge',
        trafficData.accountVo.rechargeAmount ? '' : 'yes',
      );
      //更新账户信息
      getTrafficInfo?.();
    },
  });
  //创建支付订单
  const { run: postOrder, loading: orderLoading } = useRequest(postOrder_api, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.orderUUID) {
        gtag?.('event', 'confirm_recharge', {
          page_title: 'cdn recharge',
        });
        //第二步：根据第一得到oderUUID去支付订单
        const _paymentMethodId = form.getFieldValue('paymentMethodId');
        const param = {
          orderUUID: res.orderUUID,
          paymentMethod: method, //支付方式
          paymentMethodId: _paymentMethodId || '',
          successUrl:
            window?.location?.origin +
            backUrl +
            'type=payResult&orderType=OPERATE_BALANCE&orderId=' +
            res.orderUUID,
          failureUrl:
            window?.location?.origin +
            '/billing?type=payFail&orderType=OPERATE_BALANCE&orderId=' +
            res.orderUUID,
        };
        postPaymentOrder(param);
      }
    },
  });
  //完成
  const onFinish = (values: any) => {
    // console.log('onFinish', values, method);
    const { orderAmt, amountValue } = values;
    //第一步：先创建支付订单
    const param = {
      orderAmt: orderAmt || amountValue || 10, //选择|其他|默认
      orderOrigAmt: orderAmt || amountValue || 10,
      orderType: 'OPERATE_BALANCE',
    };
    postOrder(param);
  };
  //计算充值套餐价格
  const {
    run: postCalcRecharge,
    loading: loadingCalc,
    data: calcDataText,
  } = useRequest(postCalcRecharge_api, {
    manual: true,
    initialData: '',
    formatResult: (res: any) => {
      const _texts = (res?.items || []).map((m: any) => {
        return m.skuInfo?.resourceType === 'CDN_MONTH_PLAN'
          ? `${formatter({ id: 'billing.Payment.Form.MonthlyPlan.title' })} ${
              m.traffic?.traffic || 0
            }${m.traffic?.trafficUnit || 'GB'}`
          : `${m.traffic?.traffic || 0}${m.traffic?.trafficUnit || 'GB'} ${renderNetworkTitle(
              m.skuInfo?.nodeTemplateSimpleModel,
            )}`;
      });
      return _texts.join('/');
    },
  });

  useEffect(() => {
    if (amountVal && amountVal >= 10 && visible) {
      postCalcRecharge({ price: amountVal });
    }
  }, [amountVal, visible]);
  useEffect(() => {
    getCreditList({ page: 1, pageSize: 50 });
  }, []);
  return (
    <>
      <Modal
        title={formatter({ id: 'billing.Overview.btn.RechargeAccount' })}
        visible={visible}
        destroyOnClose={true}
        maskClosable={false}
        centered={true}
        onCancel={() => {
          onCallback(false);
        }}
        footer={null}
        className="m-RechargeModal"
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            label={formatter({ id: 'billing.RechargeModal.ChooseAmount' })}
            name="orderAmt"
            required={false}
            rules={[
              {
                required: true,
                message: formatter({ id: 'billing.Detail.Form.fullName.message' }),
              },
            ]}
            initialValue={amountNumbers[0].value}
            extra={
              <div
                className="f-flex-startBetween"
                style={{ paddingTop: 10, fontSize: 12, wordBreak: 'break-word' }}
              >
                <div className="c-grayBlack">
                  <Spin spinning={loadingCalc}>
                    {loadingCalc || !amountVal || amountVal < 10 || !calcDataText
                      ? ''
                      : `${formatter({ id: 'billing.Overview.EquivalentTo.p1' })} ${calcDataText}`}
                  </Spin>
                </div>
                <a href="https://www.lightcdn.com/en-US/pricing" target="_blank" rel="noreferrer">
                  {formatter({ id: 'billing.RechargeModal.calcLink' })}
                </a>
              </div>
            }
          >
            <Radio.Group
              onChange={(e: any) => {
                const _val = e.target.value;
                setAmountVal(_val);
                if (_val) {
                  setIsOther(false);
                } else {
                  setIsOther(true);
                  setAmountVal(0);
                  form.setFieldsValue({ amountValue: 0 });
                }
              }}
              buttonStyle="solid"
            >
              {amountNumbers.map((m: any) => {
                return (
                  <Radio.Button value={m.value} key={m.value}>
                    {m.text}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
          {isOther ? (
            <Form.Item
              name="amountValue"
              required={false}
              rules={[
                {
                  required: true,
                  message: formatter({ id: 'billing.Detail.Form.fullName.message' }),
                },
                {
                  min: 10,
                  type: 'number',
                  message: formatter({ id: 'billing.RechargeModal.minAmount.message' }),
                },
              ]}
            >
              <InputNumber
                addonBefore="$"
                min={0}
                max={99999}
                precision={0}
                placeholder={formatter({ id: 'billing.Detail.Form.fullName.message' })}
                style={{ width: '100%' }}
                size="large"
                onChange={(v: number | null) => {
                  setAmountVal(v);
                }}
              />
            </Form.Item>
          ) : null}
          {(trafficData?.accountVo?.balance || 0) < 0 ? (
            <div className="u-warnTip">
              <WarningOutlined />
              {formatter({ id: 'billing.RechargeModal.warnTip.p1' })}
              <label>-${Math.abs(trafficData?.accountVo?.balance)}</label>
              {formatter({ id: 'billing.RechargeModal.warnTip.p2' })}
              <label>{dollarFormat(trafficData?.accountVo?.balance + amountVal)}</label>
              {formatter({ id: 'billing.RechargeModal.warnTip.p3' })}
            </div>
          ) : null}
          <div className="f-flex-between u-title u-line">
            <span>{formatter({ id: 'billing.Payment.Form.totalPaymentAmt.title' })}</span>
            <span className="c-orange">{dollarFormat(amountVal || 0)}</span>
          </div>
          <div className="u-title">{formatter({ id: 'billing.Payment.title' })}</div>
          <Form.Item label="" name="paymentMethod" initialValue="CARD" className="u-paymentMethod">
            <Radio.Group
              onChange={(e: any) => {
                setMethod(e.target.value);
              }}
            >
              <Radio value={'CARD'}>
                <FormattedMessage id="billing.Payment.Form.CARD" />
              </Radio>
              <Radio value={'ALIPAY'}>
                <FormattedMessage id="billing.Payment.Form.ALIPAY" />
              </Radio>
              <Radio value={'PAY_PAL'}>Paypal</Radio>
            </Radio.Group>
          </Form.Item>
          {/* 0元不展示支付方式 */}
          {method === 'CARD' ? (
            <>
              <Form.Item
                name="paymentMethodId"
                rules={[
                  {
                    required: true,
                    message: formatter({ id: 'billing.Payment.Form.paymentMethodId.message' }),
                  },
                ]}
              >
                <Select
                  className="m-billing-Payment-select"
                  loading={creditLoading}
                  dropdownRender={(item: any) => (
                    <>
                      <Button
                        type="text"
                        style={{ width: '100%' }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                          gtag?.('event', 'click_addNewCard_pay', {
                            event_name: 'click',
                          });
                          setAddVisible(true);
                        }}
                      >
                        <FormattedMessage id="billing.Payment.Form.paymentMethodId.Add" />
                      </Button>
                      {item}
                    </>
                  )}
                  dropdownStyle={{ zIndex: 9999 }}
                >
                  {creditCardList.map((v: any) => {
                    return (
                      <Select.Option value={v.id} key={v.id}>
                        {v.number}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className="u-card-list">
                <span>
                  <FormattedMessage id="billing.cardList.text" />
                </span>
                <img src={cardVisa} alt="VISA" title="VISA" />
                <img src={cardMastercard} alt="Mastercard" title="Mastercard" />
                <img src={cardUnionPay} alt="UnionPay" title="UnionPay" />
                <img src={cardAmerican} alt="American Express" title="American Express" />
              </div>
            </>
          ) : null}
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={orderLoading || payLoading}
              disabled={!amountVal}
            >
              {formatter({ id: 'billing.Payment.Form.Pay' })} ${amountVal}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* 添加信用卡 */}
      <AddCard visible={addVisible} onCancel={() => setAddVisible(false)} onRefresh={refresh} />
    </>
  );
};
export default RechargeModal;
