export default {
  'menu.login': '登录',
  'menu.forget': '忘记密码',
  'menu.reset': '重置密码',
  'menu.register': '注册',
  'menu.updateAccount': '升级账号',
  'menu.AccountDisabled': '账号不可用',
  'menu.Home': '主页',
  'menu.CDNResources': 'CDN资源',
  'menu.SSLCerificates': 'SSL证书',
  'menu.Traffic': '流量',
  'menu.BandWidth': '带宽',
  'menu.Requests': '请求',
  'menu.StatusCodes': '状态码',
  'menu.TopFiles': '热门文件',
  'menu.TopReferrers': '热门来源',
  'menu.AccountSettings': '账号设置',
  'menu.Billing': '账单',
  'menu.UpgadeAccount': '升级账号',
  'menu.LogOut': '登出',
  'menu.null.message': '',
  'menu.LogOut.message': '登出成功',
  'menu.layout.message': '您的服务已结束，CDN资源已暂停。请选择',
  'menu.layout.message1': '升级您的帐户。',
  'menu.layout.notBuy.message': '您还没有月度计划，继续购买吧。→',
  'menu.layout.pause.message':
    '由于投诉，您的CDN资源已被暂停。请联系我们的客服或发送电子邮件以解决此问题。',
  'menu.layout.balance.message1': '余额不足，CDN资源已暂停。请选择',
  'menu.layout.balance.message2': '充值你的账户。',
  'menu.layout.accountDisabled.text': '您的帐户已被禁用。如果您有任何疑问，请联系客服。',
  'menu.Usage.Usage': '用量',
  'menu.Usage.Overage': '超量',
  'menu.Usage.Total': '总计',
  'menu.Usage.Remaining': '剩余',
  'menu.Usage.UsedPer': '已用量 {percentage}%',
  'menu.Usage.UsedPro': '已用量 {percentage}/专业版',
  'menu.Usage.UsedFree': '已用量 {percentage}/免费版',
  'menu.Usage.Suspension': '服务暂停',
  'menu.Upgrade': '升级',
  'menu.Usage.balance': '余额',
  'menu.Usage.AccountBalance': '账户余额 {balance}',
  'menu.Usage.Equal': '等于',
  'menu.Usage.Overdue': '欠费',
  'menu.404.subTitle': '抱歉，您访问的页面不存在。',
  'menu.404.BackHome': '回到主页',
  'menu.Support': '支持',
  'menu.language': '语言',
  'menu.copy.success': '复制成功',
  'menu.editCard.editText': '编辑',
  'menu.editCard.cancelText': '取消',
  'menu.editCard.saveText': '保存修改',
  'menu.list.showTotal': '总计 {totalNumber} ',
  'menu.NoDataImg.text': '暂无数据展示',
  'menu.CDNResourcesCreate': 'CDN资源创建',
  'menu.CDNResourcesDetail': 'CDN资源详情',
  'menu.SslCerificatesCreate': 'SSL证书创建',
  'menu.SslCerificatesDetail': 'SSL证书详情',
  'menu.PackageSelection': '程序包选择',
  'menu.NoAccess.button': '让我们开始吧',
  'menu.notifications.title': '通知',
  'menu.notifications.newPop': '节点更新',
  'menu.notifications.newVersion': '版本更新',
  'menu.notifications.orOther': '或其他通知',
  'menu.notifications.markedRead': '全部标记为已读',
  'menu.notifications.readAll': '读取所有通知',
  'menu.notifications.ViewMore': '查看更多',
  // end
  'menu.notifications.planExpired.title': '月度计划已过期',
  'menu.notifications.planExpired.text': '为了继续加速您的内容，请更新您的月度计划。',
  'menu.notifications.planExpired.btn': '升级帐户',
  'menu.notifications.balance.title': '余额不足',
  'menu.notifications.balance.text': '请及时充值。',
  'menu.notifications.balance.btn': '前往充值',
  'menu.notifications.Inform.title': '新通知',
};
