/**
 * cdn详情加速位置引导页
 */
import { FormattedMessage, getLocale } from 'umi';
import { useFomatter } from '@/hooks';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { closeStepRender } from './common';
import GuideBox from './GuideBox';
import guideRightImg from '@/assets/images/guide-right-img.png';
import guideLeftImg from '@/assets/images/guide-left-img.png';
const CdnLocation = (props: any) => {
  const { onClick, step = 1 } = props;
  const { formatter } = useFomatter();
  const locale = getLocale();
  return (
    <div className={'m_cdn_location_box'}>
      {step === 1 ? (
        <>
          <div className="u-img1">
            <div className="u-guide-btnBox">
              <div className="u-left">
                <img src={guideRightImg} />
                <div className="f-tc">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.('cdn_location_2');
                    }}
                  >
                    <FormattedMessage id="guide.next" />
                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                  </Button>
                  {closeStepRender({ onClick })}
                </div>
              </div>
              <div className="u-right">
                <GuideBox maxWidth={360} dir="rightTop">
                  <div>{formatter({ id: 'guide.CdnLocation.step.content1.1' })}</div>
                </GuideBox>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {step === 2 ? (
        <>
          <div className={`u-img2 u-img-${locale}`}>
            <div className="u-guide-btnBox">
              <div className="u-left">
                <GuideBox maxWidth={330} dir="left">
                  <div>{formatter({ id: 'guide.CdnLocation.step.content2.1' })}</div>
                  <div>{formatter({ id: 'guide.CdnLocation.step.content2.2' })}</div>
                </GuideBox>
              </div>
              <div className="u-right">
                <img src={guideLeftImg} />
                <div className="f-tc">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.();
                    }}
                  >
                    <FormattedMessage id="guide.cdnResources.detail.location.start" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default CdnLocation;
