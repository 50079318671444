export default {
  'guide.next': '下一步',
  'guide.pass': '我已掌握使用方法，跳過新手教程>>',
  'guide.attempt': '去嘗試一下！',
  'guide.cdnResources.detail.cname.text1': '替換文件路徑',
  'guide.cdnResources.detail.cname.text2': 'DNS解析',
  'guide.cdnResources.detail.cname.start': '去激活LightCDN吧！',
  'guide.cdnResources.detail.location.start': '快來嘗試更換網絡檔位吧！',
  'guide.cdnResources.detail.rule.start': '去設置規則',
  //
  'guide.CdnCreate.step.title1.1': '內容的來源地址，通常是服務器地址/URL',
  'guide.CdnCreate.step.title1.2': '對外展示的地址',
  'guide.CdnCreate.step.content1': '',
  'guide.CdnCreate.step.title2': '兩個網絡檔位有什麽區別',
  'guide.CdnCreate.step.content2.1': '核心網絡',
  'guide.CdnCreate.step.content2.2':
    '僅提供中國香港、日本東京、新加坡、美國矽谷、美國華盛頓、法國法蘭克福、巴西聖保羅、阿拉伯聯合酋長國迪拜8個高帶寬節點。',
  'guide.CdnCreate.step.content2.3':
    '合適大文件/視頻；或集中於該區域的業務或沒有特定業務區域的國際站點。',
  'guide.CdnCreate.step.content2.4': '標準網絡',
  'guide.CdnCreate.step.content2.5': '包含所有數據中心。',
  'guide.CdnCreate.step.content2.6':
    '合適業務區域比較穩定以及特定；或需求是網站加速或廣告投放需要分發範圍較為廣泛的業務。',
  'guide.CdnCreate.step.title3': '',
  'guide.CdnCreate.step.content3.1': '每個人都將擁有500G的30天核心網絡的流量，若你還沒領取可以前往',
  'guide.CdnCreate.step.content3.2': '升級賬號',
  'guide.CdnCreate.step.content3.3': '中領取',
  'guide.CdnCreate.step.title4': '',
  'guide.CdnCreate.step.content4.1': '1.填完加速域名和源',
  'guide.CdnCreate.step.content4.2': '2.選擇合適的網絡檔位',
  'guide.CdnCreate.step.content4.3': '就可以創建一個CDN加速資源啦！',
  //
  'guide.CdnActivateConfig.step.top.1': '現在LightCDN還沒發揮作用呢！',
  'guide.CdnActivateConfig.step.top.2': '別著急，還有最後一步！',
  'guide.CdnActivateConfig.step.title1.1': '請選擇一種方式使用CNAME地址（xxx.r.cdn36.com）',
  'guide.CdnActivateConfig.step.content1.1': 'Tips：什麽是CNAME？',
  'guide.CdnActivateConfig.step.content1.2':
    'cname是dns的一種記錄方式，將一個域名映射到另一個域名。',
  'guide.CdnActivateConfig.step.content1.3':
    'LightCDN提供的CNAME地址即加速緩存內容的地址，可用來替換源地址。',
  'guide.CdnActivateConfig.step.title2': '兩種方式有什麽不同？',
  'guide.CdnActivateConfig.step.content2.1': '方式一，更換域名地址。',
  'guide.CdnActivateConfig.step.content2.2': '將域名地址的源路徑更換為cname。合適只對',
  'guide.CdnActivateConfig.step.content2.3': '部分文件',
  'guide.CdnActivateConfig.step.content2.4': '進行緩存加速。',
  'guide.CdnActivateConfig.step.title3': '',
  'guide.CdnActivateConfig.step.content3.1': '方式二，使用DNS。',
  'guide.CdnActivateConfig.step.content3.2': '通過第三方域名平臺設置一條DNS記錄。',
  'guide.CdnActivateConfig.step.content3.3': '通常配置方式：',
  'guide.CdnActivateConfig.step.content3.4': '加速域名/加速域名的最低層級',
  'guide.CdnActivateConfig.step.content3.5': '我們生成的CNAME地址',
  'guide.CdnActivateConfig.step.content3.6': '當然，直接使用服務器的命令來完成DNS也可以。',
  'guide.CdnActivateConfig.step.title4': '',
  'guide.CdnActivateConfig.step.content4.1': '通過第三方域名平臺設置一條DNS記錄。合適需要',
  'guide.CdnActivateConfig.step.content4.2': '全局文件加速',
  'guide.CdnActivateConfig.step.content4.3':
    '的資源，此方式，若後續更換hostname，只需要DNS更換「Host Records」一個值即可。',
  'guide.CdnActivateConfig.step.title5': '如何驗證是否加速成功',
  'guide.CdnActivateConfig.step.content5.1': '若你使用的是',
  'guide.CdnActivateConfig.step.content5.2': 'DNS解析',
  'guide.CdnActivateConfig.step.content5.3': '的方式，可訪問',
  'guide.CdnActivateConfig.step.content5.4': '進行自查。',
  'guide.CdnActivateConfig.step.content5.5': '輸入加速域，選擇CNAME，進行Check。',
  //
  'guide.CdnLocation.step.title1': '',
  'guide.CdnLocation.step.content1.1':
    '這是這個資源當前的網絡檔位，展示了你可用的加速位置數量，你可以在當前頁面進行切換。',
  'guide.CdnLocation.step.content2.1': '高亮的是該資源當前的網絡檔位。',
  'guide.CdnLocation.step.content2.2': '你可以選擇更加符合你業務需求的檔位，保存即生效。',
  //
  'guide.CdnRule.step.title1': '條件如何填寫？',
  'guide.CdnRule.step.content1.1': '條件填寫的是你需要繞過緩存的文件類型。',
  'guide.CdnRule.step.content1.2': '"主題"選擇',
  'guide.CdnRule.step.content1.3': '"謂語"選擇',
  'guide.CdnRule.step.content1.4': '"值"填寫',
  'guide.CdnRule.step.content1.5': '文件後綴名',
  'guide.CdnRule.step.content1.6': '，例如圖片填寫"jpg" 。',
  'guide.CdnRule.step.title2': '如何設定動作？',
  'guide.CdnRule.step.content2.1': '若要達到繞開CDN直接訪問源，你需要設置',
  'guide.CdnRule.step.content2.2': '不緩存 - 強製邊緣不緩存',
  'guide.CdnRule.step.content2.3': '，開啟按鈕即可。',
  //
};
