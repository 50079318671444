/**
 * cdn详情激活配置引导页
 */
import { useState, useEffect } from 'react';
import { FormattedMessage, getLocale } from 'umi';
import { useFomatter } from '@/hooks';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { closeStepRender } from './common';
import GuideBox from './GuideBox';
import guideImg from '@/assets/images/guide-left-img.png';
import cdn_activateConfig_cn_line1_1 from '@/assets/images/guide/cdn_activateConfig_cn_line1_1.png';
import cdn_activateConfig_cn_line1_2 from '@/assets/images/guide/cdn_activateConfig_cn_line1_2.png';
import cdn_activateConfig_cn_line5 from '@/assets/images/guide/cdn_activateConfig_cn_line5.png';
import cdn_activateConfig_img5_img from '@/assets/images/guide/cdn_activateConfig_img5_img.png';
const CdnActivateConfig = (props: any) => {
  const { onClick, step = 1 } = props;
  const [animTime, setAnimTime] = useState(0); //step动画3定时跳
  const locale = getLocale();
  const { formatter } = useFomatter();
  useEffect(() => {
    if (step === 3) {
      //step定时动画
      setTimeout(() => {
        setAnimTime(animTime ? 0 : 1);
      }, 3000);
    }
  }, [step, animTime]);
  return (
    <div className={'m_cdn_activateConfig_box'}>
      {step === 1 ? (
        <>
          <div className={`u-img1 u-img-step1 u-img-${locale}`}>
            <div>
              <img src={cdn_activateConfig_cn_line1_1} />
            </div>
            <div>
              <img src={cdn_activateConfig_cn_line1_2} />
            </div>
          </div>
          <div className={`u-img2 u-img-step1 u-img-${locale}`}>
            <div className="u-top" style={{ paddingLeft: '6%' }}>
              <GuideBox type="top">
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.1' })}</div>
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.2' })}</div>
              </GuideBox>
            </div>
            <div className="u-guide-btnBox">
              <div className="u-left f-tc">
                <img src={guideImg} />
                <div className="u-btn-box">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.('cdn_activateConfig_2');
                    }}
                  >
                    <FormattedMessage id="guide.next" />
                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                  </Button>
                  {closeStepRender({ onClick })}
                </div>
              </div>
              <div className="u-right">
                <GuideBox
                  title={formatter({ id: 'guide.CdnActivateConfig.step.title1.1' })}
                  maxWidth={510}
                >
                  <div className="c-orange">
                    <b>{formatter({ id: 'guide.CdnActivateConfig.step.content1.1' })}</b>
                  </div>
                  <div>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content1.2' })}
                    <br />
                    {formatter({ id: 'guide.CdnActivateConfig.step.content1.3' })}
                  </div>
                </GuideBox>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {step === 2 ? (
        <>
          <div className="u-img1">
            <div>
              <img src={cdn_activateConfig_cn_line1_1} />
            </div>
          </div>
          <div className={`u-img2 u-img-step2  u-img-${locale}`}>
            <div className="u-top" style={{ paddingLeft: '6%' }}>
              <GuideBox type="top">
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.1' })}</div>
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.2' })}</div>
              </GuideBox>
            </div>
            <div className="u-guide-btnBox">
              <div className="u-left f-tc">
                <img src={guideImg} />
                <div className="u-btn-box">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.('cdn_activateConfig_3');
                      document
                        .getElementById('CdnActivateConfigLabel')
                        ?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    <FormattedMessage id="guide.next" />
                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                  </Button>
                  {closeStepRender({ onClick })}
                </div>
              </div>
              <div className="u-right">
                <GuideBox
                  title={formatter({ id: 'guide.CdnActivateConfig.step.title2' })}
                  maxWidth={390}
                >
                  <div className="c-orange">
                    <b>{formatter({ id: 'guide.CdnActivateConfig.step.content2.1' })}</b>
                  </div>
                  <div>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content2.2' })}
                    <label className="c-orange">
                      {formatter({ id: 'guide.CdnActivateConfig.step.content2.3' })}
                    </label>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content2.4' })}
                  </div>
                </GuideBox>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {step === 3 ? (
        <>
          <div className={`u-img1 u-img-step3 u-img-${locale}`}>
            <div>
              <img src={cdn_activateConfig_cn_line1_1} style={{ opacity: 0 }} />
            </div>
            <div>
              <img src={cdn_activateConfig_cn_line1_2} />
            </div>
          </div>
          <div className={`u-img2 u-img-step3 u-img-${locale}`}>
            <div className="u-top" style={{ paddingLeft: '6%' }}>
              <GuideBox type="top">
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.1' })}</div>
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.2' })}</div>
              </GuideBox>
            </div>
            <div className="u-guide-btnBox">
              <div className="u-left f-tc">
                <img src={guideImg} />
                <div className="u-btn-box">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.('cdn_activateConfig_4');
                    }}
                  >
                    <FormattedMessage id="guide.next" />
                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                  </Button>
                  {closeStepRender({ onClick })}
                </div>
              </div>
              <div className="u-right">
                <GuideBox
                  title={formatter({ id: 'guide.CdnActivateConfig.step.title2' })}
                  maxWidth={390}
                >
                  <div className="c-orange">
                    <b>{formatter({ id: 'guide.CdnActivateConfig.step.content3.1' })}</b>
                  </div>
                  <div>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content4.1' })}
                    <label className="c-orange">
                      {formatter({ id: 'guide.CdnActivateConfig.step.content4.2' })}
                    </label>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content4.3' })}
                    <br />
                    {formatter({ id: 'guide.CdnActivateConfig.step.content3.3' })}
                    <br />
                    <label className={animTime ? '' : 'bg-orange'}>Host Record：</label>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content3.4' })}
                    <br />
                    Type：CNAME
                    <br />
                    <label className={animTime ? 'bg-orange' : ''}>Records：</label>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content3.5' })}
                    <br />
                    <br />
                    {formatter({ id: 'guide.CdnActivateConfig.step.content3.6' })}
                  </div>
                </GuideBox>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {step === 4 ? (
        <>
          <div className={`u-img5 u-img-${locale}`}>
            <div className="u-top" style={{ paddingLeft: '6%' }}>
              <GuideBox type="top">
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.1' })}</div>
                <div>{formatter({ id: 'guide.CdnActivateConfig.step.top.2' })}</div>
              </GuideBox>
            </div>
            <div className="u-guide-btnBox">
              <div className="u-left f-tc">
                <img src={guideImg} />
                <div className="u-btn-box">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.();
                      document.getElementById('guide-top')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    <FormattedMessage id="guide.cdnResources.detail.cname.start" />
                  </Button>
                </div>
              </div>
              <div className="u-right">
                <GuideBox
                  title={formatter({ id: 'guide.CdnActivateConfig.step.title5' })}
                  maxWidth={390}
                >
                  <div>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content5.1' })}
                    <label className="c-orange">
                      {formatter({ id: 'guide.CdnActivateConfig.step.content5.2' })}
                    </label>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content5.3' })}
                    <a
                      href="https://dnschecked.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="u-underline"
                    >
                      dnschecked.com
                    </a>
                    {formatter({ id: 'guide.CdnActivateConfig.step.content5.4' })}
                    <br />
                    {formatter({ id: 'guide.CdnActivateConfig.step.content5.5' })}
                  </div>
                  <div>
                    <img src={cdn_activateConfig_img5_img} />
                  </div>
                </GuideBox>
              </div>
            </div>
            <div className="u-bottom">
              <img src={cdn_activateConfig_cn_line5} />
              <div className="u-btn-text c-orange">dnschecked.com</div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default CdnActivateConfig;
