export default {
  'requests.topTitle': '請求報告',
  'requests.name': '請求',
  'requests.topTitle.text': '（請按照 utc+0 的時區查看數據。）',
  'requests.content.title': '命中/未命中',
  'requests.menu.text1': '邊緣位置',
  'requests.menu.text2': '大洲',
  'requests.menu.text3': 'CDN資源',
  'requests.chart.text1': '命中',
  'requests.chart.text2': '未命中',
  'requests.chart.text3': '來源',
  'requests.Request': '請求',
  'requests.oneColumns.title2': '請求',
  'requests.oneColumns.title3': '命中 / %',
  'requests.oneColumns.title4': '未命中 / %',
};
