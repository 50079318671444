export default {
  'topFiles.list.title': '熱門文件',
  'topFiles.list.columns.title1': '文件路徑',
  'topFiles.list.columns.title2': '資源',
  'topFiles.list.columns.title3': '請求',
  'topFiles.list.columns.title4': '命中',
  'topFiles.list.columns.title5': '未命中',
  'topFiles.list.columns.title6': '流量',
  'topFiles.list.columns.title7': '操作',
  'topFiles.list.PopoverText1': '清除',
  'topFiles.list.PopoverText2': '預警設置',
  'topFiles.modal.NoticeSetting.title': '預警設置',
  'topFiles.modal.NoticeSetting.success': '設置成功',
  'topFiles.modal.CleanUp.success': '清除成功',
  'topFiles.modal.Button.Save': '保存',
  'topFiles.modal.NoticeSetting.text1': '過去30天的日均請求流量：',
  'topFiles.modal.NoticeSetting.text2': '若文件的請求流量在一天內達到了',
  'topFiles.modal.NoticeSetting.text3': '，我們將發送預警通知郵件。',
  'topFiles.modal.NoticeSetting.message': '請輸入預警值',
  'topReferrers.list.title': '熱門來源',
};
