import request from '@/utils/request';
export function get(url: string, params?: Record<string, any>) {
  return request.get(url, { params });
}
export function kdelete(url: string, params?: Record<string, any>) {
  return request.delete(url, { params });
}

export function put(url: string, params?: Record<string, any>) {
  return request.put(url, {
    data: { ...params, timestamp: parseInt((new Date().getTime() / 1000).toFixed(0), 10) },
  });
}

export function post(url: string, params?: Record<string, any>, otherParams?: any) {
  return request.post(url, {
    data: { ...params, timestamp: parseInt((new Date().getTime() / 1000).toFixed(0), 10) },
    ...otherParams,
  });
}
// formData
export function postForm(url: string, params?: Record<string, any>) {
  return request.post(url, {
    data: params,
    requestType: 'form',
  });
}
