/**
 * 档位选择，类型
 * 一、余额充足&无月度计划，包含有余额有免费试用
 * 二、仅有免费试用，无余额
 * 三、已有月度计划
 */
import { useState, useEffect } from 'react';
import { CheckOutlined, ArrowRightOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Link, getLocale } from 'umi';
import { useFomatter } from '@/hooks';
import './style.less';

//转化多语言标题
export const renderNetworkTitle = (m: any) => {
  let _title = m?.nodeTemplateName || '';
  if (m?.name) {
    const _name = JSON.parse(m?.name);
    _title = _name[getLocale().substring(3).toLowerCase()];
  }
  return _title;
};
export const renderNetworkRemark = (m: any) => {
  let _remark = '';
  if (m?.remark) {
    const remark = JSON.parse(m?.remark);
    _remark = remark[getLocale().substring(3).toLowerCase()];
  }
  return _remark;
};
const NetworkList = (props: { data: any; onCallback: any; skuUUID?: string | null }) => {
  const { formatter } = useFomatter();
  const { data = {}, onCallback, skuUUID } = props;
  const [selectUUID, setSelectUUID] = useState<any>('');
  const [showOption, setShowOption] = useState(false);
  const [skuInfos, setSkuInfos] = useState<any[]>([]);
  const [optionSkuInfos, setOptionSkuInfos] = useState<any[]>([]);
  useEffect(() => {
    if (data?.skuInfos?.length) {
      //单价由低到高排序
      let _data = data?.skuInfos.sort((x: any, y: any) => {
        return (x.gbReferUnitPrice || x.price) - (y.gbReferUnitPrice || y.price);
      });
      if (skuUUID) {
        _data = _data.sort((x: any, y: any) => {
          if (y.resourceUUID === skuUUID || x.resourceUUID === skuUUID) {
            return -1;
          } else {
            return 0;
          }
        });
      }
      //有余额，虽然领了免费试用，但是也进入类型一，所以把免费数据也插入此列
      if (data.haveFree && data.haveLimitBalance && data?.optionSkuInfos.length) {
        const _free = data?.optionSkuInfos?.find((f: any) => {
          return f.resourceType === 'POST_PAID' && f.free;
        });
        if (_free) {
          _data = [..._data, _free];
        }
      }
      setSkuInfos(_data);
    }
    if (data?.optionSkuInfos?.length && !(data.haveFree && data.haveLimitBalance)) {
      //有余额，虽然领了免费试用，但是也进入类型一，所以可以不用渲染
      //单价由低到高排序
      const _data = data?.optionSkuInfos.sort((x: any, y: any) => {
        return (x.gbReferUnitPrice || x.price) - (y.gbReferUnitPrice || y.price);
      });
      setOptionSkuInfos(_data);
    }
    if (
      ((data?.haveFree && !data?.haveLimitBalance && !data?.haveMonthPlan) ||
        data?.haveMonthPlan) &&
      data?.skuInfos?.length
    ) {
      //1、无余额、无月度计划，仅有免费试用，除免费其他不可选
      //2、已有月度计划
      //只会有一个，也只能选一个
      setSelectUUID(data.skuInfos[0].resourceUUID);
      onCallback?.(data.skuInfos[0]);
    }
  }, [data]);
  useEffect(() => {
    if (skuUUID) {
      setSelectUUID(skuUUID);
      const _d = data?.skuInfos?.find((f: any) => {
        return f.resourceUUID === skuUUID;
      });
      if (_d) {
        onCallback?.(_d);
      }
    }
  }, [skuUUID]);
  return (
    <>
      <div className="tier-box">
        {skuInfos?.map((m: any) => {
          return (
            <div
              className={`tier-list ${selectUUID === m.resourceUUID ? 'u-active' : ''}`}
              onClick={() => {
                if (m.disabled) return;
                setSelectUUID(m.resourceUUID);
                onCallback?.(m);
              }}
              key={m.resourceUUID}
            >
              <div className="f-pr">
                <CheckOutlined />
              </div>
              <div>
                <b>{renderNetworkTitle(m.nodeTemplateSimpleModel)}</b>
              </div>
              <div className="u-strong">
                <strong>${m.gbReferUnitPrice || m.price}/GB</strong>
                {m.free && data.haveFree ? (
                  <label>
                    {formatter({
                      id: 'cdnResources.create.step1.fristFree',
                      dynamicMessage: {
                        val: `${m.freeTrafficLimit}GB`,
                      },
                    })}
                  </label>
                ) : null}
              </div>
              <p>{renderNetworkRemark(m.nodeTemplateSimpleModel)}</p>
            </div>
          );
        })}
      </div>
      {data.haveFree && !data.haveLimitBalance && !data.haveMonthPlan ? (
        <div className="tier-more">
          {formatter({ id: 'cdnResources.create.NetworkList.tierRecharge.p1' })}
          <Link to={'/billing?modalVal=recharge'}>
            {formatter({ id: 'cdnResources.create.NetworkList.tierRecharge.p2' })}{' '}
            <ArrowRightOutlined />
          </Link>
          <div
            className="f-pr"
            onClick={() => {
              setShowOption(!showOption);
            }}
            id="tierFollow"
          >
            {showOption ? <UpOutlined /> : <DownOutlined />}
          </div>
        </div>
      ) : null}
      {data.haveMonthPlan ? (
        <div className="tier-more">
          {formatter({ id: 'cdnResources.create.NetworkList.planLearn.p1' })}
          <Link to={'/billing'}>
            {formatter({ id: 'cdnResources.create.NetworkList.planLearn.p2' })}{' '}
            <ArrowRightOutlined />
          </Link>
          <div
            className="f-pr"
            onClick={() => {
              setShowOption(!showOption);
            }}
            id="tierFollow"
          >
            {showOption ? <UpOutlined /> : <DownOutlined />}
          </div>
        </div>
      ) : null}
      <div className={`tier-box ${showOption ? '' : 'u-hide'}`}>
        {optionSkuInfos?.map((m: any) => {
          // 无余额、无月度计划，仅有免费试用，除免费其他不可选
          const _disabled =
            (data.haveFree && !data.haveLimitBalance && !data.haveMonthPlan) || data.haveMonthPlan;
          return (
            <div
              className={`tier-list ${selectUUID === m.resourceUUID ? 'u-active' : ''} ${
                _disabled ? 'u-disabled' : ''
              }`}
              onClick={() => {
                if (_disabled) return;
                setSelectUUID(m.resourceUUID);
                onCallback?.(m);
              }}
              key={m.resourceUUID}
            >
              <div className="f-pr">
                <CheckOutlined />
              </div>
              <div>
                <b>{renderNetworkTitle(m.nodeTemplateSimpleModel)}</b>
              </div>
              <div className="u-strong">
                <strong>${m.gbReferUnitPrice || m.price}/GB</strong>
                {m.free && data.haveFree ? (
                  <label>
                    {formatter({
                      id: 'cdnResources.create.step1.fristFree',
                      dynamicMessage: {
                        val: `${m.freeTrafficLimit}GB`,
                      },
                    })}
                  </label>
                ) : null}
              </div>
              <p>{renderNetworkRemark(m.nodeTemplateSimpleModel)}</p>
            </div>
          );
        })}
      </div>
      {/* 有余额，无计划 */}
      {data.haveLimitBalance && !data.haveMonthPlan ? (
        <div className="tier-more f-tr">
          <Link to={'/upgadeAccount/packageSelection'}>
            {formatter({ id: 'cdnResources.create.NetworkList.moreFavorable' })}{' '}
            <ArrowRightOutlined />
          </Link>
        </div>
      ) : null}
    </>
  );
};
export default NetworkList;
