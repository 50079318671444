export default {
  'menu.login': '登錄',
  'menu.forget': '忘記密碼',
  'menu.reset': '重置密碼',
  'menu.register': '註冊',
  'menu.updateAccount': '升級賬號',
  'menu.AccountDisabled': '賬號不可用',
  'menu.Home': '主頁',
  'menu.CDNResources': 'CDN資源',
  'menu.SSLCerificates': 'SSL證書',
  'menu.Traffic': '流量',
  'menu.BandWidth': '帶寬',
  'menu.Requests': '請求',
  'menu.StatusCodes': '狀態碼',
  'menu.TopFiles': '熱門文件',
  'menu.TopReferrers': '熱門來源',
  'menu.AccountSettings': '賬號設置',
  'menu.Billing': '賬單',
  'menu.UpgadeAccount': '升級賬號',
  'menu.LogOut': '登出',
  'menu.null.message': '',
  'menu.LogOut.message': '登出成功',
  'menu.layout.message': '您的服務已結束，CDN資源已暫停。請選擇',
  'menu.layout.message1': '升級您的帳戶。',
  'menu.layout.notBuy.message': '您還沒有月度計劃，繼續購買吧。→',
  'menu.layout.pause.message':
    '由於投訴，您的CDN資源已被暫停。請聯系我們的客服或發送電子郵件以解決此問題。',
  'menu.layout.balance.message1': '余額不足，CDN資源已暫停。請選擇',
  'menu.layout.balance.message2': '充值你的賬戶。',
  'menu.layout.accountDisabled.text': '您的帳戶已被禁用。如果您有任何疑問，請聯系客服。',
  'menu.Usage.Usage': '用量',
  'menu.Usage.Overage': '超量',
  'menu.Usage.Total': '總計',
  'menu.Usage.Remaining': '剩余',
  'menu.Usage.UsedPer': '已用量 {percentage}%',
  'menu.Usage.UsedPro': '已用量 {percentage}/專業版',
  'menu.Usage.UsedFree': '已用量 {percentage}/免費版',
  'menu.Usage.Suspension': '服務暫停',
  'menu.Upgrade': '升級',
  'menu.Usage.balance': '余額',
  'menu.Usage.AccountBalance': '賬戶余額 {balance}',
  'menu.Usage.Equal': '等於',
  'menu.Usage.Overdue': '欠費',
  'menu.404.subTitle': '抱歉，您訪問的頁面不存在。',
  'menu.404.BackHome': '回到主頁',
  'menu.Support': '支持',
  'menu.language': '語言',
  'menu.copy.success': '復製成功',
  'menu.editCard.editText': '編輯',
  'menu.editCard.cancelText': '取消',
  'menu.editCard.saveText': '保存修改',
  'menu.list.showTotal': '總計 {totalNumber} ',
  'menu.NoDataImg.text': '暫無數據展示',
  'menu.CDNResourcesCreate': 'CDN資源創建',
  'menu.CDNResourcesDetail': 'CDN資源詳情',
  'menu.SslCerificatesCreate': 'SSL證書創建',
  'menu.SslCerificatesDetail': 'SSL證書詳情',
  'menu.PackageSelection': '程序包選擇',
  'menu.NoAccess.button': '讓我們開始吧',
  'menu.notifications.title': '通知',
  'menu.notifications.newPop': '節點更新',
  'menu.notifications.newVersion': '版本更新',
  'menu.notifications.orOther': '或其他通知',
  'menu.notifications.markedRead': '全部標記為已讀',
  'menu.notifications.readAll': '讀取所有通知',
  'menu.notifications.ViewMore': '查看更多',
  // end
  'menu.notifications.planExpired.title': '月度計劃已過期',
  'menu.notifications.planExpired.text': '為了繼續加速您的內容，請更新您的月度計劃。',
  'menu.notifications.planExpired.btn': '升級帳戶',
  'menu.notifications.balance.title': '余額不足',
  'menu.notifications.balance.text': '請及時充值。',
  'menu.notifications.balance.btn': '前往充值',
  'menu.notifications.Inform.title': '新通知',
};
