//根目录
export const ROOT_DIRECTORY = '/api/';
// 超管
export const ADMIN_URL = '/admin-api/';
// 用户
export const APP_URL = '/app-api/';

export const CONFIG = `${ROOT_DIRECTORY}config/`;

export const RESOURCE = `${ADMIN_URL}resource/`;

export const RESOURCES = `${ADMIN_URL}resources/`;
//用户
export const USER_URL = `${ROOT_DIRECTORY}user/admin/portal/`;
//uaav1
export const UAAV1_URL = `${ROOT_DIRECTORY}uaa/v1/`;

// 资源管理 -> 互联
export const RESOURCE_URL2 = `${ROOT_DIRECTORY}/products/v1/`;

// v1
export const v1 = '/v1/';
// 边缘组信息
export const EDGE_URL = `${APP_URL}edge/`;
export const EDGE_ADMIN_URL = `${ADMIN_URL}edge/`;
// cdn
export const CDN_v1_URL = `${APP_URL}cdn/v1/`;
export const CDN_URL = `${APP_URL}cdn/`;
// 套餐
export const MONTH_PLAN_URL = `${APP_URL}month-plan/`;
// 礼包码
export const GIFTCODE_URL = `${APP_URL}v1/giftcode`;
// 监控
export const MONITOR_URL = `${APP_URL}monitor/v1/`;
export const MONITORV2_URL = `${APP_URL}monitor/v2/`;
// 消息通知
export const SYSTEM_NOTICE_URL = `${APP_URL}system/notice/v2`;
export const NOTICE_URL = `${APP_URL}notice/v2`;
//账户
export const ACCOUNT_URL = `${APP_URL}account/`;
//订单
export const ORDER_URL = `${APP_URL}order/`;
//邀请码
export const INVITE_URL = `${APP_URL}invite/`;
