/**
 * 写cookie
 * @param name     cookie名
 * @param value    cookie值
 * @param days     cookie过期天数
 */
export function setCookie(name: string, value: any, days?: number) {
  if (days) {
    const exp: any = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString();
  } else {
    document.cookie = name + '=' + escape(value);
  }
}
/**
 * 读cookie
 * @param name              cookie名
 * @returns {null}          cookie值
 */
export function getCookie(name: string) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  const arr = document.cookie.match(reg);
  if (arr) return unescape(arr[2]);
  return null;
}
/**
 * 删除cookie
 * @param name          cookie名
 */
export function delCookie(name: string) {
  const exp: any = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval !== null) document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
}
/**
 * 跨主、二级域名写cookie
 */
export function setCrossCookie(name: string, value: any, days?: number) {
  const hostIndex = location.host.indexOf('.');
  const domain = location.host.substring(hostIndex);
  if (days) {
    const exp: any = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + '=' + escape(value) + ';expires=' + exp.toGMTString() + ';domain=' + domain;
  } else {
    document.cookie = name + '=' + escape(value) + ';domain=' + domain + ';path=/';
  }
}
/**
 * 跨域删除cookie
 */
export function delCrossCookie(name: string) {
  const hostIndex = location.host.indexOf('.');
  const domain = location.host.substring(hostIndex);
  const exp: any = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval !== null)
    document.cookie =
      name + '=' + cval + ';expires=' + exp.toGMTString() + ';domain=' + domain + ';path=/';
}
