import { get, post } from '../request';
import { MONTH_PLAN_URL } from '../url_config';
// 用户是否拥有过月度计划
export async function getHasMonthPlan_api() {
  return get(`${MONTH_PLAN_URL}v1/month-plan/user/has-once`);
}
// 用户最后一次购买的规格
export async function getMonthPlanLastPurchase_api(params?: any) {
  return get(`${MONTH_PLAN_URL}v1/month-plan/user/last-purchase`, params);
}
// 用户获取月度计划信息
export async function getMonthPlan_api(params?: any) {
  return get(`${MONTH_PLAN_URL}v1/month-plan/user`, params);
}
// 校验是否可以创建自定义工单
export async function postMonthPlanCustomCheck_api(params?: any) {
  return post(`${MONTH_PLAN_URL}custom/check`, params);
}
/**
 * 校验是否可以创建自定义工单
 * @string businessType 业务类型COMPANY,PERSONAL
 * @string company 公司
 * @string email 邮箱
 * @string industry 行业
 * @string name 名称
 * @string projectOptional Project name/URL
 * @string trafficEstimate 每月预估流量
 * @string remark 备注
 */
export async function postMonthPlanCustom_api(params: any) {
  return post(`${MONTH_PLAN_URL}custom`, params);
}
// 查询用户的月度计划
export async function getMonthPlanList_api(params?: any) {
  return get(`${MONTH_PLAN_URL}v1`, params);
}
// 查询自动变更为后付费信息
export async function getMonthPlanAutoPostPaid_api() {
  return get(`${MONTH_PLAN_URL}v1/autoPostPaid`);
}
/**
 * 变更为后付费
 * @string skuUUID 套餐id
 * @string open 开关
 */
export async function postMonthPlanAutoPostPaid_api(params: any) {
  return post(`${MONTH_PLAN_URL}v1/autoPostPaid`, params);
}
