export default {
  'login.success.message': 'Log in succeeded',
  'login.validatorEmail.message': 'Please input correct Email Address',
  'login.Forget_Password': 'Forget Password?',
  'login.WELCOME_TO_LightCDN': 'WELCOME_TO_LightCDN',
  'login.BEST_CDN_FOR_YOU': 'BEST_CDN_FOR_YOU',
  'login.login': 'Log in',
  'login.form.email.name': 'Email',
  'login.form.email.message': 'Please input your Email Address!',
  'login.form.password.name': 'Password',
  'login.form.password.message': 'Please input your password!',
  'login.Sign_up_for_free_trial': 'Sign up for free trial',
  // 公共组件内容
  'login.components.infomation.title': 'INFORM',
  'login.components.infomation.text1': 'New PoP:Dubai',
  'login.components.infomation.text2': "We've just launched a new PoP in Dubai!",
  'login.components.infomation.text3': 'New Tool For Free',
  'login.components.infomation.text4':
    'You can now easily generate Signed URLs straight from the UI if you have the Secure token enabled for your CDN Resource.Find it in your Client Panel,section Access protection',
  'login.components.infomation.text5': 'Documentation',
  'login.text1': 'WELCOME TO LightCDN',
  'login.text2': 'BEST CDN FOR YOU',
  'login.FactorAuth.title': 'Verify your login',
  'login.FactorAuth.label': 'Enter your two-factor authentication code below.',
};
