export default {
  'forget.success.message': '邮件发送成功',
  'forget.validatorEmail.message': '请输入正确的邮箱地址',
  'forget.Modal.content': '我们已发送将密码重置到您的电子邮件地址的链接。请在电子邮件中操作。',
  'forget.Modal.footer': '完成',
  'forget.Forget_Password': '忘记密码',
  'forget.text': '输入您的电子邮件，我们将向您发送链接',
  'forget.subText': '去创建新密码',
  'forget.form.email.name': '邮件',
  'forget.form.email.message': '请输入您的电子邮件！',
  'forget.form.Reset_password': '重置密码',
};
