import React, { useState, useEffect } from 'react';
import { history } from 'umi';
import { Modal, Form, Input, notification } from 'antd';
import { checkPassword_api, changePassword_api } from '@/api/user';
import { testPassWord, TEST_PASSWORD_TWO } from '@/utils/regular';
import './style.less';
const Editpassword: React.FC<{ visible: boolean; close: () => void }> = (props) => {
  const { visible, close } = props;
  const [oldpwd, setOldpwd] = useState('');
  const [newpwd, setNewpwd] = useState('');
  const [valid, setValid] = useState({});
  const [form] = Form.useForm();
  useEffect(() => {
    if (newpwd && newpwd !== oldpwd) {
      // form.setFields([{ name: 'confirmpwd', errors: ['密码不一致'] }]);
      setValid({ validateStatus: 'error', help: '密码不一致' });
      console.log(form);
      console.log('密码不一致');
    } else {
      setValid({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldpwd, newpwd]);
  const onOK = () => {
    // form.submit();
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        const params = {
          password: values.oldpwd,
          newpassword: values.newpwd,
        };
        changePassword_api(params).then((res) => {
          if (res.success) {
            notification.success({
              message: '修改成功',
            });
            setTimeout(() => {
              history.push('/login');
            }, 1000);
          }
        });
      })
      .catch((info) => {
        console.log('err', info);
      });
  };
  // 提交
  const submit = (v: Record<string, any>) => {
    console.log(v);
  };
  // 校验旧密码
  const checkOld = async (e: any) => {
    if (e.target.value) {
      const res = await checkPassword_api(e.target.value);
      if (!res.success) {
        form.setFields([{ name: 'oldpwd', errors: ['密码错误'] }]);
      }
    }
  };
  // 校验密码
  const testPwd = (rule: any, value: any, callback: any) => {
    try {
      if (value) {
        if (testPassWord(value)) {
          if (form?.getFieldValue('confirmPassword')) {
            //激活第二次验证
            form.validateFields(['confirmPassword']);
          }
          callback();
        } else if (!TEST_PASSWORD_TWO.test(value)) {
          callback('输入的密码包含非法英文字符或中文');
        } else {
          callback('输入的密码格式有误');
        }
      } else {
        callback('输入的密码为空');
      }
    } catch (err) {
      callback(err);
    }
  };
  return (
    <Modal
      onCancel={close}
      onOk={onOK}
      title="修改密码"
      visible={visible}
      centered={true}
      maskClosable={false}
    >
      <Form form={form} onFinish={submit}>
        <Form.Item
          label="旧密码"
          name="oldpwd"
          rules={[{ required: true, message: '请输入旧密码' }]}
        >
          <Input.Password onBlur={checkOld} placeholder="请输入旧密码" />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="newpwd"
          rules={[{ required: true, message: '请输入8-20个字符密码' }, { validator: testPwd }]}
        >
          <Input.Password
            onChange={(e: any) => setOldpwd(e.target.value)}
            placeholder="请输入8-20个字符密码"
          />
        </Form.Item>
        <Form.Item
          {...valid}
          label="确认密码"
          name="confirmpwd"
          rules={[{ required: true, message: '请再次输入密码' }]}
        >
          <Input.Password
            onChange={(e: any) => setNewpwd(e.target.value)}
            placeholder="请再次输入密码"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default Editpassword;
