export default {
  'sslCerificates.list.deleteCdn.message': '刪除成功',
  'sslCerificates.list.Popconfirm.title': '你確認刪除該SSL證書嗎？',
  'sslCerificates.list.Popconfirm.okText': '確認',
  'sslCerificates.list.Popconfirm.cancelText': '不，取消',
  'sslCerificates.list.title': 'SSL證書',
  'sslCerificates.list.Create': '創建SSL證書',
  'sslCerificates.list.columns.title1': '名字',
  'sslCerificates.list.columns.title2': '操作選項',
  'sslCerificates.list.PopoverText1': '編輯SSL證書',
  'sslCerificates.list.PopoverText2': 'SSL證書詳情',
  'sslCerificates.list.PopoverText3': '刪除',

  'sslCerificates.create.onSubmit.message': '創建成功',
  'sslCerificates.create.validatorName.message': '請輸入正確的名字',
  'sslCerificates.create.validatorName.rule': '請使用 4-20 字符',
  'sslCerificates.create.validatorSSLKey.rule':
    '必須以 -----BEGIN CERTIFICATE----- 開始並以 -----END CERTIFICATE----- 結尾',
  'sslCerificates.create.validatorSSLPrivateKey.rule':
    '必須以 -----BEGIN RSA PRIVATE KEY----- 開始並以 -----END RSA PRIVATE KEY----- 結尾',
  'sslCerificates.create.step1.Form.name.name': '名字',
  'sslCerificates.create.step1.Form.name.message': '請輸入名字！',
  'sslCerificates.create.step1.Form.sslCertificateKey.name': 'SSL證書密鑰',
  'sslCerificates.create.step1.Form.sslCertificateKey.message': '請輸入SSL證書密鑰！',
  'sslCerificates.create.step1.Form.privateKey.name': '私鑰',
  'sslCerificates.create.step1.Form.privateKey.message': '請輸入私鑰！',
  'sslCerificates.create.step1.Form.Button': '創建SSL證書',

  'sslCerificates.detail.handleSave.message': '編輯成功',
  'sslCerificates.detail.title1': '您的SSL證書',
  'sslCerificates.detail.title2': '關聯的CDN資源',
  'sslCerificates.detail.Button.Edit': '編輯',
  'sslCerificates.detail.Button.Cancel': '取消',
  'sslCerificates.detail.Button.Save': '保存',
};
