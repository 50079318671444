/**
 * cdn详情规则页引导页
 */
import { FormattedMessage, getLocale } from 'umi';
import { useFomatter } from '@/hooks';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { closeStepRender } from './common';
import GuideBox from './GuideBox';
import guideImg from '@/assets/images/guide-right-img.png';
const CdnRule = (props: any) => {
  const { onClick, step = 1 } = props;
  const locale = getLocale();
  const { formatter } = useFomatter();
  return (
    <div className={'m_cdn_rule_box'}>
      {step === 1 ? (
        <>
          <div className={`u-img1 u-img-${locale}`}>
            <div className="u-guide-btnBox">
              <div className="u-left">
                <img src={guideImg} />
                <div className="f-tc">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.('cdn_rule_2');
                      document.getElementById('act')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    <FormattedMessage id="guide.next" />
                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                  </Button>
                  {closeStepRender({ onClick })}
                </div>
              </div>
              <div className="u-right">
                <GuideBox title={formatter({ id: 'guide.CdnRule.step.title1' })} maxWidth={400}>
                  <div>{formatter({ id: 'guide.CdnRule.step.content1.1' })}</div>
                  <div>
                    {formatter({ id: 'guide.CdnRule.step.content1.2' })}
                    <label className="c-orange">extension</label>；
                  </div>
                  <div>
                    {formatter({ id: 'guide.CdnRule.step.content1.3' })}
                    <label className="c-orange">equals</label>；
                  </div>
                  <div>
                    {formatter({ id: 'guide.CdnRule.step.content1.4' })}
                    <label className="c-orange">
                      {formatter({ id: 'guide.CdnRule.step.content1.5' })}
                    </label>
                    {formatter({ id: 'guide.CdnRule.step.content1.6' })}
                  </div>
                </GuideBox>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {step === 2 ? (
        <>
          <div className={`u-img2 u-img-${locale}`}>
            <div className="u-guide-btnBox">
              <div className="u-left">
                <img src={guideImg} />
                <div className="f-tc">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.();
                      document
                        .getElementById('rc-tabs-0-panel-Rules')
                        ?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    <FormattedMessage id="guide.cdnResources.detail.rule.start" />
                  </Button>
                </div>
              </div>
              <div className="u-right">
                <GuideBox title={formatter({ id: 'guide.CdnRule.step.title2' })} maxWidth={350}>
                  <div>
                    {formatter({ id: 'guide.CdnRule.step.content2.1' })}
                    <label className="c-orange">
                      {formatter({ id: 'guide.CdnRule.step.content2.2' })}
                    </label>
                    {formatter({ id: 'guide.CdnRule.step.content2.3' })}
                  </div>
                </GuideBox>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default CdnRule;
