export default {
  'home.Today': '今天',
  'home.topTitle': '帶寬',
  'home.reportTitle': '大洲數據報告',
  'home.statusTitle': '狀態碼報告',
  'home.reportTitle.memus1': '流量',
  'home.reportTitle.memus2': '緩存率',
  'home.headerTitle': '概覽',
  'home.homeTip': '（請按照 utc+0 的時區查看數據。）',
  'home.ProCard.title1': '峰值',
  'home.ProCard.title2': '峰值發生時間',
  'home.StatusOverView.series': '請求',
  'home.StatusOverView.upViewbtn': '查看完成的狀態碼報告 →',
  'home.line.series': '總計',
  'home.menuTable.menu1': '流量',
  'home.requestData.series': '命中',
  'home.traffic.upViewbtn': '查看完整的流量報告 →',
  'home.requests.upViewbtn': '查看完整的請求報告 →',
  'home.oneColumns.Continent': '大洲',
  'home.oneColumns.Traffic': '流量',
  'home.oneColumns.Percent': '百分比',
  'home.twoColumns.Time': '時間',
  'home.twoColumns.Bandwidth_peak': '帶寬峰值',
  'home.threeColumns.Hit_ratio': '命中率',
  'home.statusColumns.status_code': '狀態碼',
  'home.statusColumns.Requests': '請求',

  'home.header.title': '你好！歡迎回來',
  'home.balance.tip1':
    '很遺憾，您的賬戶余額不足，您將無法再使用LightCDN服務。 要繼續您的服務，請檢查您的',
  'home.balance.tip2': '賬單',
  'home.balance.tip3': '，為您的帳戶充值或購買包月套餐。 如有疑問請聯系客服或聯系',
  'home.balance.tip4': '發送郵件。',
  'home.locations': 'CDN加速位置',
  'home.top.title1': 'HTTP錯誤報告（4XX 和 5XX）排名前5',
  'home.top.title2': 'CDN資源排名前5',
  'home.top.title3': '國家/地區來訪者排名前5',
  'home.top.title4': '來源排名前5',
  'home.TopToday.Traffic': '流量（今日）',
  'home.TopToday.Bandwidth': '帶寬（今日）',
  'home.TopToday.Peak': '峰值',
  'home.TopToday.PeakTime': '峰值時間',
  'home.TopToday.CacheHitRate': '緩存命中率（今日）',
};
