export default {
  'packageSelection.title': '升級',
  'packageSelection.planList.free': '免費',
  'packageSelection.planList.free.title': '免費月度計劃',
  'packageSelection.planList.free.nextText': '用於個人非專業網站',
  'packageSelection.planList.free.support': '幫助文檔',
  'packageSelection.planList.free.perMonth': '每月{numUnit}',
  'packageSelection.planList.free.price': '每月可回購',
  'packageSelection.planList.free.config': '可用的國際節點',
  'packageSelection.planList.free.buttonText': '選擇',
  'packageSelection.planList.free.Modal.text': '還沒到購買時間，請等待開啟。',
  'packageSelection.planList.free.extraCore': '',
  'packageSelection.planList.free.corefeatures.p1': '快速且簡便的使用CDN',
  'packageSelection.planList.free.corefeatures.p2': '免費的SSL證書',
  'packageSelection.planList.free.corefeatures.p3': '100GB月度流量',
  'packageSelection.planList.free.corefeatures.p4': '基礎數據以及訪客分析',
  'packageSelection.planList.free.corefeatures.p5': '中國大陸的國際訪問',
  'packageSelection.planList.free.corefeatures.p6': '完善的安全保護功能政策',
  'packageSelection.planList.free.corefeatures.p7': '可自定義的8個核心國際節點',
  'packageSelection.planList.free.corefeatures.p8': '無合同協議',
  'packageSelection.planList.pro.title': '專業版月度計劃',
  'packageSelection.planList.pro.nextText': '非商業專業網站',
  'packageSelection.planList.pro.support': '在線客服，支持郵箱',
  'packageSelection.planList.pro.perMonth': '低至每月{low}',
  // 'packageSelection.planList.pro.perMonth': '2023特惠！一個開放所有節點的500G計劃！',
  'packageSelection.planList.pro.price': '單價低至 {unitPrice}/GB',
  'packageSelection.planList.pro.config': '所有Pops，包括隨後開放的罕見Pops',
  'packageSelection.planList.pro.buttonText': '選擇',
  'packageSelection.planList.pro.extraCore': '免費計劃中的所有，並且還包括：',
  'packageSelection.planList.pro.corefeatures.p1': '所有節點，包括稀有的邊緣節點',
  'packageSelection.planList.pro.corefeatures.p2': '每月{min}~{max}，單價低至 {unitPrice}',
  'packageSelection.planList.pro.corefeatures.p3': '快速便捷的WAF功能',
  'packageSelection.planList.pro.corefeatures.p5': '無上限的緩存規則（即將上線）',
  'packageSelection.planList.remind.title': '自定義月度計劃',
  'packageSelection.planList.remind.nextText': '專業和商業網站',
  'packageSelection.planList.remind.support': '專屬1對1客服，解決方案工程師支持，',
  'packageSelection.planList.remind.support1': '提供付費節點測試',
  'packageSelection.planList.remind.perMonth': '每月需求量超過50T',
  'packageSelection.planList.remind.price': '更實惠的單價',
  'packageSelection.planList.remind.config': '',
  'packageSelection.planList.remind.buttonText': '申請報價',
  'packageSelection.planList.remind.disabledText': '請等待處理',
  'packageSelection.planList.remind.Modal.ok': '好的',
  'packageSelection.planList.remind.Modal.text':
    '我們已收到您的請求。功能上線後，我們將通過電子郵件通知您。',
  'packageSelection.planList.remind.extraCore': '專業套餐中的所有，並且還包括：',
  'packageSelection.planList.remind.corefeatures.p1': '支持團隊共享',
  'packageSelection.planList.remind.corefeatures.p2': '最具成本效益的解決方案',
  'packageSelection.planList.remind.corefeatures.p3': '獲得非合同服務',
  'packageSelection.planList.Support': '支持',
  'packageSelection.planList.CoreFeatures': '核心功能',
  'packageSelection.help1': '如果你需要測試付費的節點，請',
  'packageSelection.help2': '聯系我們，',
  'packageSelection.help3': '我們將很高興為你服務。',
  //v1.2.0
  'packageSelection.planList.title1': '按量付費',
  'packageSelection.planList.title2': '月度計劃',
  'packageSelection.planList.title3': '一體化解決方案',
  'packageSelection.planList.title1.text': '按小時結算和按需加速',
  'packageSelection.planList.title2.text': '30天限時套餐，單價更實惠',
  'packageSelection.planList.title3.text': '根據業務需求定製解決方案',
  'packageSelection.planList.Tier': '檔位',
  'packageSelection.planList.international.nodes': '國際節點',
  'packageSelection.planList.core.nodes': '核心國際節點',
  'packageSelection.planList.Other': '其他',
  'packageSelection.planList.nodes.text1':
    '所有節點都適合非洲、中東、東南亞等地區有穩定業務需求的客戶。',
  'packageSelection.planList.nodes.text2': '覆蓋五大區域的骨幹網絡節點。滿足國際訪問需求。',
  'packageSelection.planList.nodes.text3': '添加你所需要的新節點',
  'packageSelection.planList.Support.help': '幫助文檔，郵件支持',
  'packageSelection.planList.Support.help1': '客戶服務1對1，解決方案工程師支持',
  'packageSelection.planList.Recharge': '賬戶充值',
  'packageSelection.planList.button2': '獲取方案',
  'packageSelection.planList.button4': '獲取免費額度！',
  'packageSelection.planList.button5': '已兌換 {date}',
  'packageSelection.planList.Operation.successful': '操作成功',
  'packageSelection.planList.noFree.p': '當前有月度計劃，暫時不可領取免費額度。',
  'packageSelection.planList.day': '天',
  'packageSelection.planList.billingDetails.p1': '您還未填寫賬單信息，請前往',
  'packageSelection.planList.billingDetails.p2': '賬單詳情',
  'packageSelection.planList.billingDetails.p3': '填寫後方可領取額度！',
  'packageSelection.planList.Successfully': '兌換成功!!',
  'packageSelection.planList.CreateCDN': '創建新的CDN資源',
};
