export default {
  'billing.title': 'Billing',
  'billing.tabTitle1': 'Overview',
  'billing.tabTitle2': 'Invoices',
  'billing.tabTitle3': 'Payment settings',
  'billing.tabTitle4': 'Billing details',
  'billing.tabTitle5': 'Payment record',
  'billing.tabTitle6': 'Recharge record',
  'billing.tabTitle7': 'Earn balance',
  'billing.AddCard.postCreditCard.message': 'add card successfully',
  'billing.AddCard.Modal.title': 'Add new card',
  'billing.AddCard.Modal.okText': 'Add card',
  'billing.AddCard.Form.number.title': 'Card number',
  'billing.AddCard.Form.number.message': 'Please enter card number',
  'billing.AddCard.Form.expDate.title': 'Expiration date',
  'billing.AddCard.Form.expDate.message': 'Please enter expiration date',
  'billing.AddCard.Form.expDate.validator': 'Illegal date',
  'billing.AddCard.Form.expDate.MMYY': 'MM/YY',
  'billing.AddCard.Form.cvc.title': 'Security code',
  'billing.AddCard.Form.cvc.message': 'Please enter security code',
  'billing.AddCard.Form.cvc.CVC': 'CVC',
  'billing.Payment.title': 'Payment',
  'billing.Payment.Form.MonthlyPlan.title': 'Monthly plan',
  'billing.Payment.Form.MonthlyPlan.text': 'per month',
  'billing.Payment.Form.oriPaymentAmt.title': 'Amount previously paid',
  'billing.Payment.Form.price.title': 'Price',
  'billing.Payment.Form.Overages.title': 'Overages',
  'billing.Payment.Form.discount.title': 'Discount savings',
  'billing.Payment.Form.discount.placeholder': 'Please select a discount',
  'billing.Payment.Form.discount.NoneAvailable': 'None available',
  'billing.Payment.Form.discount.text': '{per}% off for {num}',
  'billing.Payment.Form.discount.traffic.text': '{per}% off for {num}',
  'billing.Payment.Form.discount.Annually': 'Annually',
  'billing.Payment.Form.discount.plan': '{price} annually & {monthPrice} /mo',
  'billing.Payment.Form.mo': '{price} /mo',
  // 'billing.Payment.Form.totalPaymentAmt.title': 'Amount to pay',
  'billing.Payment.Form.totalPaymentAmt.title': 'Subtotal',
  'billing.Payment.Form.Over': 'Over {num}',
  'billing.Payment.Form.CARD': 'Credit card',
  'billing.Payment.Form.ALIPAY': 'Alipay',
  'billing.Payment.Form.BALANCE': 'Account balance',
  'billing.Payment.Form.BALANCE.Recharge': 'Recharge',
  'billing.Payment.Form.paymentMethodId.message': 'please select',
  'billing.Payment.Form.paymentMethodId.Add': 'Add Credit card',
  'billing.Payment.Form.Pay': 'Pay',
  'billing.Payment.Form.Pay.tips': 'Please go to the Billing details to fill in, then you can pay!',
  'billing.Payment.Pay.Modal.balance': 'After resource settlement, the balance is insufficient.',
  'billing.Payment.Pay.Modal.debt': 'Account debt, please settle first.',
  'billing.Payment.Pay.Modal.recharge': 'Go to recharge',
  'billing.Detail.handleSave.message': 'modified successfully',
  'billing.Detail.validatorEmail.message': 'Please input correct Email Address',
  'billing.Detail.validatorEnglish_number.message': 'Please enter English or Number',
  'billing.Detail.validatorEnglish.message': 'Please enter English',
  'billing.Detail.title': 'Billing details',
  'billing.Detail.Form.company.title1': 'Company',
  'billing.Detail.Form.company.title2': '(optional)',
  'billing.Detail.Form.fullName.title': 'Full name',
  'billing.Detail.Form.fullName.message': 'Please enter',
  'billing.Detail.Form.email.title': 'Email',
  'billing.Detail.Form.billingAddress.title': 'Street',
  'billing.Detail.Form.city.title': 'City',
  'billing.Detail.Form.postalCode.title': 'Postal code/ZIP',
  'billing.Detail.Form.Country.title': 'Country/Region',
  'billing.Detail.Form.Country.message': 'Please input your Country/Region!',
  'billing.ExtraTraffic.title': 'Extra traffic',
  'billing.ExtraTraffic.Form.plan1.title': 'Your current monthly plan',
  'billing.ExtraTraffic.Form.plan2.title': 'Traffic usage',
  'billing.ExtraTraffic.Form.Quantity.title': 'Quantity',
  'billing.Invoices.postDownloadMonthBill.message': 'download successfully',
  'billing.Invoices.columns.title1': 'Payment Date',
  'billing.Invoices.columns.title2': 'Invoice number',
  'billing.Invoices.columns.title3': 'VAT',
  'billing.Invoices.columns.title4': 'Amount',
  'billing.Invoices.columns.title5': 'Download',
  'billing.InvoicesHistory': 'Invoice history',
  'billing.Overview.ProCard.title1': 'Suggestion',
  'billing.Overview.ProCard.Button1': 'Purchase',
  'billing.Overview.Item.title1': 'Your trial remaining',
  'billing.Overview.Item.title1.render': '（Reset traffic after {hour}）',
  'billing.Overview.Item.title2': 'Last purchased plan',
  'billing.Overview.Item.title3': 'Traffic in last 30 days',
  'billing.Overview.Item.title4': 'Best fitting monthly plan',
  'billing.Overview.ProCard.title2': 'Current period',
  'billing.Overview.ProCard.Button3': 'Upgrade',
  'billing.Overview.columns.title1': 'Service period',
  'billing.Overview.columns.title1.render': 'Expires on {slot}（Reset traffic after {hour}）',
  'billing.Overview.columns.title2': 'Traffic usage of this cycle',
  'billing.Overview.columns.title2.1': 'Traffic remaining of this cycle',
  'billing.Overview.columns.title2.2': 'Overage of this cycle',
  'billing.Overview.columns.title3': 'Current monthly plan',
  'billing.Overview.columns.title4': 'Price',
  'billing.Overview.hasService.columns.title2': 'Extra traffic remaining',
  'billing.Overview.ProCard.Button4': 'Repayment',
  'billing.Overages.title': 'Overages',
  'billing.Overage.title': 'Overage',
  'billing.Overview.Overages.columns.title1': 'Cumulative overage',
  'billing.Overview.Overages.columns.title2': 'Overages this period',
  'billing.Overview.Overages.columns.title3': 'Unit price for overages',
  'billing.PaymentSetting.title': 'Credit cards',
  'billing.PaymentSetting.delCreditCard.message': 'delete successfully',
  'billing.PaymentSetting.Button1': 'Remove',
  'billing.PaymentSetting.Button2': 'set as default',
  'billing.PaymentSetting.expires': 'expires ',
  'billing.PaymentSetting.Modal.title': 'Remove card',
  'billing.PaymentSetting.Modal.p': 'Are you sure you want to remove the credit card',
  'billing.PayResult.handleCheck.message': 'Payment is being processed',
  'billing.PayResult.timeout.message': 'time out',
  'billing.PayResult.success.message': 'Payment success',
  'billing.PayResult.fail.message': 'Payment fail',
  'billing.PayResult.title': 'Billing',
  'billing.PayResult.p1': 'Configuring for you.Please wait a moment.',
  'billing.PayResult.p2': 'Congratulations on your purchase.',
  'billing.PayResult.p3': 'An error has occurred. ',
  'billing.PayResult.p4': 'You can go to the billing page to confirm your purchase.',
  'billing.Purchase.title1': 'Upgrade monthly plan',
  'billing.Purchase.title2': 'Purchase monthly plan',
  'billing.Purchase.Form.monthPlan.title': 'Select new monthly plan',
  'billing.Purchase.Form.Choose.p1': 'Choose purchase duration',
  'billing.Purchase.Form.Choose.p2':
    'You can buy it for 12 months at most. For one month period, we will calculate the price based on 30 days.',
  'billing.Purchase.Form.Choose.p2-free':
    'You can buy it for 1 month. For one month period, we will calculate the price based on 30 days.',
  'billing.Purchase.Form.purchaseQuantity.title': 'Purchase duration',
  'billing.cardList.text': 'We support:',
  'billing.PaymentRecord.columns.title1': 'Order number',
  'billing.PaymentRecord.columns.title2': 'Descripition',
  'billing.PaymentRecord.columns.title2.text1': 'Purchase monthly plan {val}',
  'billing.PaymentRecord.columns.title2.text2': 'Purchase extra traffic {val}',
  'billing.PaymentRecord.columns.title2.text3': 'Upgrade monthly plan {val}',
  'billing.PaymentRecord.columns.title2.text4': '',
  'billing.PaymentRecord.columns.title2.text5': 'Refound monthly plan {val}',
  'billing.PaymentRecord.columns.title2.text6': 'Refound extra traffic {val}',
  'billing.PaymentRecord.columns.title3': 'Amount',
  'billing.PaymentRecord.columns.title4': 'Status',
  'billing.PaymentRecord.columns.title5': 'Create time',
  'billing.PaymentRecord.columns.title6': 'Payment time',
  'billing.PaymentRecord.columns.title7': 'Payment method',
  'billing.PaymentRecord.columns.title8': 'Options',
  'billing.PaymentRecord.columns.pay': 'PAY',
  'billing.PaymentRecord.columns.pay.text': 'remind',
  'billing.PaymentRecord.Payment.topTip':
    'Please go to the Billing details to fill in, then you can pay!',
  'billing.PaymentRecord.Payment.bottomTip': 'Please complete the payment before {amount}.',
  'billing.RechargeRecord.columns.title1': 'Recharge amount',
  'billing.Purchase.OrderUpgrade.title': 'Select the month to upgrade',
  'billing.Purchase.OrderUpgrade.Collapse.title':
    'The following cycle has already purchased the highest monthly traffic and cannot be upgraded.',
  'billing.Purchase.customized.text1': 'Not satisfied? Find our customer service or ',
  'billing.Purchase.customized.text2': 'apply for a customized monthly plan!',
  'billing.Purchase.customized.text0':
    'You have applied for a customized monthly plan, please wait for processing.',
  'billing.PaymentTerms.title': 'Please confirm that you have understood the payment terms.',
  'billing.PaymentTermsModal.title': 'Payment terms',
  'billing.PaymentTermsModal.text1':
    'After purchasing the monthly plan, we support purchasing extra traffic to supplement when it is about to exceed the limit.',
  'billing.PaymentTermsModal.text2':
    'Otherwise, we will require you to repay the excess amount at a unit price of {val}.',
  'billing.PaymentTermsModal.text3': 'For more detailed terms of service, please',
  'billing.PaymentTermsModal.text4': ' click here to view',
  'billing.PaymentTermsModal.button': 'Okay, I understand.',
  //v1.2.0
  'billing.AutoSettings.title': 'Auto settings',
  'billing.AutoSettings.name': 'Automatic fee deduction',
  'billing.AutoSettings.p1': 'If you want to keep your business when the ',
  'billing.AutoSettings.p2': 'monthly plan expires',
  'billing.AutoSettings.p3':
    ', please enable the function and we will directly deduct the balance to ensure that your business does not go down.',
  'billing.AutoSettings.Default.tier': 'Default tier',
  'billing.AutoSettings.autoModal.p0':
    'When the monthly plan expires, your business will also be retained, and the balance will be directly deducted based on the same tier of pay-as-you-go unit price to ensure that your business will not be interrupted.',
  'billing.AutoSettings.autoModal.p1.1': 'The current',
  'billing.AutoSettings.autoModal.p1.2': ' automatic fee deduction ',
  'billing.AutoSettings.autoModal.p1.3': 'is turned off.',
  'billing.AutoSettings.autoModal.p2':
    'If you want to keep your business when the monthly plan expires, please enable the function and we will directly deduct the balance to ensure that your business does not go down.',
  'billing.AutoSettings.autoModal.btn': 'Go to Settings',
  'billing.Overview.ToRecharge':
    'To recharge your account, please fill in your billing details first.',
  'billing.Overview.btn.RechargeAccount': 'Recharge account',
  'billing.Overview.EquivalentTo.p1': 'Equivalent to',
  'billing.Overview.EquivalentTo.p2': 'of standard network',
  'billing.Overview.EquivalentTo.free': 'Go get your balance for free!',
  'billing.Overview.UsageInMonth': 'Usage in {val}',
  'billing.Overview.UsageInMonth.p1': 'This section does not include traffic in monthly plan.',
  'billing.Overview.UsageInMonth.p2.1': 'Your total monthly traffic usage in {val} is ',
  'billing.Overview.UsageInMonth.p2.2': 'with an average cost of ',
  'billing.Overview.BillingHistory': 'Billing history',
  'billing.Overview.PaymentAmount': 'Payment amount',
  'billing.RechargeModal.warnTip.p1': 'You still have a debt of ',
  'billing.RechargeModal.warnTip.p2': ' . After recharging, your account balance will remain ',
  'billing.RechargeModal.warnTip.p3': '.',
  'billing.RechargeModal.ChooseAmount': 'Choose the amount',
  'billing.RechargeModal.minAmount.message': 'The recharge amount cannot be less than $10.',
  'billing.RechargeModal.calcLink': 'Go to calculate the price >',
  'billing.RechargeModal.firstRecharge.title': 'Congratulations on completing your first charge!',
  'billing.RechargeModal.firstRecharge.content':
    "Let's take a look at how your resources can change the network tier!",
  'billing.RechargeModal.firstRecharge.btn': 'Enter the guide',
  //v1.2.3
  'billing.EarnBalance.title1':
    'For every new user invited, you will receive a rebate of 20% of their recharge amount.',
  'billing.EarnBalance.title2': 'Total rewards',
  'billing.EarnBalance.title3': 'Share code: ',
  'billing.EarnBalance.title4': 'Total Invitees: ',
  'billing.EarnBalance.balance.p0':
    'Invitees who meet the following conditions will receive rewards:',
  'billing.EarnBalance.balance.p1':
    'The invited user is a new user and cannot apply for duplicate registration.',
  'billing.EarnBalance.balance.p2': 'New users generate payments.',
  'billing.EarnBalance.Share.Copy': 'Copy',
  'billing.EarnBalance.Share.Jump': 'Jump',
  'billing.EarnBalance.columns.title1': 'Invitee',
  'billing.EarnBalance.columns.title2': 'Rewards',
  'billing.EarnBalance.columns.title3': 'Registration time',
  'billing.EarnBalance.columns.title4': 'Update time',
  'billing.EarnBalance.status.p1': 'Active resources that last for 14 days',
  'billing.EarnBalance.status.p2': 'New users',
  'billing.EarnBalance.status.p3': 'Payment',
  'billing.EarnBalance.isBillAddress.text1':
    'In order to protect your rights for future consumption, please ',
  'billing.EarnBalance.isBillAddress.text2': 'complete the billing details',
  'billing.EarnBalance.isBillAddress.text3': ' before participating in the activity.',
  'billing.modal.NoticeSetting.text1': 'The consumption balance in the past 30 days:',
  'billing.modal.NoticeSetting.text2': 'If the balance is only ',
  'billing.modal.NoticeSetting.text3': ' left, we will send a warning email.',
  'billing.modal.NoticeSetting.tip': '(If you do not need a warning, please leave it blank.)',
};
