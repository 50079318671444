/**
 * 公共内容
 */
import { getLocale, FormattedMessage } from 'umi';
//处理不同语言的图片
export const imgRender = (p: any = {}) => {
  const locale = getLocale();
  return p[locale];
};
//跳过引导
export const closeStepRender = (props: any) => {
  const { onClick } = props;
  return (
    <p
      onClick={() => {
        onClick?.();
      }}
    >
      <FormattedMessage id="guide.pass" />
    </p>
  );
};
