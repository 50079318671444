import type { RunTimeLayoutConfig } from 'umi';
import { history } from 'umi';
// 组件
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import { SettingDrawer, PageLoading } from '@ant-design/pro-components';
import defaultSettings from '../config/defaultSettings';
import Layout from './layouts/BasicLayout';
import RightContent from '@/components/RightContent';
import { Result, Button } from 'antd';
// api
import { postCdnTrafficInfo_api } from '@/api/traffic';
import { getUserInfo_api } from '@/api/user';
import { getHasMonthPlan_api } from '@/api/monthPlan';
// import { getAccount_api } from '@/api/account';
// 方法
import { getLocalStorage, delObjKey } from '@/utils/utils';

const BasicLayout = Layout as any;
const loginPath = [
  '/user/login',
  '/user/register',
  '/user/forget',
  '/user/reset',
  '/user/updateAccount',
  '/user/accountDisabled',
];

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

// 获取流量信息
const getTrafficInfo = async () => {
  const token = getLocalStorage('cdnControl-token');
  if (token) {
    const res: any = await postCdnTrafficInfo_api();
    if (res.success) {
      return delObjKey(res, 'requestId');
    }
  }
  return {};
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  // currentAccount?: API.CurrentAccount;
  userAccess?: string[];
  trafficData?: any;
  hasMonthPlan?: boolean; //是否有月度计划，如果有，则不展示升级页面
  // accountUsable?: boolean; //
  loading?: boolean;
  collapsed?: boolean;
  resourceSuspend?: boolean; //是否有资源暂停
  sysSuspend?: boolean; //是否有系统暂停
  guideType?: string; //引导遮罩类型
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
  // fetchUserAccount?: () => Promise<API.CurrentAccount | undefined>;
  getTrafficInfo?: () => Promise<any>;
  getHasMonthPlan?: () => Promise<any>;
}> {
  //增加维护页，强制跳转
  // const _releaseStorage = sessionStorage.getItem('lightcdn_release');
  // if (!(_releaseStorage || location.search.indexOf('release=1') > -1)) {
  //   location.href = '/maintain/index.html';
  // }
  // if (location.search.indexOf('release=1') > -1) {
  //   sessionStorage.setItem('lightcdn_release', '1');
  // }
  // 获取用户信息
  const fetchUserInfo = async () => {
    const token = getLocalStorage('cdnControl-token');
    if (token) {
      const res = await getUserInfo_api();
      return res.data || {};
    } else {
      history.push(loginPath[0]);
    }
  };
  // 获取用户账户
  // const fetchUserAccount = async () => {
  //   const token = getLocalStorage('cdnControl-token');
  //   if (token) {
  //     const res = await getAccount_api();
  //     return res || {};
  //   }
  // };

  // 用户是否拥有月度计划
  const getHasMonthPlan = async () => {
    const token = getLocalStorage('cdnControl-token');
    if (token) {
      const res = await getHasMonthPlan_api();
      if (res.success) {
        return res.data || false;
      }
    }
    return false;
  };

  // 如果不是登录等页面，执行
  if (!loginPath.includes(history.location.pathname)) {
    const currentUser = await fetchUserInfo();
    // const currentAccount = await fetchUserAccount();
    const trafficData = await getTrafficInfo();
    const hasMonthPlan = await getHasMonthPlan();
    return {
      fetchUserInfo,
      // fetchUserAccount,
      getTrafficInfo,
      getHasMonthPlan,
      currentUser,
      // currentAccount,
      trafficData,
      hasMonthPlan,
      // accountUsable: true,
      settings: defaultSettings,
    };
  }
  return {
    fetchUserInfo,
    // fetchUserAccount,
    settings: defaultSettings,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }: any) => {
  // console.log('layout-initialState', initialState);
  return {
    rightContentRender: () => null,
    disableContentMargin: false,
    // waterMarkProps: {
    //   content: initialState?.currentUser?.username,
    // },
    headerContentRender: () => {
      return (
        <div className="headerBox">
          <RightContent />
        </div>
      );
    },
    onCollapse: (collapsed: boolean) => {
      setInitialState({
        ...initialState,
        collapsed,
      });
    },
    footerRender: () => null,
    onPageChange: async () => {
      const { location } = history;
      const token = getLocalStorage('cdnControl-token');
      // 如果没有登录，重定向到 login
      if (!token && !loginPath.includes(location.pathname)) {
        history.push(loginPath[0]);
        setInitialState({
          guideType: '',
        });
      }
      // 已登录，切换路由重新获取流量
      if (token && !loginPath.includes(location.pathname) && !location.search) {
        const trafficData = await getTrafficInfo();
        setInitialState({
          ...initialState,
          trafficData,
        });
      }
    },
    ...initialState?.settings,
    // collapsedButtonRender: false, //不显示伸缩按钮
    breadcrumbRender: false,
    // siderWidth: !initialState.accountUsable ? 0 : 260,
    siderWidth: 260,
    headerHeight: 50,
    // layout: 'mix',
    // splitMenus: true,
    loading: false,
    unAccessible: (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => history.push('/')}>
            Back Home
          </Button>
        }
      />
    ),
    // pageTitleRender: false,
    childrenRender: (dom: any, props: any) => {
      return (
        <>
          <BasicLayout>{dom}</BasicLayout>
          {/* 不能删除SettingDrawer，删除后刷新会导致原来覆盖部分主题颜色的样式失效，已隐藏SettingDrawer这个组件 */}
          {!props.location?.pathname?.includes('/login') && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                if (typeof setInitialState === 'function') {
                  setInitialState((preInitialState: any) => ({
                    ...preInitialState,
                    settings,
                  }));
                }
              }}
            />
          )}
        </>
      );
    },
  };
};

// 国际化
export const locale = {
  getLocale() {
    if (window.location?.search) {
      // 跳转语言设置 ie 11不支持断言 只能这样不够精确
      const reg = new RegExp('zh-CN|zh-HK|en-US');
      const match = window.location.search.match(reg);
      if (match?.[0]) {
        localStorage.setItem('umi_locale', match?.[0]);
        return match[0];
      }
    }
    const lang = localStorage.getItem('umi_locale');
    // 初始化设置
    if (!lang) {
      localStorage.setItem('umi_locale', 'en-US');
    }

    return lang || 'en-US';
  },
  // 设置语言时子应用页面会刷新且重新挂载
  setLocale({ lang, updater }: any) {
    updater();
    localStorage.setItem('umi_locale', lang);
  },
};
