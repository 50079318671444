/**
 * 首页 - 开屏弹窗
 */
import { useEffect, useState } from 'react';
import { useModel, history, getLocale } from 'umi';
import { useFomatter } from '@/hooks';
import { useRequest } from 'ahooks';
import { ArrowRightOutlined } from '@ant-design/icons';
import ProModal from '@/pages/common/modal';
import { Button, Carousel } from 'antd';
import { getSystemNotice_api } from '@/api/notice';
import { NODE_NEW, VERSION_NEW, notifyGroupFilter } from '@/enumeration/notifyEnum';
import { getLocalStorage, setLocalStorage, renderTypeName } from '@/utils/utils';
import moment from 'moment';
import noAccessImg from '@/assets/images/no-access-img.png';
import iconBanbenImg from '@/assets/images/icon/icon-banben.png';
import iconJiedianImg from '@/assets/images/icon/icon-jiedian.png';
import iconQitaImg from '@/assets/images/icon/icon-qita.png';
import styles from '../index.less';

const _origin =
  process.env.UMI_ENV === 'prod' ? `https://www.lightcdn.com` : `http://website.lightcdn.kpy.cloud`;
const MsgModal: React.FC<any> = () => {
  const { initialState } = useModel('@@initialState');
  const { trafficData } = initialState || {};
  const { formatter } = useFomatter();
  const locale = getLocale();
  const [msgVisible, setMsgVisible] = useState(false);
  //查询开屏弹窗消息
  const { run: getSystemNotice, data: systemNoticeData } = useRequest(getSystemNotice_api, {
    manual: true,
    initialData: [],
    formatResult: (res: any) => res?.items || [],
    onSuccess: (res: any) => {
      if (res.length) {
        //一天只弹出一次
        setMsgVisible(true);
        setLocalStorage('CDN_systemInform', moment().format('YYYY-MM-DD'));
      }
    },
  });
  useEffect(() => {
    const systemInform = getLocalStorage('CDN_systemInform');
    if (!systemInform || !moment(systemInform).isSame(moment(), 'day')) {
      //没有缓存或者不是同一天
      getSystemNotice({
        stationCode: 'POP_UP',
        page: 1,
        pageSize: 5,
      });
    }
  }, []);
  //计划过期
  const isExpired =
    trafficData?.monthPlanInfo?.hasService &&
    moment(trafficData?.monthPlanInfo?.serviceEndTime).isBefore(moment());
  //余额<=0
  const isBalance = trafficData?.accountVo?.balance < 0;
  return (
    <ProModal
      visible={msgVisible}
      content={
        <div className={styles.MsgModal}>
          {/* 1、月度计划过期，2、余额<0 */}
          {isExpired ? (
            <div className="MsgModal-left">
              <img src={noAccessImg} />
              <b>{formatter({ id: 'menu.notifications.planExpired.title' })}</b>
              <p>{formatter({ id: 'menu.notifications.planExpired.text' })}</p>
              <div className="f-tc">
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    setMsgVisible(false);
                    history.push('/billing?type=UPGRADE');
                  }}
                  style={{ width: 160 }}
                >
                  {formatter({ id: 'menu.notifications.planExpired.btn' })}
                </Button>
              </div>
            </div>
          ) : isBalance ? (
            <div className="MsgModal-left">
              <img src={noAccessImg} />
              <b>{formatter({ id: 'menu.notifications.balance.title' })}</b>
              <p>{formatter({ id: 'menu.notifications.balance.text' })}</p>
              <div className="f-tc">
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    setMsgVisible(false);
                    history.push('/billing?modalVal=recharge');
                  }}
                  style={{ width: 160 }}
                >
                  {formatter({ id: 'menu.notifications.balance.btn' })}
                </Button>
              </div>
            </div>
          ) : null}
          <div className="MsgModal-right">
            {!isExpired && !isBalance ? <img src={noAccessImg} /> : null}
            <Carousel autoplay autoplaySpeed={5000} dotPosition="top">
              {systemNoticeData.map((m: any) => {
                let _img = iconQitaImg;
                if (m.noticeGroups === NODE_NEW) {
                  _img = iconJiedianImg;
                } else if (m.noticeGroups === VERSION_NEW) {
                  _img = iconBanbenImg;
                }
                const _type = renderTypeName(notifyGroupFilter, m.noticeGroups, locale);
                return (
                  <div key={m.noticeUUID}>
                    <div className="u-type">
                      <img src={_img} />
                      <b>{_type}</b>
                    </div>
                    <div className="u-title">
                      <b>{m.title || ''}</b>
                    </div>
                    <div className="u-title">
                      {m.createTime ? moment(m.createTime).format('YYYY-MM-DD') : ''}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: m.content,
                      }}
                      className="u-content"
                    ></div>
                    <div
                      className={!isExpired && !isBalance ? 'u-more f-tr' : 'u-more f-tc'}
                      onClick={() => {
                        window.open(`${_origin}/${locale}/notify/${m.noticeUUID}`, '_blank');
                      }}
                    >
                      {formatter({ id: 'menu.notifications.ViewMore' })} <ArrowRightOutlined />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      }
      width={!isExpired && !isBalance ? 700 : 800}
      footer={null}
      cancelHandle={(b: boolean) => {
        console.log('bbb=', b);
        setMsgVisible(false);
      }}
    />
  );
};
export default MsgModal;
