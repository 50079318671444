export default {
  'traffic.topTitle': '流量报告',
  'traffic.name': '流量',
  'traffic.topTitle.text': '（请按照 utc+0 的时区查看数据。）',
  'traffic.content.title': '缓存/非缓存数据',
  'traffic.content.tip': '正常缓存率高于90%。如果您的命中率太低，您可以参考',
  'traffic.content.tip1': '文档',
  'traffic.content.tip2': '进行排查；若依旧有问题请联系客服或发送邮件，我们将很高兴为你服务。',
  'traffic.menu.text1': '边缘位置',
  'traffic.menu.text2': '大洲',
  'traffic.menu.text3': 'CDN资源',
  'traffic.menu.text4': '访问者分析',

  'traffic.chart.text1': '缓存',
  'traffic.chart.text2': '非缓存',
  'traffic.oneColumns.title1': '边缘位置',
  'traffic.oneColumns.title2': '总计流量',
  'traffic.twoColumns.title1': '大洲',
  'traffic.threeColumns.title1': 'CDN资源',
  'traffic.threeColumns.title2': '错误请求',
  'traffic.threeColumns.title3': '过去一小时',
  'traffic.threeColumns.title4': '这个小时',
  'traffic.getCdnList.all': '全部',
  'traffic.getCdnList.showAll': '全部显示',
  'traffic.chartName': '流量',
  'traffic.total': '总计',
  'traffic.chart.cached': '缓存',
  'traffic.chart.nonCached': '非缓存',

  'traffic.fourColumns.h3': '流量最高的国家/地区',
  'traffic.fourColumns.title1': '国家/地区',
  'traffic.fourColumns.title2': '总流量',
  'traffic.fourColumns.title3': '平均延时',
  'traffic.fourColumns.title4': '平均传输速度',
  'traffic.fourColumns.title5': '请求',
  'traffic.Analytics.loading': '加载中...',
  'traffic.Analytics.unkown': '未知',
};
