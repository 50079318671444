/**
 * 消息
 */
import { useEffect } from 'react';
import { useFomatter } from '@/hooks';
import { useRequest } from 'ahooks';
import { getLocale } from 'umi';
import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, Popover } from 'antd';
import MsgModal from './MsgModal';
import { getNoticeList_api, getNoticeRead_api } from '@/api/notice';
import { NODE_NEW, VERSION_NEW } from '@/enumeration/notifyEnum';
import moment from 'moment';
import styles from '../index.less';
import iconBanbenImg from '@/assets/images/icon/icon-banben.png';
import iconJiedianImg from '@/assets/images/icon/icon-jiedian.png';
import iconQitaImg from '@/assets/images/icon/icon-qita.png';
const _origin =
  process.env.UMI_ENV === 'prod' ? `https://www.lightcdn.com` : `http://website.lightcdn.kpy.cloud`;
const regExp = /<[^>]+>/g; //去除富文本标签正则
const Notify = () => {
  const { formatter } = useFomatter();
  const locale = getLocale();
  //查询消息列表
  const { run: getNoticeList, data: noticeData } = useRequest(getNoticeList_api, {
    manual: true,
    initialData: [],
    formatResult: (res: any) => res?.items || [],
  });
  //标记已读
  const { run: getNoticeRead, loading: readLoading } = useRequest(getNoticeRead_api, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success) {
        getNoticeList();
      }
    },
  });
  useEffect(() => {
    getNoticeList();
  }, []);
  /**
   * 展示规则：
   * 1.展示所有未读，固定高度，可滑动查看
   * 2.所有都已读，展示最新三条
   */
  // 未读的
  const _readNoData = noticeData.filter((f: any) => {
    return !f.read;
  });
  // 已读的
  const _readYesData = noticeData.filter((f: any) => {
    return f.read;
  });
  let _listData = [];
  if (!_readNoData.length) {
    _listData = _readYesData.filter((f: any, i: number) => {
      return i < 3;
    });
  } else {
    _listData = _readNoData;
  }
  //跳转消息页面，有uuid即代表详情，无即列表
  const handleToNotify = (uuid?: string) => {
    if (uuid) {
      window.open(`${_origin}/${locale}/notify/${uuid}`, '_blank');
    } else {
      window.open(`${_origin}/${locale}/notify`, '_blank');
      if (_readNoData.length) {
        getNoticeRead({ markAll: true });
      }
    }
  };
  const content = (
    <div className={styles.NotifyPopoverContent}>
      <div className="u-listBox">
        {_listData.map((m: any) => {
          let _img = iconQitaImg;
          if (m.noticeGroups === NODE_NEW) {
            _img = iconJiedianImg;
          } else if (m.noticeGroups === VERSION_NEW) {
            _img = iconBanbenImg;
          }
          return (
            <div
              className="NotifyPopoverContent-list"
              key={m.noticeUUID}
              onClick={() => {
                handleToNotify(m.noticeUUID);
                if (!m.read) {
                  getNoticeRead({ noticeUUID: m.noticeUUID });
                }
              }}
            >
              <div className="u-left">
                <div className="left-top">
                  <Badge dot={!m.read}>
                    <img src={_img} />
                  </Badge>
                  <a>{m.title}</a>
                  <label className="u-date">{moment(m.createTime).format('YYYY-MM-DD')}</label>
                </div>
                <div className="left-bot">{m.content.replace(regExp, '')}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="u-btn">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            handleToNotify();
          }}
        >
          {formatter({ id: 'menu.notifications.readAll' })}
        </Button>
      </div>
    </div>
  );
  return (
    <div className={styles.headerNotify}>
      <Popover
        content={content}
        title={
          <div className={styles.NotifyPopoverTitle}>
            <b>{formatter({ id: 'menu.notifications.title' })}</b>
            {_readNoData.length ? (
              <span
                onClick={() => {
                  if (readLoading) return;
                  getNoticeRead({ markAll: true });
                }}
              >
                {formatter({ id: 'menu.notifications.markedRead' })}
              </span>
            ) : null}
          </div>
        }
        placement="bottomLeft"
      >
        <div className={styles.NotifyTop}>
          <Badge count={_readNoData.length}>
            <BellOutlined />
          </Badge>
          {noticeData.length ? (
            <div
              className="notify-text"
              // onClick={() => {
              //   handleToNotify(noticeData[0].noticeUUID);
              //   if (!noticeData[0].read) {
              //     getNoticeRead({ noticeUUID: noticeData[0].noticeUUID });
              //   }
              // }}
            >
              {noticeData[0].title}
            </div>
          ) : null}
        </div>
      </Popover>
      {/* 开屏弹窗 */}
      <MsgModal />
    </div>
  );
};
export default Notify;
