export default {
  'accountSettings.title': 'Account Settings',
  'accountSettings.tabTitle1': 'General',
  'accountSettings.tabTitle2': 'TeamMembers',
  'accountSettings.tabTitle3': '2-factor Authentication',
  'accountSettings.general.factor': '2-factor authentication',
  'accountSettings.general.handleSave.message': 'Modified successfully',
  'accountSettings.general.validatorPhone.rule': 'Use 6-11 characters',
  'accountSettings.general.validatorPhone.message': 'Please input correct phone',
  'accountSettings.general.title': 'Account infomation',
  'accountSettings.general.Edit': 'Edit',
  'accountSettings.general.Cancel': 'Cancel',
  'accountSettings.general.Save': 'Save changes',
  'accountSettings.general.Form.email.title': 'Email address',
  'accountSettings.general.Form.email.message': 'Please input your Email Address!',
  'accountSettings.general.change': 'change',
  'accountSettings.general.Form.fullname.title': 'Full name',
  'accountSettings.general.Form.fullname.message': 'Please input your Full Name!',
  'accountSettings.general.Form.phone.title': 'Phone',
  'accountSettings.general.Form.country.message': 'Please select your Country!',
  'accountSettings.general.Form.phone.message': 'Please input your Phone!',
  'accountSettings.general.Form.companyName.title1': 'Company name',
  'accountSettings.general.Form.companyName.title2': '（optional）',
  'accountSettings.general.Form.password.title': 'Password',
  'accountSettings.general.Form.password.message': 'Please input your password!',
  'accountSettings.general.Form.rePassword.title': 'Re-type password',
  'accountSettings.general.ChangePassword': 'Change password',
  'accountSettings.general.handleChangeEmail.message': 'Email sent successfully',
  'accountSettings.general.validatorPassword.message': 'Incorrect password',
  'accountSettings.general.validatorEmail.rule': 'Email Address already exists',
  'accountSettings.general.validatorEmail.message': 'Please input correct Email Address',
  'accountSettings.general.Modal.title1': 'Change email address',
  'accountSettings.general.Modal.okText': 'Change email address',
  'accountSettings.general.Modal.p0': 'Please enter the password to verify your identity.',
  'accountSettings.general.Modal.p1':
    'If the email address is changed, the account will also be changed. Please enter the password to verify your identity.',
  'accountSettings.general.Modal.title2': 'Change email address',
  'accountSettings.general.Modal.p2':
    'The authentication is successful. We will send a confirmation email to the new email address. Please click the email link to activate it.',
  'accountSettings.general.Modal.email.title': 'New email address',
  'accountSettings.general.validatorRePassword.message': 'Passwords are inconsistent',
  'accountSettings.FactorAuth.enableText': 'Enable 2-factor authentication',
  'accountSettings.FactorAuth.disableText': 'Disable 2-factor authentication',
  'accountSettings.FactorAuth.disableTip1': 'Are you sure to disable the 2-step authentication? ',
  'accountSettings.FactorAuth.disableTip2': 'Please complete the following steps to close.',
  'accountSettings.FactorAuth.Status': 'Status',
  'accountSettings.FactorAuth.Status.text':
    'Two factor authentication is a security mechanism designed to prevent others from accessing or using your account, even if they know your password. It requires users to verify their identity using a certain device or other recognized method. This mechanism improves account security by adding an additional verification step.',
  'accountSettings.APIKey.text1': 'This is how you can use ',
  'accountSettings.APIKey.text2': ' to securely update your LightCDN resources.',
  'accountSettings.APIKey.Reset.title': 'Reset API access key',
  'accountSettings.APIKey.Reset.text':
    'Please confirm if you want to reset your API access key. Once confirmed, the old key will become invalid and a new one will be generated. Are you sure to reset?',
  'accountSettings.FactorAuth.enable.step1.1':
    'Download the Google Authenticator app on your phone（',
  'accountSettings.FactorAuth.enable.step1.Iphone': 'Iphone',
  'accountSettings.FactorAuth.enable.step1.Android': 'Android',
  'accountSettings.FactorAuth.enable.step1.2': '）and use it to scan the following QR code.',
  'accountSettings.FactorAuth.enable.step1.p1': "Can't scan the code?",
  'accountSettings.FactorAuth.enable.step1.p2':
    'You can manually fill in the following key to the application on your phone.',
  'accountSettings.FactorAuth.enable.step2':
    'Verify the configuration by filling in the 6 digit code from your authentication app.',
  'accountSettings.FactorAuth.enable.step3':
    'Please save these codes. In case of any issues with your phone, you can use a backup verification code to help reset Google Authenticator.',
  'accountSettings.FactorAuth.enable.success': 'Open success',
  'accountSettings.FactorAuth.disable.success': 'Close success',
};
