export const VERSION_NEW = 'VERSION_NEW';
export const NODE_NEW = 'NODE_NEW';
export const OTHER = 'OTHER';
//-----
export const LIST = 'LIST';
export const CLIENT_HEADER = 'CLIENT_HEADER';
export const LOGIN = 'LOGIN';
export const MAIN_HEADER = 'MAIN_HEADER';

//内容分组
export const notifyGroupFilter = [
  {
    text: 'New pop',
    value: VERSION_NEW,
    'zh-CN': '版本更新',
    'zh-HK': '版本更新',
  },
  {
    text: 'New version',
    value: NODE_NEW,
    'zh-CN': '节点更新',
    'zh-HK': '節點更新',
  },
  {
    text: 'Or other communication',
    value: OTHER,
    'zh-CN': '其他',
    'zh-HK': '其他',
  },
];
//消息位置
export const notifyLocationFilter = [
  {
    text: '通知列表',
    value: LIST,
    disabled: true,
  },
  {
    text: '客户端头部',
    value: CLIENT_HEADER,
    disabled: true,
  },
  {
    text: '登录页',
    value: LOGIN,
  },
  {
    text: '官网头部',
    value: MAIN_HEADER,
  },
];
