export default {
  'cdnResources.list.deleteCdn.message': '刪除成功',
  'cdnResources.list.columns.Tag.suspend': '暫停',
  'cdnResources.list.Popconfirm.title': '您確定刪除該CDN資源嗎？',
  'cdnResources.list.Popconfirm.okText': '是的',
  'cdnResources.list.Popconfirm.cancelText': '不，取消',
  'cdnResources.list.title': 'CDN資源',
  'cdnResources.list.Create': '創建新的CDN資源',
  //v1.2.0
  'cdnResources.list.ConfirmAndCreate': '確認並創建',
  'cdnResources.list.Create.Disabled': '已達到上限',
  'cdnResources.list.Empty.btnText': '開始加速',
  'cdnResources.list.Empty.desc': '3分鐘創建資源，開啟加速',
  'cdnResources.list.columns.title1': 'CDN加速域名',
  'cdnResources.list.columns.title2': 'CNAME',
  'cdnResources.list.columns.title3': '來源',
  'cdnResources.list.columns.title4': '操作選項',
  //v1.2.0
  'cdnResources.list.columns.title5': '檔位',
  'cdnResources.list.columns.title6': '本月用量',
  'cdnResources.list.PopoverText1': '數據詳情',
  'cdnResources.list.PopoverText2': '設置',
  'cdnResources.list.PopoverText3': '刪除資源',
  'cdnResources.list.PopoverText4': '清除所有緩存',

  'cdnResources.create.step1.fristFree': '前{val}免費',

  'cdnResources.create.stepBox.step1': '基礎設置',
  'cdnResources.create.stepBox.step2': '完成',
  'cdnResources.create.onSubmit.message': '創建成功',
  'cdnResources.create.step1.validatorCdnHostName.same': 'CDN加速域名和源是一樣的',
  'cdnResources.create.step1.Form.cdnHostName.name': 'CDN加速域名',
  'cdnResources.create.step1.Form.cdnHostName.message': '請輸入您的CDN加速域名！',
  'cdnResources.create.step1.Form.origin.name': '來源',
  'cdnResources.create.step1.Form.origin.message': '請輸入源！',
  'cdnResources.create.step1.Form.Checkbox.name': '使用自定義端口',
  'cdnResources.create.step1.Form.port.name': '端口（非必填）',
  'cdnResources.create.step1.Form.port.message': '請輸入端口號！',
  'cdnResources.create.step2.message': '創建成功',
  'cdnResources.create.step2.title1': 'CDN資源已成功創建，CNAME將在',
  'cdnResources.create.step2.title2': '秒內生成',
  'cdnResources.create.step1.tip1':
    '您要加速的網址通常是對用戶開放的公開顯示地址。 完成後，您可以使用我們的 CNAME 或您的顯示地址來加速您的資源。',
  'cdnResources.create.step1.tip2': '在此處填寫web內容源的地址，可以是URL或IP。',
  'cdnResources.create.step1.tip3': '(註意URL格式：{domain1}、 {domain2}，不支持{domain3}）',
  'cdnResources.create.step1.tip3.0': '註意URL格式：',
  'cdnResources.create.step1.tip4': '來源政策為AUTO時，不支持填寫端口。',
  'cdnResources.create.step1.help1': '如果你需要更多幫助，你可以 ',
  'cdnResources.create.step1.help2': '點擊這裏。',
  'cdnResources.create.step1.origin.note1': '請註意：',
  'cdnResources.create.step1.origin.note2': '多源僅能以IP的格式填寫。',
  'cdnResources.create.step1.origin.message': '超過一條源時，僅可使用IP地址。',
  'cdnResources.create.step1.origin.validatorMessage1': '請填寫有效的源。',
  'cdnResources.create.step1.origin.validatorMessage2': '請填寫有效的加速域名。',
  'cdnResources.create.step1.origin.validatorMessage3': '這個IP地址已存在。',
  'cdnResources.create.step1.origin.validatorMessage4': '請填寫有效的IP地址。',
  'cdnResources.create.step1.origin.validatorMessage5': '多源时，端口号必须一致。',
  'cdnResources.create.step1.origin.unique': '源已經存在',
  'cdnResources.create.step1.hostname.unique': 'CDN加速域名已存在。',
  //v1.2.0
  'cdnResources.create.StandardNetwork': '標準網絡',
  'cdnResources.create.CoreNetwork': '核心網絡',
  'cdnResources.create.noPlan.modal.text': '余額不足&沒有計劃',
  'cdnResources.create.noPlan.modal.p': '您還有免費流量可以兌換！',
  'cdnResources.create.noPlan.modal.button': '獲取方案',
  'cdnResources.create.No.additional': '無額外收費。',
  'cdnResources.detail.tabKey4.tier.Current': '當前檔位',
  'cdnResources.detail.tabKey4.tier.edit': '更換檔位',
  'cdnResources.detail.tabKey4.tier.Usage': '本月用量',
  'cdnResources.detail.tabKey4.tier.Unitprice': '單價',
  'cdnResources.detail.tabKey4.EnableEdgeLocation': '開啟邊緣位置',
  'cdnResources.detail.tabKey4.tier.Modal.p1': '您當前沒有此邊緣位置的權限。請切換層，然後重試。',
  'cdnResources.detail.tabKey4.tier.Recommended': '推薦檔位：',
  'cdnResources.create.NetworkList.moreFavorable': '點擊此處獲取更優惠的價格',
  'cdnResources.create.title.resources': '創建您的CDN資源',
  'cdnResources.create.title.tier': '選擇您的檔位',
  'cdnResources.create.tier.loading': '正在為你處理資源，請等待...',
  'cdnResources.create.NetworkList.tierRecharge.p1': '以下檔位需要確保余額充足，',
  'cdnResources.create.NetworkList.tierRecharge.p2': '请前往充值',
  'cdnResources.create.NetworkList.planLearn.p1':
    '您當前有一個月度計劃，該計劃將自動激活相應的檔位。',
  'cdnResources.create.NetworkList.planLearn.p2': '了解你的月度計劃',
  'cdnResources.create.postpaid.price.p':
    '如果半小時的流量極小，我們將扣除0.000001美元的最低金額。如果超過此金額，我們將根據正常計費扣除。',
  'cdnResources.detail.NoPermission': '無權限',
  'cdnResources.detail.SuggestedTier': '建議檔位：',

  'cdnResources.detail.title': 'CDN資源',
  'cdnResources.detail.tabKey1': '基礎設置',
  'cdnResources.detail.tabKey2': '高級設置',
  'cdnResources.detail.tabKey3': '加速位置',
  'cdnResources.detail.tabKey4': '清除',
  'cdnResources.detail.tabKey5': '預存',
  'cdnResources.detail.tabKey6': '激活配置',
  'cdnResources.detail.tabKey7': '安全保护',
  'cdnResources.detail.tabKey8': '規則集',
  'cdnResources.detail.tabKey1.onSuccess.message': '更新成功',
  'cdnResources.detail.tabKey1.title': '基礎信息',
  'cdnResources.detail.tabKey1.cdnHostName.name': 'CDN加速域名',
  'cdnResources.detail.tabKey1.cdnHostName.message': '請輸入',
  'cdnResources.detail.tabKey1.cdnHostName.placeholder': '每個CDN加速域名都需要填寫一行，最多七行',
  'cdnResources.detail.tabKey1.cdnHostName.validatorMessage': '請輸入有效的主機名。',
  // 'cdnResources.detail.tabKey1.origin.name': 'Origin',
  'cdnResources.detail.tabKey1.port.name': '端口',
  'cdnResources.detail.tabKey1.select.message': '請選擇',
  'cdnResources.detail.tabKey1.Button': '新增',
  'cdnResources.detail.tabKey1.ssl.title': 'SSL',
  'cdnResources.detail.tabKey1.ssl.Tag1': '已啟用',
  'cdnResources.detail.tabKey1.ssl.Tag2': '未啟用',
  'cdnResources.detail.tabKey1.ssl.tip':
    '加速域名必須具有SSL證書，才能通過HTTPS訪問。您可以打開SSL證書來配置域名。',
  'cdnResources.detail.tabKey1.ssl.tip1': '若你剛配置完CDN，發現你的站點',
  'cdnResources.detail.tabKey1.ssl.tip2': '無法安全打開',
  'cdnResources.detail.tabKey1.ssl.tip3': '，說明你需要在我們平臺上進行SSL證書的配置。',
  'cdnResources.detail.tabKey1.ssl.group.label1': "Let's Encrypt",
  'cdnResources.detail.tabKey1.ssl.group.p1':
    "請輸入完成的主機名，並確保它不以'.r.cdn36.com'結尾，不然他將無法自動開啟SSL證書。",
  'cdnResources.detail.tabKey1.ssl.group.label2': '自義定 SNI SSL',
  'cdnResources.detail.tabKey1.ssl.group.p2':
    '若開啟輔助加速域名，自定義SNI證書將同時作用於加速域和輔助加速域。',
  'cdnResources.detail.tabKey1.HostName.title': '輔助加速域名',
  'cdnResources.detail.tabKey1.HostName.modal.title': '保存',
  'cdnResources.detail.tabKey1.HostName.modal.text1':
    "如果你開啟了輔助加速域名，我們將自動為你切換或開啟Let's Encrypt SSL證書。",
  'cdnResources.detail.tabKey1.HostName.modal.text2': '請確認是否需要開啟輔助加速域名？',
  'cdnResources.detail.tabKey1.HostName.message1': '可以填寫的輔助CDN加速域名已超過上限！',
  'cdnResources.detail.tabKey1.HostName.message2': '不能重復或跟CDN加速域名相同。',
  'cdnResources.detail.tabKey1.HostName.message3': '填寫的域名不可跟加速域相同。',
  'cdnResources.detail.tabKey1.HostName.message4': '不可重復或與CDN加速域名和輔助CDN加速域名相同。',
  'cdnResources.detail.tabKey1.HostName.tip1':
    'CDN輔助域支持添加多個加速域名，用戶可以利用並行下載來加快頁面加載速度。您需要為CDN主機名和輔助CDN主機名稱設置CNAME。更多詳情',
  'cdnResources.detail.tabKey1.HostName.tip2': '查看文檔',
  'cdnResources.detail.tabKey2.tip.name': '如何繞過 LightCDN 的邊緣服務器直接訪問源呢？',
  'cdnResources.detail.tabKey2.tip.pop': '點擊進入引導',
  'cdnResources.detail.tabKey2.tip.content':
    '你可以嘗試為特定的文件將具有 Cache-Control 標頭的CDN資源的緩存規則添加為「no-cache」或者「max-age = 0」。同時，您可以將Force Edge設置為從不緩存，這將強製CDN邊緣服務器在緩存規則中從不緩存請求。',
  'cdnResources.detail.tabKey2.HLS_force_cache.name': 'HLS 強製緩存',
  'cdnResources.detail.tabKey2.HLS_force_cache.p1':
    '強製m3u8文件邊緣服務器不緩存並支持設置過期時間。 該設置僅在啟用',
  'cdnResources.detail.tabKey2.HLS_force_cache.p2': '「HLS優化」',
  'cdnResources.detail.tabKey2.HLS_force_cache.p3': '時生效。',
  'cdnResources.detail.tabKey2.title': '緩存過期時間',
  'cdnResources.detail.tabKey2.Modal.title': '添加SSL證書',
  'cdnResources.detail.tabKey2.cacheExpiry.name': '緩存有效期',
  'cdnResources.detail.tabKey2.cacheExpiry.message': '請輸入緩存有效期！',
  'cdnResources.detail.tabKey2.cacheExpiry.fieldProps': '分鐘 (默認12天)',
  'cdnResources.detail.tabKey2.cacheExpiry.default': '默認',
  'cdnResources.detail.tabKey2.cacheExpiry404.name': '404狀態碼的緩存有效期',
  'cdnResources.detail.tabKey2.cacheExpiry404.fieldProps': '1分鐘 (默認)',
  'cdnResources.detail.tabKey2.rules.title': '配置',
  'cdnResources.detail.tabKey2.originPolicy.name': '來源政策',
  'cdnResources.detail.tabKey2.originPolicy.label1': '自動',
  'cdnResources.detail.tabKey2.originPolicy.label2': 'HTTP',
  'cdnResources.detail.tabKey2.originPolicy.label3': 'HTTPS',
  'cdnResources.detail.tabKey2.originPolicy.tip1':
    'LightCDN的來源政策選項允許您設置從CDN邊緣服務器到源服務器的拉取請求的協議。你可以',
  'cdnResources.detail.tabKey2.originPolicy.tip2': '查看文檔',
  'cdnResources.detail.tabKey2.originPolicy.tip3': '來了解更多。',
  'cdnResources.detail.tabKey2.originPolicy.p1':
    'HTTP—邊緣服務器和始終使用HTTP的源之間的連接。它是默認值。',
  'cdnResources.detail.tabKey2.originPolicy.p2': 'HTTPS—邊緣服務器和始終使用HTTPS的源之間的連接。',
  'cdnResources.detail.tabKey2.originPolicy.p3':
    'AUTO—基於訪問者請求（HTTP或HTTPS）的邊緣服務器和源服務器之間的連接。但請註意，當您的源已填寫端口時，無法選擇AUTO。',
  'cdnResources.detail.tabKey2.corsOn.name': 'CORS 標頭',
  'cdnResources.detail.tabKey2.corsOn.p1':
    '啟用後，通過添加具有訪問控製允許源的HTTP標頭來啟用跨域資源共享（CORS）。',
  'cdnResources.detail.tabKey2.corsOn.p2':
    "若開啟後，訪問加速域名報錯，「No 'Access-Control-Allow-Origin' header is present on the requested resource.」 ，可",
  'cdnResources.detail.tabKey2.corsOn.p3': '查看文檔',
  'cdnResources.detail.tabKey2.corsOn.p4': '看具體解決方式。',
  'cdnResources.detail.tabKey2.hostHeaderForwarding.name': '主機標頭轉發',
  'cdnResources.detail.tabKey2.hostHeaderForwarding.p1': `CDN邊緣服務器僅在邊緣服務器中不存在文件（緩存）時（對於所有場景）才返回原始位置，並且在從CDN邊緣-->原始位置的路徑中會出現“passthrough http host header to origin”功能。此功能不是“使所有請求返回到源”，而是“將主機標頭信息發送到源”。`,
  'cdnResources.detail.tabKey2.IgnoreSetCookie.name': '緩存 Set-Cookie',
  // 'cdnResources.detail.tabKey2.IgnoreSetCookie.p1':
  //   'CDN為會話跟蹤設置cookie。我們的CDN不緩存帶有Set-Cookie響應標頭的任何內容，包括JPEG文件等靜態內容。如果因此導致緩存命中率為0%，請啟用此設置，以便緩存此類響應。',
  'cdnResources.detail.tabKey2.IgnoreSetCookie.p1':
    '我們的CDN不緩存帶有Set-Cookie響應標頭的任何內容，包括JPEG等靜態內容，如果因此導致緩存命中率0%，請啟用此設置，一邊緩存此類響應。緩存Set-Cookie的相關內容可以參考',
  'cdnResources.detail.tabKey2.IgnoreSetCookie.p2': '此處文檔。',
  'cdnResources.detail.tabKey2.HTTPSRedirect.name': 'HTTPS重定向',
  'cdnResources.detail.tabKey2.HTTPSRedirect.p1':
    '返回從http地址到https地址的301重定向響應，而不是從緩存或上遊加載。',
  'cdnResources.detail.tabKey2.StreamingMedia.name': '流媒體',
  'cdnResources.detail.tabKey2.StreamingMedia.MP4': 'MP4偽流',
  'cdnResources.detail.tabKey2.StreamingMedia.FLV': 'FLV偽流媒體',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS': 'HLS優化',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS.p1': '如果您想創建強製緩存過期的規則，可以在',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS.p2': '頁面的緩存規則部分快速啟用。',

  'cdnResources.detail.tabKey3.header1': '亞太地區',
  'cdnResources.detail.tabKey3.header2': '中東',
  'cdnResources.detail.tabKey3.header3': '歐洲',
  'cdnResources.detail.tabKey3.header4': '北美地區',
  'cdnResources.detail.tabKey3.header5': '南美地區',
  'cdnResources.detail.tabKey3.header6': '非洲地區',
  'cdnResources.detail.tabKey3.text1': '矽谷',
  'cdnResources.detail.tabKey3.text2': '邁阿密',
  'cdnResources.detail.tabKey3.text3': '華盛頓',
  'cdnResources.detail.tabKey3.text4': '聖保羅',
  'cdnResources.detail.tabKey3.text5': '法蘭克福',
  'cdnResources.detail.tabKey3.text6': '迪拜',
  'cdnResources.detail.tabKey3.text7': '新加坡',
  'cdnResources.detail.tabKey3.text8': '胡誌明市',
  'cdnResources.detail.tabKey3.text9': '香港',
  'cdnResources.detail.tabKey3.text10': '東京',
  'cdnResources.detail.tabKey3.text11': '利雅得',
  'cdnResources.detail.tabKey3.text12': '開羅',
  'cdnResources.detail.tabKey3.text13': '倫敦',
  'cdnResources.detail.tabKey3.text14': '馬賽',
  'cdnResources.detail.tabKey3.text15': '曼谷',
  'cdnResources.detail.tabKey3.text16': '馬尼拉',
  'cdnResources.detail.tabKey3.text17': '河內',
  'cdnResources.detail.tabKey3.text18': '金邊',
  'cdnResources.detail.tabKey3.text19': '馬斯喀特',
  'cdnResources.detail.tabKey3.text20': '約翰內斯堡',
  'cdnResources.detail.tabKey3.text21': '巴林',
  'cdnResources.detail.tabKey3.Panel1': '亞太地區',
  'cdnResources.detail.tabKey3.Panel1.p1': '香港，中國',
  'cdnResources.detail.tabKey3.Panel1.p2': '東京，日本',
  'cdnResources.detail.tabKey3.Panel1.p3': '新加坡',
  'cdnResources.detail.tabKey3.Panel1.p4': '曼谷，泰國',
  'cdnResources.detail.tabKey3.Panel1.p5': '馬尼拉，菲律賓',
  'cdnResources.detail.tabKey3.Panel1.p6': '河內，越南',
  'cdnResources.detail.tabKey3.Panel1.p7': '金邊，柬埔寨',
  'cdnResources.detail.tabKey3.Panel2': '中東地區',
  'cdnResources.detail.tabKey3.Panel2.p1': '迪拜，阿拉伯聯合酋長國',
  'cdnResources.detail.tabKey3.Panel2.p2': '利雅得，沙特阿拉伯',
  'cdnResources.detail.tabKey3.Panel2.p3': '馬斯喀特，阿曼',
  'cdnResources.detail.tabKey3.Panel2.p4': '巴林',
  'cdnResources.detail.tabKey3.Panel3': '歐洲',
  'cdnResources.detail.tabKey3.Panel3.p1': '法蘭克福，德國',
  'cdnResources.detail.tabKey3.Panel3.p2': '倫敦，英國',
  'cdnResources.detail.tabKey3.Panel3.p3': '馬賽，法國',
  'cdnResources.detail.tabKey3.Panel4': '北美',
  'cdnResources.detail.tabKey3.Panel4.p1': '矽谷，美國',
  'cdnResources.detail.tabKey3.Panel4.p2': '華盛頓，美國',
  'cdnResources.detail.tabKey3.Panel5': '南美地區',
  'cdnResources.detail.tabKey3.Panel5.p1': '聖保羅，巴西',
  'cdnResources.detail.tabKey3.Panel6': '非洲地區',
  'cdnResources.detail.tabKey3.Panel6.p1': '開羅，埃及',
  'cdnResources.detail.tabKey3.Panel6.p2': '約翰內斯堡，南非',
  'cdnResources.detail.tabKey4.tip':
    '清除工具允許您立即從所有數據中心刪除緩存內容。您可以使用特定路徑單獨清除文件，也可以一次清除所有文件。',
  'cdnResources.detail.tabKey4.Radio1': '清除所有文件',
  'cdnResources.detail.tabKey4.Radio2': '清除特定文件',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.message': '請輸入清除路徑',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.placeholder':
    '/images/image01.png（多路徑需要換行填寫）',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.Button': '清除特定文件',
  'cdnResources.detail.tabKey4.ProCard.title': '清除日誌',
  'cdnResources.detail.tabKey4.ProCard.subTitle': '（僅保存3個月的日誌數據）',
  'cdnResources.detail.tabKey4.ProCard.Option1': '所有日誌',
  'cdnResources.detail.tabKey4.ProCard.Option2': '清除所有文件',
  'cdnResources.detail.tabKey4.ProCard.Option3': '清除特定文件',
  'cdnResources.detail.tabKey4.columns.purgePaths.Button': '查看文件路徑',
  'cdnResources.detail.tabKey4.selected.message': '請確保你至少選擇了一個邊緣位置加速。',
  'cdnResources.detail.tabKey4.Modal.title': '文件路徑',
  'cdnResources.detail.tabKey4.Modal.text': '所有文件',

  'cdnResources.detail.tabKey4.ModalPurge.title': '清除所有文件',
  'cdnResources.detail.tabKey4.ModalPurge.text':
    '如果您選擇清除所有文件，我們將再次從您的源中提取文件。是否確實要清除所有緩存？',
  'cdnResources.detail.tabKey4.ModalPurge.button': '是的，我確定。',
  'cdnResources.detail.tabKey4.Modal.node.title': '加速節點列表',
  'cdnResources.detail.tabKey4.Modal.node.tip': '保存後，你將加速的節點為：',

  'cdnResources.detail.tabKey5.onSuccess.message': '提交成功',
  'cdnResources.detail.tabKey5.tip': `預取工具允許您將新添加的內容預填充到CDN緩存中。這是如果你最近上傳了一個大文件到你的服務器上，特別有用，因為您可以快速提示緩存服務器獲取該文件並強製它們緩存該文件`,
  'cdnResources.detail.tabKey5.Form.prefetchPaths.message': '請輸入預存路徑',
  'cdnResources.detail.tabKey5.Form.prefetchPaths.Button': '預存特定路徑',
  'cdnResources.detail.tabKey5.Prefetchlog.title': '預存日誌',
  'cdnResources.detail.tabKey6.title1': '用CNAME配置',
  'cdnResources.detail.tabKey6.text1': '通過DNS加速所有靜態文件。',
  'cdnResources.detail.tabKey6.text2': '1.您可以在您的域名服務商處添加CNAME',
  'cdnResources.detail.tabKey6.text3': '加速域名：',
  'cdnResources.detail.tabKey6.text4': 'CNAME：',
  'cdnResources.detail.tabKey6.text5': '2.DNS生成需要一些時間。您可以使用以下工具檢查DNS',
  'cdnResources.detail.tabKey6.text6': '狀態：',
  'cdnResources.detail.tabKey6.text7': '3.這就完成了！',
  'cdnResources.detail.tabKey6.text8': '開始您的LightCDN CDN之旅！如果您有任何其他問題，請聯系我們',
  'cdnResources.detail.tabKey6.text9': '客服',
  'cdnResources.detail.tabKey6.title2': '不用CNAME配置',
  'cdnResources.detail.tabKey6.title11': '簡單的設置，只需更新指向靜態文件的URL。',
  'cdnResources.detail.tabKey6.title12':
    '1.更改需要CDN加速的每個文件（圖像、視頻、CSS等）的源路徑：',
  'cdnResources.detail.tabKey6.title13': '不用CDN：',
  'cdnResources.detail.tabKey6.title14': '用CDN：',
  'cdnResources.detail.tabKey6.text15': '2.這就完成了！',
  'cdnResources.detail.tabKey7.title': '訪問保護',
  'cdnResources.detail.tabKey7.countries.name': '國家白名單',
  'cdnResources.detail.tabKey7.CountryAccess.p1':
    '配置規則以啟用/禁用對指定國家/地區CDN資源內容的訪問。',
  'cdnResources.detail.tabKey7.CountryAccess.p2': '你{val}有訪問，除了部分國家。 ',
  'cdnResources.detail.tabKey7.clickHere': '點擊此處查看列表。',
  'cdnResources.detail.tabKey7.countries.title': '（以下國家/地區除外，可不填寫）',
  'cdnResources.detail.tabKey7.hotlinkDomains.name': 'URL白名單',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p1':
    '配置一個規則，以啟用/禁用對指定URL的CDN資源內容的訪問。',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p2': '你{val}訪問，除了部分域名。',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p3': '（CDN加速域名和輔助加速域名將被默認添加。）',
  'cdnResources.detail.tabKey7.domians.title': '（以下域名除外，可不填寫）',
  'cdnResources.detail.tabKey1.domians.message': '請输入域名！',
  'cdnResources.detail.tabKey1.domians.placeholder': 'www.example.com（多個域名需要換行填寫。）',
  'cdnResources.detail.tabKey7.ipAddresses.name': 'IP白名單',
  'cdnResources.detail.tabKey7.IPAccess.p1':
    '配置一個規則，以啟用/禁用對一系列IP地址（包括IPv4和IPv6）的CDN資源內容的訪問。',
  'cdnResources.detail.tabKey7.IPAccess.p2': '你{val}，除了部分IP地址。',
  'cdnResources.detail.tabKey7.addresses.title': '（以下IP地址除外，可不填寫）',
  'cdnResources.detail.tabKey1.addresses.message': '請輸入IP地址！',
  'cdnResources.detail.tabKey1.addresses.placeholder':
    '10.10.10.10,20.20.20.0/24,2001:CDBA::64,...',
  'cdnResources.detail.tabKey7.URLSigning.name': 'URL簽名',
  'cdnResources.detail.tabKey7.URLSigning.p1': '使用密鑰保護您的文件免受未經授權的訪問。',
  'cdnResources.detail.tabKey7.signingKey.title': 'URL簽名秘鑰',
  'cdnResources.detail.tabKey7.URLSigning.Generate': '生成一個新的',
  'cdnResources.detail.tabKey7.URLSigning.message': '請輸入6-32的字符串，不支持空格。',
  'cdnResources.detail.tabKey7.URLSigning.validatorMessage': 'URL簽名密鑰無效。',
  'cdnResources.detail.tabKey7.crawlers.name': '阻止搜索引擎爬網程序',
  'cdnResources.detail.tabKey7.crawlers.p1':
    '阻止搜索引擎爬網程序用於阻止網絡爬網機器人對CDN內容進行索引。',
  'cdnResources.detail.tabKey7.Modal.here.title': '{name}列表',
  'cdnResources.detail.tabKey7.Modal.here.tip': '你{val}，除了：',
  'cdnResources.detail.tabKey7.SafetyWaf.name': 'OWASP最大威脅',
  'cdnResources.detail.tabKey7.SafetyWaf.title': 'WAF',
  'cdnResources.detail.tabKey7.SafetyWaf.tip1': '如果您不知道OWSAP的具體定義，可以查看',
  'cdnResources.detail.tabKey7.SafetyWaf.tip2': '文檔',
  'cdnResources.detail.tabKey7.SafetyWaf.tip3': '去了解。',
  'cdnResources.detail.tabKey7.URLSigning.pop1': '安全令牌由以下參數組成：',
  'cdnResources.detail.tabKey7.URLSigning.pop2':
    'Expires — URL的到期時間或URL變得無效的時間。時間以Unix時間戳格式在URL中傳遞，並參與哈希生成。它是一個可選參數。',
  'cdnResources.detail.tabKey7.URLSigning.pop3': 'Path — 文件路徑或文件目錄',
  'cdnResources.detail.tabKey7.URLSigning.pop4': 'Key — 一個URL簽名秘鑰',
  'cdnResources.detail.tabKey7.URLSigning.pop5':
    'IP— 提供訪問的IP。它是可選的，每次生成哈希鍵時只允許使用一個IP。',
  'cdnResources.detail.tabKey7.URLSigning.pop6': 'URL簽名可以采用以下格式之一：',
  'cdnResources.detail.tabKey7.URLSigning.pop7':
    '查詢字符串格式：http://example.com/filename?secure=DMF1ucDxtqgxwYQ&expires=1546300800&ip=1.2.3.4',
  'cdnResources.detail.tabKey7.URLSigning.pop8':
    '路徑格式：http://example.com/secure=DMF1ucDxtqgxwYQ&expires=1546300800&ip=1.2.3.4/filename',
  'cdnResources.detail.tabKey7.Access.label1': '未開啟',
  'cdnResources.detail.tabKey7.Access.label2': '默認開啟所有',
  'cdnResources.detail.tabKey7.Access.label3': '默認阻止所有',
  'cdnResources.detail.tabKey7.allowed': '默認開啟所有',
  'cdnResources.detail.tabKey7.block': '默認阻止所有',
  'cdnResources.detail.tabKey7.disabled': '未開啟',
  'cdnResources.detail.tabKey7.SafetyNginx.title': 'Nginx設置',
  'cdnResources.detail.tabKey7.SafetyNginx.title1': '速率設置',
  'cdnResources.detail.tabKey7.SafetyNginx.title2': '緩存鍵',
  'cdnResources.detail.tabKey7.SafetyNginx.text1': '支持限製客戶每個請求的相應速度。',
  'cdnResources.detail.tabKey7.SafetyNginx.text2': '當前設置：當請求達到了',
  'cdnResources.detail.tabKey7.SafetyNginx.text3': '，響應速度將降至',
  'cdnResources.detail.tabKey7.SafetyNginx.text4': '支持從四種類型的緩存鍵中選擇一種進行緩存。',
  'cdnResources.detail.tabKey7.SafetyNginx.message': '請不要超過2T。',
  // v1.1.13
  'cdnResources.detail.tabKey8.tabs1': '緩存規則',
  'cdnResources.detail.tabKey8.tabs2': '重定向規則',
  'cdnResources.detail.tabKey8.tabs3': '修改標頭',
  'cdnResources.detail.tabKey8.tabs4': '源規則',
  'cdnResources.detail.tabKey8.tabs.tip0': '如何繞過 LightCDN 的邊緣服務器直接訪問源呢？',
  'cdnResources.detail.tabKey8.tabs.tip1': '通過CDN訪問源站提示重定向的次數過多？',
  'cdnResources.detail.tabKey8.tabs.tip2': '來看看如何解決吧！→',
  'cdnResources.detail.tabKey8.tabs.tip3': '來看看如何解決吧！→',
  'cdnResources.detail.tabKey8.tabs1.create': '創建一條新的規則', // {num}
  'cdnResources.detail.tabKey8.tabs1.p1': '自定義HTTP緩存規則。 ',
  'cdnResources.detail.tabKey8.tabs1.p2': '閱讀文檔來了解更多。',
  'cdnResources.detail.tabKey8.tabs1.p3.1': '你當前可以創建',
  'cdnResources.detail.tabKey8.tabs1.p3.2': '條規則。',
  'cdnResources.detail.tabKey8.tabs1.p3.3': '去購買更多的規則。',
  'cdnResources.detail.tabKey8.tabs1.p4': '去購買更多的規則。',
  'cdnResources.detail.tabKey8.tabs1.table.title1': '名稱',
  'cdnResources.detail.tabKey8.tabs1.table.title2': '選項',
  'cdnResources.detail.tabKey8.tabs1.table.compensation': '系統補償',
  'cdnResources.detail.tabKey8.tabs1.form.title1.message': '請輸入名稱！',
  'cdnResources.detail.tabKey8.tabs1.form.title1.exists': '規則名稱已存在。',
  'cdnResources.detail.tabKey8.tabs1.form.title1.invalidName': '无效名称，請输入字母或数字。',
  'cdnResources.detail.tabKey8.tabs1.form.invalidName': '無效名稱',
  'cdnResources.detail.tabKey8.tabs1.form.title1.cnMessage': '請輸入字母或數字。',
  'cdnResources.detail.tabKey8.tabs1.form.title1.button': '生成一個名稱',
  'cdnResources.detail.tabKey8.tabs1.form.title2': '條件（傳入請求匹配…時）',
  'cdnResources.detail.tabKey8.tabs1.form.title3': '操作（然後…）',
  'cdnResources.detail.tabKey8.tabs1.form.title4': '動作',
  'cdnResources.detail.tabKey8.tabs1.form.title4.message': '請選擇動作',
  'cdnResources.detail.tabKey8.tabs1.form.title4.message1': '請至少選擇一個動作開啟。',
  'cdnResources.detail.tabKey8.tabs1.form.title5': '從不緩存',
  'cdnResources.detail.tabKey8.tabs1.form.title6': '緩存',
  'cdnResources.detail.tabKey8.tabs1.form.title7': '強製邊緣從不緩存',
  'cdnResources.detail.tabKey8.tabs1.form.title8': '強製客戶端從不緩存',
  'cdnResources.detail.tabKey8.tabs1.form.title9': '強製邊緣緩存',
  'cdnResources.detail.tabKey8.tabs1.form.title10': '強製客戶端緩存',
  'cdnResources.detail.tabKey8.tabs1.form.title11': '覆蓋默認緩存有效性',
  'cdnResources.detail.tabKey8.tabs1.form.title12': 'TTL',
  'cdnResources.detail.tabKey8.tabs1.form.title12.message': '請輸入TTL',
  'cdnResources.detail.tabKey8.tabs1.form.title7.text':
    '強製CDN邊緣服務器從不緩存請求。但是，如果請求已經緩存（例如，如果它是在設置此規則之前緩存的），則不會強製將其從緩存中取出。',
  'cdnResources.detail.tabKey8.tabs1.form.title8.text':
    '強製客戶端從不緩存請求。這是通過刪除所有「Cache-Control」和「Expires」響應標頭來實現的，而非發送「Cache-Control: no-cache」。',
  'cdnResources.detail.tabKey8.tabs1.form.title9.text':
    '強製CDN邊緣服務器將請求緩存指定的持續時間。這將覆蓋來自源的任何「Cache-Control」或「Expires」標頭，即使它們指定了「private」或「no-cache」。',
  'cdnResources.detail.tabKey8.tabs1.form.title9.second': '值必須填寫1s或更長。',
  'cdnResources.detail.tabKey8.tabs1.form.title10.text':
    '強製客戶端將請求緩存指定的持續時間。這是通過刪除所有「Cache-Control」和「Expires」響應標頭來實現的，而非發送「Cache-Control: max-age=...」。',
  'cdnResources.detail.tabKey8.tabs1.form.title11.text':
    '為未明確指定「Cache-Control」或「Expires」的源響應更改CDN邊緣服務器的默認有效期。具有「Cache Control」或「Expires」標頭的響應仍將被接受。',
  'cdnResources.detail.tabKey8.tabs1.form.item1.name': '條件',
  'cdnResources.detail.tabKey8.tabs1.form.item2.name': '主題',
  'cdnResources.detail.tabKey8.tabs1.form.item3.name': 'Cookie/Param參數/Header標頭',
  'cdnResources.detail.tabKey8.tabs1.form.item3.message': '請输入Cookie/Param参数/Header标头',
  'cdnResources.detail.tabKey8.tabs1.form.item4.name': '謂語',
  'cdnResources.detail.tabKey8.tabs1.form.item5.name': '值',
  'cdnResources.detail.tabKey8.tabs1.form.item5.message': '請输入值',
  'cdnResources.detail.tabKey8.tabs1.form.create': '創建一條新的規則',
  'cdnResources.detail.tabKey8.tabs1.form.save': '保存修改',
  'cdnResources.detail.tabKey8.tabs1.PopoverText1': '編輯',
  'cdnResources.detail.tabKey8.tabs1.PopoverText2': '刪除',
  'cdnResources.detail.tabKey8.tabs1.Delete.content':
    '刪除後，該規則將變為無效。你確定要刪除它嗎？',
  'cdnResources.detail.tabKey8.tabs2.title': '快速開啟配置',
  'cdnResources.detail.tabKey8.tabs2.QuicklyEnable': '快速開啟',
  'cdnResources.detail.tabKey8.tabs2.card.title': 'HTTPS重定向',
  'cdnResources.detail.tabKey8.tabs2.card.p1':
    '返回從http地址到https地址的301重定向響應，而不是從緩存或上遊加載。',
  'cdnResources.detail.tabKey8.tabs2.card.p2':
    '我們將直接為您生成一個規則，並將其顯示在下面的列表中。',
  'cdnResources.detail.tabKey8.tabs2.p1': '創建規則將訪問者從源URL重定向到目標URL。',
  'cdnResources.detail.tabKey8.tabs2.form.title1': '禁止訪問客戶端',
  'cdnResources.detail.tabKey8.tabs2.form.title2': '設置重定向',
  'cdnResources.detail.tabKey8.tabs2.form.title3': '將客戶端從HTTP重定向到HTTPs',
  'cdnResources.detail.tabKey8.tabs2.form.title4': '重定向客戶端',
  'cdnResources.detail.tabKey8.tabs2.form.title5': 'URL',
  'cdnResources.detail.tabKey8.tabs2.form.title5.message': '請輸入URL',
  'cdnResources.detail.tabKey8.tabs2.form.title1.text': '向客戶端返回一個簡單的403 Forbidden響應。',
  'cdnResources.detail.tabKey8.tabs2.form.title2.text':
    '返回從http地址到https地址的301重定向響應，而不是從緩存或上遊加載。',
  'cdnResources.detail.tabKey8.tabs2.form.title3.text':
    '將302重定向響應返回到客戶端的指定URL。必須完整指定URL，以http://或https://開頭。',
  'cdnResources.detail.tabKey8.tabs3.p1': '調整邊緣的請求和響應的HTTP標頭。',
  'cdnResources.detail.tabKey8.tabs3.form.title1': '設置請求標頭',
  'cdnResources.detail.tabKey8.tabs3.form.title2': '設置響應標頭',
  'cdnResources.detail.tabKey8.tabs3.form.title3': '在請求標頭中設置客戶端IP',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Header': '標頭',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Header.message': '請輸入標頭',
  'cdnResources.detail.tabKey8.tabs3.form.title1.HeaderValue.message': '請輸入標頭和值',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Value': '值',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Value.message': '請輸入值',
  'cdnResources.detail.tabKey8.tabs3.form.title1.text':
    '將請求標頭覆蓋到源。必須提供標頭名稱和標頭值。',
  'cdnResources.detail.tabKey8.tabs3.form.title2.text':
    '覆蓋客戶端的響應標頭。必須提供標頭名稱和標頭值。',
  'cdnResources.detail.tabKey8.tabs3.form.title3.text':
    '將請求標頭中客戶端的IP地址設置為源。必須提供標頭名稱。',
  'cdnResources.detail.tabKey8.tabs4.p1': '自定義原始配置請求的目的地。',
  'cdnResources.detail.tabKey8.tabs4.form.title1': '準備源目錄',
  'cdnResources.detail.tabKey8.tabs4.form.title2': '設置自定義源',
  'cdnResources.detail.tabKey8.tabs4.form.title3': '將HTTP主機頭傳遞到源',
  'cdnResources.detail.tabKey8.tabs4.form.title4': '其他設置',
  'cdnResources.detail.tabKey8.tabs4.form.title1.text':
    '邊緣服務器從源位置請求時，將目錄設置為預先準備的URL。',
  'cdnResources.detail.tabKey8.tabs4.form.title2.text': '替代邊緣服務器連接到源。',
  'cdnResources.detail.tabKey8.tabs4.form.title3.text':
    '當條件為true時，返回原點，在第一次期間，並且隨後的請求（緩存在邊緣中）將不會再次返回原點，盡管條件為true。',
  'cdnResources.detail.tabKey8.InvalidFormat': '無效格式',
};
