export default {
  'packageSelection.title': '升级',
  'packageSelection.planList.free': '免费',
  'packageSelection.planList.free.title': '免费月度计划',
  'packageSelection.planList.free.nextText': '用于个人非专业网站',
  'packageSelection.planList.free.support': '帮助文档',
  'packageSelection.planList.free.perMonth': '每月{numUnit}',
  'packageSelection.planList.free.price': '每月可回购',
  'packageSelection.planList.free.config': '可用的国际节点',
  'packageSelection.planList.free.buttonText': '选择',
  'packageSelection.planList.free.Modal.text': '还没到购买时间，请等待开启。',
  'packageSelection.planList.free.extraCore': '',
  'packageSelection.planList.free.corefeatures.p1': '快速且简便的使用CDN',
  'packageSelection.planList.free.corefeatures.p2': '免费的SSL证书',
  'packageSelection.planList.free.corefeatures.p3': '100GB月度流量',
  'packageSelection.planList.free.corefeatures.p4': '基础数据以及访客分析',
  'packageSelection.planList.free.corefeatures.p5': '中国大陆的国际访问',
  'packageSelection.planList.free.corefeatures.p6': '完善的安全保护功能政策',
  'packageSelection.planList.free.corefeatures.p7': '可自定义的8个核心国际节点',
  'packageSelection.planList.free.corefeatures.p8': '无合同协议',
  'packageSelection.planList.pro.title': '专业版月度计划',
  'packageSelection.planList.pro.nextText': '非商业专业网站',
  'packageSelection.planList.pro.support': '在线客服，支持邮箱',
  'packageSelection.planList.pro.perMonth': '低至每月{low}',
  // 'packageSelection.planList.pro.perMonth': '2023特惠！一个开放所有节点的500G计划！',
  'packageSelection.planList.pro.price': '单价低至 {unitPrice}/GB',
  'packageSelection.planList.pro.config': '所有Pops，包括随后开放的罕见Pops',
  'packageSelection.planList.pro.buttonText': '选择',
  'packageSelection.planList.pro.extraCore': '免费计划中的所有，并且还包括：',
  'packageSelection.planList.pro.corefeatures.p1': '所有节点，包括稀有的边缘节点',
  'packageSelection.planList.pro.corefeatures.p2': '每月{min}~{max}，单价低至 {unitPrice}',
  'packageSelection.planList.pro.corefeatures.p3': '快速便捷的WAF功能',
  'packageSelection.planList.pro.corefeatures.p5': '无上限的缓存规则（即将上线）',
  'packageSelection.planList.remind.title': '自定义月度计划',
  'packageSelection.planList.remind.nextText': '专业和商业网站',
  'packageSelection.planList.remind.support': '专属1对1客服，解决方案工程师支持，',
  'packageSelection.planList.remind.support1': '提供付费节点测试',
  'packageSelection.planList.remind.perMonth': '每月需求量超过50T',
  'packageSelection.planList.remind.price': '更实惠的单价',
  'packageSelection.planList.remind.config': '',
  'packageSelection.planList.remind.buttonText': '申请报价',
  'packageSelection.planList.remind.disabledText': '请等待处理',
  'packageSelection.planList.remind.Modal.ok': '好的',
  'packageSelection.planList.remind.Modal.text':
    '我们已收到您的请求。功能上线后，我们将通过电子邮件通知您。',
  'packageSelection.planList.remind.extraCore': '专业套餐中的所有，并且还包括：',
  'packageSelection.planList.remind.corefeatures.p1': '支持团队共享',
  'packageSelection.planList.remind.corefeatures.p2': '最具成本效益的解决方案',
  'packageSelection.planList.remind.corefeatures.p3': '获得非合同服务',
  'packageSelection.planList.Support': '支持',
  'packageSelection.planList.CoreFeatures': '核心功能',
  'packageSelection.help1': '如果你需要测试付费的节点，请',
  'packageSelection.help2': '联系我们，',
  'packageSelection.help3': '我们将很高兴为你服务。',
  //v1.2.0
  'packageSelection.planList.title1': '按量付费',
  'packageSelection.planList.title2': '月度计划',
  'packageSelection.planList.title3': '一体化解决方案',
  'packageSelection.planList.title1.text': '按小时结算和按需加速',
  'packageSelection.planList.title2.text': '30天限时套餐，单价更实惠',
  'packageSelection.planList.title3.text': '根据业务需求定制解决方案',
  'packageSelection.planList.Tier': '档位',
  'packageSelection.planList.international.nodes': '国际节点',
  'packageSelection.planList.core.nodes': '核心国际节点',
  'packageSelection.planList.Other': '其他',
  'packageSelection.planList.nodes.text1':
    '所有节点都适合非洲、中东、东南亚等地区有稳定业务需求的客户。',
  'packageSelection.planList.nodes.text2': '覆盖五大区域的骨干网络节点。满足国际访问需求。',
  'packageSelection.planList.nodes.text3': '添加你所需要的新节点',
  'packageSelection.planList.Support.help': '帮助文档，邮件支持',
  'packageSelection.planList.Support.help1': '客户服务1对1，解决方案工程师支持',
  'packageSelection.planList.Recharge': '账户充值',
  'packageSelection.planList.button2': '获取方案',
  'packageSelection.planList.button4': '获取免费额度！',
  'packageSelection.planList.button5': '已兑换 {date}',
  'packageSelection.planList.Operation.successful': '操作成功',
  'packageSelection.planList.noFree.p': '当前有月度计划，暂时不可领取免费额度。',
  'packageSelection.planList.day': '天',
  'packageSelection.planList.billingDetails.p1': '您还未填写账单信息，请前往',
  'packageSelection.planList.billingDetails.p2': '账单详情',
  'packageSelection.planList.billingDetails.p3': '填写后方可领取额度！',
  'packageSelection.planList.Successfully': '兑换成功!!',
  'packageSelection.planList.CreateCDN': '创建新的CDN资源',
};
