export default {
  'cdnResources.list.deleteCdn.message': 'Delete succeeded',
  'cdnResources.list.columns.Tag.suspend': 'suspend',
  'cdnResources.list.Popconfirm.title': 'Are you sure delete this CDN Resource?',
  'cdnResources.list.Popconfirm.okText': 'Yes',
  'cdnResources.list.Popconfirm.cancelText': 'No',
  'cdnResources.list.title': 'CDN Resource',
  'cdnResources.list.Create': 'Create new CDN resource',
  'cdnResources.list.Create.Disabled': 'Reached the upper limit',
  //v1.2.0
  'cdnResources.list.ConfirmAndCreate': 'Confirm and create',
  'cdnResources.list.Empty.btnText': 'Get Started',
  'cdnResources.list.Empty.desc': 'Speed up within 3 minutes',
  'cdnResources.list.columns.title1': 'CDN hostname',
  'cdnResources.list.columns.title2': 'CNAME',
  'cdnResources.list.columns.title3': 'Origin',
  'cdnResources.list.columns.title4': 'Options',
  //v1.2.0
  'cdnResources.list.columns.title5': 'Tier',
  'cdnResources.list.columns.title6': 'Usage this month',
  'cdnResources.list.PopoverText1': 'Data details',
  'cdnResources.list.PopoverText2': 'Settings',
  'cdnResources.list.PopoverText3': 'Delete resources',
  'cdnResources.list.PopoverText4': 'Purge all files',

  'cdnResources.create.step1.fristFree': 'First {val} free',

  'cdnResources.create.stepBox.step1': 'Basic Settings',
  'cdnResources.create.stepBox.step2': 'Completed',
  'cdnResources.create.onSubmit.message': 'Created succeeded',
  'cdnResources.create.step1.validatorCdnHostName.same': 'CDN hostname and Origin are the same',
  'cdnResources.create.step1.Form.cdnHostName.name': 'CDN hostname',
  'cdnResources.create.step1.Form.cdnHostName.message': 'Please input your CDN hostname!',
  'cdnResources.create.step1.Form.origin.name': 'Origin',
  'cdnResources.create.step1.Form.origin.message': 'Please input Origin!',
  'cdnResources.create.step1.Form.Checkbox.name': 'Use custom port',
  'cdnResources.create.step1.Form.port.name': 'port(optional)',
  'cdnResources.create.step1.Form.port.message': 'Please input Port!',
  'cdnResources.create.step2.message': 'Create succeeded',
  'cdnResources.create.step2.title1':
    'CDN Resource was sucessfully created and CNAME will be available in ',
  'cdnResources.create.step2.title2': 'seconds',

  'cdnResources.create.step1.tip1':
    'The URL you want to speed up is usually the public display address open to users. After finishing, you can use our CNAME or your display address to speed up your resources.',
  'cdnResources.create.step1.tip2':
    'Fill in the address of the web content source here, which can be URL or IP.',
  'cdnResources.create.step1.tip3':
    '(Note the URL format: {domain1}、 {domain2}，unavailable {domain3}）',
  'cdnResources.create.step1.tip3.0': 'Note the URL format: ',
  'cdnResources.create.step1.tip4':
    'When the origin policy is AUTO, filling in ports is not supported.',
  'cdnResources.create.step1.help1': 'If you need more help, you can ',
  'cdnResources.create.step1.help2': 'click here.',
  'cdnResources.create.step1.origin.note1': 'Please note:',
  'cdnResources.create.step1.origin.note2': 'Multiple sources can only fill in IP format.',
  'cdnResources.create.step1.origin.message':
    'When more than one origin is used, only IP addresses are allowed.',
  'cdnResources.create.step1.origin.validatorMessage1': 'Please fill in a valid origin.',
  'cdnResources.create.step1.origin.validatorMessage2': 'Please fill in a valid CDN hostname.',
  'cdnResources.create.step1.origin.validatorMessage3': 'The IP already exists.',
  'cdnResources.create.step1.origin.validatorMessage4': 'Please fill in a valid IP addresses.',
  'cdnResources.create.step1.origin.validatorMessage5':
    'When using multiple sources, the port numbers must be consistent.',
  'cdnResources.create.step1.origin.unique': 'The orign already exsists.',
  'cdnResources.create.step1.hostname.unique': 'The CDN hostname already exsists.',
  //v1.2.0
  'cdnResources.create.StandardNetwork': 'Standard network',
  'cdnResources.create.CoreNetwork': 'Core network',
  'cdnResources.create.noPlan.modal.text': 'Insufficient Balance & No plan',
  'cdnResources.create.noPlan.modal.p': 'You still have free traffic to redeem!',
  'cdnResources.create.noPlan.modal.button': 'Get a offer',
  'cdnResources.create.No.additional': 'No additional charges required.',
  'cdnResources.detail.tabKey4.tier.Current': 'Current tier',
  'cdnResources.detail.tabKey4.tier.edit': 'Change tiers',
  'cdnResources.detail.tabKey4.tier.Usage': 'Usage of this month',
  'cdnResources.detail.tabKey4.tier.Unitprice': 'Unit price',
  'cdnResources.detail.tabKey4.EnableEdgeLocation': 'Enable edge location',
  'cdnResources.detail.tabKey4.tier.Modal.p1':
    'You currently do not have permission for this edge location. Please switch tier and try again.',
  'cdnResources.detail.tabKey4.tier.Recommended': 'Recommended tier: ',
  'cdnResources.create.NetworkList.moreFavorable': 'Get a more favorable price by click here',
  'cdnResources.create.title.resources': 'Create your CDN resources',
  'cdnResources.create.title.tier': 'Select your tier',
  'cdnResources.create.tier.loading':
    'We are processing resources for you, please wait a moment...',
  'cdnResources.create.NetworkList.tierRecharge.p1':
    'The following tier need to ensure sufficient balance, ',
  'cdnResources.create.NetworkList.tierRecharge.p2': 'go to recharge',
  'cdnResources.create.NetworkList.planLearn.p1':
    'You currently have a monthly plan, which will automatically activate the corresponding tier. ',
  'cdnResources.create.NetworkList.planLearn.p2': 'Learn about your monthly plan',
  'cdnResources.create.postpaid.price.p':
    'If the amount of traffic for half an hour is extremely small, we will deduct the minimum amount of $0.000001. If the amount exceeds this, we will deduct it according to normal billing.',
  'cdnResources.detail.NoPermission': 'No permission',
  'cdnResources.detail.SuggestedTier': 'Suggested tier: ',

  'cdnResources.detail.title': 'CDN Resources',
  'cdnResources.detail.tabKey1': 'Basic Settings',
  'cdnResources.detail.tabKey2': 'Advanced Settings',
  'cdnResources.detail.tabKey3': 'CDN Locations',
  'cdnResources.detail.tabKey4': 'Purge',
  'cdnResources.detail.tabKey5': 'Prefetch',
  'cdnResources.detail.tabKey6': 'Activate configuration',
  'cdnResources.detail.tabKey7': 'SafetyProtection',
  'cdnResources.detail.tabKey8': 'Rules',
  'cdnResources.detail.tabKey1.onSuccess.message': 'update successfully',
  'cdnResources.detail.tabKey1.title': 'Basic Information',
  'cdnResources.detail.tabKey1.cdnHostName.name': 'CDN hostname',
  'cdnResources.detail.tabKey1.cdnHostName.message': 'please enter',
  'cdnResources.detail.tabKey1.cdnHostName.placeholder':
    'Each CDN hostname needs to be filled in a new line,up to seven',
  'cdnResources.detail.tabKey1.cdnHostName.validatorMessage': 'Please enter a valid host name.',
  // 'cdnResources.detail.tabKey1.origin.name': 'Origin',
  'cdnResources.detail.tabKey1.port.name': 'Port',
  'cdnResources.detail.tabKey1.select.message': 'Please select',
  'cdnResources.detail.tabKey1.Button': 'Add',
  'cdnResources.detail.tabKey1.ssl.title': 'SSL',
  'cdnResources.detail.tabKey1.ssl.Tag1': 'Enabled',
  'cdnResources.detail.tabKey1.ssl.Tag2': 'Disabled',
  'cdnResources.detail.tabKey1.ssl.tip':
    'The accelerated domain name must have an SSL certificate before it can be accessed by HTTPS. You can open the SSL certificate to configure your domain name',
  'cdnResources.detail.tabKey1.ssl.tip1':
    'If you have just configured CDN and find that your site ',
  'cdnResources.detail.tabKey1.ssl.tip2': 'cannot be opened securely',
  'cdnResources.detail.tabKey1.ssl.tip3':
    ', it means that you need to configure an SSL certificate on our platform.',
  'cdnResources.detail.tabKey1.ssl.group.label1': "Let's Encrypt",
  'cdnResources.detail.tabKey1.ssl.group.p1':
    "Please type the full hostname and ensure it doesn't end with '.r.cdn36.com' otherwise SSL cannot be enabled automatically.",
  'cdnResources.detail.tabKey1.ssl.group.label2': 'Custom SNI SSL',
  'cdnResources.detail.tabKey1.ssl.group.p2':
    'If you enable secondary CDN hostnames, the SNI certificate will be applied to both the CDN hostname and secondary CDN hostnames.',
  'cdnResources.detail.tabKey1.HostName.title': 'Secondary CDN Hostnames',
  'cdnResources.detail.tabKey1.HostName.modal.title': 'Save changes',
  'cdnResources.detail.tabKey1.HostName.modal.text1':
    "If you turn on the secondary CDN hostnames, we will automatically switch or turn on the Let's Encrypt SSL certificate for you.",
  'cdnResources.detail.tabKey1.HostName.modal.text2':
    'Are you sure you need to enable secondary CDN hostnames?',
  'cdnResources.detail.tabKey1.HostName.message1':
    'The secondary CDN hostname that can be filled in has exceeded the upper limit!',
  'cdnResources.detail.tabKey1.HostName.message2':
    'It cannot be duplicated or the same as CDN hostname.',
  'cdnResources.detail.tabKey1.HostName.message3':
    'The domain name filled in cannot be the same as the CDN hostname.',
  'cdnResources.detail.tabKey1.HostName.message4':
    'It cannot be duplicated or the same as CDN hostname and secondary CDN hostnames.',
  'cdnResources.detail.tabKey1.HostName.tip1':
    'Secondary CDN hostnames supports adding multiple accelerated domain names, allowing users to use parallel downloads to speed up page loading. You need to set a CNAME for the CDN hostname and the Secondary CDN hostnames. For more details,',
  'cdnResources.detail.tabKey1.HostName.tip2': ', please refer to the document.',
  'cdnResources.detail.tabKey2.tip.name':
    "How to bypass LightCDN's edge server and access the origin directly?",
  'cdnResources.detail.tabKey2.tip.pop': 'Click to enter the guide',
  'cdnResources.detail.tabKey2.tip.content':
    'You can try adding a cache rule for CDN resources with a Cache-Control header as "no-cache" or "max-age = 0" for specific files. At the same time, you can set Force Edge to never cache, forcing the CDN edge server to never cache requests in the cache rule.',
  'cdnResources.detail.tabKey2.HLS_force_cache.name': 'HLS force cache',
  'cdnResources.detail.tabKey2.HLS_force_cache.p1':
    'Force the m3u8 file edge server not to cache and support setting expiration times. This setting only takes effect when ',
  'cdnResources.detail.tabKey2.HLS_force_cache.p2': '"HLS optimization"',
  'cdnResources.detail.tabKey2.HLS_force_cache.p3': ' is enabled.',
  'cdnResources.detail.tabKey2.title': 'Cache expiration',
  'cdnResources.detail.tabKey2.Modal.title': 'Add SSL Certificates',
  'cdnResources.detail.tabKey2.cacheExpiry.name': 'Expiration period',
  'cdnResources.detail.tabKey2.cacheExpiry.message': 'please enter Expiration period!',
  'cdnResources.detail.tabKey2.cacheExpiry.fieldProps': 'min (default 12 days)',
  'cdnResources.detail.tabKey2.cacheExpiry.default': 'default',
  'cdnResources.detail.tabKey2.cacheExpiry404.name': 'Expiration period for 404 codes',
  'cdnResources.detail.tabKey2.cacheExpiry404.fieldProps': '1 minutes (default)',
  'cdnResources.detail.tabKey2.rules.title': 'Configure',
  'cdnResources.detail.tabKey2.originPolicy.name': 'Origin policy',
  'cdnResources.detail.tabKey2.originPolicy.label1': 'AUTO',
  'cdnResources.detail.tabKey2.originPolicy.label2': 'HTTP',
  'cdnResources.detail.tabKey2.originPolicy.label3': 'HTTPS',
  'cdnResources.detail.tabKey2.originPolicy.tip1':
    "LightCDN's origin policy option allows you to set the protocol for pull requests from CDN edge servers to origin servers. You can check out the ",
  'cdnResources.detail.tabKey2.originPolicy.tip2': 'documentation',
  'cdnResources.detail.tabKey2.originPolicy.tip3': ' to learn more.',
  'cdnResources.detail.tabKey2.originPolicy.p1':
    'HTTP—connection between an edge server and an origin where HTTP is always used. It is a default value',
  'cdnResources.detail.tabKey2.originPolicy.p2':
    'HTTPS—connection between an edge server and an origin where HTTPS is always used',
  'cdnResources.detail.tabKey2.originPolicy.p3':
    "AUTO—connection between an edge server and an origin based on a visitor's request (HTTP or HTTPS). But please note that when your source has filled in the port, AUTO cannot be selected.",
  'cdnResources.detail.tabKey2.corsOn.name': 'CORS headers',
  'cdnResources.detail.tabKey2.corsOn.p1':
    'When enabled, enable cross domain resource sharing (CORS) by adding an HTTP header with access control allow origin',
  'cdnResources.detail.tabKey2.corsOn.p2': `If it is enabled and an error message is displayed when accessing the accelerated domain name, "No 'Access-Control-Allow-Origin' header is present on the requested resource.", please refer to the `,
  'cdnResources.detail.tabKey2.corsOn.p3': 'document',
  'cdnResources.detail.tabKey2.corsOn.p4': 'for specific solutions.',
  'cdnResources.detail.tabKey2.hostHeaderForwarding.name': 'Host header forwarding',
  'cdnResources.detail.tabKey2.hostHeaderForwarding.p1': `CDN edge server will only go back to the origin when the file does not exist (cache) in
    the edge server (for ALL scenario), and the feature "passthrough http host header to
    origin" happens during the path from cdn edge --> origin. This feature is not
    about "making all requests goes back to origin", but "sending the host header info to origin".`,
  'cdnResources.detail.tabKey2.IgnoreSetCookie.name': 'Cache set-cookie',
  // 'cdnResources.detail.tabKey2.IgnoreSetCookie.p1':
  //   'CDNs set cookies for session tracking. Our CDN does not cache any content with Set-Cookie response headers, including static content like JPEG files. If you experience 0% cache hits due to this, enable this setting so that such responses are cached.',
  'cdnResources.detail.tabKey2.IgnoreSetCookie.p1':
    'LightCDN does not cache any content with a Set-Cookie response header, including static content such as JPEG. If this results in a 0% cache hit rate, please enable this setting to cache such responses. For more information on caching Set-Cookie, please refer to the ',
  'cdnResources.detail.tabKey2.IgnoreSetCookie.p2': 'document here.',
  'cdnResources.detail.tabKey2.HTTPSRedirect.name': 'HTTPS redirect',
  'cdnResources.detail.tabKey2.HTTPSRedirect.p1':
    'Returns a 301 Redirect response from http address to https address instead of loading from cache or upstream.',
  'cdnResources.detail.tabKey2.StreamingMedia.name': 'Streaming media',
  'cdnResources.detail.tabKey2.StreamingMedia.MP4': 'MP4 pseudo streaming',
  'cdnResources.detail.tabKey2.StreamingMedia.FLV': 'FLV pseudo streaming',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS': 'HLS Optimization',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS.p1':
    'If you want to create a rule to force cache expiration, you can quickly enable it in the cache rules section of the ',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS.p2': ' page.',

  'cdnResources.detail.tabKey3.header1': 'Asia Pacific',
  'cdnResources.detail.tabKey3.header2': 'Middle East',
  'cdnResources.detail.tabKey3.header3': 'Europe',
  'cdnResources.detail.tabKey3.header4': 'North America',
  'cdnResources.detail.tabKey3.header5': 'South America',
  'cdnResources.detail.tabKey3.header6': 'Africa',
  'cdnResources.detail.tabKey3.text1': 'Silicon Valley',
  'cdnResources.detail.tabKey3.text2': 'Miami',
  'cdnResources.detail.tabKey3.text3': 'Washington',
  'cdnResources.detail.tabKey3.text4': 'Sao Paulo',
  'cdnResources.detail.tabKey3.text5': 'Frankfurt',
  'cdnResources.detail.tabKey3.text6': 'Dubai',
  'cdnResources.detail.tabKey3.text7': 'Singapore',
  'cdnResources.detail.tabKey3.text8': 'Ho Chi Minh',
  'cdnResources.detail.tabKey3.text9': 'Hong Kong',
  'cdnResources.detail.tabKey3.text10': 'Tokyo',
  'cdnResources.detail.tabKey3.text11': 'Riyadh',
  'cdnResources.detail.tabKey3.text12': 'Cairo',
  'cdnResources.detail.tabKey3.text13': 'London',
  'cdnResources.detail.tabKey3.text14': 'Marseille',
  'cdnResources.detail.tabKey3.text15': 'Bangkok',
  'cdnResources.detail.tabKey3.text16': 'Manila',
  'cdnResources.detail.tabKey3.text17': 'Hanoi',
  'cdnResources.detail.tabKey3.text18': 'Phnom Penh',
  'cdnResources.detail.tabKey3.text19': 'Muscat',
  'cdnResources.detail.tabKey3.text20': 'Johannesburg',
  'cdnResources.detail.tabKey3.text21': 'Bahrain',
  'cdnResources.detail.tabKey3.Panel1': 'Asia Pacific',
  'cdnResources.detail.tabKey3.Panel1.p1': 'Hongkong,China',
  'cdnResources.detail.tabKey3.Panel1.p2': 'Tokyo,Japan',
  'cdnResources.detail.tabKey3.Panel1.p3': 'Singapore',
  'cdnResources.detail.tabKey3.Panel1.p4': 'Bangkok,Tailand',
  'cdnResources.detail.tabKey3.Panel1.p5': 'Manila,Philippines',
  'cdnResources.detail.tabKey3.Panel1.p6': 'Hanoi,Vietnam',
  'cdnResources.detail.tabKey3.Panel1.p7': 'Phnom Penh,Cambodia',
  'cdnResources.detail.tabKey3.Panel2': 'Middle East',
  'cdnResources.detail.tabKey3.Panel2.p1': 'Dubai,The United Arab Emirates',
  'cdnResources.detail.tabKey3.Panel2.p2': 'Riyadh,Saudi Arabia',
  'cdnResources.detail.tabKey3.Panel2.p3': 'Muscat,Oman',
  'cdnResources.detail.tabKey3.Panel2.p4': 'Bahrain,The Kingdom of Bahrain',
  'cdnResources.detail.tabKey3.Panel3': 'Europe',
  'cdnResources.detail.tabKey3.Panel3.p1': 'Frankfurt,Germany',
  'cdnResources.detail.tabKey3.Panel3.p2': 'London,the United Kingdom',
  'cdnResources.detail.tabKey3.Panel3.p3': 'Marseille,France',
  'cdnResources.detail.tabKey3.Panel4': 'North America',
  'cdnResources.detail.tabKey3.Panel4.p1': 'San Mateo,America',
  'cdnResources.detail.tabKey3.Panel4.p2': 'Washington,America',
  'cdnResources.detail.tabKey3.Panel5': 'South America',
  'cdnResources.detail.tabKey3.Panel5.p1': 'Sao Paulo, Brazil',
  'cdnResources.detail.tabKey3.Panel6': 'Africa',
  'cdnResources.detail.tabKey3.Panel6.p1': 'Cairo,Egypt',
  'cdnResources.detail.tabKey3.Panel6.p2': 'Johannesburg,South Africa',
  'cdnResources.detail.tabKey4.tip':
    'The purge tool allows you to remove your cached content from all data centers instantly. You can purge files individually using a specific path or purge all files at once.',
  'cdnResources.detail.tabKey4.Radio1': 'Purge all files',
  'cdnResources.detail.tabKey4.Radio2': 'Purge individual files',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.message': 'Please enter purge paths',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.placeholder':
    '/images/image01.png (Multi path needs to be filled in line feed)',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.Button': 'Purge Specified files',
  'cdnResources.detail.tabKey4.ProCard.title': 'Purge log',
  'cdnResources.detail.tabKey4.ProCard.subTitle': '(Save log data for only 3 months)',
  'cdnResources.detail.tabKey4.ProCard.Option1': 'All',
  'cdnResources.detail.tabKey4.ProCard.Option2': 'Purge-all',
  'cdnResources.detail.tabKey4.ProCard.Option3': 'Purge-individual files',
  'cdnResources.detail.tabKey4.columns.purgePaths.Button': 'View file path',
  'cdnResources.detail.tabKey4.selected.message':
    'Please ensure that you have selected at least one acceleration edge location.',
  'cdnResources.detail.tabKey4.Modal.title': 'File paths',
  'cdnResources.detail.tabKey4.Modal.text': 'all files',

  'cdnResources.detail.tabKey4.ModalPurge.title': 'Purge all files',
  'cdnResources.detail.tabKey4.ModalPurge.text':
    'If you choose to clear all files, we will pull files from your source again. Are you sure you want to clear all caches?',
  'cdnResources.detail.tabKey4.ModalPurge.button': "Yes, I'm sure.",
  'cdnResources.detail.tabKey4.Modal.node.title': 'Acceleration nodes list',
  'cdnResources.detail.tabKey4.Modal.node.tip': 'After saving, the nodes you will accelerate are：',

  'cdnResources.detail.tabKey5.onSuccess.message': 'submit successfully',
  'cdnResources.detail.tabKey5.tip': `The prefetch tool allows you to prepopulate newly added content into CDN caches. This is
    especially beneficial if you have recently uploaded a large file onto your server because
    you can quickly prompt the cache servers to get that file and force them to cache it.`,
  'cdnResources.detail.tabKey5.Form.prefetchPaths.message': 'Please enter prefetch paths',
  'cdnResources.detail.tabKey5.Form.prefetchPaths.Button': 'Prefetch Specified files',
  'cdnResources.detail.tabKey5.Prefetchlog.title': 'Prefetch log',
  'cdnResources.detail.tabKey6.title1': 'Setup with CNAME',
  'cdnResources.detail.tabKey6.text1': 'Accelerate all static files through DNS.',
  'cdnResources.detail.tabKey6.text2': '1.You can add a CNAME to your hosting provider',
  'cdnResources.detail.tabKey6.text3': 'CDN hostname: ',
  'cdnResources.detail.tabKey6.text4': 'CNAME: ',
  'cdnResources.detail.tabKey6.text5':
    '2.DNS generation takes some time. You can use the following tools to check the DNS ',
  'cdnResources.detail.tabKey6.text6': 'status: ',
  'cdnResources.detail.tabKey6.text7': "3.It's DONE!",
  'cdnResources.detail.tabKey6.text8':
    'Start your LightCDN CDN journey! If you have any other questions, you can contact our',
  'cdnResources.detail.tabKey6.text9': 'customer service',
  'cdnResources.detail.tabKey6.title2': 'Setup without CNAME',
  'cdnResources.detail.tabKey6.title11':
    'Simple setup, just update the URLs pointing to your static files.',
  'cdnResources.detail.tabKey6.title12':
    '1.Change the Origin path for each file you want to deliver through the CDN (images, videos, CSS, etc.): ',
  'cdnResources.detail.tabKey6.title13': 'Without CDN: ',
  'cdnResources.detail.tabKey6.title14': 'With CDN: ',
  'cdnResources.detail.tabKey6.text15': "2.It's DONE!",
  'cdnResources.detail.tabKey7.title': 'Access Protection',
  'cdnResources.detail.tabKey7.countries.name': 'Country access',
  'cdnResources.detail.tabKey7.CountryAccess.p1':
    'Configure a rule to enable/disable access to the CDN resource’s content for specified countries.',
  'cdnResources.detail.tabKey7.CountryAccess.p2': 'You {val}, except some countries. ',
  'cdnResources.detail.tabKey7.clickHere': 'Click here for the list.',
  'cdnResources.detail.tabKey7.countries.title': '(Except for countries，can be blank)',
  'cdnResources.detail.tabKey7.hotlinkDomains.name': 'Hotlink policy',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p1':
    'Configure a rule to enable/disable access to the CDN resource’s content for specified URL.',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p2': 'You {val}, except some dmians. ',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p3':
    '(The CDN hostname and secondary CDN hostnames will be added by default.)',
  'cdnResources.detail.tabKey7.domians.title': '(Except for domians，can be blank)',
  'cdnResources.detail.tabKey1.domians.message': 'Please input domians!',
  'cdnResources.detail.tabKey1.domians.placeholder':
    'www.example.com(Multi domain needs to be fillede in line feed.)',
  'cdnResources.detail.tabKey7.ipAddresses.name': 'IP access',
  'cdnResources.detail.tabKey7.IPAccess.p1':
    'Configure a rule to enable/disable access to the CDN resource’s content for a range of IP addresses, including both IPv4 and IPv6.',
  'cdnResources.detail.tabKey7.IPAccess.p2': 'You {val}, except some IP addresses. ',
  'cdnResources.detail.tabKey7.addresses.title': '(Except for IP addresses，can be blank)',
  'cdnResources.detail.tabKey1.addresses.message': 'Please input IP addresses!',
  'cdnResources.detail.tabKey1.addresses.placeholder':
    '10.10.10.10,20.20.20.0/24,2001:CDBA::64,...',
  'cdnResources.detail.tabKey7.URLSigning.name': 'URL signing',
  'cdnResources.detail.tabKey7.URLSigning.p1':
    'Protect your files from unauthorized access with a key.  ',
  'cdnResources.detail.tabKey7.signingKey.title': 'URL signing key',
  'cdnResources.detail.tabKey7.URLSigning.Generate': 'Generate a new one',
  'cdnResources.detail.tabKey7.URLSigning.message':
    'Please enter a string of 6-32, spaces are not supported',
  'cdnResources.detail.tabKey7.URLSigning.validatorMessage': 'Url signing key is invalid.',
  'cdnResources.detail.tabKey7.crawlers.name': 'Block search engine crawlers',
  'cdnResources.detail.tabKey7.crawlers.p1':
    'Block search engine crawlers is used to block web crawling bots from indexing the CDN content.',
  'cdnResources.detail.tabKey7.Modal.here.title': 'List for {name}',
  'cdnResources.detail.tabKey7.Modal.here.tip': 'You {val}, except for:',
  'cdnResources.detail.tabKey7.SafetyWaf.name': 'OWASP TOP THREATS',
  'cdnResources.detail.tabKey7.SafetyWaf.title': 'WAF',
  'cdnResources.detail.tabKey7.SafetyWaf.tip1':
    'If you do not know the specific definition of WSAP, you can check the ',
  'cdnResources.detail.tabKey7.SafetyWaf.tip2': 'document',
  'cdnResources.detail.tabKey7.SafetyWaf.tip3': ' to understand.',
  'cdnResources.detail.tabKey7.URLSigning.pop1':
    'A secure token consists of the following parameters:',
  'cdnResources.detail.tabKey7.URLSigning.pop2':
    'Expires—the expiration time of a URL or the time when an URL becomes invalid. The time is passed in the URL itself in a Unix timestamp format and takes part in hash generation. It is an optional parameter.',
  'cdnResources.detail.tabKey7.URLSigning.pop3': 'Path—a file path or file directory',
  'cdnResources.detail.tabKey7.URLSigning.pop4': 'Key—a URL signing key',
  'cdnResources.detail.tabKey7.URLSigning.pop5':
    'IP— an IP that provides access. It is optional and only one IP allowed when generating the hash key each time.',
  'cdnResources.detail.tabKey7.URLSigning.pop6':
    'A signed URL can be in one of the following formats:',
  'cdnResources.detail.tabKey7.URLSigning.pop7':
    'Query string format: http://example.com/filename?secure=DMF1ucDxtqgxwYQ&expires=1546300800&ip=1.2.3.4',
  'cdnResources.detail.tabKey7.URLSigning.pop8':
    'Path format: http://example.com/secure=DMF1ucDxtqgxwYQ&expires=1546300800&ip=1.2.3.4/filename',
  'cdnResources.detail.tabKey7.Access.label1': ' Disabled',
  'cdnResources.detail.tabKey7.Access.label2': ' Allow all by default',
  'cdnResources.detail.tabKey7.Access.label3': ' Block all by default',
  'cdnResources.detail.tabKey7.allowed': 'allowed all by default',
  'cdnResources.detail.tabKey7.block': 'blocked all by default',
  'cdnResources.detail.tabKey7.disabled': 'disabled',

  'cdnResources.detail.tabKey7.SafetyNginx.title': 'Nginx Settings',
  'cdnResources.detail.tabKey7.SafetyNginx.title1': 'Rate Settings',
  'cdnResources.detail.tabKey7.SafetyNginx.title2': 'Cache Key',
  'cdnResources.detail.tabKey7.SafetyNginx.text1':
    'Support limiting the corresponding speed of each request to customers.',
  'cdnResources.detail.tabKey7.SafetyNginx.text2': 'Current setting:  When the request reaches',
  'cdnResources.detail.tabKey7.SafetyNginx.text3': ', the response speed drops to',
  'cdnResources.detail.tabKey7.SafetyNginx.text4':
    'Support selecting one of the four types of keys to cache.',
  'cdnResources.detail.tabKey7.SafetyNginx.message': 'Please do not exceed 2T.',
  // v1.1.13
  'cdnResources.detail.tabKey8.tabs1': 'Cache rules',
  'cdnResources.detail.tabKey8.tabs2': 'Redict rules',
  'cdnResources.detail.tabKey8.tabs3': 'Modify header',
  'cdnResources.detail.tabKey8.tabs4': 'Origin rules',
  'cdnResources.detail.tabKey8.tabs.tip0':
    'How to bypass the edge server of LightCDN and directly access the origin? ',
  'cdnResources.detail.tabKey8.tabs.tip1':
    'Is there too many redirection prompts when accessing the origin through CDN? ',
  'cdnResources.detail.tabKey8.tabs.tip2': 'Let’s see how to solve it! →',
  'cdnResources.detail.tabKey8.tabs.tip3': "Let's take a look at how to solve it! →",
  'cdnResources.detail.tabKey8.tabs1.create': 'Create a new rule', // {num}
  'cdnResources.detail.tabKey8.tabs1.p1': 'Customize HTTP caching rules. ',
  'cdnResources.detail.tabKey8.tabs1.p2': 'Read the documentation to learn more.',
  'cdnResources.detail.tabKey8.tabs1.p3.1': 'You can currently create ',
  'cdnResources.detail.tabKey8.tabs1.p3.2': 'rules. ',
  'cdnResources.detail.tabKey8.tabs1.p3.3': 'Go buy more rules.',
  'cdnResources.detail.tabKey8.tabs1.p4': 'Go buy more rules.',
  'cdnResources.detail.tabKey8.tabs1.table.title1': 'Name',
  'cdnResources.detail.tabKey8.tabs1.table.title2': 'Options',
  'cdnResources.detail.tabKey8.tabs1.table.compensation': 'System compensation',
  'cdnResources.detail.tabKey8.tabs1.form.title1.message': 'Please input Name!',
  'cdnResources.detail.tabKey8.tabs1.form.title1.exists': 'The rule name already exists.',
  'cdnResources.detail.tabKey8.tabs1.form.title1.invalidName':
    'Invalid name, please enter letters or numbers.',
  'cdnResources.detail.tabKey8.tabs1.form.invalidName': 'Invalid name',
  'cdnResources.detail.tabKey8.tabs1.form.title1.cnMessage': 'Please enter letters or numbers.',
  'cdnResources.detail.tabKey8.tabs1.form.title1.button': 'Generate a name',
  'cdnResources.detail.tabKey8.tabs1.form.title2': 'Conditions(When incoming requests match...)',
  'cdnResources.detail.tabKey8.tabs1.form.title3': 'Actions ( Then...)',
  'cdnResources.detail.tabKey8.tabs1.form.title4': 'Act',
  'cdnResources.detail.tabKey8.tabs1.form.title4.message': 'Please input Act',
  'cdnResources.detail.tabKey8.tabs1.form.title4.message1':
    'Please select at least one action to enable.',
  'cdnResources.detail.tabKey8.tabs1.form.title5': 'Never cache',
  'cdnResources.detail.tabKey8.tabs1.form.title6': 'Cache',
  'cdnResources.detail.tabKey8.tabs1.form.title7': 'Force edge to never cache',
  'cdnResources.detail.tabKey8.tabs1.form.title8': 'Force client to never cache',
  'cdnResources.detail.tabKey8.tabs1.form.title9': 'Force edge to cache',
  'cdnResources.detail.tabKey8.tabs1.form.title10': 'Force client to cache',
  'cdnResources.detail.tabKey8.tabs1.form.title11': 'Override default cache validity',
  'cdnResources.detail.tabKey8.tabs1.form.title12': 'TTL',
  'cdnResources.detail.tabKey8.tabs1.form.title12.message': 'Please enter TTL',
  'cdnResources.detail.tabKey8.tabs1.form.title7.text':
    'Forces the CDN edge server to never cache the request. However, if the request is already cached (for example, if it was cached prior to setting up this rule), it will not be forced out of cache.',
  'cdnResources.detail.tabKey8.tabs1.form.title8.text':
    'Forces the client to never cache the request. This is achieved by removing all Cache-Control and Expires response headers, sending instead “Cache-Control: no-cache”.',
  'cdnResources.detail.tabKey8.tabs1.form.title9.text':
    'Forces the CDN edge server to cache the request for a specified duration. This overrides any Cache-Control or Expires headers from the origin, even if they specify “private” or “no-cache”.',
  'cdnResources.detail.tabKey8.tabs1.form.title9.second': 'The value must be 1 second or longer.',
  'cdnResources.detail.tabKey8.tabs1.form.title10.text':
    'Forces the client to cache the request for a specified duration. This is achieved by removing all Cache-Control and Expires response headers, sending instead “Cache-Control: max-age=...”.',
  'cdnResources.detail.tabKey8.tabs1.form.title11.text':
    'Changes the CDN edge server’s default validity period for origin responses that do not explicitly specify Cache-Control or Expires. Responses with Cache-Control or Expires headers are still honored.',
  'cdnResources.detail.tabKey8.tabs1.form.item1.name': 'Connective',
  'cdnResources.detail.tabKey8.tabs1.form.item2.name': 'Subject',
  'cdnResources.detail.tabKey8.tabs1.form.item3.name': 'Cookie/Param/Header',
  'cdnResources.detail.tabKey8.tabs1.form.item3.message': 'Please input Cookie/Param/Header',
  'cdnResources.detail.tabKey8.tabs1.form.item4.name': 'Predicate',
  'cdnResources.detail.tabKey8.tabs1.form.item5.name': 'Value',
  'cdnResources.detail.tabKey8.tabs1.form.item5.message': 'Please input Value',
  'cdnResources.detail.tabKey8.tabs1.form.create': 'Create a new rule',
  'cdnResources.detail.tabKey8.tabs1.form.save': 'save changes',
  'cdnResources.detail.tabKey8.tabs1.PopoverText1': 'Edit',
  'cdnResources.detail.tabKey8.tabs1.PopoverText2': 'Delete',
  'cdnResources.detail.tabKey8.tabs1.Delete.content':
    'The rule will become invalid after deletion. Are you sure you want to delete it?',
  'cdnResources.detail.tabKey8.tabs2.title': 'Quickly enable configuration',
  'cdnResources.detail.tabKey8.tabs2.QuicklyEnable': 'Quickly enable',
  'cdnResources.detail.tabKey8.tabs2.card.title': 'HTTPS redirect',
  'cdnResources.detail.tabKey8.tabs2.card.p1':
    'Returns a 301 Redirect response from http address to https address instead of loading from cache or upstream.',
  'cdnResources.detail.tabKey8.tabs2.card.p2':
    'We will directly generate a rule for you and display it in the list below.',
  'cdnResources.detail.tabKey8.tabs2.p1':
    'Create rules to redirect visitors from a source URL to a target URL. ',
  'cdnResources.detail.tabKey8.tabs2.form.title1': 'Forbid client',
  'cdnResources.detail.tabKey8.tabs2.form.title2': 'Set Redirection',
  'cdnResources.detail.tabKey8.tabs2.form.title3': 'Redirect Client from HTTP to HTTPs',
  'cdnResources.detail.tabKey8.tabs2.form.title4': 'Redirect Client',
  'cdnResources.detail.tabKey8.tabs2.form.title5': 'URL',
  'cdnResources.detail.tabKey8.tabs2.form.title5.message': 'Please input URL',
  'cdnResources.detail.tabKey8.tabs2.form.title1.text':
    'Returns a simple 403 Forbidden response to the client.',
  'cdnResources.detail.tabKey8.tabs2.form.title2.text':
    'Returns a 301 Redirect response from http address to https address instead ofloading from cache or upstream.',
  'cdnResources.detail.tabKey8.tabs2.form.title3.text':
    'Returns a 302 Redirect response to the client, to the specified URL. The URL must be specified in full, starting with http:// or https://.',
  'cdnResources.detail.tabKey8.tabs3.p1':
    'Adjust HTTP headers of requests and responses at the edge. ',
  'cdnResources.detail.tabKey8.tabs3.form.title1': 'Set Request Header',
  'cdnResources.detail.tabKey8.tabs3.form.title2': 'Set Response Header',
  'cdnResources.detail.tabKey8.tabs3.form.title3': 'Set Client IP In Request Header',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Header': 'Header',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Header.message': 'Please input Header',
  'cdnResources.detail.tabKey8.tabs3.form.title1.HeaderValue.message':
    'Please input Header and Value',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Value': 'Value',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Value.message': 'Please input Value',
  'cdnResources.detail.tabKey8.tabs3.form.title1.text':
    'Overrides a request header to the origin. A header name and header value must be provided.',
  'cdnResources.detail.tabKey8.tabs3.form.title2.text':
    'Overrides a response header to the client. A header name and header value must be provided.',
  'cdnResources.detail.tabKey8.tabs3.form.title3.text':
    'Sets the client’s IP address in a request header to the origin. A header name must be provided.',
  'cdnResources.detail.tabKey8.tabs4.p1':
    'Customize the destination of the origin request for configuration. ',
  'cdnResources.detail.tabKey8.tabs4.form.title1': 'Prepend Origin Directory',
  'cdnResources.detail.tabKey8.tabs4.form.title2': 'Set Custom Origin',
  'cdnResources.detail.tabKey8.tabs4.form.title3': 'Passthrough HTTP Host Header to Origin',
  'cdnResources.detail.tabKey8.tabs4.form.title4': 'Other settings',
  'cdnResources.detail.tabKey8.tabs4.form.title1.text':
    'Prepends a directory to the URL when the edge server requests it from the origin.',
  'cdnResources.detail.tabKey8.tabs4.form.title2.text':
    'Overrides the origin that the edge server connects to.',
  'cdnResources.detail.tabKey8.tabs4.form.title3.text':
    'Goes back to the origin when the condition = true, during the first time, and the subsequent request (being cached in edge) will not go back to the origin again although the condition = true.',
  'cdnResources.detail.tabKey8.InvalidFormat': 'Invalid format',
};
