import { get, post, put } from '../request';
import { APP_URL } from '../url_config';

// 获取用户月度账单列表
export async function getMonthBills_api(params?: any) {
  return get(`${APP_URL}month-bill/v1/month-bills/user`, params);
}
// 下载月度账单
export async function postDownloadMonthBill_api(params: { monthBillUUID: string; userId: any }) {
  return post(`${APP_URL}month-bill/v1/month-bill/user/download`, params, {
    params,
    responseType: 'blob',
  });
}
// 获取用户账单地址
export async function getBillAddress_api(params?: any) {
  return get(`${APP_URL}bill-address/v1/bill-address`, params);
}
// 保存用户账单地址
export async function putBillAddress_api(params?: any) {
  return put(`${APP_URL}bill-address/v1/bill-address`, params);
}
