export default {
  'requests.topTitle': 'Requests report',
  'requests.name': 'requests',
  'requests.topTitle.text': '(Please view the data according to utc+0.)',
  'requests.content.title': 'Hit/Miss',
  'requests.menu.text1': 'Edge locations',
  'requests.menu.text2': 'Continents',
  'requests.menu.text3': 'CDN Resources',
  'requests.chart.text1': 'Hit',
  'requests.chart.text2': 'Miss',
  'requests.chart.text3': 'Referrer',
  'requests.Request': 'Request',
  'requests.oneColumns.title2': 'Requests',
  'requests.oneColumns.title3': 'Hit / %',
  'requests.oneColumns.title4': 'Miss / %',
};
