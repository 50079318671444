/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { userAccess?: string[] } | any) {
  const { hasMonthPlan } = initialState || {};
  const payState: any = JSON.parse(sessionStorage?.getItem('payState') || '{}');
  return {
    hasMonthPlan: payState?.type === 'payResult' && payState?.orderId ? false : !hasMonthPlan, //支付中隐藏菜单
    // HomeAccess: initialState.accountUsable,
    // CDNResourcesAccess: initialState.accountUsable,
    // SSLCerificatesAccess: initialState.accountUsable,
    // TrafficAccess: initialState.accountUsable,
    // BandWidthAccess: initialState.accountUsable,
    // RequestsAccess: initialState.accountUsable,
    // StatusCodesAccess: initialState.accountUsable,
    // AccountSettingsAccess: initialState.accountUsable,
    // BillingAccess: initialState.accountUsable,
  };
}
