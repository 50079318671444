/**
 * 日期单位枚举
 */
export const dateUnitEnum = [
  {
    text: 'days',
    value: 'days',
    'zh-CN': '天',
    'zh-HK': '天',
  },
  {
    text: 'day',
    value: 'day',
    'zh-CN': '天',
    'zh-HK': '天',
  },
  {
    text: 'hours',
    value: 'hours',
    'zh-CN': '小时',
    'zh-HK': '小時',
  },
  {
    text: 'hour',
    value: 'hour',
    'zh-CN': '小时',
    'zh-HK': '小時',
  },
  {
    text: 'minutes',
    value: 'minutes',
    'zh-CN': '分钟',
    'zh-HK': '分鐘',
  },
  {
    text: 'minute',
    value: 'minute',
    'zh-CN': '分钟',
    'zh-HK': '分鐘',
  },
];
