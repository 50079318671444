import { useState, useCallback } from 'react';
// api
import { getSyncPayment, getPaymentTransactionId_api } from '@/api/prod';
import moment from 'moment';

export default function usePay() {
  const [payResult, setPayResult] = useState<string>('pending');
  const [failReason, setFailReason] = useState<string>('');

  // 获取交易记录
  const handleCheck = async (id: any, date: number | string, res?: any) => {
    const syncRes = await getSyncPayment(id); //获取交易记录
    if (syncRes.success) {
      if (syncRes.paymentStatus === 'PAYING') {
        if (moment(date).isAfter(moment())) {
          setTimeout(() => {
            handleCheck(id, syncRes?.timeoutData || date, res);
          }, 1000);
        } else {
          setFailReason('timeout');
          setPayResult('fail');
        }
      } else if (syncRes.paymentStatus === 'PAID') {
        if (res?.orderType === 'OPERATE_BALANCE') {
          gtag?.('event', 'pay_success', {
            page_title: 'cdn recharge',
          });
        } else {
          gtag?.('event', 'click_payMp_success', {
            page_title: res?.orderType === 'UPGRADE' ? 'cdn upgrade' : 'cdn purchase',
          });
        }
        // if (res?.resourceType === 'CDN_EXTRA_TRAFFIC') {
        //   // 续包购买成功
        //   gtag?.('event', 'pay_extra_success', {
        //     event_name: 'click',
        //   });
        // }
        setPayResult('success');
      } else if (syncRes.paymentStatus !== 'PAYING') {
        setPayResult('fail');
      }
    } else {
      // setTimeout(() => {
      //   handleCheck(id, date, res);
      // }, 1000);
    }
  };

  const getPaymentTransactionId = useCallback((state: any, initialState: any) => {
    const payState = sessionStorage?.getItem('payState');
    if ((state?.type === 'payResult' && state?.orderId && !payState) || payState) {
      let orderId = state?.orderId;
      if (payState) {
        orderId = JSON.parse(payState)?.orderId;
        const type = JSON.parse(payState)?.type;
        if (type === 'payFail') {
          setPayResult('fail');
          return;
        }
      } else {
        sessionStorage.setItem('payState', JSON.stringify(state));
      }
      getPaymentTransactionId_api(orderId, { type: state.orderType }).then((res) => {
        if (res.success) {
          if (res?.paymentTransactionId) {
            const d = Date.now() + 2 * 60 * 1000;
            handleCheck(res?.paymentTransactionId, d, { ...res, ...initialState });
          } else if (res.resourceType === 'CDN_FREE_TRY' && res.orderStatus === 'PAID') {
            //免费套餐没有交易记录id，所以直接成功
            gtag?.('event', 'pay_free_success', {
              event_name: 'click',
            });
            setPayResult('success');
          }
        } else {
          setPayResult('fail');
        }
      });
    } else if (state?.type === 'payFail' && state?.orderId && !payState) {
      sessionStorage.setItem('payState', JSON.stringify(state));
      setPayResult('fail');
    }
  }, []);

  return {
    payResult,
    failReason,
    getPaymentTransactionId,
    setPayResult,
    setFailReason,
  };
}
