export default {
  'home.Today': '今天',
  'home.topTitle': '带宽',
  'home.reportTitle': '大洲数据报告',
  'home.statusTitle': '状态码报告',
  'home.reportTitle.memus1': '流量',
  'home.reportTitle.memus2': '缓存率',
  'home.headerTitle': '概览',
  'home.homeTip': '（请按照 utc+0 的时区查看数据。）',
  'home.ProCard.title1': '峰值',
  'home.ProCard.title2': '峰值发生时间',
  'home.StatusOverView.series': '请求',
  'home.StatusOverView.upViewbtn': '查看完成的状态码报告 →',
  'home.line.series': '总计',
  'home.menuTable.menu1': '流量',
  'home.requestData.series': '命中',
  'home.traffic.upViewbtn': '查看完整的流量报告 →',
  'home.requests.upViewbtn': '查看完整的请求报告 →',
  'home.oneColumns.Continent': '大洲',
  'home.oneColumns.Traffic': '流量',
  'home.oneColumns.Percent': '百分比',
  'home.twoColumns.Time': '时间',
  'home.twoColumns.Bandwidth_peak': '带宽峰值',
  'home.threeColumns.Hit_ratio': '命中率',
  'home.statusColumns.status_code': '状态码',
  'home.statusColumns.Requests': '请求',

  'home.header.title': '你好！欢迎回来',
  'home.balance.tip1':
    '很遗憾，您的账户余额不足，您将无法再使用LightCDN服务。 要继续您的服务，请检查您的',
  'home.balance.tip2': '账单',
  'home.balance.tip3': '，为您的帐户充值或购买包月套餐。 如有疑问请联系客服或联系',
  'home.balance.tip4': '发送邮件。',
  'home.locations': 'CDN加速位置',
  'home.top.title1': 'HTTP错误报告（4XX 和 5XX）排名前5',
  'home.top.title2': 'CDN资源排名前5',
  'home.top.title3': '国家/地区来访者排名前5',
  'home.top.title4': '来源排名前5',
  'home.TopToday.Traffic': '流量（今日）',
  'home.TopToday.Bandwidth': '带宽（今日）',
  'home.TopToday.Peak': '峰值',
  'home.TopToday.PeakTime': '峰值时间',
  'home.TopToday.CacheHitRate': '缓存命中率（今日）',
};
