import React, { useEffect, useState } from 'react';
import { useModel, history, FormattedMessage, getLocale } from 'umi';
// 组件
import { Space, Button, Tooltip, Tag } from 'antd';
import Avatar from './AvatarDropdown';
// import GiftCode from '@/pages/common/GiftCode';
import SetLocale from '@/components/SetLocale';
import { useFomatter } from '@/hooks';
import { useRequest } from 'ahooks';
import { divide, round } from 'lodash';
import { conversionUnit, dollarFormat } from '@/utils/utils';
import Notify from './notify';
import moment from 'moment';
import { getPostpaidStatistics_api } from '@/api/account';
import { getBillAddress_api } from '@/api/bill';
// 图片
// import cells from '@/assets/images/icon/cells.png';
import IconHelp from '@/assets/images/icon/Help.png';
import dollarImg from '@/assets/images/icon/icon-dollar.png';
import chartImg from '@/assets/images/icon/icon-chart.png';
// 样式
import styles from './index.less';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  const [percentage, setPercentage] = useState<number>(0); //使用流量百分比
  const { formatter } = useFomatter();
  const { initialState } = useModel('@@initialState');
  const { trafficData } = initialState || {};
  const {
    trafficUsage = {},
    trafficRemain = {},
    monthPlanInfo = {},
    totalTraffic = {},
    // treeTryInfo = {},
  } = trafficData || {};
  // const { monthPlantItem = {} } = monthPlanInfo;
  const locale = getLocale();

  // 本月使用情况
  const { run: getPostpaidStatistics, data: postpaidData } = useRequest(getPostpaidStatistics_api, {
    manual: true,
    initialData: {},
    formatResult: (res: any) => res.data || {},
  });
  //得到用户账单地址，判断用户是否有填写
  const { run: runGetBillAdress, data: isBillAddress } = useRequest(getBillAddress_api, {
    manual: true,
    initialData: true,
    formatResult: (res: any) => !!res?.email,
  });
  useEffect(() => {
    getPostpaidStatistics({
      beginDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
    });
    runGetBillAdress();
  }, []);
  useEffect(() => {
    if (trafficData?.totalTraffic) {
      let _percentage = 0;
      // 如果单位一样直接计算，否则先换算单位
      let total = trafficData?.totalTraffic?.traffic;
      if (trafficData?.trafficUsage?.trafficUnit !== trafficData?.totalTraffic?.trafficUnit) {
        total = conversionUnit(
          trafficData?.totalTraffic?.traffic,
          trafficData?.totalTraffic?.trafficUnit,
          trafficData?.trafficUsage?.trafficUnit,
        );
      }
      _percentage = round(divide(trafficData?.trafficUsage?.traffic, total) * 100, 2);
      setPercentage(_percentage);
    }
  }, [trafficData]);

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let _SpaceClass = styles.right;
  if (navTheme === 'dark' && layout === 'top') {
    _SpaceClass = `${styles.right}  ${styles.dark}`;
  }

  const Usage = (
    <div className={styles.usageDetail}>
      <div className="usage-block">
        {monthPlanInfo?.hasService ? (
          <div className="u-left">
            {/* 有月度计划，展示剩余量，如果已经超量，则展示超量值 */}
            <span>
              <FormattedMessage
                id={trafficRemain?.traffic ? 'menu.Usage.Remaining' : 'menu.Usage.Overage'}
              />
            </span>
            {trafficRemain?.traffic ? (
              <b>
                {trafficRemain?.traffic || 0}
                {trafficRemain.trafficUnit || ''}
              </b>
            ) : (
              <b>
                {monthPlanInfo?.excessTraffic?.traffic || 0}
                {monthPlanInfo?.excessTraffic?.trafficUnit || ''}
              </b>
            )}
          </div>
        ) : (
          <div className="u-left">
            {/* 无月度计划，展示当月使用量 */}
            <span>
              <FormattedMessage id="menu.Usage.Usage" />({moment().locale(locale).format('MMM')})
            </span>
            <b>
              {postpaidData.traffic?.traffic || 0}
              {postpaidData.traffic?.trafficUnit || ''}
            </b>
          </div>
        )}
        <div className="u-right">
          <img src={chartImg} />
        </div>
        {monthPlanInfo?.hasService ? (
          <div className="u-bottom c-color1">
            <span>
              <FormattedMessage id="menu.Usage.Usage" />
              <Tag color="rgba(255, 132, 23, 0.1)">
                {trafficUsage?.traffic || trafficUsage?.traffic === 0
                  ? trafficUsage.traffic + trafficUsage.trafficUnit
                  : '--'}
              </Tag>
            </span>
            <span>
              <FormattedMessage id="menu.Usage.Total" />
              <Tag color="rgba(255, 132, 23, 0.1)">
                {totalTraffic?.traffic || totalTraffic?.traffic === 0
                  ? totalTraffic.traffic + totalTraffic.trafficUnit
                  : '--'}
              </Tag>
            </span>
          </div>
        ) : null}
      </div>
      <div className="usage-block">
        <div className="u-left">
          <span>{formatter({ id: 'menu.Usage.balance' })}</span>
          <b>
            {dollarFormat(trafficData?.accountVo?.balance || 0, 2)}
            {trafficData?.accountVo?.balance < 0 ? (
              <Tag
                color="rgba(246, 64, 65, 0.1)"
                style={{
                  verticalAlign: 'middle',
                  marginTop: '-3px',
                  marginLeft: 5,
                  color: '#F64041',
                }}
              >
                {formatter({ id: 'menu.Usage.Overdue' })}
              </Tag>
            ) : null}
          </b>
        </div>
        <div className="u-right">
          <img src={dollarImg} />
        </div>
        {/* <div className="u-bottom c-color1">
          <span>
            {formatter({ id: 'menu.Usage.Equal' })}
            <Tag color="rgba(255, 184, 0, 0.1)">
              {substrNumber(trafficData?.accountVo?.remainTraffic?.traffic || 0)}
              {trafficData?.accountVo?.remainTraffic?.trafficUnit || ''}
            </Tag>
          </span>
        </div> */}
      </div>
      <div className="usage-button">
        <Button
          type="primary"
          onClick={() => {
            if (isBillAddress) {
              gtag?.('event', 'click_recharge04', {
                page_title: 'cdn recharge04',
              });
            }
            history.push(isBillAddress ? '/billing?modalVal=recharge' : '/billing');
          }}
        >
          <FormattedMessage id="billing.Payment.Form.BALANCE.Recharge" />
        </Button>
      </div>
    </div>
  );
  return (
    <div className={styles.headerNav}>
      <Notify />
      <Space className={_SpaceClass} size="large">
        {/* <GiftCode /> */}
        <SetLocale isMoblie={true} />
        <a
          href="https://docs.lightcdn.com/"
          target="_blank"
          style={{ display: 'block' }}
          rel="noreferrer"
          className="u-help"
        >
          <img
            src={IconHelp}
            alt="help"
            title={formatter({ id: 'menu.Support' })}
            style={{ display: 'block' }}
          />
        </a>
        <Avatar />
        <Tooltip
          placement="bottomRight"
          title={Usage}
          color="#fff"
          overlayClassName="rightContent-Tooltip"
        >
          <div className={styles.HeadUsage}>
            {/* {!trafficData?.treeTryInfo?.hasService && !monthPlanInfo?.hasService ? (
              <img src={cells} alt="cells" />
            ) : ( */}
            <span className={`${styles.HeadUsageIcon} anticon`}>
              {trafficData?.treeTryInfo?.trafficUsagePercentage || 0}%
            </span>
            {/* )} */}
            {/* 20230203v1.1.0运营优化，改动展示 */}
            <span className={`${styles.name} anticon`}>
              {trafficData?.treeTryInfo?.hasService ? (
                <FormattedMessage
                  id="menu.Usage.UsedFree"
                  values={{
                    percentage: `${percentage}%`,
                  }}
                />
              ) : null}
              {monthPlanInfo?.hasService ? (
                <FormattedMessage
                  id="menu.Usage.UsedPro"
                  values={{
                    percentage: `${percentage}%`,
                  }}
                />
              ) : (
                <label>
                  <FormattedMessage
                    id="menu.Usage.AccountBalance"
                    values={{ balance: dollarFormat(trafficData?.accountVo?.balance || 0, 0) }}
                  />
                </label>
              )}
              {/* {!trafficData?.treeTryInfo?.hasService && !monthPlanInfo?.hasService ? (
                <label style={{ marginLeft: 10 }}>
                  <FormattedMessage id="menu.Usage.Suspension" />
                </label>
              ) : null} */}
            </span>
            <span className={styles.triggle} />
          </div>
        </Tooltip>
      </Space>
    </div>
  );
};
export default GlobalHeaderRight;
