import { Input } from 'antd';

let isDelete = false;
const InputNumber = (props: any) => {
  const onChange = (e: any) => {
    const { value } = e.target;
    const { isExpDate } = props;
    // 限制只能输入数字
    let v = value.replace(/\D+/g, '');
    if (isExpDate && value) {
      // MM数值范围为1-12，显示为01-12
      if (v.length === 1 && Number(v) > 1 && Number(v) < 10) {
        v = 0 + v;
      }
      if (v.length === 2 && Number(v) > 12) {
        v = '12';
      }
      const valueTemp = v.replace(/(\d{2})(\d*)/, function (a: string, b: string, c: string) {
        // 删除时自动去除/
        if (a.length === 2) {
          isDelete = !isDelete;
        }
        if (a.length > 2) {
          isDelete = true;
        }
        // YY数字范围21-99（即最小年份要>=当前年份-2000）
        const year = new Date().getFullYear() - 2000;
        let yy = c;
        if (a.length === 4 && c.length === 2 && Number(c) < year) {
          yy = String(year);
        }
        return isDelete ? b + '/' + yy : b + yy;
      });
      props?.onChange(valueTemp);
      return;
    }
    props?.onChange(v);
  };

  return (
    <Input
      onChange={onChange}
      value={props?.value}
      maxLength={props?.maxLength}
      placeholder={props?.placeholder}
    />
  );
};

export default InputNumber;
