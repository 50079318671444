// @ts-nocheck
import './core/polyfill';
import '../global.tsx';
import 'antd/dist/antd.less';
import { plugin } from './core/plugin';
import './core/pluginRegister';
import { createHistory } from './core/history';
import { ApplyPluginsType } from '/root/.npm/node_modules/@umijs/runtime';
import { renderClient } from '/root/.npm/node_modules/@umijs/renderer-react/dist/index18.js';
import { getRoutes } from './core/routes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { history } from './core/history';
import { matchPath } from 'react-router-dom';

import '../global.less';
import { _onCreate } from './plugin-locale/locale';
_onCreate();

const getClientRender = (args: { hot?: boolean; routes?: any[] } = {}) => plugin.applyPlugins({
  key: 'render',
  type: ApplyPluginsType.compose,
  initialValue: () => {
    const opts = plugin.applyPlugins({
      key: 'modifyClientRenderOpts',
      type: ApplyPluginsType.modify,
      initialValue: {
        routes: args.routes || getRoutes(),
        plugin,
        history: createHistory(args.hot),
        isServer: process.env.__IS_SERVER,
        dynamicImport: true,
        rootElement: 'root',
      },
    });
    return renderClient(opts);
  },
  args,
});

const clientRender = getClientRender();
export default clientRender();


    window.g_umi = {
      version: '3.5.34',
    };
  
Sentry.init({
      dsn:"https://becd3c42c9c84263886b40f1f258e885@sentry.kaopuyun.com/26",
      tracesSampleRate: 1,
      integrations: [new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history,getRoutes(),matchPath),
      })],
      ...{"ignoreErrors":["ResizeObserver loop limit exceeded","Failed to fetch","Failed to execute 'insertBefore' on 'Node'","Failed to execute 'removeChild' on 'Node'","Loading CSS chunk","Loading chunk","Unexpected token '<'","chunkId is not defined","Cannot read properties of null (reading 'removeChild')","Cannot set properties of undefined (setting 'cLt')","Ue is undefined","Failed to load Stripe.js"]}
    });

// hot module replacement
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./core/routes', () => {
    const ret = require('./core/routes');
    if (ret.then) {
      ret.then(({ getRoutes }) => {
        getClientRender({ hot: true, routes: getRoutes() })();
      });
    } else {
      getClientRender({ hot: true, routes: ret.getRoutes() })();
    }
  });
}
