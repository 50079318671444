export default {
  'cdnResources.list.deleteCdn.message': '删除成功',
  'cdnResources.list.columns.Tag.suspend': '暂停',
  'cdnResources.list.Popconfirm.title': '您确定删除该CDN资源吗？',
  'cdnResources.list.Popconfirm.okText': '是的',
  'cdnResources.list.Popconfirm.cancelText': '不，取消',
  'cdnResources.list.title': 'CDN资源',
  'cdnResources.list.Create': '创建新的CDN资源',
  'cdnResources.list.Create.Disabled': '已达到上限',
  //v1.2.0
  'cdnResources.list.ConfirmAndCreate': '确认并创建',
  'cdnResources.list.Empty.btnText': '开始加速',
  'cdnResources.list.Empty.desc': '3分钟创建资源，开启加速',
  'cdnResources.list.columns.title1': 'CDN加速域名',
  'cdnResources.list.columns.title2': 'CNAME',
  'cdnResources.list.columns.title3': '来源',
  'cdnResources.list.columns.title4': '操作选项',
  //v1.2.0
  'cdnResources.list.columns.title5': '档位',
  'cdnResources.list.columns.title6': '本月用量',
  'cdnResources.list.PopoverText1': '数据详情',
  'cdnResources.list.PopoverText2': '设置',
  'cdnResources.list.PopoverText3': '删除资源',
  'cdnResources.list.PopoverText4': '清除所有缓存',

  'cdnResources.create.step1.fristFree': '前{val}免费',

  'cdnResources.create.stepBox.step1': '基础设置',
  'cdnResources.create.stepBox.step2': '完成',
  'cdnResources.create.onSubmit.message': '创建成功',
  'cdnResources.create.step1.validatorCdnHostName.same': 'CDN加速域名和源是一样的',
  'cdnResources.create.step1.Form.cdnHostName.name': 'CDN加速域名',
  'cdnResources.create.step1.Form.cdnHostName.message': '请输入您的CDN加速域名！',
  'cdnResources.create.step1.Form.origin.name': '来源',
  'cdnResources.create.step1.Form.origin.message': '请输入来源！',
  'cdnResources.create.step1.Form.Checkbox.name': '使用自定义端口',
  'cdnResources.create.step1.Form.port.name': '端口（非必填）',
  'cdnResources.create.step1.Form.port.message': '请输入端口号！',
  'cdnResources.create.step2.message': '创建成功',
  'cdnResources.create.step2.title1': 'CDN资源已经创建成功，CNAME将在',
  'cdnResources.create.step2.title2': '秒内生成',
  'cdnResources.create.step1.tip1':
    '您要加速的网址通常是对用户开放的公开显示地址。 完成后，您可以使用我们的 CNAME 或您的显示地址来加速您的资源。',
  'cdnResources.create.step1.tip2': '在此处填写web内容源的地址，可以是URL或IP。',
  'cdnResources.create.step1.tip3': '(注意URL格式：{domain1}、 {domain2}，不支持{domain3}）',
  'cdnResources.create.step1.tip3.0': '注意URL格式：',
  'cdnResources.create.step1.tip4': '来源政策为AUTO时，不支持填写端口。',
  'cdnResources.create.step1.help1': '如果你需要更多帮助，你可以 ',
  'cdnResources.create.step1.help2': '点击这里。',
  'cdnResources.create.step1.origin.note1': '请注意：',
  'cdnResources.create.step1.origin.note2': '多源仅能以IP的格式填写，端口号必须一致。',
  'cdnResources.create.step1.origin.message': '超过一条源时，仅可使用IP地址。',
  'cdnResources.create.step1.origin.validatorMessage1': '请填写有效的源。',
  'cdnResources.create.step1.origin.validatorMessage2': '请填写有效的加速域名。',
  'cdnResources.create.step1.origin.validatorMessage3': '这个IP地址已存在。',
  'cdnResources.create.step1.origin.validatorMessage4': '请填写有效的IP地址。',
  'cdnResources.create.step1.origin.validatorMessage5': '多源时，端口号必须一致。',
  'cdnResources.create.step1.origin.unique': '源已经存在',
  'cdnResources.create.step1.hostname.unique': 'CDN加速域名已存在。',
  //v1.2.0
  'cdnResources.create.StandardNetwork': '标准网络',
  'cdnResources.create.CoreNetwork': '核心网络',
  'cdnResources.create.noPlan.modal.text': '余额不足&没有计划',
  'cdnResources.create.noPlan.modal.p': '您还有免费流量可以兑换！',
  'cdnResources.create.noPlan.modal.button': '获取方案',
  'cdnResources.create.No.additional': '无额外收费。',
  'cdnResources.detail.tabKey4.tier.Current': '当前档位',
  'cdnResources.detail.tabKey4.tier.edit': '更换档位',
  'cdnResources.detail.tabKey4.tier.Usage': '本月用量',
  'cdnResources.detail.tabKey4.tier.Unitprice': '单价',
  'cdnResources.detail.tabKey4.EnableEdgeLocation': '开启边缘位置',
  'cdnResources.detail.tabKey4.tier.Modal.p1': '您当前没有此边缘位置的权限。请切档位，然后重试。',
  'cdnResources.detail.tabKey4.tier.Recommended': '推荐档位：',
  'cdnResources.create.NetworkList.moreFavorable': '点击此处获取更优惠的价格',
  'cdnResources.create.title.resources': '创建您的CDN资源',
  'cdnResources.create.title.tier': '选择您的档位',
  'cdnResources.create.tier.loading': '正在为你处理资源，请等待...',
  'cdnResources.create.NetworkList.tierRecharge.p1': '以下档位需要确保余额充足，',
  'cdnResources.create.NetworkList.tierRecharge.p2': '请前往充值',
  'cdnResources.create.NetworkList.planLearn.p1':
    '您当前有一个月度计划，该计划将自动激活相应的档位。',
  'cdnResources.create.NetworkList.planLearn.p2': '了解你的月度计划',
  'cdnResources.create.postpaid.price.p':
    '如果半小时的流量极小，我们将扣除0.000001美元的最低金额。如果超过此金额，我们将根据正常计费扣除。',
  'cdnResources.detail.NoPermission': '无权限',
  'cdnResources.detail.SuggestedTier': '建议档位：',

  'cdnResources.detail.title': 'CDN资源',
  'cdnResources.detail.tabKey1': '基础设置',
  'cdnResources.detail.tabKey2': '高级设置',
  'cdnResources.detail.tabKey3': '加速位置',
  'cdnResources.detail.tabKey4': '清除',
  'cdnResources.detail.tabKey5': '预存',
  'cdnResources.detail.tabKey6': '激活配置',
  'cdnResources.detail.tabKey7': '安全保护',
  'cdnResources.detail.tabKey8': '规则集',
  'cdnResources.detail.tabKey1.onSuccess.message': '更新成功',
  'cdnResources.detail.tabKey1.title': '基础信息',
  'cdnResources.detail.tabKey1.cdnHostName.name': 'CDN加速域名',
  'cdnResources.detail.tabKey1.cdnHostName.message': '请输入',
  'cdnResources.detail.tabKey1.cdnHostName.placeholder': '每个CDN加速域名都需要填写一行，最多七行',
  'cdnResources.detail.tabKey1.cdnHostName.validatorMessage': '请输入有效的主机名。',
  // 'cdnResources.detail.tabKey1.origin.name': 'Origin',
  'cdnResources.detail.tabKey1.port.name': '端口',
  'cdnResources.detail.tabKey1.select.message': '请选择',
  'cdnResources.detail.tabKey1.Button': '新增',
  'cdnResources.detail.tabKey1.ssl.title': 'SSL',
  'cdnResources.detail.tabKey1.ssl.Tag1': '已启用',
  'cdnResources.detail.tabKey1.ssl.Tag2': '未启用',
  'cdnResources.detail.tabKey1.ssl.tip':
    '加速域名必须具有SSL证书，才能通过HTTPS访问。您可以打开SSL证书来配置域名。',
  'cdnResources.detail.tabKey1.ssl.tip1': '若你刚配置完CDN，发现你的站点',
  'cdnResources.detail.tabKey1.ssl.tip2': '无法安全打开',
  'cdnResources.detail.tabKey1.ssl.tip3': '，说明你需要在我们平台上进行SSL证书的配置。',
  'cdnResources.detail.tabKey1.ssl.group.label1': "Let's Encrypt",
  'cdnResources.detail.tabKey1.ssl.group.p1':
    "请输入完成的主机名，并确保它不以'.r.cdn36.com'结尾，不然他将无法自动开启SSL证书。",
  'cdnResources.detail.tabKey1.ssl.group.label2': '自义定 SNI SSL',
  'cdnResources.detail.tabKey1.ssl.group.p2':
    '若开启辅助加速域名，自定义SNI证书将同时作用于加速域和辅助加速域。',
  'cdnResources.detail.tabKey1.HostName.title': '辅助加速域名',
  'cdnResources.detail.tabKey1.HostName.modal.title': '保存',
  'cdnResources.detail.tabKey1.HostName.modal.text1':
    "如果你开启了辅助加速域名，我们将自动为你切换或开启Let's Encrypt SSL证书。",
  'cdnResources.detail.tabKey1.HostName.modal.text2': '请确认是否需要开启辅助加速域名？',
  'cdnResources.detail.tabKey1.HostName.message1': '可以填写的辅助CDN加速域名已超过上限！',
  'cdnResources.detail.tabKey1.HostName.message2': '不能重复或跟CDN加速域名相同。',
  'cdnResources.detail.tabKey1.HostName.message3': '填写的域名不可跟加速域相同。',
  'cdnResources.detail.tabKey1.HostName.message4': '不可重复或与CDN加速域名和辅助CDN加速域名相同。',
  'cdnResources.detail.tabKey1.HostName.tip1':
    'CDN辅助域支持添加多个加速域名，用户可以利用并行下载来加快页面加载速度。您需要为CDN主机名和辅助CDN主机名称设置CNAME。更多详情',
  'cdnResources.detail.tabKey1.HostName.tip2': '查看文档',
  'cdnResources.detail.tabKey2.tip.name': '如何绕过 LightCDN 的边缘服务器直接访问源呢？',
  'cdnResources.detail.tabKey2.tip.pop': '点击进入引导',
  'cdnResources.detail.tabKey2.tip.content':
    '你可以尝试为特定的文件将具有 Cache-Control 标头的CDN资源的缓存规则添加为“no-cache”或者“max-age = 0”。同时，您可以将Force Edge设置为从不缓存，这将强制CDN边缘服务器在缓存规则中从不缓存请求。',
  'cdnResources.detail.tabKey2.HLS_force_cache.name': 'HLS 强制缓存',
  'cdnResources.detail.tabKey2.HLS_force_cache.p1':
    '强制m3u8文件边缘服务器不缓存并支持设置过期时间。 该设置仅在启用',
  'cdnResources.detail.tabKey2.HLS_force_cache.p2': '“HLS优化”',
  'cdnResources.detail.tabKey2.HLS_force_cache.p3': '时生效。',
  'cdnResources.detail.tabKey2.title': '缓存过期时间',
  'cdnResources.detail.tabKey2.Modal.title': '添加SSL证书',
  'cdnResources.detail.tabKey2.cacheExpiry.name': '缓存有效期',
  'cdnResources.detail.tabKey2.cacheExpiry.message': '请输入缓存有效期！',
  'cdnResources.detail.tabKey2.cacheExpiry.fieldProps': '分钟 (默认12天)',
  'cdnResources.detail.tabKey2.cacheExpiry.default': '默认',
  'cdnResources.detail.tabKey2.cacheExpiry404.name': '404状态码的缓存有效期',
  'cdnResources.detail.tabKey2.cacheExpiry404.fieldProps': '1分钟 (默认)',
  'cdnResources.detail.tabKey2.rules.title': '配置',
  'cdnResources.detail.tabKey2.originPolicy.name': '来源政策',
  'cdnResources.detail.tabKey2.originPolicy.label1': '自动',
  'cdnResources.detail.tabKey2.originPolicy.label2': 'HTTP',
  'cdnResources.detail.tabKey2.originPolicy.label3': 'HTTPS',
  'cdnResources.detail.tabKey2.originPolicy.tip1':
    'LightCDN的来源政策选项允许您设置从CDN边缘服务器到源服务器的拉取请求的协议。你可以',
  'cdnResources.detail.tabKey2.originPolicy.tip2': '查看文档',
  'cdnResources.detail.tabKey2.originPolicy.tip3': '来了解更多。',
  'cdnResources.detail.tabKey2.originPolicy.p1':
    'HTTP—边缘服务器和始终使用HTTP的源之间的连接。它是默认值。',
  'cdnResources.detail.tabKey2.originPolicy.p2': 'HTTPS—边缘服务器和始终使用HTTPS的源之间的连接。',
  'cdnResources.detail.tabKey2.originPolicy.p3':
    'AUTO—基于访问者请求（HTTP或HTTPS）的边缘服务器和源服务器之间的连接。但请注意，当您的源已填写端口时，无法选择AUTO。',
  'cdnResources.detail.tabKey2.corsOn.name': 'CORS 标头',
  'cdnResources.detail.tabKey2.corsOn.p1':
    '启用后，通过添加具有访问控制允许源的HTTP标头来启用跨域资源共享（CORS）。',
  'cdnResources.detail.tabKey2.corsOn.p2':
    "若开启后，访问加速域名报错，“No 'Access-Control-Allow-Origin' header is present on the requested resource.” ，可",
  'cdnResources.detail.tabKey2.corsOn.p3': '查看文档',
  'cdnResources.detail.tabKey2.corsOn.p4': '看具体解决方式。',
  'cdnResources.detail.tabKey2.hostHeaderForwarding.name': '主机标头转发',
  'cdnResources.detail.tabKey2.hostHeaderForwarding.p1': `CDN边缘服务器仅在边缘服务器中不存在文件（缓存）时（对于所有场景）才返回原始位置，并且在从CDN边缘-->原始位置的路径中会出现“passthrough http host header to origin”功能。此功能不是“使所有请求返回到源”，而是“将主机标头信息发送到源”。`,
  'cdnResources.detail.tabKey2.IgnoreSetCookie.name': '缓存 Set-Cookie',
  // 'cdnResources.detail.tabKey2.IgnoreSetCookie.p1':
  //   'CDN为会话跟踪设置cookie。我们的CDN不缓存带有Set-Cookie响应标头的任何内容，包括JPEG文件等静态内容。如果因此导致缓存命中率为0%，请启用此设置，以便缓存此类响应。',
  'cdnResources.detail.tabKey2.IgnoreSetCookie.p1':
    'LightCDN不缓存带有Set-Cookie响应标头的任何内容，包括JPEG等静态内容，如果因此导致缓存命中率0%，请启用此设置，一边缓存此类响应。缓存Set-Cookie的相关内容可以参考',
  'cdnResources.detail.tabKey2.IgnoreSetCookie.p2': '此处文档。',
  'cdnResources.detail.tabKey2.HTTPSRedirect.name': 'HTTPS重定向',
  'cdnResources.detail.tabKey2.HTTPSRedirect.p1':
    '返回从http地址到https地址的301重定向响应，而不是从缓存或上游加载。',
  'cdnResources.detail.tabKey2.StreamingMedia.name': '流媒体',
  'cdnResources.detail.tabKey2.StreamingMedia.MP4': 'MP4伪流',
  'cdnResources.detail.tabKey2.StreamingMedia.FLV': 'FLV伪流媒体',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS': 'HLS优化',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS.p1': '如果您想创建强制缓存过期的规则，可以在',
  'cdnResources.detail.tabKey2.StreamingMedia.HLS.p2': '页面的缓存规则部分快速启用。',

  'cdnResources.detail.tabKey3.header1': '亚太地区',
  'cdnResources.detail.tabKey3.header2': '中东',
  'cdnResources.detail.tabKey3.header3': '欧洲',
  'cdnResources.detail.tabKey3.header4': '北美地区',
  'cdnResources.detail.tabKey3.header5': '南美地区',
  'cdnResources.detail.tabKey3.header6': '非洲地区',
  'cdnResources.detail.tabKey3.text1': '硅谷',
  'cdnResources.detail.tabKey3.text2': '迈阿密',
  'cdnResources.detail.tabKey3.text3': '华盛顿',
  'cdnResources.detail.tabKey3.text4': '圣保罗',
  'cdnResources.detail.tabKey3.text5': '法兰克福',
  'cdnResources.detail.tabKey3.text6': '迪拜',
  'cdnResources.detail.tabKey3.text7': '新加坡',
  'cdnResources.detail.tabKey3.text8': '胡志明市',
  'cdnResources.detail.tabKey3.text9': '香港',
  'cdnResources.detail.tabKey3.text10': '东京',
  'cdnResources.detail.tabKey3.text11': '利雅得',
  'cdnResources.detail.tabKey3.text12': '开罗',
  'cdnResources.detail.tabKey3.text13': '伦敦',
  'cdnResources.detail.tabKey3.text14': '马赛',
  'cdnResources.detail.tabKey3.text15': '曼谷',
  'cdnResources.detail.tabKey3.text16': '马尼拉',
  'cdnResources.detail.tabKey3.text17': '河内',
  'cdnResources.detail.tabKey3.text18': '金边',
  'cdnResources.detail.tabKey3.text19': '马斯喀特',
  'cdnResources.detail.tabKey3.text20': '约翰内斯堡',
  'cdnResources.detail.tabKey3.text21': '巴林',
  'cdnResources.detail.tabKey3.Panel1': '亚太地区',
  'cdnResources.detail.tabKey3.Panel1.p1': '香港，中国',
  'cdnResources.detail.tabKey3.Panel1.p2': '东京，日本',
  'cdnResources.detail.tabKey3.Panel1.p3': '新加坡',
  'cdnResources.detail.tabKey3.Panel1.p4': '曼谷，泰国',
  'cdnResources.detail.tabKey3.Panel1.p5': '马尼拉，菲律宾',
  'cdnResources.detail.tabKey3.Panel1.p6': '河内，越南',
  'cdnResources.detail.tabKey3.Panel1.p7': '金边，柬埔寨',
  'cdnResources.detail.tabKey3.Panel2': '中东地区',
  'cdnResources.detail.tabKey3.Panel2.p1': '迪拜，阿拉伯联合酋长国',
  'cdnResources.detail.tabKey3.Panel2.p2': '利雅得，沙特阿拉伯',
  'cdnResources.detail.tabKey3.Panel2.p3': '马斯喀特，阿曼',
  'cdnResources.detail.tabKey3.Panel2.p4': '巴林',
  'cdnResources.detail.tabKey3.Panel3': '欧洲',
  'cdnResources.detail.tabKey3.Panel3.p1': '法兰克福，德国',
  'cdnResources.detail.tabKey3.Panel3.p2': '伦敦，英国',
  'cdnResources.detail.tabKey3.Panel3.p3': '马赛，法国',
  'cdnResources.detail.tabKey3.Panel4': '北美',
  'cdnResources.detail.tabKey3.Panel4.p1': '硅谷，美国',
  'cdnResources.detail.tabKey3.Panel4.p2': '华盛顿，美国',
  'cdnResources.detail.tabKey3.Panel5': '南美地区',
  'cdnResources.detail.tabKey3.Panel5.p1': '圣保罗，巴西',
  'cdnResources.detail.tabKey3.Panel6': '非洲地区',
  'cdnResources.detail.tabKey3.Panel6.p1': '开罗，埃及',
  'cdnResources.detail.tabKey3.Panel6.p2': '约翰内斯堡，南非',
  'cdnResources.detail.tabKey4.tip':
    '清除工具允许您立即从所有数据中心删除缓存内容。您可以使用特定路径单独清除文件，也可以一次清除所有文件。',
  'cdnResources.detail.tabKey4.Radio1': '清除所有文件',
  'cdnResources.detail.tabKey4.Radio2': '清除特定文件',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.message': '请输入清除路径',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.placeholder':
    '/images/image01.png （多路径需要换行填写）',
  'cdnResources.detail.tabKey4.Radio2.purgePaths.Button': '清除特定文件',
  'cdnResources.detail.tabKey4.ProCard.title': '清除日志',
  'cdnResources.detail.tabKey4.ProCard.subTitle': '（仅保存3个月的日志数据）',
  'cdnResources.detail.tabKey4.ProCard.Option1': '所有日志',
  'cdnResources.detail.tabKey4.ProCard.Option2': '清除所有文件',
  'cdnResources.detail.tabKey4.ProCard.Option3': '清除特定文件',
  'cdnResources.detail.tabKey4.columns.purgePaths.Button': '查看文件路径',
  'cdnResources.detail.tabKey4.selected.message': '请确保你至少选择了一个边缘位置加速。',
  'cdnResources.detail.tabKey4.Modal.title': '文件路径',
  'cdnResources.detail.tabKey4.Modal.text': '所有文件',
  'cdnResources.detail.tabKey4.ModalPurge.title': '清除所有文件',
  'cdnResources.detail.tabKey4.ModalPurge.text':
    '如果您选择清除所有文件，我们将再次从您的源中提取文件。是否确实要清除所有缓存？',
  'cdnResources.detail.tabKey4.ModalPurge.button': '是的，我确定。',
  'cdnResources.detail.tabKey4.Modal.node.title': '加速节点列表',
  'cdnResources.detail.tabKey4.Modal.node.tip': '保存后，你将加速的节点为：',

  'cdnResources.detail.tabKey5.onSuccess.message': '提交成功',
  'cdnResources.detail.tabKey5.tip': `预取工具允许您将新添加的内容预填充到CDN缓存中。这是如果你最近上传了一个大文件到你的服务器上，特别有用，因为您可以快速提示缓存服务器获取该文件并强制它们缓存该文件。`,
  'cdnResources.detail.tabKey5.Form.prefetchPaths.message': '请输入预存路径',
  'cdnResources.detail.tabKey5.Form.prefetchPaths.Button': '预存特定路径',
  'cdnResources.detail.tabKey5.Prefetchlog.title': '预存日志',
  'cdnResources.detail.tabKey6.title1': '用CNAME配置',
  'cdnResources.detail.tabKey6.text1': '通过DNS加速所有静态文件。',
  'cdnResources.detail.tabKey6.text2': '1.您可以在您的域名服务商处添加CNAME',
  'cdnResources.detail.tabKey6.text3': '加速域名：',
  'cdnResources.detail.tabKey6.text4': 'CNAME：',
  'cdnResources.detail.tabKey6.text5': '2.DNS生成需要一些时间。您可以使用以下工具检查DNS',
  'cdnResources.detail.tabKey6.text6': '状态：',
  'cdnResources.detail.tabKey6.text7': '3.这就完成了！',
  'cdnResources.detail.tabKey6.text8': '开始您的LightCDN CDN之旅！如果您有任何其他问题，请联系我们',
  'cdnResources.detail.tabKey6.text9': '客服',
  'cdnResources.detail.tabKey6.title2': '不用CNAME配置',
  'cdnResources.detail.tabKey6.title11': '简单的设置，只需更新指向静态文件的URL。',
  'cdnResources.detail.tabKey6.title12':
    '1.更改需要CDN加速的每个文件（图像、视频、CSS等）的源路径：',
  'cdnResources.detail.tabKey6.title13': '不用CDN：',
  'cdnResources.detail.tabKey6.title14': '用CDN：',
  'cdnResources.detail.tabKey6.text15': '2.这就完成了！',
  'cdnResources.detail.tabKey7.title': '访问保护',
  'cdnResources.detail.tabKey7.countries.name': '国家白名单',
  'cdnResources.detail.tabKey7.CountryAccess.p1':
    '配置规则以启用/禁用对指定国家/地区CDN资源内容的访问。',
  'cdnResources.detail.tabKey7.CountryAccess.p2': '你{val}访问，除了部分国家。',
  'cdnResources.detail.tabKey7.clickHere': '点击此处查看列表。',
  'cdnResources.detail.tabKey7.countries.title': '（以下国家/地区除外，可不填写）',
  'cdnResources.detail.tabKey7.hotlinkDomains.name': 'URL白名单',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p1':
    '配置一个规则，以启用/禁用对指定URL的CDN资源内容的访问。',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p2': '你{val}访问，除了部分域名。 ',
  'cdnResources.detail.tabKey7.hotlinkPolicy.p3': '（CDN加速域名和辅助加速域名将被默认添加。）',
  'cdnResources.detail.tabKey7.domians.title': '（以下域名除外，可不填写）',
  'cdnResources.detail.tabKey1.domians.message': '请输入域名！',
  'cdnResources.detail.tabKey1.domians.placeholder': 'www.example.com（多个域名需要换行填写。）',
  'cdnResources.detail.tabKey7.ipAddresses.name': 'IP白名单',
  'cdnResources.detail.tabKey7.IPAccess.p1':
    '配置一个规则，以启用/禁用对一系列IP地址（包括IPv4和IPv6）的CDN资源内容的访问。',
  'cdnResources.detail.tabKey7.IPAccess.p2': '你{val}，除了部分IP地址。',
  'cdnResources.detail.tabKey7.addresses.title': '（以下IP地址除外，可不填写）',
  'cdnResources.detail.tabKey1.addresses.message': '请输入IP地址！',
  'cdnResources.detail.tabKey1.addresses.placeholder':
    '10.10.10.10,20.20.20.0/24,2001:CDBA::64,...',
  'cdnResources.detail.tabKey7.URLSigning.name': 'URL签名',
  'cdnResources.detail.tabKey7.URLSigning.p1': '使用密钥保护您的文件免受未经授权的访问。 ',
  'cdnResources.detail.tabKey7.signingKey.title': 'URL签名秘钥',
  'cdnResources.detail.tabKey7.URLSigning.Generate': '生成一个新的',
  'cdnResources.detail.tabKey7.URLSigning.message': '请输入6-32的字符串，不支持空格。',
  'cdnResources.detail.tabKey7.URLSigning.validatorMessage': 'URL签名密钥无效。',
  'cdnResources.detail.tabKey7.crawlers.name': '阻止搜索引擎爬网程序',
  'cdnResources.detail.tabKey7.crawlers.p1':
    '阻止搜索引擎爬网程序用于阻止网络爬网机器人对CDN内容进行索引。',
  'cdnResources.detail.tabKey7.Modal.here.title': '{name}列表',
  'cdnResources.detail.tabKey7.Modal.here.tip': '你{val}，除了：',
  'cdnResources.detail.tabKey7.SafetyWaf.name': 'OWASP最大威胁',
  'cdnResources.detail.tabKey7.SafetyWaf.title': 'WAF',
  'cdnResources.detail.tabKey7.SafetyWaf.tip1': '如果您不知道OWSAP的具体定义，可以查看',
  'cdnResources.detail.tabKey7.SafetyWaf.tip2': '文档',
  'cdnResources.detail.tabKey7.SafetyWaf.tip3': '去了解。',
  'cdnResources.detail.tabKey7.URLSigning.pop1': '安全令牌由以下参数组成：',
  'cdnResources.detail.tabKey7.URLSigning.pop2':
    'Expires — URL的到期时间或URL变得无效的时间。时间以Unix时间戳格式在URL中传递，并参与哈希生成。它是一个可选参数。',
  'cdnResources.detail.tabKey7.URLSigning.pop3': 'Path — 文件路径或文件目录',
  'cdnResources.detail.tabKey7.URLSigning.pop4': 'Key — 一个URL签名秘钥',
  'cdnResources.detail.tabKey7.URLSigning.pop5':
    'IP — 提供访问的IP。它是可选的，每次生成哈希键时只允许使用一个IP。',
  'cdnResources.detail.tabKey7.URLSigning.pop6': 'URL签名可以采用以下格式之一：',
  'cdnResources.detail.tabKey7.URLSigning.pop7':
    '查询字符串格式： http://example.com/filename?secure=DMF1ucDxtqgxwYQ&expires=1546300800&ip=1.2.3.4',
  'cdnResources.detail.tabKey7.URLSigning.pop8':
    '路径格式：http://example.com/secure=DMF1ucDxtqgxwYQ&expires=1546300800&ip=1.2.3.4/filename',
  'cdnResources.detail.tabKey7.Access.label1': '未开启',
  'cdnResources.detail.tabKey7.Access.label2': '默认开启所有',
  'cdnResources.detail.tabKey7.Access.label3': '默认阻止所有',
  'cdnResources.detail.tabKey7.allowed': '默认开启所有',
  'cdnResources.detail.tabKey7.block': '默认阻止所有',
  'cdnResources.detail.tabKey7.disabled': '未开启',
  'cdnResources.detail.tabKey7.SafetyNginx.title': 'Nginx设置',
  'cdnResources.detail.tabKey7.SafetyNginx.title1': '速率设置',
  'cdnResources.detail.tabKey7.SafetyNginx.title2': '缓存键',
  'cdnResources.detail.tabKey7.SafetyNginx.text1': '支持限制客户每个请求的相应速度。',
  'cdnResources.detail.tabKey7.SafetyNginx.text2': '当前设置：当请求达到了',
  'cdnResources.detail.tabKey7.SafetyNginx.text3': '，响应速度将降至',
  'cdnResources.detail.tabKey7.SafetyNginx.text4': '支持从四种类型的缓存键中选择一种进行缓存。',
  'cdnResources.detail.tabKey7.SafetyNginx.message': '请不要超过2T。',
  // v1.1.13
  'cdnResources.detail.tabKey8.tabs1': '缓存规则',
  'cdnResources.detail.tabKey8.tabs2': '重定向规则',
  'cdnResources.detail.tabKey8.tabs3': '修改标头',
  'cdnResources.detail.tabKey8.tabs4': '源规则',
  'cdnResources.detail.tabKey8.tabs.tip0': '如何绕过 LightCDN 的边缘服务器直接访问源呢？',
  'cdnResources.detail.tabKey8.tabs.tip1': '通过CDN访问源站提示重定向的次数过多？',
  'cdnResources.detail.tabKey8.tabs.tip2': '来看看如何解决吧！→',
  'cdnResources.detail.tabKey8.tabs.tip3': '来看看如何解决吧！→',
  'cdnResources.detail.tabKey8.tabs1.create': '创建一条新的规则', // {num}
  'cdnResources.detail.tabKey8.tabs1.p1': '自定义HTTP缓存规则。',
  'cdnResources.detail.tabKey8.tabs1.p2': '阅读文档来了解更多。',
  'cdnResources.detail.tabKey8.tabs1.p3.1': '你当前可以创建',
  'cdnResources.detail.tabKey8.tabs1.p3.2': '条规则。',
  'cdnResources.detail.tabKey8.tabs1.p3.3': '去购买更多的规则。',
  'cdnResources.detail.tabKey8.tabs1.p4': '去购买更多的规则。',
  'cdnResources.detail.tabKey8.tabs1.table.title1': '名称',
  'cdnResources.detail.tabKey8.tabs1.table.title2': '选项',
  'cdnResources.detail.tabKey8.tabs1.table.compensation': '系统补偿',
  'cdnResources.detail.tabKey8.tabs1.form.title1.message': '请输入名称！',
  'cdnResources.detail.tabKey8.tabs1.form.title1.exists': '规则名称已存在。',
  'cdnResources.detail.tabKey8.tabs1.form.title1.invalidName': '无效名称，请输入字母或数字。',
  'cdnResources.detail.tabKey8.tabs1.form.invalidName': '无效名称',
  'cdnResources.detail.tabKey8.tabs1.form.title1.cnMessage': '请输入字母或数字。',
  'cdnResources.detail.tabKey8.tabs1.form.title1.button': '生成一个名称',
  'cdnResources.detail.tabKey8.tabs1.form.title2': '条件（传入请求匹配…时）',
  'cdnResources.detail.tabKey8.tabs1.form.title3': '操作（然后…）',
  'cdnResources.detail.tabKey8.tabs1.form.title4': '动作',
  'cdnResources.detail.tabKey8.tabs1.form.title4.message': '请选择动作',
  'cdnResources.detail.tabKey8.tabs1.form.title4.message1': '请至少选择一个动作开启。',
  'cdnResources.detail.tabKey8.tabs1.form.title5': '从不缓存',
  'cdnResources.detail.tabKey8.tabs1.form.title6': '缓存',
  'cdnResources.detail.tabKey8.tabs1.form.title7': '强制边缘从不缓存',
  'cdnResources.detail.tabKey8.tabs1.form.title8': '强制客户端从不缓存',
  'cdnResources.detail.tabKey8.tabs1.form.title9': '强制边缘缓存',
  'cdnResources.detail.tabKey8.tabs1.form.title10': '强制客户端缓存',
  'cdnResources.detail.tabKey8.tabs1.form.title11': '覆盖默认缓存有效性',
  'cdnResources.detail.tabKey8.tabs1.form.title12': 'TTL',
  'cdnResources.detail.tabKey8.tabs1.form.title12.message': '请输入TTL',
  'cdnResources.detail.tabKey8.tabs1.form.title7.text':
    '强制CDN边缘服务器从不缓存请求。但是，如果请求已经缓存（例如，如果它是在设置此规则之前缓存的），则不会强制将其从缓存中取出。',
  'cdnResources.detail.tabKey8.tabs1.form.title8.text':
    '强制客户端从不缓存请求。这是通过删除所有“Cache-Control”和“Expires”响应标头来实现的，而非发送“Cache-Control: no-cache”。',
  'cdnResources.detail.tabKey8.tabs1.form.title9.text':
    '强制CDN边缘服务器将请求缓存指定的持续时间。这将覆盖来自源的任何“Cache-Control”或“Expires”标头，即使它们指定了“private”或“no-cache”。',
  'cdnResources.detail.tabKey8.tabs1.form.title9.second': '值必须填写1s或更长。',
  'cdnResources.detail.tabKey8.tabs1.form.title10.text':
    '强制客户端将请求缓存指定的持续时间。这是通过删除所有“Cache-Control”和“Expires”响应标头来实现的，而非发送“Cache-Control: max-age=...”。',
  'cdnResources.detail.tabKey8.tabs1.form.title11.text':
    '为未明确指定“Cache-Control”或“Expires”的源响应更改CDN边缘服务器的默认有效期。具有“Cache Control”或“Expires”标头的响应仍将被接受。',
  'cdnResources.detail.tabKey8.tabs1.form.item1.name': '条件',
  'cdnResources.detail.tabKey8.tabs1.form.item2.name': '主题',
  'cdnResources.detail.tabKey8.tabs1.form.item3.name': 'Cookie/Param参数/Header标头',
  'cdnResources.detail.tabKey8.tabs1.form.item3.message': '请输入Cookie/Param参数/Header标头',
  'cdnResources.detail.tabKey8.tabs1.form.item4.name': '谓语',
  'cdnResources.detail.tabKey8.tabs1.form.item5.name': '值',
  'cdnResources.detail.tabKey8.tabs1.form.item5.message': '请输入值',
  'cdnResources.detail.tabKey8.tabs1.form.create': '创建一条新的规则',
  'cdnResources.detail.tabKey8.tabs1.form.save': '保存修改',
  'cdnResources.detail.tabKey8.tabs1.PopoverText1': '编辑',
  'cdnResources.detail.tabKey8.tabs1.PopoverText2': '删除',
  'cdnResources.detail.tabKey8.tabs1.Delete.content':
    '删除后，该规则将变为无效。你确定要删除它吗？',
  'cdnResources.detail.tabKey8.tabs2.title': '快速开启配置',
  'cdnResources.detail.tabKey8.tabs2.QuicklyEnable': '快速开启',
  'cdnResources.detail.tabKey8.tabs2.card.title': 'HTTPS重定向',
  'cdnResources.detail.tabKey8.tabs2.card.p1':
    '返回从http地址到https地址的301重定向响应，而不是从缓存或上游加载。',
  'cdnResources.detail.tabKey8.tabs2.card.p2':
    '我们将直接为您生成一个规则，并将其显示在下面的列表中。',
  'cdnResources.detail.tabKey8.tabs2.p1': '创建规则将访问者从源URL重定向到目标URL。',
  'cdnResources.detail.tabKey8.tabs2.form.title1': '禁止访问客户端',
  'cdnResources.detail.tabKey8.tabs2.form.title2': '设置重定向',
  'cdnResources.detail.tabKey8.tabs2.form.title3': '将客户端从HTTP重定向到HTTPs',
  'cdnResources.detail.tabKey8.tabs2.form.title4': '重定向客户端',
  'cdnResources.detail.tabKey8.tabs2.form.title5': 'URL',
  'cdnResources.detail.tabKey8.tabs2.form.title5.message': '请输入URL',
  'cdnResources.detail.tabKey8.tabs2.form.title1.text': '向客户端返回一个简单的403 Forbidden响应。',
  'cdnResources.detail.tabKey8.tabs2.form.title2.text':
    '返回从http地址到https地址的301重定向响应，而不是从缓存或上游加载。',
  'cdnResources.detail.tabKey8.tabs2.form.title3.text':
    '将302重定向响应返回到客户端的指定URL。必须完整指定URL，以http://或https://开头。',
  'cdnResources.detail.tabKey8.tabs3.p1': '调整边缘的请求和响应的HTTP标头。',
  'cdnResources.detail.tabKey8.tabs3.form.title1': '设置请求标头',
  'cdnResources.detail.tabKey8.tabs3.form.title2': '设置响应标头',
  'cdnResources.detail.tabKey8.tabs3.form.title3': '在请求标头中设置客户端IP',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Header': '标头',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Header.message': '请输入标头',
  'cdnResources.detail.tabKey8.tabs3.form.title1.HeaderValue.message': '请输入标头和值',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Value': '值',
  'cdnResources.detail.tabKey8.tabs3.form.title1.Value.message': '请输入值',
  'cdnResources.detail.tabKey8.tabs3.form.title1.text':
    '将请求标头覆盖到源。必须提供标头名称和标头值。',
  'cdnResources.detail.tabKey8.tabs3.form.title2.text':
    '覆盖客户端的响应标头。必须提供标头名称和标头值。',
  'cdnResources.detail.tabKey8.tabs3.form.title3.text':
    '将请求标头中客户端的IP地址设置为源。必须提供标头名称。',
  'cdnResources.detail.tabKey8.tabs4.p1': '自定义原始配置请求的目的地。',
  'cdnResources.detail.tabKey8.tabs4.form.title1': '准备源目录',
  'cdnResources.detail.tabKey8.tabs4.form.title2': '设置自定义源',
  'cdnResources.detail.tabKey8.tabs4.form.title3': '将HTTP主机头传递到源',
  'cdnResources.detail.tabKey8.tabs4.form.title4': '其他设置',
  'cdnResources.detail.tabKey8.tabs4.form.title1.text':
    '当边缘服务器从源位置请求时，将目录设置为预先准备的URL。',
  'cdnResources.detail.tabKey8.tabs4.form.title2.text': '替代边缘服务器连接到源。',
  'cdnResources.detail.tabKey8.tabs4.form.title3.text':
    '当条件为true时，返回原点，在第一次期间，并且随后的请求（缓存在边缘中）将不会再次返回原点，尽管条件为true。',
  'cdnResources.detail.tabKey8.InvalidFormat': '无效格式',
};
