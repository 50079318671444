import { get, post } from '../request';
import { APP_URL } from '../url_config';

// 获取产品配置列表
export async function getSkusList_api(params: any) {
  return get(`${APP_URL}sku/v1/skus`, params);
}
// 获取免费产品配置列表
export async function getSkusFree_api() {
  return get(`${APP_URL}sku/v1/skus/free`);
}
// 获取订单价格
export async function postGetPrice_api(params: any) {
  return post(`${APP_URL}prod/v1/price`, params);
}
/**
 * 获取升级订单价格
 * @array monthPlanUUIDS  需要变更的月度计划id列表
 * @string orderType  订单类型
 * @string resourceType  资源类型
 * @string resourceUUID  资源id
 */
export async function postProdUpgradePrice_api(params: any) {
  return post(`${APP_URL}prod/upgrade/price`, params);
}
// 获取套餐选择页动态销售信息
export async function getSkuConfigDynamicSaleInfo_api() {
  return get(`${APP_URL}sku/v1/consoleSkuConfigDynamicSaleInfo`);
}
// 根据套餐uuid查询套餐信息
export async function getSkuInfoByUUID_api(uuid: string | number) {
  return get(`${APP_URL}sku/v1/getSkuInfoByUUID/${uuid}`);
}
// 自定义套餐需求意向统计
export async function postCustomerDemandIntentionStatistics_api() {
  return post(`${APP_URL}sku/v1/customerDemandIntentionStatistics`);
}
// 计算充值套餐价格
export async function postCalcRecharge_api(params: any) {
  return post(`${APP_URL}sku/v1/calc/recharge`, params);
}
