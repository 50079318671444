export default {
  'billing.title': '账单',
  'billing.tabTitle1': '概览',
  'billing.tabTitle2': '发票',
  'billing.tabTitle3': '支付设置',
  'billing.tabTitle4': '账单详情',
  'billing.tabTitle5': '支付记录',
  'billing.tabTitle6': '充值记录',
  'billing.tabTitle7': '邀请有礼',
  'billing.AddCard.postCreditCard.message': '成功添加信用卡',
  'billing.AddCard.Modal.title': '添加新的信用卡',
  'billing.AddCard.Modal.okText': '添加信用卡',
  'billing.AddCard.Form.number.title': '卡号',
  'billing.AddCard.Form.number.message': '请输入卡号',
  'billing.AddCard.Form.expDate.title': '到期时间',
  'billing.AddCard.Form.expDate.message': '请输入到期时间',
  'billing.AddCard.Form.expDate.validator': '错误日期',
  'billing.AddCard.Form.expDate.MMYY': 'MM/YY',
  'billing.AddCard.Form.cvc.title': '安全码',
  'billing.AddCard.Form.cvc.message': '请输入安全码',
  'billing.AddCard.Form.cvc.CVC': 'CVC',
  'billing.Payment.title': '支付',
  'billing.Payment.Form.MonthlyPlan.title': '月度计划',
  'billing.Payment.Form.MonthlyPlan.text': '每月',
  'billing.Payment.Form.oriPaymentAmt.title': '已支付金额',
  'billing.Payment.Form.price.title': '价格',
  'billing.Payment.Form.Overages.title': '超量',
  'billing.Payment.Form.discount.title': '优惠',
  'billing.Payment.Form.discount.placeholder': '请选择一个折扣',
  'billing.Payment.Form.discount.NoneAvailable': '没有可用的礼包码',
  'billing.Payment.Form.discount.text': '{num}个月 {per}折',
  'billing.Payment.Form.discount.traffic.text': '{num}个 {per}折',
  'billing.Payment.Form.discount.Annually': '年付',
  'billing.Payment.Form.discount.plan': '年付{price} & 月付{monthPrice}',
  'billing.Payment.Form.mo': '月付{price}',
  // 'billing.Payment.Form.totalPaymentAmt.title': '需支付金额',
  'billing.Payment.Form.totalPaymentAmt.title': '合计',
  'billing.Payment.Form.Over': '满 {num}',
  'billing.Payment.Form.CARD': '信用卡',
  'billing.Payment.Form.ALIPAY': '支付宝',
  'billing.Payment.Form.BALANCE': '账户余额',
  'billing.Payment.Form.BALANCE.Recharge': '充值',
  'billing.Payment.Form.paymentMethodId.message': '请选择',
  'billing.Payment.Form.paymentMethodId.Add': '添加信用卡',
  'billing.Payment.Form.Pay': '支付结算',
  'billing.Payment.Form.Pay.tips': '您当前还未填写账单信息，请前往账单详情填写后方可支付！',
  'billing.Payment.Pay.Modal.balance': '资源结算后余额不足。',
  'billing.Payment.Pay.Modal.debt': '账户欠款，请先结清。',
  'billing.Payment.Pay.Modal.recharge': '去充值',
  'billing.Detail.handleSave.message': '已成功修改',
  'billing.Detail.validatorEmail.message': '请输入正确的邮箱地址',
  'billing.Detail.validatorEnglish_number.message': '请输入英文或数字',
  'billing.Detail.validatorEnglish.message': '请输入英文',
  'billing.Detail.title': '账单详情',
  'billing.Detail.Form.company.title1': '公司',
  'billing.Detail.Form.company.title2': '（非必填）',
  'billing.Detail.Form.fullName.title': '全名',
  'billing.Detail.Form.fullName.message': '请输入',
  'billing.Detail.Form.email.title': '邮箱',
  'billing.Detail.Form.billingAddress.title': '街道',
  'billing.Detail.Form.city.title': '城市',
  'billing.Detail.Form.postalCode.title': '邮政编码',
  'billing.Detail.Form.Country.title': '国家/地区',
  'billing.Detail.Form.Country.message': '请选择国家/地区！',
  'billing.ExtraTraffic.title': '额外流量',
  'billing.ExtraTraffic.Form.plan1.title': '您当前的月度计划',
  'billing.ExtraTraffic.Form.plan2.title': '流量使用量',
  'billing.ExtraTraffic.Form.Quantity.title': '数量',
  'billing.Invoices.postDownloadMonthBill.message': '下载成功',
  'billing.Invoices.columns.title1': '支付日期',
  'billing.Invoices.columns.title2': '发票号',
  'billing.Invoices.columns.title3': 'VAT',
  'billing.Invoices.columns.title4': '总金额',
  'billing.Invoices.columns.title5': '下载',
  'billing.InvoicesHistory': '发票历史记录',
  'billing.Overview.ProCard.title1': '建议',
  'billing.Overview.ProCard.Button1': '购买',
  'billing.Overview.Item.title1': '您的试用剩余',
  'billing.Overview.Item.title1.render': '（{hour}后重置）',
  'billing.Overview.Item.title2': '之前购买的月度计划',
  'billing.Overview.Item.title3': '近30天的使用量',
  'billing.Overview.Item.title4': '最推荐的月度计划',
  'billing.Overview.ProCard.title2': '当前周期',
  'billing.Overview.ProCard.Button3': '升级',
  'billing.Overview.columns.title1': '服务周期',
  'billing.Overview.columns.title1.render': '至{slot}到期（{hour}后将重置流量）',
  'billing.Overview.columns.title2': '本周期使用量',
  'billing.Overview.columns.title2.1': '本周期剩余量',
  'billing.Overview.columns.title2.2': '本周期超量',
  'billing.Overview.columns.title3': '当前月度计划',
  'billing.Overview.columns.title4': '价格',
  'billing.Overview.hasService.columns.title2': '额外流量剩余',
  'billing.Overview.ProCard.Button4': '还款',
  'billing.Overages.title': '超量',
  'billing.Overage.title': '超量',
  'billing.Overview.Overages.columns.title1': '累计超量',
  'billing.Overview.Overages.columns.title2': '本周期超量',
  'billing.Overview.Overages.columns.title3': '超量的单价',
  'billing.PaymentSetting.title': '信用卡',
  'billing.PaymentSetting.delCreditCard.message': '删除成功',
  'billing.PaymentSetting.Button1': '解除绑定',
  'billing.PaymentSetting.Button2': '设为默认',
  'billing.PaymentSetting.expires': '到期 ',
  'billing.PaymentSetting.Modal.title': '解绑信用卡',
  'billing.PaymentSetting.Modal.p': '您确认解绑该信用卡吗',
  'billing.PayResult.handleCheck.message': '正在处理付款',
  'billing.PayResult.timeout.message': '超时',
  'billing.PayResult.success.message': '支付成功',
  'billing.PayResult.fail.message': '支付失败',
  'billing.PayResult.title': '账单',
  'billing.PayResult.p1': '正在为您配置，请稍后。',
  'billing.PayResult.p2': '购买成功。',
  'billing.PayResult.p3': '发生了错误。',
  'billing.PayResult.p4': '您可以去账单中去确认您的购买。',
  'billing.Purchase.title1': '升级月度计划',
  'billing.Purchase.title2': '购买月度计划',
  'billing.Purchase.Form.monthPlan.title': '选择新的月度计划',
  'billing.Purchase.Form.Choose.p1': '选择购买周期',
  'billing.Purchase.Form.Choose.p2': '您可以最多购买12个月。一个月我们将以30天计算。',
  'billing.Purchase.Form.Choose.p2-free': '您可以买一个月。一个月内，我们将以30天为基础计算价格。',
  'billing.Purchase.Form.purchaseQuantity.title': '购买周期',
  'billing.cardList.text': '我们支持：',
  'billing.PaymentRecord.columns.title1': '订单号',
  'billing.PaymentRecord.columns.title2': '描述',
  'billing.PaymentRecord.columns.title2.text1': '购买月度计划 {val}',
  'billing.PaymentRecord.columns.title2.text2': '购买额外流量  {val}',
  'billing.PaymentRecord.columns.title2.text3': '升级月度计划 {val}',
  'billing.PaymentRecord.columns.title2.text4': '',
  'billing.PaymentRecord.columns.title2.text5': '月度计划退款  {val}',
  'billing.PaymentRecord.columns.title2.text6': '额外流量退款  {val}',
  'billing.PaymentRecord.columns.title3': '金额',
  'billing.PaymentRecord.columns.title4': '状态',
  'billing.PaymentRecord.columns.title5': '创建时间',
  'billing.PaymentRecord.columns.title6': '支付时间',
  'billing.PaymentRecord.columns.title7': '支付方式',
  'billing.PaymentRecord.columns.title8': '操作',
  'billing.PaymentRecord.columns.pay': '支付',
  'billing.PaymentRecord.columns.pay.text': '提醒',
  'billing.PaymentRecord.Payment.topTip': '请转到账单详细信息填写，然后您就可以付款了！',
  'billing.PaymentRecord.Payment.bottomTip': '请在{amount}之前完成支付。',
  'billing.RechargeRecord.columns.title1': '充值金额',
  'billing.Purchase.OrderUpgrade.title': '选择需要升级的周期',
  'billing.Purchase.OrderUpgrade.Collapse.title': '以下周期已经购买了最高的月流量，无法升级。',
  'billing.Purchase.customized.text1': '不满足您的需求？联系客服或',
  'billing.Purchase.customized.text2': '申请一个定制的月度计划！',
  'billing.Purchase.customized.text0': '您已经申请了定制的月度计划，请等待处理。',
  'billing.PaymentTerms.title': '请确认你已理解支付条款。',
  'billing.PaymentTermsModal.title': '支付条款',
  'billing.PaymentTermsModal.text1':
    '购买月计划后，我们支持购买额外的流量用来在即将超限时进行补充。',
  'billing.PaymentTermsModal.text2': '否则，我们将要求您以｛val｝的单价偿还超量金额。',
  'billing.PaymentTermsModal.text3': '更多服务条款细节，请',
  'billing.PaymentTermsModal.text4': '点击此处查看',
  'billing.PaymentTermsModal.button': '好的，我理解了。',
  //v1.2.0
  'billing.AutoSettings.title': '自动化设置',
  'billing.AutoSettings.name': '自动扣费',
  'billing.AutoSettings.p1': '若要保留你的业务',
  'billing.AutoSettings.p2': '当月度计划过期时',
  'billing.AutoSettings.p3': '，请开启功能，届时我们将直接扣除余额，以确保您的业务不会中断。',
  'billing.AutoSettings.Default.tier': '默认档位',
  'billing.AutoSettings.autoModal.p0':
    '当月计划到期时，您的业务也将被保留，余额将根据同一档位的按量付费单价直接扣除，以确保您的业务不会中断。',
  'billing.AutoSettings.autoModal.p1.1': '当前',
  'billing.AutoSettings.autoModal.p1.2': '自动扣费',
  'billing.AutoSettings.autoModal.p1.3': '尚未开启。',
  'billing.AutoSettings.autoModal.p2':
    '若要保留你的业务当月度计划过期时，请开启功能，届时我们将直接扣除余额，以确保您的业务不会中断。',
  'billing.AutoSettings.autoModal.btn': '前往设置',
  'billing.Overview.ToRecharge': '要为您的帐户充值，请先填写您的账单详细信息。',
  'billing.Overview.btn.RechargeAccount': '充值账户',
  'billing.Overview.EquivalentTo.p1': '相当于',
  'billing.Overview.EquivalentTo.p2': '标准网络',
  'billing.Overview.EquivalentTo.free': '免费获取余额！',
  'billing.Overview.UsageInMonth': '{val}用量',
  'billing.Overview.UsageInMonth.p1': '该部分不包括月度计划中的流量。',
  'billing.Overview.UsageInMonth.p2.1': '你{val}的总用量是',
  'billing.Overview.UsageInMonth.p2.2': '且平均单价为',
  'billing.Overview.BillingHistory': '历史账单',
  'billing.Overview.PaymentAmount': '支付金额',
  'billing.RechargeModal.warnTip.p1': '你仍然还存在 ',
  'billing.RechargeModal.warnTip.p2': ' 的欠款。充值后，你的账户余额将剩余 ',
  'billing.RechargeModal.warnTip.p3': '。',
  'billing.RechargeModal.ChooseAmount': '选择金额',
  'billing.RechargeModal.minAmount.message': '充值金额不能低于$10。',
  'billing.RechargeModal.calcLink': '前往账单计算器，来计算价格 >',
  'billing.RechargeModal.firstRecharge.title': '恭喜你完成首充！',
  'billing.RechargeModal.firstRecharge.content': '让我们来看看你的资源可以如何调整网络档位吧！',
  'billing.RechargeModal.firstRecharge.btn': '进入教程',
  //v1.2.3
  'billing.EarnBalance.title1': '每邀请一名新用户，您将获得其充值金额 20% 的返利。',
  'billing.EarnBalance.title2': '总奖励',
  'billing.EarnBalance.title3': '邀请码：',
  'billing.EarnBalance.title4': '受邀人数总计：',
  'billing.EarnBalance.balance.p0': '满足以下条件的邀请人将获得奖励：',
  'billing.EarnBalance.balance.p1': '受邀用户为新用户，不能申请重复注册。',
  'billing.EarnBalance.balance.p2': '新用户产生付款。',
  'billing.EarnBalance.Share.Copy': '复制',
  'billing.EarnBalance.Share.Jump': '跳转',
  'billing.EarnBalance.columns.title1': '受邀者',
  'billing.EarnBalance.columns.title2': '奖励',
  'billing.EarnBalance.columns.title3': '受邀时间',
  'billing.EarnBalance.columns.title4': '更新时间',
  'billing.EarnBalance.status.p1': '持续14天的活跃资源',
  'billing.EarnBalance.status.p2': '新用户',
  'billing.EarnBalance.status.p3': '支付',
  'billing.EarnBalance.isBillAddress.text1': '为了保障你后续消费的权益，请',
  'billing.EarnBalance.isBillAddress.text2': '完善账单详情',
  'billing.EarnBalance.isBillAddress.text3': '后参与活动。',
  'billing.modal.NoticeSetting.text1': '过去30天消耗了余额：',
  'billing.modal.NoticeSetting.text2': '如果余额仅剩',
  'billing.modal.NoticeSetting.text3': '，我们将向您发送预警邮件。',
  'billing.modal.NoticeSetting.tip': '（如果您不需要警告，请留空。）',
};
