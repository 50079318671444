export default {
  'billing.title': '賬單',
  'billing.tabTitle1': '概覽',
  'billing.tabTitle2': '發票',
  'billing.tabTitle3': '支付設置',
  'billing.tabTitle4': '賬單詳情',
  'billing.tabTitle5': '支付記錄',
  'billing.tabTitle6': '充值記錄',
  'billing.tabTitle7': '邀請有禮',
  'billing.AddCard.postCreditCard.message': '成功添加信用卡',
  'billing.AddCard.Modal.title': '添加新的信用卡',
  'billing.AddCard.Modal.okText': '添加信用卡',
  'billing.AddCard.Form.number.title': '卡號',
  'billing.AddCard.Form.number.message': '請輸入卡號',
  'billing.AddCard.Form.expDate.title': '到期時間',
  'billing.AddCard.Form.expDate.message': '請輸入到期時間',
  'billing.AddCard.Form.expDate.validator': '錯誤日期',
  'billing.AddCard.Form.expDate.MMYY': 'MM/YY',
  'billing.AddCard.Form.cvc.title': '安全碼',
  'billing.AddCard.Form.cvc.message': '請輸入安全碼',
  'billing.AddCard.Form.cvc.CVC': 'CVC',
  'billing.Payment.title': '支付',
  'billing.Payment.Form.MonthlyPlan.title': '月度計劃',
  'billing.Payment.Form.MonthlyPlan.text': '每月',
  'billing.Payment.Form.oriPaymentAmt.title': '已支付金額',
  'billing.Payment.Form.price.title': '價格',
  'billing.Payment.Form.Overages.title': '超量',
  'billing.Payment.Form.discount.title': '优惠',
  'billing.Payment.Form.discount.placeholder': '請選擇一個折扣',
  'billing.Payment.Form.discount.NoneAvailable': '沒有可用的禮包碼',
  'billing.Payment.Form.discount.text': '{num}個月 {per}折',
  'billing.Payment.Form.discount.traffic.text': '{num}個 {per}折',
  'billing.Payment.Form.discount.Annually': '年付',
  'billing.Payment.Form.discount.plan': '年付{price} & 月付{monthPrice}',
  'billing.Payment.Form.mo': '月付{price}',
  // 'billing.Payment.Form.totalPaymentAmt.title': '需支付金額',
  'billing.Payment.Form.totalPaymentAmt.title': '合計',
  'billing.Payment.Form.Over': '滿 {num}',
  'billing.Payment.Form.CARD': '信用卡',
  'billing.Payment.Form.ALIPAY': '支付寶',
  'billing.Payment.Form.BALANCE': '賬戶余額',
  'billing.Payment.Form.BALANCE.Recharge': '充值',
  'billing.Payment.Form.paymentMethodId.message': '請選擇',
  'billing.Payment.Form.paymentMethodId.Add': '添加信用卡',
  'billing.Payment.Form.Pay': '支付結算',
  'billing.Payment.Form.Pay.tips': '您當前還未填寫賬單信息，請前往賬單詳情填寫後方可支付！',
  'billing.Payment.Pay.Modal.balance': '資源結算後余額不足。',
  'billing.Payment.Pay.Modal.debt': '賬戶欠款，請先結清。',
  'billing.Payment.Pay.Modal.recharge': '去充值',
  'billing.Detail.handleSave.message': '已成功修改',
  'billing.Detail.validatorEmail.message': '請輸入正確的郵箱地址',
  'billing.Detail.validatorEnglish_number.message': '請輸入英文或數字',
  'billing.Detail.validatorEnglish.message': '請輸入英文',
  'billing.Detail.title': '賬單詳情',
  'billing.Detail.Form.company.title1': '公司',
  'billing.Detail.Form.company.title2': '（非必填）',
  'billing.Detail.Form.fullName.title': '全名',
  'billing.Detail.Form.fullName.message': '請輸入',
  'billing.Detail.Form.email.title': '郵箱',
  'billing.Detail.Form.billingAddress.title': '街道',
  'billing.Detail.Form.city.title': '城市',
  'billing.Detail.Form.postalCode.title': '郵政編碼',
  'billing.Detail.Form.Country.title': '國家/地區',
  'billing.Detail.Form.Country.message': '請選擇國家/地區！',
  'billing.ExtraTraffic.title': '額外流量',
  'billing.ExtraTraffic.Form.plan1.title': '您當前的月度計劃',
  'billing.ExtraTraffic.Form.plan2.title': '流量使用量',
  'billing.ExtraTraffic.Form.Quantity.title': '數量',
  'billing.Invoices.postDownloadMonthBill.message': '下載成功',
  'billing.Invoices.columns.title1': '支付日期',
  'billing.Invoices.columns.title2': '發票號',
  'billing.Invoices.columns.title3': 'VAT',
  'billing.Invoices.columns.title4': '总金额',
  'billing.Invoices.columns.title5': '下載',
  'billing.InvoicesHistory': '發票歷史記錄',
  'billing.Overview.ProCard.title1': '建議',
  'billing.Overview.ProCard.Button1': '購買',
  'billing.Overview.Item.title1': '您的試用剩余',
  'billing.Overview.Item.title1.render': '（{hour}後重置）',
  'billing.Overview.Item.title2': '之前購買的月度計劃',
  'billing.Overview.Item.title3': '近30天的使用量',
  'billing.Overview.Item.title4': '最推薦的月度計劃',
  'billing.Overview.ProCard.title2': '当前周期',
  'billing.Overview.ProCard.Button3': '升級',
  'billing.Overview.columns.title1': '服務周期',
  'billing.Overview.columns.title1.render': '至{slot}到期（{hour}後將重置流量）',
  'billing.Overview.columns.title2': '本周期使用量',
  'billing.Overview.columns.title2.1': '本周期剩余量',
  'billing.Overview.columns.title2.2': '本周期超量',
  'billing.Overview.columns.title3': '当前月度計劃',
  'billing.Overview.columns.title4': '價格',
  'billing.Overview.hasService.columns.title2': '額外流量剩余',
  'billing.Overview.ProCard.Button4': '還款',
  'billing.Overages.title': '超量',
  'billing.Overage.title': '超量',
  'billing.Overview.Overages.columns.title1': '累計超量',
  'billing.Overview.Overages.columns.title2': '本周期超量',
  'billing.Overview.Overages.columns.title3': '超量的單價',
  'billing.PaymentSetting.title': '信用卡',
  'billing.PaymentSetting.delCreditCard.message': '刪除成功',
  'billing.PaymentSetting.Button1': '解除綁定',
  'billing.PaymentSetting.Button2': '設為默認',
  'billing.PaymentSetting.expires': '到期 ',
  'billing.PaymentSetting.Modal.title': '解綁信用卡',
  'billing.PaymentSetting.Modal.p': '您確認解綁該信用卡嗎',
  'billing.PayResult.handleCheck.message': '正在處理付款',
  'billing.PayResult.timeout.message': '超時',
  'billing.PayResult.success.message': '支付成功',
  'billing.PayResult.fail.message': '支付失敗',
  'billing.PayResult.title': '賬單',
  'billing.PayResult.p1': '正在為您配置，請稍後。',
  'billing.PayResult.p2': '購買成功。',
  'billing.PayResult.p3': '發生了錯誤。 ',
  'billing.PayResult.p4': '您可以去賬單中去確認您的購買。',
  'billing.Purchase.title1': '升級月度計劃',
  'billing.Purchase.title2': '購買月度計劃',
  'billing.Purchase.Form.monthPlan.title': '選擇新的月度計劃',
  'billing.Purchase.Form.Choose.p1': '選擇購買周期',
  'billing.Purchase.Form.Choose.p2': '您可以最多購買12個月。一個月我們將以30天計算。',
  'billing.Purchase.Form.Choose.p2-free': '您可以買一個月。一個月內，我們將以30天為基礎計算價格。',
  'billing.Purchase.Form.purchaseQuantity.title': '購買周期',
  'billing.cardList.text': '我們支持：',
  'billing.PaymentRecord.columns.title1': '訂單號',
  'billing.PaymentRecord.columns.title2': '描述',
  'billing.PaymentRecord.columns.title2.text1': '购买月度计划 {val}',
  'billing.PaymentRecord.columns.title2.text2': '购买额外流量  {val}',
  'billing.PaymentRecord.columns.title2.text3': '升级月度计划 {val}',
  'billing.PaymentRecord.columns.title2.text4': '',
  'billing.PaymentRecord.columns.title2.text5': '月度计划退款  {val}',
  'billing.PaymentRecord.columns.title2.text6': '额外流量退款  {val}',
  'billing.PaymentRecord.columns.title3': '金額',
  'billing.PaymentRecord.columns.title4': '狀態',
  'billing.PaymentRecord.columns.title5': '創建時間',
  'billing.PaymentRecord.columns.title6': '支付時間',
  'billing.PaymentRecord.columns.title7': '支付方式',
  'billing.PaymentRecord.columns.title8': '操作',
  'billing.PaymentRecord.columns.pay': '支付',
  'billing.PaymentRecord.columns.pay.text': '提醒',
  'billing.PaymentRecord.Payment.topTip': '請轉到賬單詳細信息填寫，然後您就可以付款了！',
  'billing.PaymentRecord.Payment.bottomTip': '請在{amount}之前完成支付。',
  'billing.RechargeRecord.columns.title1': '充值金額',
  'billing.Purchase.OrderUpgrade.title': '選擇需要升級的周期',
  'billing.Purchase.OrderUpgrade.Collapse.title': '以下周期已經購買了最高的月流量，無法升級。',
  'billing.Purchase.customized.text1': '不滿足您的需求？聯系客服或',
  'billing.Purchase.customized.text2': '申請一個定製的月度計劃！',
  'billing.Purchase.customized.text0': '您已經申請了定製的月度計劃，請等待處理。',
  'billing.PaymentTerms.title': '請確認你已理解支付條款。',
  'billing.PaymentTermsModal.title': '支付條款',
  'billing.PaymentTermsModal.text1':
    '購買月計劃後，我們支持購買額外的流量用來在即將超限時進行補充。',
  'billing.PaymentTermsModal.text2': '否則，我們將要求您以｛val｝的單價償還超量金額。',
  'billing.PaymentTermsModal.text3': '更多服務條款細節，請',
  'billing.PaymentTermsModal.text4': '點擊此處查看',
  'billing.PaymentTermsModal.button': '好的，我理解了。',
  //v1.2.0
  'billing.AutoSettings.title': '自動化設置',
  'billing.AutoSettings.name': '自動扣費',
  'billing.AutoSettings.p1': '若要保留你的業務',
  'billing.AutoSettings.p2': '當月度計劃過期時',
  'billing.AutoSettings.p3': '，請開啟功能，屆時我們將直接扣除余額，以確保您的業務不會中斷。',
  'billing.AutoSettings.Default.tier': '默認檔位',
  'billing.AutoSettings.autoModal.p0':
    '當月計劃到期時，您的業務也將被保留，余額將根據同一檔位的按量付費單價直接扣除，以確保您的業務不會中斷。',
  'billing.AutoSettings.autoModal.p1.1': '當前',
  'billing.AutoSettings.autoModal.p1.2': '自動扣費',
  'billing.AutoSettings.autoModal.p1.3': '尚未開啟。',
  'billing.AutoSettings.autoModal.p2':
    '若要保留你的業務當月度計劃過期時，請開啟功能，屆時我們將直接扣除余額，以確保您的業務不會中斷。',
  'billing.AutoSettings.autoModal.btn': '前往設置',
  'billing.Overview.ToRecharge': '要為您的帳戶充值，請先填寫您的賬單詳細信息。',
  'billing.Overview.btn.RechargeAccount': '充值賬戶',
  'billing.Overview.EquivalentTo.p1': '相當於',
  'billing.Overview.EquivalentTo.p2': '標準網絡',
  'billing.Overview.EquivalentTo.free': '免費獲取余額！',
  'billing.Overview.UsageInMonth': '{val}用量',
  'billing.Overview.UsageInMonth.p1': '該部分不包括月度計劃中的流量。',
  'billing.Overview.UsageInMonth.p2.1': '你{val}的總用量是',
  'billing.Overview.UsageInMonth.p2.2': '且平均單價為',
  'billing.Overview.BillingHistory': '歷史賬單',
  'billing.Overview.PaymentAmount': '支付金額',
  'billing.RechargeModal.warnTip.p1': '你仍然還存在 ',
  'billing.RechargeModal.warnTip.p2': ' 的欠款。充值後，你的賬戶余額將剩余 ',
  'billing.RechargeModal.warnTip.p3': '。',
  'billing.RechargeModal.ChooseAmount': '選擇金額',
  'billing.RechargeModal.minAmount.message': '充值金額不能低於$10。',
  'billing.RechargeModal.calcLink': '前往賬單計算器，來計算價格 >',
  'billing.RechargeModal.firstRecharge.title': '恭喜你完成首充！',
  'billing.RechargeModal.firstRecharge.content': '讓我們來看看你的資源可以如何調整網絡檔位吧！',
  'billing.RechargeModal.firstRecharge.btn': '進入教程',
  //v1.2.3
  'billing.EarnBalance.title1': '每邀請一名新用戶，您將獲得其儲值金額 20% 的回饋。',
  'billing.EarnBalance.title2': '總獎勵',
  'billing.EarnBalance.title3': '邀請碼： ',
  'billing.EarnBalance.title4': '受邀人數總計： ',
  'billing.EarnBalance.balance.p0': '滿足以下條件的邀請者將獲得獎勵：',
  'billing.EarnBalance.balance.p1': '受邀用戶為新用戶，不能申請重復註冊。',
  'billing.EarnBalance.balance.p2': '新用戶產生付款。',
  'billing.EarnBalance.Share.Copy': '復製',
  'billing.EarnBalance.Share.Jump': '跳轉',
  'billing.EarnBalance.columns.title1': '受邀者',
  'billing.EarnBalance.columns.title2': '獎勵',
  'billing.EarnBalance.columns.title3': '受邀時間',
  'billing.EarnBalance.columns.title4': '更新時間',
  'billing.EarnBalance.status.p1': '持續14天的活躍資源',
  'billing.EarnBalance.status.p2': '新用戶',
  'billing.EarnBalance.status.p3': '支付',
  'billing.EarnBalance.isBillAddress.text1': '為了保障你後續消費的權益，請',
  'billing.EarnBalance.isBillAddress.text2': '完善賬單詳情',
  'billing.EarnBalance.isBillAddress.text3': '後參與活動。',
  'billing.modal.NoticeSetting.text1': '過去30天消耗了余額：',
  'billing.modal.NoticeSetting.text2': '如果余額僅剩',
  'billing.modal.NoticeSetting.text3': '，我們將向您發送預警郵件。',
  'billing.modal.NoticeSetting.tip': '（如果您不需要警告，請留空。）',
};
