export default {
  'register.changePassword.message.rule': '使用8-20個字符',
  'register.changePassword.message.error': '請輸入正確的密碼！',
  'register.changePassword.rule.title': '我們建議：',
  'register.changePassword.rule.text1': '一個字母',
  'register.changePassword.rule.text2': '一個數字',
  'register.changePassword.rule.text3': '一個特殊字符',
  'register.changePassword.rule.text4': '8-20個字符',
  'register.changePassword.rule.title2': '至少使用:',
  'register.changePassword.rule.text21': '一個數字',
  'register.changePassword.rule.text22': '一個字母',
  'register.changePassword.rule.text23': '8-20個字符',
  'register.changePassword.rule.weak': '弱密碼',
  'register.changePassword.message.strong': '強密碼',
  'register.changePassword.message': '請輸入您的密碼！',
  'register.modified.message': '密碼修改成功',
  'register.validateFields.message': '註冊成功',
  'register.okHandle.message.logging': '登錄中...',
  'register.okHandle.message.success': '登錄成功',
  'register.okHandle.message.error': '登錄失敗',
  'register.validatorEmail.message.success': '該郵箱已存在',
  'register.validatorEmail.message.failed': '該郵箱驗證失敗',
  'register.validatorEmail.message': '請輸入正確的郵箱地址',
  'register.validatorPhone.message.rule': '使用6-11個字符',
  'register.validatorPhone.message': '請輸入正確的電話',
  'register.validatorRePassword.message': '密碼不一致',
  'register.saveCount.message': '郵件發送成功',
  'register.Modal.content.title': '請驗證您的郵箱。',
  'register.Modal.content.code1': '您可以重新發送驗證在',
  'register.Modal.content.code2': '秒後',
  'register.Modal.content.code': '發送驗證碼 →',
  'register.Modal.content.okText': '開始免費試用!',
  'register.Modal.content.cancelText': '取消',
  'register.verificationCode': '驗證碼',
  'register.verificationCode.message': '請輸入驗證碼',
  'register.step1': '先用後付，最小投入',
  'register.step2': '3分鐘內快速完成配置',
  'register.step3': 'CDN加速覆蓋全球地區',
  'register.step4': '價格比CDN77更低',
  'register.form.fullName.name': '全名',
  'register.form.fullName.message': '請輸入您的全名！',
  'register.form.email.name': '郵箱',
  'register.form.email.message': '請輸入您的郵箱地址！',
  'register.form.companyName.name1': '公司名稱',
  'register.form.companyName.name2': '非必填',
  'register.form.Phone.name': '手機號',
  'register.form.country.message': '請選擇國家！',
  'register.form.mobile.message': '請輸入您的手機號！',
  'register.form.password.name': '密碼',
  'register.form.rePassword.name': '再次輸入密碼',
  'register.form.rePassword.message': '請再次輸入您的密碼！',
  'register.form.Sign_up': '註冊',

  'register.Modal.okText': '開始免費試用',
  'register.Modal.cancelText': '取消',
  'register.form.tip1': '點擊註冊後，將默認同意LightCDN的 ',
  'register.form.tip2': '條款， ',
  'register.form.tip3': '隱私政策。',
  'register.form.code': '郵件驗證碼',
  'register.form.codeButton': '獲取驗證碼',
  'register.form.code.message': '請輸入郵件驗證碼',
  'register.form.code.rule': '請輸入正確的郵件驗證碼',
  'register.form.Back_to_login': '← 返回登錄',
  'register.text1': '開始使用LightCDN加速你的業務',
  'register.text2': '立即體驗LightCDN所有特色',
  'register.Invitor': '邀請碼',
  'register.Invitor.message': '無效碼',
};
