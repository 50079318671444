export default {
  'login.success.message': '登錄成功',
  'login.validatorEmail.message': '請輸入正確的郵箱地址',
  'login.Forget_Password': '忘記密碼？',
  'login.WELCOME_TO_LightCDN': '歡迎來到LightCDN',
  'login.BEST_CDN_FOR_YOU': '為您提供最好用的CDN',
  'login.login': '登錄',
  'login.form.email.name': '郵箱',
  'login.form.email.message': '請輸入您的郵箱地址！',
  'login.form.password.name': '密碼',
  'login.form.password.message': '請輸入密碼！',
  'login.Sign_up_for_free_trial': '註冊獲得免費試用',
  // 公共组件内容
  'login.components.infomation.title': '通知',
  'login.components.infomation.text1': 'New PoP:Dubai',
  'login.components.infomation.text2': "We've just launched a new PoP in Dubai!",
  'login.components.infomation.text3': 'New Tool For Free',
  'login.components.infomation.text4':
    'You can now easily generate Signed URLs straight from the UI if you have the Secure token enabled for your CDN Resource.Find it in your Client Panel,section Access protection',
  'login.components.infomation.text5': '幫助文檔',
  'login.text1': '歡迎來到LightCDN',
  'login.text2': '為您提供最好的CDN',
  'login.FactorAuth.title': '驗證您的登錄',
  'login.FactorAuth.label': '在下面輸入您的雙因驗證碼。',
};
