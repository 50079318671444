export default {
  'giftCode-title': '礼包码',
  'giftCode-tab-1': '兑换新的礼包码',
  'giftCode-tab-2': '查看已兑换的礼包码',
  'giftCode-tab-1-1': '兑换',
  'giftCode-tab-2-1': '查看',
  'giftCode-RedeemCode-code-placeholder': '请输入礼包码',
  'giftCode-RedeemCode-validatorCode-msg1': '无效的礼包码。',
  'giftCode-RedeemCode-validatorCode-msg2': '已经兑换过该礼包码。',
  'giftCode-RedeemCode-Cancel': '取消',
  'giftCode-RedeemCode-Redeem': '兑换',
  'giftCode-RedeemCode-result-success-1': '已成功兑换礼包码。',
  'giftCode-RedeemCode-result-success-2': '成功兑换了{num}。',
  'giftCode-RedeemCode-result-success-2-tip': '若月度计划中断，则赠送流量自动失效。请及时续费。',
  'giftCode-RedeemCode-result-fail-1': '失败',
  'giftCode-RedeemCode-result-fail-2':
    '我们检测到您目前没有月度计划。请尽快购买月度计划以兑换免费流量。',
  'giftCode-RedeemCode-code-extra-1': '点击兑换，默认你同意了礼包码的',
  'giftCode-RedeemCode-code-extra-2': '条款和条件',
  'giftCode-RedeemCode-code-extra-3': '（如适用）。',
  'giftCode-RedeemTable-name-1': '至{date}到期',
  'giftCode-RedeemTable-name-2': '{date}使用',
  'giftCode-RedeemTable-status-1': '去购买计划',
  'giftCode-RedeemTable-status-1-1': '去购买流量',
  'giftCode-RedeemTable-status-2': '已使用',
  'giftCode-RedeemTable-status-3': '过期',
  'giftCode-RedeemTable-status-4': '已锁定',
  'giftCode-select-NotSatisfied': '不满足条件',
};
