import { Modal, Form, notification } from 'antd';
import { useRequest } from 'ahooks';
import { useFomatter } from '@/hooks';
import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'umi';
import InputNumber from '@/components/InputNumber';
import { postCreditCard_api } from '@/api/stripe';
import { getBillAddress_api } from '@/api/bill';
import cardVisa from '@/assets/images/card-visa.png';
import cardMastercard from '@/assets/images/card-Mastercard.png';
import cardUnionPay from '@/assets/images/card-unionPay.png';
import cardAmerican from '@/assets/images/card-American.png';
const Add: React.FC<any> = ({ visible = false, onCancel, onRefresh }) => {
  const { formatter } = useFomatter();
  const [form] = Form.useForm();

  const handleCancel = () => {
    form?.resetFields();
    onCancel?.();
  };

  const { run, loading } = useRequest(postCreditCard_api, {
    manual: true,
    onSuccess(res: { success: boolean }) {
      if (res.success) {
        gtag?.('event', 'click_addNewCard_success', {
          event_name: 'click',
        });
        onRefresh();
        handleCancel();
        notification.success({
          message: formatter({ id: 'billing.AddCard.postCreditCard.message' }),
        });
      }
    },
  });

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const detail = await getBillAddress_api();
        const expDateArr = values.expDate.split('/');
        const params = {
          expYear: Number(expDateArr[1]),
          expMonth: Number(expDateArr[0]),
          cvc: values.cvc,
          number: values.number,
          billingAddress: detail.billingAddress,
          city: detail.city,
          company: detail.company,
          email: detail.email,
          holderName: detail.fullName,
          nation: detail.nation,
          postalCode: detail.postalCode,
        };
        // console.log(params);
        run(params);
      })
      .catch((errorInfo) => {
        console.log(errorInfo, '.catch(errorInfo =');
      });
  };
  return (
    <Modal
      title={<FormattedMessage id="billing.AddCard.Modal.title" />}
      visible={visible}
      centered={true}
      destroyOnClose={true}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      okText={<FormattedMessage id="billing.AddCard.Modal.okText" />}
      maskClosable={false}
      className="m-AddCard"
    >
      <Form layout={'vertical'} form={form}>
        <Form.Item
          label={<FormattedMessage id="billing.AddCard.Form.number.title" />}
          className="number"
        >
          <Form.Item
            name="number"
            rules={[
              {
                required: true,
                message: formatter({ id: 'billing.AddCard.Form.number.message' }),
              },
            ]}
          >
            <InputNumber
              maxLength={16}
              placeholder={formatter({ id: 'billing.AddCard.Form.number.message' })}
            />
          </Form.Item>
          <div className="m-card-list">
            <img src={cardVisa} alt="VISA" title="VISA" />
            <img src={cardMastercard} alt="Mastercard" title="Mastercard" />
            <img src={cardUnionPay} alt="UnionPay" title="UnionPay" />
            <img src={cardAmerican} alt="American Express" title="American Express" />
          </div>
        </Form.Item>
        <Form.Item
          label={formatter({ id: 'billing.AddCard.Form.expDate.title' })}
          name="expDate"
          className="number"
          style={{
            width: '49%',
            display: 'inline-block',
          }}
          rules={[
            {
              required: true,
              message: formatter({ id: 'billing.AddCard.Form.expDate.message' }),
            },
            {
              validator: (rule: any, value: string, callback: any) => {
                if (value) {
                  if (value.length !== 5) {
                    callback(formatter({ id: 'billing.AddCard.Form.expDate.validator' }));
                  } else {
                    const date = moment().format('YYYY-MM');
                    const exp = 2000 + Number(value.split('/')[1]) + '-' + value.split('/')[0];
                    if (moment(exp).isBefore(moment(date))) {
                      callback(formatter({ id: 'billing.AddCard.Form.expDate.validator' }));
                    }
                    callback();
                  }
                  callback();
                }
                callback();
              },
            },
          ]}
        >
          <InputNumber
            placeholder={formatter({ id: 'billing.AddCard.Form.expDate.MMYY' })}
            maxLength={5}
            isExpDate={true}
          />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="billing.AddCard.Form.cvc.title" />}
          name="cvc"
          className="number"
          style={{ width: '49%', display: 'inline-block', float: 'right' }}
          rules={[
            {
              required: true,
              message: formatter({ id: 'billing.AddCard.Form.cvc.message' }),
            },
          ]}
        >
          <InputNumber
            maxLength={4}
            placeholder={formatter({ id: 'billing.AddCard.Form.cvc.CVC' })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default Add;
