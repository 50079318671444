export default {
  'upgadeAccount.title': 'Upgrade',
  'upgadeAccount.p1': 'Choose your situation',
  'upgadeAccount.p2': 'Fill in your billing details',
  'upgadeAccount.p3': 'Fill in your payment information',
  'upgadeAccount.step1.title': 'Upgrade service',
  'upgadeAccount.step1.columns.title1': 'Monthly plan',
  'upgadeAccount.step1.columns.title2': 'Monthly Price',
  'upgadeAccount.step1.p1': 'The global acceleration，unrestricted Pops',
  'upgadeAccount.step1.p2': 'All functions',
  'upgadeAccount.step1.p3': '24/7 Online after-sales support',
  'upgadeAccount.step1.p4': 'Unlimited HTTP(S) requests',
  'upgadeAccount.step1.p5': 'Uniform global pricing, regardless of geography',
  'upgadeAccount.step1.Choose.title': 'Choose purchase duration',
  'upgadeAccount.step1.p6':
    'You can buy it for 12 months at most. For one month period, we will calculate the price based on 30 days.',
  'upgadeAccount.step1.p7': 'Purchase duration',
  'upgadeAccount.step1.p8': 'Service period',
  'upgadeAccount.step1.Next': 'Next',
  'upgadeAccount.step1.months': 'months',
  'upgadeAccount.step1.month': 'month',
  'upgadeAccount.step2.validatorEnglish_number.message': 'Use 3-10 characters',
  'upgadeAccount.step3.memuList.title1': 'Credit card',
  'upgadeAccount.step3.memuList.title2': 'Alipay',
  'upgadeAccount.step3.columns.title2': 'Payment details',
  'upgadeAccount.step3.columns.title4': 'Traffic allowance per month',
  'upgadeAccount.step3.columns.title5': 'Total amount to pay',
  'upgadeAccount.step3.PaymentSucceeded': 'Payment succeeded',
  'upgadeAccount.step3.PaymentFailed': 'Payment failed',
  'upgadeAccount.step3.title': 'Payment',
  'upgadeAccount.step3.postCreditCard.message': 'Added successfully',
};
