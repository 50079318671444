export default {
  'requests.topTitle': '请求报告',
  'requests.name': '请求',
  'requests.topTitle.text': '（请按照 utc+0 的时区查看数据。）',
  'requests.content.title': '命中/未命中',
  'requests.menu.text1': '边缘位置',
  'requests.menu.text2': '大洲',
  'requests.menu.text3': 'CDN资源',
  'requests.chart.text1': '命中',
  'requests.chart.text2': '未命中',
  'requests.chart.text3': '来源',
  'requests.Request': '请求',
  'requests.oneColumns.title2': '请求',
  'requests.oneColumns.title3': '命中 / %',
  'requests.oneColumns.title4': '未命中 / %',
};
