// 弹窗
import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Divider } from 'antd';
import styles from './style.less';
import closeIcon from '@/assets/images/close.png';

export const handleHidden = () => {
  // @ts-ignore
  document.body.removeChild(document.getElementById('guideMask'));
};
type ProType = {
  visible: boolean;
  cancelHandle: any; //关闭弹窗
  content?: any; //内容
  width?: number; //弹窗宽度
  footer?: boolean | React.ReactDOM | JSX.Element | null; //底部按钮
  okText?: string; //确定按钮文字
  okBtnLoading?: boolean; //确定按钮loading
  okBtnDisabled?: boolean; //确定按钮disabled
  cancelText?: string; //取消按钮文字
  okHandle?: any; //点击确定按钮回调
  style?: any;
  isCloseIcon?: boolean; //是否展示右上角关闭按钮
};

const Modal: React.FC<ProType> = ({
  cancelHandle,
  visible,
  content,
  okText,
  cancelText,
  okHandle,
  okBtnDisabled,
  okBtnLoading,
  footer = (
    <div className={styles.footer}>
      <Divider />
      <div className={styles.footerBtns}>
        <Button
          onClick={() => {
            cancelHandle(false);
          }}
          className={styles.cancelBtn}
        >
          {cancelText ? cancelText : 'Cancel'}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            okHandle();
          }}
          loading={okBtnLoading}
          disabled={okBtnDisabled}
        >
          {okText ? okText : 'OK'}
        </Button>
      </div>
    </div>
  ),
  width,
  style = {},
  isCloseIcon = true,
}) => {
  return visible
    ? ReactDOM.createPortal(
        <div className={`${styles.guideMask}`}>
          <div className={`${styles.modal}`} style={{ width: width ? width : 400, ...style }}>
            {isCloseIcon ? (
              <div
                className={styles.closeIcon}
                onClick={() => {
                  cancelHandle(false);
                }}
              >
                <img src={closeIcon} alt="closeIcon" className={styles.iconimage} />
              </div>
            ) : null}
            {content ? content : null}
            {footer ? footer : null}
          </div>
        </div>,
        document.body,
      )
    : null;
};
export default Modal;
