/**
 * 引导层，
 * 需要接口的枚举：CREATE_CDN、CDN_LOCATION、CDN_ACTIVE
 */
import { useModel } from 'umi';
import CdnCreate from './subpage/CdnCreate';
import CdnActivateConfig from './subpage/CdnActivateConfig';
import CdnLocation from './subpage/CdnLocation';
import CdnRule from './subpage/CdnRule';
import './style.less';
const GuidePop = () => {
  const { initialState = {}, setInitialState } = useModel('@@initialState');
  const guideType = initialState.guideType;
  //清除引导页
  const handleClose = (t: string) => {
    setInitialState({ ...initialState, guideType: t || '' });
  };
  return (
    <>
      {guideType ? <div className="m-GuidePop-fixed"></div> : null}
      {guideType ? (
        <div className="m-GuidePop-box">
          {/* cdn_create */}
          {guideType === 'cdn_create_1' ? <CdnCreate onClick={handleClose} step={1} /> : null}
          {guideType === 'cdn_create_2' ? <CdnCreate onClick={handleClose} step={2} /> : null}
          {guideType === 'cdn_create_3' ? <CdnCreate onClick={handleClose} step={3} /> : null}
          {guideType === 'cdn_create_4' ? <CdnCreate onClick={handleClose} step={4} /> : null}
          {/* cdn_activateConfig */}
          {guideType === 'cdn_activateConfig_1' ? (
            <CdnActivateConfig onClick={handleClose} step={1} />
          ) : null}
          {guideType === 'cdn_activateConfig_2' ? (
            <CdnActivateConfig onClick={handleClose} step={2} />
          ) : null}
          {guideType === 'cdn_activateConfig_3' ? (
            <CdnActivateConfig onClick={handleClose} step={3} />
          ) : null}
          {guideType === 'cdn_activateConfig_4' ? (
            <CdnActivateConfig onClick={handleClose} step={4} />
          ) : null}
          {/* cdn_location */}
          {guideType === 'cdn_location_1' ? <CdnLocation onClick={handleClose} step={1} /> : null}
          {guideType === 'cdn_location_2' ? <CdnLocation onClick={handleClose} step={2} /> : null}
          {/* cdn_rule */}
          {guideType === 'cdn_rule_1' ? <CdnRule onClick={handleClose} step={1} /> : null}
          {guideType === 'cdn_rule_2' ? <CdnRule onClick={handleClose} step={2} /> : null}
        </div>
      ) : null}
    </>
  );
};
export default GuidePop;
