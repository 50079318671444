export default {
  'sslCerificates.list.deleteCdn.message': '删除成功',
  'sslCerificates.list.Popconfirm.title': '你确认删除该SSL证书吗？',
  'sslCerificates.list.Popconfirm.okText': '确认',
  'sslCerificates.list.Popconfirm.cancelText': '不，取消',
  'sslCerificates.list.title': 'SSL证书',
  'sslCerificates.list.Create': '创建SSL证书',
  'sslCerificates.list.columns.title1': '名字',
  'sslCerificates.list.columns.title2': '操作选项',

  'sslCerificates.list.PopoverText1': '编辑SSL证书',
  'sslCerificates.list.PopoverText2': 'SSL证书详情',
  'sslCerificates.list.PopoverText3': '删除',

  'sslCerificates.create.onSubmit.message': '创建成功',
  'sslCerificates.create.validatorName.message': '请输入正确的名字',
  'sslCerificates.create.validatorName.rule': '请使用 4-20 字符',
  'sslCerificates.create.validatorSSLKey.rule':
    '必须以 -----BEGIN CERTIFICATE----- 开始并以 -----END CERTIFICATE----- 结尾',
  'sslCerificates.create.validatorSSLPrivateKey.rule':
    '必须以 -----BEGIN RSA PRIVATE KEY----- 开始并以 -----END RSA PRIVATE KEY----- 结尾',
  'sslCerificates.create.step1.Form.name.name': '名字',
  'sslCerificates.create.step1.Form.name.message': '请输入名字！',
  'sslCerificates.create.step1.Form.sslCertificateKey.name': 'SSL证书密钥',
  'sslCerificates.create.step1.Form.sslCertificateKey.message': '请输入SSL证书密钥！',
  'sslCerificates.create.step1.Form.privateKey.name': '私钥',
  'sslCerificates.create.step1.Form.privateKey.message': '请输入私钥！',
  'sslCerificates.create.step1.Form.Button': '创建SSL证书',

  'sslCerificates.detail.handleSave.message': '编辑成功',
  'sslCerificates.detail.title1': '您的SSL证书',
  'sslCerificates.detail.title2': '关联的CDN资源',
  'sslCerificates.detail.Button.Edit': '编辑',
  'sslCerificates.detail.Button.Cancel': '取消',
  'sslCerificates.detail.Button.Save': '保存',
};
