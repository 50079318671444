export default {
  'register.changePassword.message.rule': '使用8-20个字符',
  'register.changePassword.message.error': '请输入正确的密码！',
  'register.changePassword.rule.title': '我们建议：',
  'register.changePassword.rule.text1': '一个字母',
  'register.changePassword.rule.text2': '一个数字',
  'register.changePassword.rule.text3': '一个特殊字符',
  'register.changePassword.rule.text4': '8-20个字符',
  'register.changePassword.rule.title2': '至少使用:',
  'register.changePassword.rule.text21': '一个数字',
  'register.changePassword.rule.text22': '一个字母',
  'register.changePassword.rule.text23': '8-20个字符',
  'register.changePassword.rule.weak': '弱密码',
  'register.changePassword.message.strong': '强密码',
  'register.changePassword.message': '请输入您的密码！',
  'register.modified.message': '密码修改成功',
  'register.validateFields.message': '注册成功',
  'register.okHandle.message.logging': '登录中...',
  'register.okHandle.message.success': '登录成功',
  'register.okHandle.message.error': '登录失败',
  'register.validatorEmail.message.success': '该邮箱已存在',
  'register.validatorEmail.message.failed': '该邮箱验证失败',
  'register.validatorEmail.message': '请输入正确的邮箱地址',
  'register.validatorPhone.message.rule': '使用6-11个字符',
  'register.validatorPhone.message': '请输入正确的电话',
  'register.validatorRePassword.message': '密码不一致',
  'register.saveCount.message': '邮件发送成功',
  'register.Modal.content.title': '请验证您的邮箱。',
  'register.Modal.content.code1': '您可以重新发送验证在',
  'register.Modal.content.code2': '秒后',
  'register.Modal.content.code': '发送验证码 →',
  'register.Modal.content.okText': '开始免费试用!',
  'register.Modal.content.cancelText': '取消',
  'register.verificationCode': '验证码',
  'register.verificationCode.message': '请输入验证码',
  'register.step1': '先用后付，最小投入',
  'register.step2': '3分钟内快速完成配置',
  'register.step3': 'CDN加速覆盖全球地区',
  'register.step4': '价格比CDN77更低',
  'register.form.fullName.name': '全名',
  'register.form.fullName.message': '请输入您的全名！',
  'register.form.email.name': '邮箱',
  'register.form.email.message': '请输入您的邮箱地址！',
  'register.form.companyName.name1': '公司名称',
  'register.form.companyName.name2': '非必填',
  'register.form.Phone.name': '手机号',
  'register.form.country.message': '请选择国家！',
  'register.form.mobile.message': '请输入您的手机号！',
  'register.form.password.name': '密码',
  'register.form.rePassword.name': '再次输入密码',
  'register.form.rePassword.message': '请再次输入您的密码！',
  'register.form.Sign_up': '注册',
  'register.Modal.okText': '开始免费试用',
  'register.Modal.cancelText': '取消',
  'register.form.tip1': '点击注册后，将默认同意LightCDN的 ',
  'register.form.tip2': '条款，',
  'register.form.tip3': '隐私政策。',
  'register.form.code': '邮件验证码',
  'register.form.codeButton': '获取验证码',
  'register.form.code.message': '请输入邮件验证码',
  'register.form.code.rule': '请输入正确的邮件验证码',
  'register.form.Back_to_login': '← 返回登录',
  'register.text1': '开始使用LightCDN加速你的业务',
  'register.text2': '立即体验LightCDN所有特色',
  'register.Invitor': '邀请码',
  'register.Invitor.message': '无效码',
};
