export default {
  'guide.next': 'Next step',
  'guide.pass': 'I have mastered the method, skip the guide>>',
  'guide.attempt': 'Try it!',
  'guide.cdnResources.detail.cname.text1': 'Replace file path',
  'guide.cdnResources.detail.cname.text2': 'DNS resolution',
  'guide.cdnResources.detail.cname.start': 'Activate LightCDN now!',
  'guide.cdnResources.detail.location.start': 'Come and try changing the network tier!',
  'guide.cdnResources.detail.rule.start': 'Set rules',
  //
  'guide.CdnCreate.step.title1.1': 'The origin address is usually the server address/URL',
  'guide.CdnCreate.step.title1.2': 'Address for external display',
  'guide.CdnCreate.step.content1': '',
  'guide.CdnCreate.step.title2': 'What is the difference between two network tiers',
  'guide.CdnCreate.step.content2.1': 'Core Network',
  'guide.CdnCreate.step.content2.2':
    'only provides 8 high bandwidth nodes, including Hong Kong in China, Tokyo in Japan, Singapore, Silicon Valley in the United States, Washington D.C. in the United States, Frankfurt in France, Sao Paulo in Brazil, and Dubai in the United Arab Emirates.',
  'guide.CdnCreate.step.content2.3':
    'Suitable for large files/videos; Or concentrate on the business in that region or have international sites without specific business areas.',
  'guide.CdnCreate.step.content2.4': 'Standard Network',
  'guide.CdnCreate.step.content2.5': 'including all data centers.',
  'guide.CdnCreate.step.content2.6':
    'Suitable business areas are relatively stable and specific; Or the demand is for website acceleration or advertising placement that requires a wide range of services to be distributed.',
  'guide.CdnCreate.step.title3': '',
  'guide.CdnCreate.step.content3.1':
    "Everyone will have 500GB of 30 day Core Network traffic. If you haven't claimed it yet, you can go to the",
  'guide.CdnCreate.step.content3.2': ' Upgrade Account ',
  'guide.CdnCreate.step.content3.3': 'to claim it.',
  'guide.CdnCreate.step.title4': '',
  'guide.CdnCreate.step.content4.1': '1. Complete the CDN hostname and Origin.',
  'guide.CdnCreate.step.content4.2': '2. Choose the appropriate network tier.',
  'guide.CdnCreate.step.content4.3': 'You can create a CDN resource now!',
  //
  'guide.CdnActivateConfig.step.top.1': "LightCDN hasn't worked yet!",
  'guide.CdnActivateConfig.step.top.2': "Don't worry, there's one final step!",
  'guide.CdnActivateConfig.step.title1.1':
    'Please choose a way to use the CNAME address (xxx.r.cdn36.com)',
  'guide.CdnActivateConfig.step.content1.1': 'Tips: What is a CNAME?',
  'guide.CdnActivateConfig.step.content1.2':
    'CNAME is a method of DNS recording that maps one domain name to another.',
  'guide.CdnActivateConfig.step.content1.3':
    'The CNAME address provided by LightCDN is the address for accelerating cached content and can be used to replace the origin address.',
  'guide.CdnActivateConfig.step.title2': 'What is the difference between the two methods?',
  'guide.CdnActivateConfig.step.content2.1': 'Method 1: Change the domain name address.',
  'guide.CdnActivateConfig.step.content2.2':
    'Replace the source path of the domain name address with CNAME. It is appropriate to cache and accelerate ',
  'guide.CdnActivateConfig.step.content2.3': 'only some files',
  'guide.CdnActivateConfig.step.content2.4': '.',
  'guide.CdnActivateConfig.step.title3': '',
  'guide.CdnActivateConfig.step.content3.1': 'Method 2: Use DNS.',
  'guide.CdnActivateConfig.step.content3.2':
    'Set up a DNS record through a third-party domain platform.',
  'guide.CdnActivateConfig.step.content3.3': 'Typical configuration method:',
  'guide.CdnActivateConfig.step.content3.4': 'CDN hostname/The Lowest Level of CDN hostname',
  'guide.CdnActivateConfig.step.content3.5': 'The CNAME address generated by LightCDN',
  'guide.CdnActivateConfig.step.content3.6':
    'Of course, using server commands directly to complete DNS is also possible.',
  'guide.CdnActivateConfig.step.title4': '',
  'guide.CdnActivateConfig.step.content4.1':
    'Set up a DNS record through a third-party domain platform. For resources that require ',
  'guide.CdnActivateConfig.step.content4.2': 'global file acceleration',
  'guide.CdnActivateConfig.step.content4.3':
    ", this method only requires DNS to change the value of 'Host Records' if the hostname is changed later.",
  'guide.CdnActivateConfig.step.title5': 'How to verify if the acceleration is successful',
  'guide.CdnActivateConfig.step.content5.1': 'If you are using ',
  'guide.CdnActivateConfig.step.content5.2': 'DNS resolution',
  'guide.CdnActivateConfig.step.content5.3': ', you can visit ',
  'guide.CdnActivateConfig.step.content5.4': ' for self inspection.',
  'guide.CdnActivateConfig.step.content5.5':
    'Enter the CDN hostname, select CNAME, and perform a check.',
  //
  'guide.CdnLocation.step.title1': '',
  'guide.CdnLocation.step.content1.1':
    'This is the current network tier of this resource, displaying the number of available CDN locations for you to switch on the current page.',
  'guide.CdnLocation.step.content2.1':
    'The highlighted is the current network tier of the resource.',
  'guide.CdnLocation.step.content2.2':
    'You can choose the tier that better meets your business needs, and it will take effect upon saving.',
  //
  'guide.CdnRule.step.title1': 'How to fill in the conditions?',
  'guide.CdnRule.step.content1.1': 'The condition is that you need to bypass the cached file type.',
  'guide.CdnRule.step.content1.2': '"Subject" selection ',
  'guide.CdnRule.step.content1.3': '"Predicate" Selection ',
  'guide.CdnRule.step.content1.4': '"Value"is filled with ',
  'guide.CdnRule.step.content1.5': 'file suffix',
  'guide.CdnRule.step.content1.6': ', for example, fill in the image with "jpg".',
  'guide.CdnRule.step.title2': 'How to set actions?',
  'guide.CdnRule.step.content2.1': 'To bypass CDN and directly access the source, you need to set ',
  'guide.CdnRule.step.content2.2': 'Never cache - Force edge to never cache',
  'guide.CdnRule.step.content2.3': ', and just press the button to turn it on.',
  //
};
