export default {
  'traffic.topTitle': 'Traffic report',
  'traffic.name': 'traffic',
  'traffic.topTitle.text': '(Please view the data according to utc+0.)',
  'traffic.content.title': 'Cached/Non-cached data',
  'traffic.content.tip':
    'The normal cache rate is above 90%. If your hit rate is too low, you can refer to the ',
  'traffic.content.tip1': 'documentation',
  'traffic.content.tip2':
    ' for troubleshooting; if you still have problems, please contact customer service or send an email, we will be happy to serve you.',
  'traffic.menu.text1': 'Edge locations',
  'traffic.menu.text2': 'Continents',
  'traffic.menu.text3': 'CDN Resources',
  'traffic.menu.text4': 'Visitors Analytics',
  'traffic.chart.text1': 'Cached',
  'traffic.chart.text2': 'Non-cached',
  'traffic.oneColumns.title1': 'Edge locations',
  'traffic.oneColumns.title2': 'Total traffic',
  'traffic.twoColumns.title1': 'Continent',
  'traffic.threeColumns.title1': 'CDN Resources',
  'traffic.threeColumns.title2': 'Error requests',
  'traffic.threeColumns.title3': 'Past hour',
  'traffic.threeColumns.title4': 'This hour',
  'traffic.getCdnList.all': 'all',
  'traffic.getCdnList.showAll': 'Show all',
  'traffic.chartName': 'Traffic',
  'traffic.total': 'Total',
  'traffic.chart.cached': 'cached',
  'traffic.chart.nonCached': 'non-cached',

  'traffic.fourColumns.h3': 'Top Traffic Countries / Regions',
  'traffic.fourColumns.title1': 'Country/Region',
  'traffic.fourColumns.title2': 'Total traffic',
  'traffic.fourColumns.title3': 'Avg latency',
  'traffic.fourColumns.title4': 'Avg transfer rate',
  'traffic.fourColumns.title5': 'Request',
  'traffic.Analytics.loading': 'loading...',
  'traffic.Analytics.unkown': 'unkown',
};
