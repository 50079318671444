import moment from 'moment';
import type { Moment } from 'moment';
import { getLocale } from 'umi';
import { dateUnitEnum } from '@/enumeration/dateUnitEnum';
/**
 *
 * @param access 校验权限
 * @returns
 */
export const checkAccess = (access: boolean | undefined): boolean => {
  if (access === false) {
    return false;
  } else {
    return true;
  }
};
/**
 *
 * @param arr
 * @returns
 */
export const accessToObj = (arr: string[] | undefined) => {
  const obj: Record<string, boolean> = {};
  if (arr) {
    arr.forEach((i: string) => (obj[i] = true));
  }
  return obj;
};

/**
 * 表格枚举显示
 * @param typeArr 枚举数组
 * @param value 枚举字段
 * @returns
 */
export const renderTypeName = (
  typeArr: any[] = [],
  value: string = '',
  lang: boolean = false,
  isCross: boolean = true,
) => {
  if (!typeArr.length || !value) return isCross ? '--' : '';
  const currentArr = typeArr.filter((item) => {
    return item.value === value;
  });
  if (!currentArr.length) return isCross ? '--' : '';
  let _text = currentArr[0].text;
  if (lang) {
    const _lang = getLocale();
    if (_lang && currentArr[0][_lang]) {
      _text = currentArr[0][_lang];
    }
  }
  return _text;
};
/**
 * 转换多语言枚举
 * @param arr 枚举数组
 */
export const langToFilter = (arr: any[] = []) => {
  const _lang = getLocale();
  const _filter = arr.map((m: any) => {
    let _text = m.text;
    if (_lang && m[_lang]) {
      _text = m[_lang];
    }
    return {
      ...m,
      text: _text,
    };
  });
  return _filter;
};
/**
 * 截取指定长度的字符串
 * @param str           字符串
 * @param len           最大长度
 * @returns {*}         截取后的字符串
 */
export function cutstr(str: string, len: number) {
  let str_length = 0;
  let str_len = 0;
  let str_cut = '';
  str_len = str.length;
  for (let i = 0; i < str_len; i++) {
    const a = str.charAt(i);
    str_length++;
    if (escape(a).length > 4) {
      //中文字符的长度经编码之后大于4
      str_length++;
    }
    str_cut = str_cut.concat(a);
    if (str_length >= len) {
      str_cut = str_cut.concat('...');
      return str_cut;
    }
  }
  //如果给定字符串小于指定长度，则返回源字符串；
  if (str_length <= len) {
    return str;
  } else {
    return str_cut;
  }
}

/**
 * 格式化金额(整数按整数算，最多保留3位小数)
 * deci 小数点几位，向下取整
 */
export function dollarFormat(_money: number | string, deci?: number) {
  if (_money === 0) return '$0';
  const money = +_money;
  if (!money) return ' ';
  const remainder = String(money)?.split('.')?.[1]?.length || 0;
  const decimal = remainder > 6 ? 6 : remainder;
  let moneyVal = '',
    _symbol = '$';
  if (money < 0) {
    _symbol = '-$';
    moneyVal = parseFloat(`${money * -1}`).toFixed(decimal);
  } else {
    moneyVal = parseFloat(`${money}`).toFixed(decimal);
  }
  if (deci || deci === 0) {
    //向下取整截取
    const lastIndex =
      moneyVal.lastIndexOf('.') > -1
        ? moneyVal.lastIndexOf('.') + (deci ? deci + 1 : 0)
        : moneyVal.length;
    moneyVal = moneyVal.substring(0, lastIndex);
  }
  return `${_symbol}${Number(moneyVal)}`;
}

// 向下截取数值长度
export function substrNumber(n: number | string, deci: number = 2) {
  const val = '' + n;
  const lastIndex =
    val.lastIndexOf('.') > -1 ? val.lastIndexOf('.') + (deci ? deci + 1 : 0) : val.length;
  return val.substring(0, lastIndex);
}

// 加法
export function add(...val: any) {
  let max = 0;
  let count = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    const strVal = val[i]?.toString();
    const index = strVal?.indexOf('.');
    let num = 0;
    if (index > -1) {
      num = strVal.length - 1 - index;
      max = num > max ? num : max;
    }
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    // eslint-disable-next-line no-restricted-properties
    count += Math.round(val[i] * Math.pow(10, max));
  }
  // eslint-disable-next-line no-restricted-properties
  return count / Math.pow(10, max);
}

// 减法
export function sub(...val: any) {
  let max = 0;
  let count = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    const strVal = val[i]?.toString();
    const index = strVal?.indexOf('.');
    let num = 0;
    if (index > -1) {
      num = strVal.length - 1 - index;
      max = num > max ? num : max;
    }
  }
  // eslint-disable-next-line no-restricted-properties
  count = Math.round(val[0] * Math.pow(10, max));
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < val.length; i++) {
    // eslint-disable-next-line no-restricted-properties
    count -= Math.round(val[i] * Math.pow(10, max));
  }
  // eslint-disable-next-line no-restricted-properties
  return count / Math.pow(10, max);
}

// 乘法
export function multiply(...val: any) {
  let max = 0;
  let count = 1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    const strVal = val[i]?.toString();
    const index = strVal?.indexOf('.');
    let num = 0;
    if (index > -1) {
      num = strVal.length - 1 - index;
      max += num > max ? num : max;
    }
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val.length; i++) {
    // eslint-disable-next-line no-restricted-properties
    count *= Number(val[i]?.toString().replace('.', ''));
  }
  // eslint-disable-next-line no-restricted-properties
  return count / Math.pow(10, max);
}

//过滤空值
export function filterEmptyValue(value: any) {
  if (!value) return '--';
  return value;
}
/**
 * 本地存储
 * @param {string} key
 * @param {object} obj
 */
export function setLocalStorage(key: string, obj: any) {
  localStorage.setItem(key, JSON.stringify(obj));
}
/**
 * 获取本地存储
 * @param {string} key
 */
export function getLocalStorage(key: string) {
  const local: any = localStorage.getItem(key);
  if (local) {
    return JSON.parse(local);
  }
  return null;
}
/**
 * 删除本地存储
 * @param {string} key
 */
export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}
/**
 * 下载文件流
 * @param url
 * @param filename // 文件名
 */
export function downloadFile(data: any, filename: string) {
  const href = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = href;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(href);
}

/**
 *  带宽转换
 * @param bytes //数据
 */
export function bandwidthSize(bytes: number) {
  if (!bytes || bytes === 0) return '0 bps';
  if (bytes < 1) {
    const num: any = Number(bytes).toFixed(2);
    return parseFloat(num) + 'bps';
  }
  const k = 1000, // or 1024
    sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  const num: any = (bytes / Math.pow(k, i)).toFixed(2);
  return parseFloat(num) + ' ' + sizes[i];
}

/**
 *  流量转换
 * @param bytes //数据
 */
export function bytesToSize(bytes: number) {
  if (!bytes || bytes === 0) return '0 B';
  if (bytes < 1) {
    const num: any = Number(bytes).toFixed(2);
    return parseFloat(num) + 'B';
  }
  const k = 1000, // or 1024
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  const num: any = (bytes / Math.pow(k, i)).toFixed(2);
  return parseFloat(num) + ' ' + sizes[i];
}

/**
 *  超过1000 转换成 K
 * @param num //数据
 */
export function numberFormat(num: number, unit?: number) {
  const show = unit || 2;
  if (!num) return 0;
  if (num >= 1000) {
    return Number(num / 1000).toFixed(show) + 'K';
  } else {
    return num;
  }
}

// 时间转换19-21 Jun -  20 Jul 2022/20 Jul 2022，isWrap是否换行展示，isHMS是否展示时分秒
export function formatTimeSlot(
  beginTime: string,
  endTime?: string,
  isWrap?: boolean,
  isHMS?: boolean,
) {
  const _locale = getLocale();
  const begin = moment(beginTime).locale(_locale);
  const end = moment(endTime).locale(_locale);
  const _line = isWrap ? '<br/>' : ' - ';
  if (!beginTime) {
    return '';
  } else if (beginTime && !endTime) {
    if (isHMS) {
      return begin?.format(_locale !== 'en-US' ? 'YYYY年MM月DD日 HH:mm:ss' : 'D MM YYYY, HH:mm:ss');
    } else {
      return begin?.format('LL');
    }
  } else if (begin?.year() === end?.year()) {
    if (isWrap) {
      return [begin?.format('LL'), end?.format('LL')];
    } else {
      return begin?.format(_locale !== 'en-US' ? 'LL' : 'DD MMM') + ' - ' + end?.format('LL');
    }
  } else {
    if (isWrap) {
      return [begin?.format('LL'), end?.format('LL')];
    } else {
      return begin?.format('LL') + _line + end?.format('LL');
    }
  }
}
/**
 *  流量单位转换
 * @param bytes //数据
 * @param startUnit //转换前单位
 * @param endUnit //转换后单位
 */
export function conversionUnit(bytes: number, startUnit = 'TB', endUnit = 'GB') {
  const k = 1000, // or 1024
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = sizes.indexOf(startUnit);
  const endUnitR = endUnit || sizes[i - 1];
  const j = endUnit ? sizes.indexOf(endUnitR) - i : -1;
  if (!sizes.some((v) => v === startUnit) || !sizes.some((v) => v === endUnitR)) {
    return 0;
  } else {
    const p = multiply(bytes, Math.pow(k, -j));
    return Number(p);
  }
}
//处理某些数据在 * 100之后出现的精度问题
export function signFigures(num: number, rank = 6) {
  if (!num) return 0;
  const sign = num / Math.abs(num);
  const number = num * sign;
  const temp = rank - 1 - Math.floor(Math.log10(number));
  let ans;
  if (temp > 0) {
    ans = parseFloat(number.toFixed(temp));
  } else if (temp < 0) {
    ans = Math.round(number / Math.pow(10, temp)) * temp;
  } else {
    ans = Math.round(number);
  }
  return ans * sign;
}
// 转换时间（客户端转utc,开始时间加上00：00：00，结束时间：当天输出当天的utc时间，不是当天的输出23：59：59）
export function handleUtcTime(time: Moment | null | undefined, type: string) {
  if (!time) return undefined;
  if (type === 'start') {
    return moment(time).utc().format('YYYY-MM-DD') + ' 00:00:00';
  } else {
    if (moment(time).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD')) {
      return moment(time).utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      return moment(time).utc().format('YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
// 转换时间
export function handleTime(time: Moment | null | undefined, type: string) {
  if (!time) return undefined;
  if (type === 'start') {
    return moment(time).format('YYYY-MM-DD') + ' 00:00:00';
  } else {
    // 当前时间需要取utc,time已经是utc格式了
    if (moment(time).format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD')) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return moment(time).format('YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
// 日期相减剩余多少时长（比如1天1小时）
export function dateCalculateToHour(startDate: any, endDate: any) {
  if (!startDate || !endDate) {
    return '--';
  }
  let dateDiff = 0;
  let str = '';
  const m1 = moment(startDate);
  const m2 = moment(endDate);
  dateDiff = moment(m2).diff(moment(m1), 'hours');
  if (dateDiff < 24) {
    str = dateDiff + renderTypeName(dateUnitEnum, 'hours', true);
  } else {
    str = Math.floor(dateDiff / 24) + renderTypeName(dateUnitEnum, 'days', true);
  }
  return str;
}
//url参数转object格式
export function searchToObj(searchStr: string) {
  const result = {};
  let str = searchStr;
  if (!searchStr) return result;
  if (searchStr.startsWith('?')) {
    str = searchStr.substr(1);
  }
  const searchArr = str.split('&');
  searchArr.forEach((item) => {
    const itemArr = item.split('=');
    result[itemArr[0]] = itemArr[1];
  });
  return result;
}
// 深度遍历删除某个属性
export function delObjKey(obj: any, del: string) {
  if (obj?.[del]) {
    delete obj[del];
  }
  for (const key in obj) {
    if (obj[key]?.constructor === Object) {
      delObjKey(obj[key], del);
    }
  }
  return obj;
}
// 按照屏幕分辨率判断移动端展示
export const isMobile = () => {
  if (typeof window !== 'undefined') {
    if (window.innerWidth <= 768) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
