export default {
  'guide.next': '下一步',
  'guide.pass': '我已掌握使用方法，跳过新手教程>>',
  'guide.attempt': '去尝试一下！',
  'guide.cdnResources.detail.cname.text1': '替换文件路径',
  'guide.cdnResources.detail.cname.text2': 'DNS解析',
  'guide.cdnResources.detail.cname.start': '去激活LightCDN吧！',
  'guide.cdnResources.detail.location.start': '快来尝试更换网络档位吧！',
  'guide.cdnResources.detail.rule.start': '去设置规则',
  //
  'guide.CdnCreate.step.title1.1': '内容的来源地址，通常是服务器地址/URL',
  'guide.CdnCreate.step.title1.2': '对外展示的地址',
  'guide.CdnCreate.step.content1': '',
  'guide.CdnCreate.step.title2': '两个网络档位有什么区别',
  'guide.CdnCreate.step.content2.1': '核心网络',
  'guide.CdnCreate.step.content2.2':
    '仅提供中国香港、日本东京、新加坡、美国硅谷、美国华盛顿、法国法兰克福、巴西圣保罗、阿拉伯联合酋长国迪拜8个高带宽节点。',
  'guide.CdnCreate.step.content2.3':
    '合适大文件/视频；或集中于该区域的业务或没有特定业务区域的国际站点。',
  'guide.CdnCreate.step.content2.4': '标准网络',
  'guide.CdnCreate.step.content2.5': '包含所有数据中心。',
  'guide.CdnCreate.step.content2.6':
    '合适业务区域比较稳定以及特定；或需求是网站加速或广告投放需要分发范围较为广泛的业务。',
  'guide.CdnCreate.step.title3': '',
  'guide.CdnCreate.step.content3.1': '每个人都将拥有500G的30天核心网络的流量，若你还没领取可以前往',
  'guide.CdnCreate.step.content3.2': '升级账号',
  'guide.CdnCreate.step.content3.3': '中领取',
  'guide.CdnCreate.step.title4': '',
  'guide.CdnCreate.step.content4.1': '1.填完加速域名和源',
  'guide.CdnCreate.step.content4.2': '2.选择合适的网络档位',
  'guide.CdnCreate.step.content4.3': '就可以创建一个CDN加速资源啦！',
  //
  'guide.CdnActivateConfig.step.top.1': '现在LightCDN还没发挥作用呢！',
  'guide.CdnActivateConfig.step.top.2': '别着急，还有最后一步！',
  'guide.CdnActivateConfig.step.title1.1': '请选择一种方式使用CNAME地址（xxx.r.cdn36.com）',
  'guide.CdnActivateConfig.step.content1.1': 'Tips：什么是CNAME？',
  'guide.CdnActivateConfig.step.content1.2':
    'cname是dns的一种记录方式，将一个域名映射到另一个域名。',
  'guide.CdnActivateConfig.step.content1.3':
    'LightCDN提供的CNAME地址即加速缓存内容的地址，可用来替换源地址。',
  'guide.CdnActivateConfig.step.title2': '两种方式有什么不同？',
  'guide.CdnActivateConfig.step.content2.1': '方式一，更换域名地址。',
  'guide.CdnActivateConfig.step.content2.2': '将域名地址的源路径更换为cname。合适只对',
  'guide.CdnActivateConfig.step.content2.3': '部分文件',
  'guide.CdnActivateConfig.step.content2.4': '进行缓存加速。',
  'guide.CdnActivateConfig.step.title3': '',
  'guide.CdnActivateConfig.step.content3.1': '方式二，使用DNS。',
  'guide.CdnActivateConfig.step.content3.2': '通过第三方域名平台设置一条DNS记录。',
  'guide.CdnActivateConfig.step.content3.3': '通常配置方式：',
  'guide.CdnActivateConfig.step.content3.4': '加速域名/加速域名的最低层级',
  'guide.CdnActivateConfig.step.content3.5': '我们生成的CNAME地址',
  'guide.CdnActivateConfig.step.content3.6': '当然，直接使用服务器的命令来完成DNS也可以。',
  'guide.CdnActivateConfig.step.title4': '',
  'guide.CdnActivateConfig.step.content4.1': '通过第三方域名平台设置一条DNS记录。合适需要',
  'guide.CdnActivateConfig.step.content4.2': '全局文件加速',
  'guide.CdnActivateConfig.step.content4.3':
    '的资源，此方式，若后续更换hostname，只需要DNS更换“Host Records”一个值即可。',
  'guide.CdnActivateConfig.step.title5': '如何验证是否加速成功',
  'guide.CdnActivateConfig.step.content5.1': '若你使用的是',
  'guide.CdnActivateConfig.step.content5.2': 'DNS解析',
  'guide.CdnActivateConfig.step.content5.3': '的方式，可访问',
  'guide.CdnActivateConfig.step.content5.4': '进行自查。',
  'guide.CdnActivateConfig.step.content5.5': '输入加速域，选择CNAME，进行Check。',
  //
  'guide.CdnLocation.step.title1': '',
  'guide.CdnLocation.step.content1.1':
    '这是这个资源当前的网络档位，展示了你可用的加速位置数量，你可以在当前页面进行切换。',
  'guide.CdnLocation.step.content2.1': '高亮的是该资源当前的网络档位。',
  'guide.CdnLocation.step.content2.2': '你可以选择更加符合你业务需求的档位，保存即生效。',
  //
  'guide.CdnRule.step.title1': '条件如何填写？',
  'guide.CdnRule.step.content1.1': '条件填写的是你需要绕过缓存的文件类型。',
  'guide.CdnRule.step.content1.2': '"主题"选择',
  'guide.CdnRule.step.content1.3': '"谓语"选择',
  'guide.CdnRule.step.content1.4': '"值"填写',
  'guide.CdnRule.step.content1.5': '文件后缀名',
  'guide.CdnRule.step.content1.6': '，例如图片填写"jpg" 。',
  'guide.CdnRule.step.title2': '如何设定动作？',
  'guide.CdnRule.step.content2.1': '若要达到绕开CDN直接访问源，你需要设置',
  'guide.CdnRule.step.content2.2': '不缓存 - 强制边缘不缓存',
  'guide.CdnRule.step.content2.3': '，开启按钮即可。',
  //
};
