// 无权限语句
export default {
  'noAccess.SafetyWaf.text':
    'Web应用程序防火墙（WAF）监视、过滤或阻止进出Web应用程序的流量。WAF检查每个HTML、HTTPS、SOAP和XML-RPC数据包。该防火墙能够通过可自定义的web安全规则防止诸如跨站点脚本（XSS）、SQL注入、会话劫持和缓冲区溢出等攻击。',
  'noAccess.SafetyWaf.more1': '您可以了解更多关于WAF的信息通过查看',
  'noAccess.SafetyWaf.more2': '文档。',

  'noAccess.Analytics.text':
    '我们支持您检查客户的访问状态，包括访客来自哪里、延迟、传输速率、请求量和流量。这将帮助您更好地了解网站的运营和客户结构。',

  'noAccess.Nginx.text':
    '支持根据单个文件限制速率，或设置缓存关键字以在缓存中存储和搜索请求。这通常应用于网络安全领域。例如，减缓暴力密码破解攻击，通过爬网程序对网页进行爬网，以及防止DDOS攻击。',
  'noAccess.Nginx.more1': '您可以了解有关Nginx设置的更多信息通过查看',
  'noAccess.cacheRules.text1':
    '规则支持您从IP、cookie、标头等多个维度自定义CDN边缘服务器行为，例如CDN将如何管理缓存。',
  'noAccess.cacheRules.text2':
    '这为不同平台的网站提供了很大的灵活性和适应性，可以轻松地与CDN集成。',
  'noAccess.cacheRules.more1': '您可以了解更多关于规则的信息通过查看',

  'noAccess.redictRules.text1':
    '规则支持您从IP、cookie、标头等多个维度自定义CDN边缘服务器行为，例如CDN将如何管理重定向。',
  'noAccess.redictRules.text2':
    '这为不同平台的网站提供了很大的灵活性和适应性，可以轻松地与CDN集成。',
  'noAccess.redictRules.more1': '您可以了解更多关于规则的信息通过查看',

  'noAccess.modifyHeader.text1':
    '规则支持您从IP、cookie、标头等多个维度自定义CDN边缘服务器行为，例如CDN将如何管理标头。',
  'noAccess.modifyHeader.text2':
    '这为不同平台的网站提供了很大的灵活性和适应性，可以轻松地与CDN集成。',
  'noAccess.modifyHeader.more1': '您可以了解更多关于规则的信息通过查看',

  'noAccess.originRules.text1':
    '规则支持您从IP、cookie、标头等多个维度自定义CDN边缘服务器行为，例如CDN将如何管理源。',
  'noAccess.originRules.text2':
    '这为不同平台的网站提供了很大的灵活性和适应性，可以轻松地与CDN集成。',
  'noAccess.originRules.more1': '您可以了解更多关于规则的信息通过查看',

  'noAccess.TopFile.text1': '前五个文件将显示访问权限较高的文件路径，您可以根据文件路径进行清除。',
  'noAccess.TopFile.text2':
    '这将帮助您了解业务的正常水平，如果出现异常请求或洪水，您将清楚地了解并发现问题。我们还提供了一个阈值入口，以提示您发现异常情况并及时掌握风险。',
  'noAccess.TopFile.more1': '您可以了解有关顶级文件的更多信息通过查看',
};
