import React, { useCallback, useState } from 'react';
import { LogoutOutlined, ProfileOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Spin, notification } from 'antd';
import { history, useModel, FormattedMessage } from 'umi';
import HeaderDropdown from '../HeaderDropdown';
// import ChangePwd from './changepwd';
import styles from './index.less';
import { Logout_api } from '@/api/user';
import { useFomatter } from '@/hooks';
import EditPassword from './editpassword';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { removeLocalStorage } from '@/utils/utils';
// 图片
import Account from '@/assets/images/icon/Account.png';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = () => {
  const { formatter } = useFomatter();
  const { initialState, setInitialState } = useModel('@@initialState');
  const [visible, setVisible] = useState<boolean>(false);
  /**
   * 退出登录，并且将当前的 url 保存
   */
  const loginOut = async () => {
    const res: any = await Logout_api();
    const { query = {} } = history.location;
    const { redirect } = query;
    // Note: There may be security issues, please note
    if (window.location.pathname !== '/user/login' && res.success && !redirect) {
      removeLocalStorage('cdnControl-token');
      notification.success({
        message: formatter({ id: 'menu.LogOut.message' }),
      });
      history.replace({
        pathname: '/user/login',
      });
    }
  };
  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      switch (key) {
        case 'logout':
          setInitialState((s) => ({ ...s, currentUser: undefined }));
          loginOut();
          break;
        case 'changepwd':
          setVisible(true);
          break;
        case 'Account':
          history.push('/accountSettings');
          break;
        case 'billing':
          history.push('/billing');
          break;
        default:
          break;
      }
    },
    [setInitialState],
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );
  const { currentUser, hasMonthPlan } = initialState || {};
  if (!currentUser) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.ItemGroup title={currentUser?.nickname}>
        <Menu.Item key="Account">
          <SettingOutlined />
          <FormattedMessage id="menu.AccountSettings" />
        </Menu.Item>
        {hasMonthPlan ? (
          <Menu.Item key="billing">
            <ProfileOutlined />
            <FormattedMessage id="menu.Billing" />
          </Menu.Item>
        ) : null}
        <Menu.Item key="logout">
          <LogoutOutlined />
          <FormattedMessage id="menu.LogOut" />
        </Menu.Item>
      </Menu.ItemGroup>
      {/* <Menu.Item key="changepwd">
        <EditOutlined />
        <ChangePwd />
        修改密码
      </Menu.Item> */}
    </Menu>
  );
  const closeModal = () => {
    setVisible(false);
  };
  return (
    <>
      <HeaderDropdown overlay={menuHeaderDropdown} overlayClassName={styles.AvatarDropdownMenu}>
        <span className={`${styles.action} ${styles.account}`}>
          <img src={Account} alt="Account" />
          {/* <span className={`${styles.name} anticon`} style={{ marginLeft: 10 }}>
            {currentUser?.nickname}
          </span>
          <span className={styles.triggle} /> */}
        </span>
      </HeaderDropdown>
      <EditPassword visible={visible} close={closeModal} />
    </>
  );
};

export default AvatarDropdown;
