// 无权限语句
export default {
  'noAccess.SafetyWaf.text':
    'Web應用程序防火墻（WAF）監視、過濾或阻止進出Web應用程序的流量。WAF檢查每個HTML、HTTPS、SOAP和XML-RPC數據包。該防火墻能夠通過可自定義的web安全規則防止諸如跨站點腳本（XSS）、SQL註入、會話劫持和緩沖區溢出等攻擊。',
  'noAccess.SafetyWaf.more1': '您可以了解更多關於WAF的信息通過查看',
  'noAccess.SafetyWaf.more2': '文檔。',

  'noAccess.Analytics.text':
    '我們支持您檢查客戶的訪問狀態，包括訪客來自哪裏、延遲、傳輸速率、請求量和流量。這將幫助您更好地了解網站的運營和客戶結構。',

  'noAccess.Nginx.text':
    '支持根據單個文件限製速率，或設置緩存關鍵字以在緩存中存儲和搜索請求。這通常應用於網絡安全領域。例如，減緩暴力密碼破解攻擊，通過爬網程序對網頁進行爬網，以及防止DDOS攻擊。',
  'noAccess.Nginx.more1': '您可以了解有關Nginx設置的更多信息通過查看',
  'noAccess.cacheRules.text1':
    '規則支持您從IP、cookie、標頭等多個維度自定義CDN邊緣服務器行為，例如CDN將如何管理緩存。',
  'noAccess.cacheRules.text2':
    '這為不同平臺的網站提供了很大的靈活性和適應性，可以輕松地與CDN集成。',
  'noAccess.cacheRules.more1': '您可以了解更多關於規則的信息通過查看',

  'noAccess.redictRules.text1':
    '規則支持您從IP、cookie、標頭等多個維度自定義CDN邊緣服務器行為，例如CDN將如何管理重定向。',
  'noAccess.redictRules.text2':
    '這為不同平臺的網站提供了很大的靈活性和適應性，可以輕松地與CDN集成。',
  'noAccess.redictRules.more1': '您可以了解更多關於規則的信息通過查看',

  'noAccess.modifyHeader.text1':
    '規則支持您從IP、cookie、標頭等多個維度自定義CDN邊緣服務器行為，例如CDN將如何管理標頭。',
  'noAccess.modifyHeader.text2':
    '這為不同平臺的網站提供了很大的靈活性和適應性，可以輕松地與CDN集成。',
  'noAccess.modifyHeader.more1': '您可以了解更多關於規則的信息通過查看',

  'noAccess.originRules.text1':
    '規則支持您從IP、cookie、標頭等多個維度自定義CDN邊緣服務器行為，例如CDN將如何管理源。',
  'noAccess.originRules.text2':
    '這為不同平臺的網站提供了很大的靈活性和適應性，可以輕松地與CDN集成。',
  'noAccess.originRules.more1': '您可以了解更多關於規則的信息通過查看',

  'noAccess.TopFile.text1': '前五個文件將顯示訪問權限較高的文件路徑，您可以根據文件路徑進行清除。',
  'noAccess.TopFile.text2':
    '這將幫助您了解業務的正常水平，如果出現異常請求或洪水，您將清楚地了解並發現問題。我們還提供了一個閾值入口，以提示您發現異常情況並及時掌握風險。',
  'noAccess.TopFile.more1': '您可以了解有關頂級文件的更多信息通過查看',
};
