import { get, post, put } from '../request';
import { ACCOUNT_URL, APP_URL } from '../url_config';

// 账户详情
export async function getAccount_api(params?: any) {
  return get(`${ACCOUNT_URL}v1`, params);
}
// 使用近30天的账单消费
export async function getAccountStatistics_api(params?: any) {
  return get(`${ACCOUNT_URL}v1/statistics/recent`, params);
}
// 兑换礼包码
export async function postGiftCodeRedeem_api(giftCode: string) {
  return post(`${ACCOUNT_URL}/redeem/${giftCode}`);
}
// 查询近30天消耗-返回消耗和通知配置
export async function getAccountRecent_api(params?: any) {
  return get(`${ACCOUNT_URL}v1/recent`, params);
}
// 修改余额通知配置
export async function putAccountNotice_api(params: any) {
  return put(`${ACCOUNT_URL}v1/notice`, params);
}
/**
 * postpaid
 */
// 查询使用情况
export async function getPostpaidStatistics_api(params?: any) {
  return get(`${APP_URL}postpaid/v1/statistics`, params);
}
// 创建资源页面 展示可选档位选择
export async function getPostpaid_api(params?: any) {
  return get(`${APP_URL}postpaid/v1`, params);
}
// 编辑资源页面 展示可选档位选择
export async function getPostpaidModify_api(params?: any) {
  return get(`${APP_URL}postpaid/v1/modify`, params);
}
// 是否可以领取免费的额度
export async function getPostpaidFreeCheck_api(params?: any) {
  return get(`${APP_URL}postpaid/v1/free/check`, params);
}
// 领取免费的额度
export async function postPostpaidFree_api(params?: any) {
  return post(`${APP_URL}postpaid/v1/free`, params);
}
// 查询当月是否有月度计划
export async function getPostpaidThisMonth_api() {
  return get(`${APP_URL}postpaid/v1/haveMonthPlan/thisMonth`);
}
// 查询是否有进行中的操作，因为变更档位时间比较久，所以需暂停操作，true/false
export async function getPostpaidLock_api() {
  return get(`${APP_URL}postpaid/v1/lock`);
}
