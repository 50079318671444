import login from './en-US/login';
import home from './en-US/home';
import forget from './en-US/forget';
import register from './en-US/register';
import menu from './en-US/menu';
import reset from './en-US/reset';
import cdnResources from './en-US/cdnResources';
import sslCerificates from './en-US/sslCerificates';
import bandWidth from './en-US/bandWidth';
import traffic from './en-US/traffic';
import requests from './en-US/requests';
import statusCodes from './en-US/statusCodes';
import accountSettings from './en-US/accountSettings';
import billing from './en-US/billing';
import upgadeAccount from './en-US/upgadeAccount';
import packageSelection from './en-US/packageSelection';
import workorder from './en-US/workorder';
import giftCode from './en-US/giftCode';
import topFiles from './en-US/topFiles';
import noAccess from './en-US/noAccess';
import guide from './en-US/guide';

export default {
  ...menu,
  ...home,
  ...forget,
  ...login,
  ...register,
  ...reset,
  ...cdnResources,
  ...sslCerificates,
  ...bandWidth,
  ...traffic,
  ...requests,
  ...statusCodes,
  ...accountSettings,
  ...billing,
  ...upgadeAccount,
  ...packageSelection,
  ...workorder,
  ...giftCode,
  ...topFiles,
  ...noAccess,
  ...guide,
};
