export default {
  'traffic.topTitle': '流量報告',
  'traffic.name': '流量',
  'traffic.topTitle.text': '（請按照 utc+0 的時區查看數據。）',
  'traffic.content.title': '緩存/非緩存數據',
  'traffic.content.tip': '正常緩存率高於90%。如果您的命中率太低，您可以參考',
  'traffic.content.tip1': '文檔',
  'traffic.content.tip2': '進行排查；若依舊有問題請聯系客服或發送郵件，我們將很高興為你服務。',
  'traffic.menu.text1': '邊緣位置',
  'traffic.menu.text2': '大洲',
  'traffic.menu.text3': 'CDN資源',
  'traffic.menu.text4': '訪問者分析',

  'traffic.chart.text1': '緩存',
  'traffic.chart.text2': '非緩存',
  'traffic.oneColumns.title1': '邊緣位置',
  'traffic.oneColumns.title2': '總計流量',
  'traffic.twoColumns.title1': '大洲',
  'traffic.threeColumns.title1': 'CDN資源',
  'traffic.threeColumns.title2': '錯誤請求',
  'traffic.threeColumns.title3': '過去一小時',
  'traffic.threeColumns.title4': '這個小時',
  'traffic.getCdnList.all': '全部',
  'traffic.getCdnList.showAll': '全部顯示',
  'traffic.chartName': '流量',
  'traffic.total': '總計',
  'traffic.chart.cached': '緩存',
  'traffic.chart.nonCached': '非緩存',

  'traffic.fourColumns.h3': '流量最高的國家/地區',
  'traffic.fourColumns.title1': '國家/地區',
  'traffic.fourColumns.title2': '總流量',
  'traffic.fourColumns.title3': '平均延時',
  'traffic.fourColumns.title4': '平均傳輸速度',
  'traffic.fourColumns.title5': '請求',
  'traffic.Analytics.loading': '加載中...',
  'traffic.Analytics.unkown': '未知',
};
