// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import BranchesOutlined from '@ant-design/icons/BranchesOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import ApiOutlined from '@ant-design/icons/ApiOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import ShareAltOutlined from '@ant-design/icons/ShareAltOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import ToTopOutlined from '@ant-design/icons/ToTopOutlined'

export default {
  HomeOutlined,
AppstoreOutlined,
BranchesOutlined,
LineChartOutlined,
CloudUploadOutlined,
MessageOutlined,
ApiOutlined,
FileOutlined,
ShareAltOutlined,
SettingOutlined,
ProfileOutlined,
ToTopOutlined
}
    