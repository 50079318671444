export default {
  'home.Today': 'Today',
  'home.topTitle': 'Bandwidth',
  'home.reportTitle': 'Reports of continents',
  'home.statusTitle': 'Reports of status code',
  'home.reportTitle.memus1': 'Traffic',
  'home.reportTitle.memus2': 'Hit ratio',
  'home.headerTitle': 'Overview of',
  'home.homeTip': ' (Please view the data according to utc+0.)',
  'home.ProCard.title1': 'Peak',
  'home.ProCard.title2': 'Peak time',
  'home.StatusOverView.series': 'Request',
  'home.StatusOverView.upViewbtn': 'View full code status report →',
  'home.line.series': 'total',
  'home.menuTable.menu1': 'traffic',
  'home.requestData.series': 'hit',
  'home.traffic.upViewbtn': 'View full traffic report →',
  'home.requests.upViewbtn': 'View full requests report →',
  'home.oneColumns.Continent': 'Continent',
  'home.oneColumns.Traffic': 'Traffic',
  'home.oneColumns.Percent': 'Percent',
  'home.twoColumns.Time': 'Time',
  'home.twoColumns.Bandwidth_peak': 'Bandwidth peak',
  'home.threeColumns.Hit_ratio': 'Hit ratio',
  'home.statusColumns.status_code': 'status code',
  'home.statusColumns.Requests': 'Requests',

  'home.header.title': 'Hi! welcome back',
  'home.balance.tip1':
    'Unfortunately, your account balance is insufficient and you will no longer be able to use LightCDN services. To continue your service, please check your ',
  'home.balance.tip2': 'billing',
  'home.balance.tip3':
    ', recharge your account, or purchase a monthly plan. If you have any questions, please contact customer service or contact ',
  'home.balance.tip4': ' Send an email.',
  'home.locations': 'CDN Locations',
  'home.top.title1': 'TOP 5 Http Error Report (4XX & 5XX)',
  'home.top.title2': 'TOP 5 CDN Resources',
  'home.top.title3': 'TOP 5 Visitor Countries',
  'home.top.title4': 'TOP 5 Referrers',
  'home.TopToday.Traffic': 'Traffic（Today）',
  'home.TopToday.Bandwidth': 'Bandwidth （Today）',
  'home.TopToday.Peak': 'Peak',
  'home.TopToday.PeakTime': 'Peak time',
  'home.TopToday.CacheHitRate': 'Cache Hit Rate（Today）',
};
