export default {
  'upgadeAccount.title': '升級',
  'upgadeAccount.p1': '選擇您的套餐',
  'upgadeAccount.p2': '填入您的賬單詳情',
  'upgadeAccount.p3': '填入您的支付信息',
  'upgadeAccount.step1.title': '升級服務',
  'upgadeAccount.step1.columns.title1': '月度計劃',
  'upgadeAccount.step1.columns.title2': '月度價格',
  'upgadeAccount.step1.p1': '全球加速，不受限製的節點',
  'upgadeAccount.step1.p2': '所有功能',
  'upgadeAccount.step1.p3': '24/7在線售後支持',
  'upgadeAccount.step1.p4': '無限HTTP（S）請求',
  'upgadeAccount.step1.p5': '統一的全球定價，無論地理位置',
  'upgadeAccount.step1.Choose.title': '選擇購買周期',
  'upgadeAccount.step1.p6': '您可以最多購買12個月。一個月我們將以30天計算。',
  'upgadeAccount.step1.p7': '購買周期',
  'upgadeAccount.step1.p8': '服務時間',
  'upgadeAccount.step1.Next': '下一步',
  'upgadeAccount.step1.months': '月',
  'upgadeAccount.step1.month': '月',
  'upgadeAccount.step2.validatorEnglish_number.message': '使用3-10個字符',
  'upgadeAccount.step3.memuList.title1': '信用卡',
  'upgadeAccount.step3.memuList.title2': '支付寶',
  'upgadeAccount.step3.columns.title2': '支付詳情',
  'upgadeAccount.step3.columns.title4': '每月可使用的流量',
  'upgadeAccount.step3.columns.title5': '總計需支付',
  'upgadeAccount.step3.PaymentSucceeded': '支付成功',
  'upgadeAccount.step3.PaymentFailed': '支付失敗',
  'upgadeAccount.step3.title': '支付',
  'upgadeAccount.step3.postCreditCard.message': '添加成功',
};
