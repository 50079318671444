export default {
  'topFiles.list.title': '热门文件',
  'topFiles.list.columns.title1': '文件路径',
  'topFiles.list.columns.title2': '资源',
  'topFiles.list.columns.title3': '请求',
  'topFiles.list.columns.title4': '命中',
  'topFiles.list.columns.title5': '未命中',
  'topFiles.list.columns.title6': '流量',
  'topFiles.list.columns.title7': '操作',
  'topFiles.list.PopoverText1': '清除',
  'topFiles.list.PopoverText2': '预警设置',
  'topFiles.modal.NoticeSetting.title': '预警设置',
  'topFiles.modal.NoticeSetting.success': '设置成功',
  'topFiles.modal.CleanUp.success': '清除成功',
  'topFiles.modal.Button.Save': '保存',
  'topFiles.modal.NoticeSetting.text1': '过去30天的日均请求流量：',
  'topFiles.modal.NoticeSetting.text2': '若文件的请求流量在一天内达到了',
  'topFiles.modal.NoticeSetting.text3': '，我们将发送预警通知邮件。',
  'topFiles.modal.NoticeSetting.message': '请输入预警值',
  'topReferrers.list.title': '热门来源',
};
