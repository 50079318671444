/**
 * 指引内容容器
 */
import { Image } from 'antd';
import questionIcon from '@/assets/images/icon/question-icon.png';
import './GuideBox.less';
interface p {
  title?: string;
  maxWidth?: number | string;
  dir?: string;
  isLongLine?: boolean;
  type?: string;
  children?: any;
}
const GuideBox = (props: p) => {
  const { title = '', maxWidth, dir = '', isLongLine = false, type = '', children } = props;
  return type === 'top' ? (
    <div className="m-GuideBox-top" style={{ maxWidth: maxWidth || 'auto' }}>
      {children}
    </div>
  ) : (
    <div
      className={`m-GuideBox ${dir ? `dir-${dir}` : ''} ${isLongLine ? 'u-longLine' : ''}`}
      style={{ maxWidth: maxWidth || 'auto' }}
    >
      {title ? (
        <div className="GuideBox-title">
          <Image src={questionIcon} width={26} preview={false} />
          <label>{title}</label>
        </div>
      ) : null}
      <div className={`GuideBox-content ${!title ? 'u-radius-has' : ''}`}>{children}</div>
    </div>
  );
};
export default GuideBox;
