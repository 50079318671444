export default {
  'accountSettings.title': '帐户设置',
  'accountSettings.tabTitle1': '基本设置',
  'accountSettings.tabTitle2': '团队成员',
  'accountSettings.tabTitle3': '双因验证',
  'accountSettings.general.factor': '双因验证',
  'accountSettings.general.handleSave.message': '修改成功',
  'accountSettings.general.validatorPhone.rule': '使用6-11字符',
  'accountSettings.general.validatorPhone.message': '请输入正确的电话',
  'accountSettings.general.title': '账户信息',
  'accountSettings.general.Edit': '编辑',
  'accountSettings.general.Cancel': '取消',
  'accountSettings.general.Save': '保存修改',
  'accountSettings.general.Form.email.title': '邮箱地址',
  'accountSettings.general.Form.email.message': '请填入您的邮箱地址！',
  'accountSettings.general.change': '更改',
  'accountSettings.general.Form.fullname.title': '全名',
  'accountSettings.general.Form.fullname.message': '请输入您的全名！',
  'accountSettings.general.Form.phone.title': '手机号码',
  'accountSettings.general.Form.country.message': '请选择您的国家！',
  'accountSettings.general.Form.phone.message': '请输入您的手机号！',
  'accountSettings.general.Form.companyName.title1': '公司名称',
  'accountSettings.general.Form.companyName.title2': '（非必填）',
  'accountSettings.general.Form.password.title': '密码',
  'accountSettings.general.Form.password.message': '请输入您的密码！',
  'accountSettings.general.Form.rePassword.title': '再次输入密码',
  'accountSettings.general.ChangePassword': '修改密码',
  'accountSettings.general.handleChangeEmail.message': '邮件发送成功',
  'accountSettings.general.validatorPassword.message': '错误的密码',
  'accountSettings.general.validatorEmail.rule': '该邮箱已存在',
  'accountSettings.general.validatorEmail.message': '请输入正确的邮箱地址',
  'accountSettings.general.Modal.title1': '更改邮箱地址',
  'accountSettings.general.Modal.okText': '更改邮箱地址',
  'accountSettings.general.Modal.p0': '请输入密码以验证身份。',
  'accountSettings.general.Modal.p1':
    '如果您更改了邮箱，那么您的账号也会一并更改。请输入您的密码以验证身份。',
  'accountSettings.general.Modal.title2': '更改邮箱地址',
  'accountSettings.general.Modal.p2':
    '帐户信息身份验证成功。我们将向新的电子邮件地址发送确认邮件。请点击电子邮件中的链接将其激活。',
  'accountSettings.general.Modal.email.title': '新邮箱地址',
  'accountSettings.general.validatorRePassword.message': '密码不一致',
  'accountSettings.FactorAuth.enableText': '启用双因验证',
  'accountSettings.FactorAuth.disableText': '禁用双因验证',
  'accountSettings.FactorAuth.disableTip1': '您确定要禁用双因验证吗？',
  'accountSettings.FactorAuth.disableTip2': '请完成以下步骤关闭。',
  'accountSettings.FactorAuth.Status': '状态',
  'accountSettings.FactorAuth.Status.text':
    '双重身份验证是一种安全机制，旨在防止他人访问或使用您的帐户，即使他们知道您的密码。它要求用户使用特定设备或其他可识别的方法来验证其身份。此机制通过添加额外的验证步骤来提高帐户安全性。',
  'accountSettings.APIKey.text1': '您可以通过以下方式使用',
  'accountSettings.APIKey.text2': '安全地更新您的 LightCDN 资源。',
  'accountSettings.APIKey.Reset.title': '重置 API 访问密钥',
  'accountSettings.APIKey.Reset.text':
    '请确认是否要重置 API 访问密钥。确认后，旧密钥将失效并生成新密钥。您确定要重置吗？',
  'accountSettings.FactorAuth.enable.step1.1': '在您的手机（',
  'accountSettings.FactorAuth.enable.step1.Iphone': 'Iphone',
  'accountSettings.FactorAuth.enable.step1.Android': 'Android',
  'accountSettings.FactorAuth.enable.step1.2':
    '）上下载 Google Authenticator 应用并使用它来扫描以下二维码。',
  'accountSettings.FactorAuth.enable.step1.p1': '无法扫描代码？',
  'accountSettings.FactorAuth.enable.step1.p2': '您可以在手机上手动填写以下密钥到应用程序中。',
  'accountSettings.FactorAuth.enable.step2': '填写身份验证应用程序中的 6 位代码来验证配置。',
  'accountSettings.FactorAuth.enable.step3':
    '请保存这些验证码。如果您的手机出现任何问题，您可以使用备用验证码来帮助重置 Google Authenticator。',
  'accountSettings.FactorAuth.enable.success': '开启成功',
  'accountSettings.FactorAuth.disable.success': '关闭成功',
};
