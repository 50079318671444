import login from './zh-HK/login';
import home from './zh-HK/home';
import forget from './zh-HK/forget';
import register from './zh-HK/register';
import menu from './zh-HK/menu';
import reset from './zh-HK/reset';
import cdnResources from './zh-HK/cdnResources';
import sslCerificates from './zh-HK/sslCerificates';
import bandWidth from './zh-HK/bandWidth';
import traffic from './zh-HK/traffic';
import requests from './zh-HK/requests';
import statusCodes from './zh-HK/statusCodes';
import accountSettings from './zh-HK/accountSettings';
import billing from './zh-HK/billing';
import upgadeAccount from './zh-HK/upgadeAccount';
import packageSelection from './zh-HK/packageSelection';
import workorder from './zh-HK/workorder';
import giftCode from './zh-HK/giftCode';
import topFiles from './zh-HK/topFiles';
import noAccess from './zh-HK/noAccess';
import guide from './zh-HK/guide';

export default {
  ...menu,
  ...home,
  ...forget,
  ...login,
  ...register,
  ...reset,
  ...cdnResources,
  ...sslCerificates,
  ...bandWidth,
  ...traffic,
  ...requests,
  ...statusCodes,
  ...accountSettings,
  ...billing,
  ...upgadeAccount,
  ...packageSelection,
  ...workorder,
  ...giftCode,
  ...topFiles,
  ...noAccess,
  ...guide,
};
