export const TESTPASSWORD =
  /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\\(\\)])+$)([^(0-9a-zA-Z)]|[\\(\\)]|[a-zA-Z]|[0-9]){8,20}$/; //    注册密码正则
// 新密码校验，至少包含大小写字母、数字、特殊符号的8-20位三种及以上组合
//特殊字符跟随后端设置
export const TEST_PASSWORD_Bedge =
  /^(?=.*[A-Za-z])(?=.*[0-9])[0-9a-zA-Z~!@#$%^&*()_+`{};:|<>?,./=\-\[\]']{8,20}$/; //必须包含字母+数字的8-20位，弱密码
export const TEST_PASSWORD_STRONG =
  /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[~!@#$%^&*()_+`{};:|<>?,./=\-\[\]'])[0-9a-zA-Z~!@#$%^&*()_+`{};:|<>?,./=\-\[\]']{8,20}$/; //必须包含大小写字母，数字,特殊字符其中三种的8-20位，强密码
// export const TEST_PASSWORD_Bedge = /^(?=.*[A-Za-z])(?=.*[0-9])[0-9a-zA-Z_\W,\.]{8,20}$/; //必须包含字母+数字的8-20位，弱密码
// export const TEST_PASSWORD_STRONG =
//   /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[_\W,\.])[0-9a-zA-Z_\W,\.]{8,20}$/; //必须包含大小写字母，数字,特殊字符其中三种的8-20位，强密码
export const TEST_PASSWORD_ONE =
  /(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z_\W]+$)(?![a-z0-9]+$)(?![a-z_\W]+$)(?![0-9_\W]+$)[a-zA-Z0-9_\W]{8,20}$/;
// 必须包含大写字母，小写字母，数字，特殊字符其中三种的8-20位
export const TEST_PASSWORD_TWO = /^[^\\\/\:\*\?"\<\>\|\u4e00-\u9fa5]*$/;
export const TESTNUMBER = /(^[1-9]([0-9]+)?(\.[0-9])?$)|(^(0){1}$)|(^[0-9]\.[0-9]$)/; //数值正则，保留小数点后一位；
export const TESTMONEY = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/; //金额正则，保留小数点后两位；
export const TESTMONEYMINUS = /(^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d{1,2})?$)/; //金额正则，保留小数点后两位，可为负数；
export const TESTPOSIBLENUMBER = /^[1-9]\d*$/; //正整数的正则表达式(不包括0)
export const TESTPOSIBLENUMBERWITHZERO = /^[+]{0,1}(\d+)$/; //正整数的正则表达式(包括0)
//    注册密码正则
export function testPassWord(value: string) {
  if (TEST_PASSWORD_ONE.test(value) && TEST_PASSWORD_TWO.test(value)) {
    return true;
  } else {
    return false;
  }
}
export const TESTVERY = /^\d{6}$/; // 手机验证码正则
export const TESTPHONE_NEW =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; // 手机号正则（新）
export const TESTEMAIL = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/; //   邮箱正则
export const TESTCHINESE = /^[\u4e00-\u9fa5]+$/; //中文，\u4e00-\u9fa5 是中文字符的unicode编码
export const TEST_INCLUDE_CHINESE = /[\u4e00-\u9fa5]|[\s]/; //包含中文或者空格，\u4e00-\u9fa5 是中文字符的unicode编码
export const TEST_CHINESE = /[\u4e00-\u9fa5]/; //包含中文，\u4e00-\u9fa5 是中文字符的unicode编码
export const TESTNAME2_20 = '^[\u4e00-\u9fa5-a-zA-Z0-9]{2,20}$'; //匹中文，英文字母，数字
export const TESTNUMBER_ENGLISH = /^[a-zA-Z0-9]{1,15}$/; //匹英文字母，数字
export const TESTEGNLISH = /[a-zA-Z]/; //匹配英文
export const TESTSSLKey = /^-----BEGIN CERTIFICATE-----(.|\s)*-----END CERTIFICATE-----$/; //匹配以-----BEGIN CERTIFICATE-----为开头,以-----END CERTIFICATE-----结尾
export const TESTSSL_Private_Key =
  /^-----BEGIN RSA PRIVATE KEY-----(.|\s)*-----END RSA PRIVATE KEY-----$/; //匹配以-----BEGIN RSA PRIVATE KEY-----为开头,以-----END RSA PRIVATE KEY-----结尾
//IPv4正则，公网不支持第一、四段为0，底层支持，经产品确认，本站支持0
export const TESTIPv4 =
  /^([0-9]|[1-9]\d{1}|1\d\d|2[0-4]\d|25[0-5])\.([0-9]|[1-9]\d{1}|1\d\d|2[0-4]\d|25[0-5])\.([0-9]|[1-9]\d{1}|1\d\d|2[0-4]\d|25[0-5])\.([0-9]|[1-9]\d{1}|1\d\d|2[0-4]\d|25[0-5])$/;
//IPv6正则
export const TESTIPv6 =
  /((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))[%\.\+/]?/;
//IPv4+掩码
export const TESTIPv4_MASK = /^([0-9]$|[0-2][0-9]|3[0-2])?$/;
//IPv6掩码，0-128
export const TESTIPv6_MASK = /(^[1][0-2][0-8]$)|(^[1-9][0-9]$)|(^100)|(^[1-9]$)$/;
export const TEST_NUMBER = /^[0-9]\d*$/; //匹配数字
//域名正则
export const TEST_DOMAINNAME = /^([\da-z](?:[\da-z-]*[\da-z])?\.)+[a-z]{2,6}(\/\w*)*\/?$/i;
// 空格
export const TEST_SPACE = /\s*/g;
// 替换空格
export function clearSpace(str: string) {
  return str?.replace(TEST_SPACE, '');
}
//礼包码匹配
export const TESTNUMBER_GIFTCODE = /^(?=.*[A-Za-z0-9])[0-9a-zA-Z]{12}$/; //匹英文字母，数字
//：/^(?=.*[A-Za-z])(?=.*[0-9])[0-9a-zA-Z]{12}$/
//https或http前缀正则
export const TEST_HTTPS = /^(https?:\/\/)/;
//请求头header，数字、字母和横杠
export const TEST_HEADER = /^[a-zA-Z0-9\-\s]+$/;
//请求头value，数字、字母、英文字符、空格
export const TEST_VALUE = /^[0-9a-zA-Z~!@#$%^&*()\s_+`{};:|<>?,./=\-\[\]'"]+$/;
//邀请码，必须包含数字和字母的8位数
export const TEST_INVITE_CODE = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8}$/; //匹英文字母，数字
