/**
 * 多语言控件
 */
import { useEffect } from 'react';
import { Menu, Dropdown } from 'antd';
import { GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { getLocale, setLocale } from 'umi';
import { useFomatter } from '@/hooks';
import { setCrossCookie, getCookie } from '@/utils/cookie';
import IconLang from '@/assets/images/icon/lang.png';
import './style.less';
const list = [
  {
    text: 'English',
    value: 'en-US',
  },
  {
    text: '简体中文',
    value: 'zh-CN',
  },
  {
    text: '繁體中文',
    value: 'zh-HK',
  },
];
const SetLocale = (props: any) => {
  const { formatter } = useFomatter();
  // console.log('props', getLocale());
  // const { locale } = router; //defaultLocale, locales
  const locale = getLocale();
  const { isMoblie = false, position = false } = props;
  const langChange = (k: string) => {
    setLocale(k);
    setCrossCookie('BEDGE_LANG', k);
  };
  useEffect(() => {
    const _val = getCookie('BEDGE_LANG');
    if (_val && _val !== locale) {
      setLocale(_val);
    }
  }, []);
  const menu = (
    <Menu>
      {list.map((item: any) => {
        return (
          <Menu.Item
            onClick={() => langChange(item.value)}
            key={item.value}
            className={locale === item.value ? 'u-selected' : ''}
          >
            <label>{item.value.substring(3)}</label>
            {item.text}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const handleLang = (k?: string) => {
    const _text = list.find((f: any) => {
      return f.value === k;
    })?.text;
    return _text || 'English';
  };
  return (
    <div>
      <Dropdown overlay={menu} overlayClassName="k-setlocaleStyle">
        <div className={`k-setLocaleCont ${position ? 'u-pr' : ''}`}>
          {!isMoblie ? (
            <GlobalOutlined />
          ) : (
            <div className="u-IconLang">
              <img
                src={IconLang}
                alt="language"
                title={formatter({ id: 'menu.language' })}
                style={{ display: 'block' }}
              />
              <span>{locale?.substring(3)}</span>
            </div>
          )}
          {!isMoblie ? <span className="u-text">{handleLang(locale)}</span> : null}
          {!isMoblie ? <DownOutlined /> : null}
        </div>
      </Dropdown>
    </div>
  );
};
export default SetLocale;
