export default {
  'workorder.title': '定制月度计划',
  'workorder.help.title': '我们如何帮助你？',
  'workorder.help.text1': '为你的业务找到最好的技术方案',
  'workorder.help.text2': '获取自定义报价',
  'workorder.help.text3': '自定义免费试用（持续时间和流量）',
  'workorder.help.text4': '与任何其他CDN相关的问题',
  'workorder.contact.title': '如何联系我们？',
  'workorder.contact.text1': 'support@lightcdn.com',
  'workorder.contact.text2': 'www.lightcdn.com',
  'workorder.information.title': '为了让我们更了解你，请填入一下信息。',
  'workorder.Basic.title': '你的基础信息',
  'workorder.Business.title': '你的业务信息',
  'workorder.Basic.type.name': '你的业务类型',
  'workorder.Basic.type.Company': '公司',
  'workorder.Basic.type.Personal': '个人',
  'workorder.Basic.nickname.name': '你的名字',
  'workorder.Basic.nickname.message': '请输入你的名字',
  'workorder.Basic.companyName.name': '你的公司（非必填）',
  'workorder.Basic.companyName.message': '请输入你的公司',
  'workorder.Basic.email.name': '你的商务邮箱',
  'workorder.Basic.email.message': '请输入你的商务邮箱',
  'workorder.Business.industry.name': '你的产业',
  'workorder.Business.industry.message': '请输入你的产业',
  'workorder.Business.projectName.name': '项目名称/URL（非必填）',
  'workorder.Business.projectName.message': '请输入项目名称/URL',
  'workorder.Business.estimate.name': '每月预估流量',
  'workorder.Business.estimate.message': '请输入你的产业',
  'workorder.Business.description.name': '需求描述',
  'workorder.Business.description.placeholder':
    '请描述您对CDN的要求，例如带宽速度和服务分发（即您需要加速的地区可以针对国家，越具体越好）。如果您有其他要求，可以再解释一遍。',
  'workorder.Business.description.message': '请输入需求描述',
  'workorder.information.button': '提交申请',
  'workorder.modal.text': '我们将在24小时内评估您的情况，并以电子邮件的形式与您同步。',
};
