export default {
  'giftCode-title': '禮包碼',
  'giftCode-tab-1': '兌換新的禮包碼',
  'giftCode-tab-2': '查看已兌換的禮包碼',
  'giftCode-tab-1-1': '兌換',
  'giftCode-tab-2-1': '查看',
  'giftCode-RedeemCode-code-placeholder': '請輸入禮包碼',
  'giftCode-RedeemCode-validatorCode-msg1': '無效的禮包碼。',
  'giftCode-RedeemCode-validatorCode-msg2': '已經兌換過該禮包碼。',
  'giftCode-RedeemCode-Cancel': '取消',
  'giftCode-RedeemCode-Redeem': '兌換',
  'giftCode-RedeemCode-result-success-1': '已成功兌換禮包碼。',
  'giftCode-RedeemCode-result-success-2': '成功兌換了{num}。',
  'giftCode-RedeemCode-result-success-2-tip': '若月度計劃中斷，則贈送流量自動失效。請及時續費。',
  'giftCode-RedeemCode-result-fail-1': '失敗',
  'giftCode-RedeemCode-result-fail-2':
    '我們檢測到您目前沒有月度計劃。請盡快購買月度計劃以兌換免費流量。',
  'giftCode-RedeemCode-code-extra-1': '點擊兌換，默認你同意了禮包碼的',
  'giftCode-RedeemCode-code-extra-2': '條款和條件',
  'giftCode-RedeemCode-code-extra-3': '（如適用）。',
  'giftCode-RedeemTable-name-1': '至{date}到期',
  'giftCode-RedeemTable-name-2': '{date}使用',
  'giftCode-RedeemTable-status-1': '去購買計劃',
  'giftCode-RedeemTable-status-1-1': '去購買流量',
  'giftCode-RedeemTable-status-2': '已使用',
  'giftCode-RedeemTable-status-3': '過期',
  'giftCode-RedeemTable-status-4': '已鎖定',
  'giftCode-select-NotSatisfied': '不滿足條件',
};
