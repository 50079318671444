import { get, post, put, postForm, kdelete } from '../request';
import { USER_URL, ADMIN_URL, APP_URL } from '../url_config';
import md5 from 'js-md5';
// 获取用户信息
export async function getUserInfo_api() {
  return get(`${APP_URL}member/user/get`);
}
// 登录
export async function Login_api(params: any) {
  return post(`${APP_URL}member/auth/mail-login`, params);
}
// 登出
export async function Logout_api() {
  return post(`${APP_URL}member/auth/logout-email`);
}

// 模拟登录
export async function simulationLogin_api(params: any) {
  return post(`${APP_URL}member/auth/simulation-login`, params);
}

// 修改账号信息
export async function putUpdateInfo_api(params: any) {
  return put(`${APP_URL}member/user/update-info`, params);
}

// 查询90天内是否有修改记录（true:未修改）
export async function getQueryEmailRecord_api() {
  return get(`${APP_URL}member/user/verify-update-email`);
}

// 使用邮箱注册账户
export async function sendRegistmail_api(params: any) {
  return post(`${APP_URL}member/auth/send-registration-mail`, params);
}

// 使用邮箱注册受邀请子账户
export async function registrationInvite_api(params: any) {
  return post(`${APP_URL}member/auth/registration-invite`, params);
}

// 发送注册账户邮件
export async function postRegistration_api(params: any) {
  return post(`${APP_URL}member/auth/registration`, params);
}

// 发送修改邮箱邮件
export async function postResetEmail_api(params: any) {
  return post(`${APP_URL}member/auth/send-reset-email-mail`, params);
}

// 发送修改邮箱邮件
export async function putUpdateEmail_api(params: any) {
  return put(`${APP_URL}member/user/update-email`, params);
}

// 修改用户密码
export async function postUpdatePassword_api(params: any) {
  return post(`${APP_URL}member/auth/update-password`, params);
}

// 重置用户密码
export async function postResetPassword_api(params: any) {
  return post(`${APP_URL}member/auth/reset-password`, params);
}

// 发送修改密码邮件
export async function postResetMail_api(params: any) {
  return post(`${APP_URL}member/auth/send-reset-password-mail`, params);
}

// 查询内网权限
export async function checkInnerAccess_api() {
  return get(`${USER_URL}checkInnerAccess`);
}
// 验证邮箱是否存在
export async function postVerifyEmail_api(value: any) {
  return get(`${APP_URL}member/auth/verify-email?email=${value}`);
}

// 验证密码（未登录）
export async function checkPassword_api(pwd: string) {
  const password = md5(pwd);
  return get(`${USER_URL}checkPassword`, { password });
}

// 校验密码是否正确（已登录）
export async function checkUserPassword_api(params: any) {
  return get(`${APP_URL}member/auth/verify-password?id=${params.id}&&password=${params.password}`);
}

// 获取附属账号列表
export async function getAffiliateAccount_api() {
  return get(`${APP_URL}member/user/describe-affiliate-account`);
}

// 邀请新成员
export async function postSendInviteMail_api(params: any) {
  return post(`${APP_URL}member/auth/send-invite-mail`, params);
}

// 删除成员
export async function deleteMember_api(params: any) {
  return kdelete(`${APP_URL}member/user/remove-affiliate-account`, params);
}
/**
 * 谷歌双因子认证
 */
// 查询谷歌双因子认证状态
export async function getMemberGoogle2faStatus_api() {
  return get(`${APP_URL}member/google/2fa/status`);
}
// 查询谷歌双因子认证详情
export async function getMemberGoogle2fa_api() {
  return get(`${APP_URL}member/google/2fa`);
}
// 创建双因子信息
export async function postMemberGoogle2fa_api() {
  return post(`${APP_URL}member/google/2fa`);
}
// 确认开启双因子
export async function putMemberGoogle2fa_api(params: any) {
  return put(`${APP_URL}member/google/2fa/enable`, params);
}
// 关闭双因子认证
export async function putMemberGoogle2faDisable_api(params: any) {
  return put(`${APP_URL}member/google/2fa/disable`, params);
}
// 获得用户api
export async function postMemberOpenapi_api() {
  return post(`${APP_URL}member/openapi`);
}
// 刷新用户api
export async function putMemberOpenapi_api() {
  return put(`${APP_URL}member/openapi`);
}
// 双因子认证登录
export async function postMember2faLogin_api(params: any) {
  return post(`${APP_URL}member/auth/2fa-login`, params);
}
// 查询引导页
export async function getMemberUserGuideCode_api(guideCode: string) {
  return get(`${APP_URL}member/user/user-guide/${guideCode}`);
}
export async function postMemberUserGuideCode_api(guideCode: string) {
  return post(`${APP_URL}member/user/user-guide/${guideCode}`);
}
// end

// 初始化验证接口
export async function startCaptcha_api() {
  return get(`${USER_URL}startCaptcha`);
}
interface sendMobileVerifyCodeParams {
  verifyScene: string;
  userName: string;
  geetest_challenge: string;
  geetest_seccode: string;
  geetest_validate: string;
}
// 短信验证接口
export async function sendMobileVerifyCode_api(params: sendMobileVerifyCodeParams) {
  return postForm(`${USER_URL}sendMobileVerifyCode`, params);
}
//修改密码
interface changePasswordParams {
  password: string;
  newpassword: string;
}
export function changePassword_api(params: changePasswordParams) {
  let { password, newpassword } = params;
  password = md5(password);
  newpassword = md5(newpassword);
  return put(`${ADMIN_URL}system/user/update-password`, {
    password,
    newpassword,
  });
}
