// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/.npm/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/root/.npm/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/index.html",
        "redirect": "/home",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/home",
        "exact": true
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user/",
            "redirect": "/user/login",
            "exact": true
          },
          {
            "name": "login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'@/pages/user/login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "forget",
            "path": "/user/forget",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__forget' */'@/pages/user/forget'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "reset",
            "path": "/user/reset",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__reset' */'@/pages/user/reset'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "register",
            "path": "/user/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register' */'@/pages/user/register'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "updateAccount",
            "path": "/user/updateAccount",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__otherPages__updateAccount' */'@/pages/user/otherPages/updateAccount'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/accountDisabled",
            "name": "AccountDisabled",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__accountDisabled' */'@/pages/user/accountDisabled'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/home",
        "name": "Home",
        "icon": "HomeOutlined",
        "access": "HomeAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/cdnResources",
        "name": "CDNResources",
        "icon": "AppstoreOutlined",
        "access": "CDNResourcesAccess",
        "routes": [
          {
            "path": "/cdnResources/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cdnResources__list' */'@/pages/cdnResources/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cdnResources/create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cdnResources__create' */'@/pages/cdnResources/create'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/cdnResources/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cdnResources__detail' */'@/pages/cdnResources/detail'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/sslCerificates",
        "name": "SSLCerificates",
        "icon": "BranchesOutlined",
        "access": "SSLCerificatesAccess",
        "routes": [
          {
            "path": "/sslCerificates/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sslCerificates__list' */'@/pages/sslCerificates/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sslCerificates/create",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sslCerificates__create' */'@/pages/sslCerificates/create'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/sslCerificates/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sslCerificates__detail' */'@/pages/sslCerificates/detail'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/traffic",
        "name": "Traffic",
        "icon": "LineChartOutlined",
        "access": "TrafficAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__traffic' */'@/pages/traffic'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/bandWidth",
        "name": "BandWidth",
        "icon": "CloudUploadOutlined",
        "access": "BandWidthAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__bandWidth' */'@/pages/bandWidth'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/requests",
        "name": "Requests",
        "icon": "MessageOutlined",
        "access": "RequestsAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__requests' */'@/pages/requests'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/statusCodes",
        "name": "StatusCodes",
        "icon": "ApiOutlined",
        "access": "StatusCodesAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__statusCodes' */'@/pages/statusCodes'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/topFiles",
        "name": "TopFiles",
        "icon": "FileOutlined",
        "access": "TopFilesAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__topFiles' */'@/pages/topFiles'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/topReferrers",
        "name": "TopReferrers",
        "icon": "ShareAltOutlined",
        "access": "TopReferrersAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__topReferrers' */'@/pages/topReferrers'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/accountSettings",
        "name": "AccountSettings",
        "icon": "SettingOutlined",
        "access": "AccountSettingsAccess",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accountSettings' */'@/pages/accountSettings'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/billing",
        "name": "Billing",
        "icon": "ProfileOutlined",
        "access": "BillingAccess",
        "routes": [
          {
            "path": "/billing/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__billing' */'@/pages/billing'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/billing/workorder",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__billing__workorder' */'@/pages/billing/workorder'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/upgadeAccount",
        "name": "UpgadeAccount",
        "icon": "ToTopOutlined",
        "routes": [
          {
            "path": "/upgadeAccount/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__upgadeAccount' */'@/pages/upgadeAccount'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/upgadeAccount/packageSelection",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__billing__packageSelection' */'@/pages/billing/packageSelection'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/root/.npm/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
