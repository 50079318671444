/**
 * cdn创建资源引导页
 */
import { useEffect } from 'react';
import { FormattedMessage, getLocale } from 'umi';
import { useFomatter } from '@/hooks';
import { Button } from 'antd';
import {
  ArrowRightOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import { closeStepRender } from './common';
import GuideBox from './GuideBox';
import guideImg from '@/assets/images/guide-right-img.png';
const CdnCreate = (props: any) => {
  const { onClick, step = 1 } = props;
  const locale = getLocale();
  const { formatter } = useFomatter();
  useEffect(() => {
    if (step === 2 && document.getElementById('tierFollow')) {
      if (document.querySelector('.tier-box.u-hide')) {
        document.getElementById('tierFollow')?.click();
      }
    }
    if (step === 4) {
      if (!document.querySelector('.tier-box.u-hide')) {
        document.getElementById('tierFollow')?.click();
      }
    }
  }, [step]);
  return (
    <div className={'m_cdn_create_box'}>
      {step === 1 ? (
        <>
          <div className={`u-img1 u-img-${locale}`}>
            <GuideBox title={formatter({ id: 'guide.CdnCreate.step.title1.1' })} dir="bottomLeft">
              <div>
                <CloseCircleFilled className="c-red" /> example.com/folder
              </div>
              <div>
                <CheckCircleFilled className="c-green" /> example.com
              </div>
              <div>
                <CheckCircleFilled className="c-green" /> subexample.example.com
              </div>
              <div>
                <CheckCircleFilled className="c-green" /> 125.125.125.125
              </div>
            </GuideBox>
          </div>
          <div className="u-img2">
            <div className="u-guide-btnBox">
              <div className="u-left">
                <img src={guideImg} />
              </div>
              <div className="u-right">
                <GuideBox title={formatter({ id: 'guide.CdnCreate.step.title1.2' })} dir="topLeft">
                  <div>
                    <WarningFilled className="c-orange" /> example.com
                  </div>
                  <div>
                    <CheckCircleFilled className="c-green" /> subexample.example.com
                  </div>
                </GuideBox>
                <div className="u-btn-box">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClick?.('cdn_create_2');
                      document
                        .getElementById('guideNetworkList')
                        ?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    <FormattedMessage id="guide.next" />
                    <ArrowRightOutlined style={{ marginLeft: 5 }} />
                  </Button>
                  {closeStepRender({ onClick })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {step === 2 ? (
        <div className={`u-img3 u-img-${locale}`}>
          <div className="u-guide-btnBox">
            <div className="u-left">
              <img src={guideImg} />
            </div>
            <div className="u-right">
              <GuideBox
                title={formatter({ id: 'guide.CdnCreate.step.title2' })}
                dir="rightBottom"
                maxWidth={560}
              >
                <div className="c-orange">
                  <b>{formatter({ id: 'guide.CdnCreate.step.content2.1' })}</b>
                </div>
                <div>
                  {formatter({ id: 'guide.CdnCreate.step.content2.2' })}
                  <br />
                  <br />
                  {formatter({ id: 'guide.CdnCreate.step.content2.3' })}
                </div>
                <div className="c-orange">
                  <br />
                  <b>{formatter({ id: 'guide.CdnCreate.step.content2.4' })}</b>
                </div>
                <div>
                  {formatter({ id: 'guide.CdnCreate.step.content2.5' })}
                  <br />
                  <br />
                  {formatter({ id: 'guide.CdnCreate.step.content2.6' })}
                </div>
              </GuideBox>
              <div className="u-btn-box">
                <Button
                  type="primary"
                  onClick={() => {
                    onClick?.('cdn_create_3');
                  }}
                >
                  <FormattedMessage id="guide.next" />
                  <ArrowRightOutlined style={{ marginLeft: 5 }} />
                </Button>
                {closeStepRender({ onClick })}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {step === 3 ? (
        <div className={`u-img4 u-img-${locale}`}>
          <div className="u-guide-btnBox">
            <div className="u-left f-tc">
              <img src={guideImg} />
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    onClick?.('cdn_create_4');
                    document.getElementById('cdnCreateBtn')?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <FormattedMessage id="guide.next" />
                  <ArrowRightOutlined style={{ marginLeft: 5 }} />
                </Button>
                {closeStepRender({ onClick })}
              </div>
            </div>
            <div className="u-right">
              <GuideBox dir="bottomRight" maxWidth={400} isLongLine={true}>
                <div>
                  {formatter({ id: 'guide.CdnCreate.step.content3.1' })}
                  <label className="c-orange">
                    {formatter({ id: 'guide.CdnCreate.step.content3.2' })}
                  </label>
                  {formatter({ id: 'guide.CdnCreate.step.content3.3' })}
                </div>
              </GuideBox>
            </div>
          </div>
        </div>
      ) : null}
      {step === 4 ? (
        <div className="u-img5">
          <div className="u-guide-btnBox">
            <div className="u-left">
              <img src={guideImg} />
            </div>
            <div className="u-right">
              <GuideBox dir="bottomRight" maxWidth={400} isLongLine={true}>
                <div>
                  {formatter({ id: 'guide.CdnCreate.step.content4.1' })}
                  <br />
                  {formatter({ id: 'guide.CdnCreate.step.content4.2' })}
                  <br />
                  <br />
                  {formatter({ id: 'guide.CdnCreate.step.content4.3' })}
                </div>
              </GuideBox>
              <div className="f-pl">
                <Button
                  type="primary"
                  onClick={() => {
                    onClick?.();
                    document.getElementById('guide-top')?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <FormattedMessage id="guide.attempt" />
                  <ArrowRightOutlined style={{ marginLeft: 5 }} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default CdnCreate;
