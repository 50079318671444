export default {
  'sslCerificates.list.deleteCdn.message': 'Delete succeeded',
  'sslCerificates.list.Popconfirm.title': 'Are you sure delete this?',
  'sslCerificates.list.Popconfirm.okText': 'Yes',
  'sslCerificates.list.Popconfirm.cancelText': 'No',
  'sslCerificates.list.title': 'SSL Certificates',
  'sslCerificates.list.Create': 'Create SSL certficates',
  'sslCerificates.list.columns.title1': 'Name',
  'sslCerificates.list.columns.title2': 'options',

  'sslCerificates.list.PopoverText1': 'Edit SSL',
  'sslCerificates.list.PopoverText2': 'SSL Details',
  'sslCerificates.list.PopoverText3': 'Delete',

  'sslCerificates.create.onSubmit.message': 'Created succeeded',
  'sslCerificates.create.validatorName.message': 'Please input correct Name',
  'sslCerificates.create.validatorName.rule': 'Use 4-20 characters',
  'sslCerificates.create.validatorSSLKey.rule':
    'Must start with -----BEGIN CERTIFICATE----- and end with -----END CERTIFICATE-----',
  'sslCerificates.create.validatorSSLPrivateKey.rule':
    'Must start with -----BEGIN RSA PRIVATE KEY----- and end with -----END RSA PRIVATE KEY-----',
  'sslCerificates.create.step1.Form.name.name': 'Name',
  'sslCerificates.create.step1.Form.name.message': 'Please input Name!',
  'sslCerificates.create.step1.Form.sslCertificateKey.name': 'SSL certificate key',
  'sslCerificates.create.step1.Form.sslCertificateKey.message': 'Please input SSL certificate key!',
  'sslCerificates.create.step1.Form.privateKey.name': 'Private key',
  'sslCerificates.create.step1.Form.privateKey.message': 'Please input Private key!',
  'sslCerificates.create.step1.Form.Button': 'Create SSL certificates',

  'sslCerificates.detail.handleSave.message': 'Edit succeeded',
  'sslCerificates.detail.title1': 'Your SSL certificates',
  'sslCerificates.detail.title2': 'Associated CDN  resources',
  'sslCerificates.detail.Button.Edit': 'Edit',
  'sslCerificates.detail.Button.Cancel': 'Cancel',
  'sslCerificates.detail.Button.Save': 'Save',
};
