import { get, post, kdelete } from '../request';
// 查询当前用户的信用卡列表
export async function getCreditCard_api(params?: any) {
  return get(`/service/v1/stripe/credit_card/self`, params);
}
// 新增信用卡
export async function postCreditCard_api(params?: any) {
  return post(`/service/v1/stripe/credit_card/self`, params);
}
export async function delCreditCard_api(params?: any) {
  return kdelete(`/service/v1/stripe/credit_card/${params.id}`, params);
}
