export default {
  'forget.success.message': 'Email sent successfully',
  'forget.validatorEmail.message': 'Please input correct Email Address',
  'forget.Modal.content':
    'We have sent the link to reset the password to your e-mail address. Please operate in your e-mail.',
  'forget.Modal.footer': 'Done',
  'forget.Forget_Password': 'Forget Password',
  'forget.text': 'insert your e-mail and we will send you a link',
  'forget.subText': 'to create your new password',
  'forget.form.email.name': 'Email',
  'forget.form.email.message': 'Please input your Email!',
  'forget.form.Reset_password': 'Reset password',
};
