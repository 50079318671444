export default {
  'topFiles.list.title': 'Top Files',
  'topFiles.list.columns.title1': 'File url',
  'topFiles.list.columns.title2': 'Resource',
  'topFiles.list.columns.title3': 'Request',
  'topFiles.list.columns.title4': 'Hit',
  'topFiles.list.columns.title5': 'Miss',
  'topFiles.list.columns.title6': 'Traffic',
  'topFiles.list.columns.title7': 'Options',
  'topFiles.list.PopoverText1': 'Purge',
  'topFiles.list.PopoverText2': 'Notice settings',
  'topFiles.modal.NoticeSetting.title': 'Notice settings',
  'topFiles.modal.NoticeSetting.success': 'Settings success',
  'topFiles.modal.CleanUp.success': 'Purge success',
  'topFiles.modal.Button.Save': 'Save',
  'topFiles.modal.NoticeSetting.text1': 'The daily request traffic in the past 30 days:',
  'topFiles.modal.NoticeSetting.text2': 'If the request traffic for the file reaches',
  'topFiles.modal.NoticeSetting.text3': 'on the same day, we will send a warning email.',
  'topFiles.modal.NoticeSetting.message': 'Please enter a threshold',
  'topReferrers.list.title': 'Top Referrers',
};
