export default {
  'forget.success.message': '郵件發送成功',
  'forget.validatorEmail.message': '請輸入正確的郵箱地址',
  'forget.Modal.content':
    '我們已發送將密碼重置到您的電子郵件地址的鏈接。請在電子郵件中操作。請輸入正確的郵箱地址',
  'forget.Modal.footer': '完成',
  'forget.Forget_Password': '忘記密碼',
  'forget.text': '輸入您的電子郵件，我們將向您發送鏈接',
  'forget.subText': '去創建新密碼',
  'forget.form.email.name': '郵件',
  'forget.form.email.message': '請輸入您的電子郵件！',
  'forget.form.Reset_password': '重置密碼',
};
