import type { ResponseError } from 'umi-request';
import { notification } from 'antd';
import { extend } from 'umi-request';
import { randomUUID } from './authToken';
import { stringify } from 'querystring';
import { history } from 'umi';
import { getLocalStorage, removeLocalStorage } from '@/utils/utils';
import CryptoJS from 'crypto-js';

// 需要抛出异常且不做操作的接口
const thowErrorUrl: string[] = [
  '/api/user/admin/portal/describeUserInfo',
  '/api/user/admin/portal/checkInnerAccess',
  '/api/user/admin/portal/checkPassword',
  '/app-api/v1/giftcode/redeem/',
];
// 此接口请求的是文件流 返回文件流接口
const downStreamFiles = ['/app-api/month-bill/v1/month-bill/user/download'];
/**
 * 异常处理程序
 * 最后执行,需要在response中throw异常才可以，return/throw结果不同
 */
const errorHandler = (error: ResponseError<any>) => {
  const resUrl = error?.request?.url;
  const resMessage = error?.data?.msg;
  const errorCode = error?.data?.code as string;
  const resStatus = error.response?.status;
  if (!error.response) {
    return {
      code: 1,
      success: false,
      msg: resMessage,
    };
  }

  if (resStatus === 401 || ['NoUrlAuthority'].includes(errorCode)) {
    console.log('无权限');
  }
  if (resStatus === 400 && error.data === '与服务器时间相差较大，请校验系统时间') {
    console.log('与服务器时间相差较大，请校验系统时间');
  }
  if (
    (resStatus === 401 || resStatus === 500) &&
    resMessage &&
    resMessage === 'Account not logged in'
  ) {
    const { pathname, search } = window.location;
    removeLocalStorage('cdnControl-token');
    history.replace({
      pathname: '/user/login',
      search: stringify({
        redirect: pathname + search,
      }),
    });
    openNotificationWithIcon('warning', 'Invalid login information');
    return {
      code: 1,
      success: false,
      msg: resMessage,
    };
  }
  if (resStatus === 400 && resMessage && resMessage.trim() === '内部错误') {
    // openNotificationWithIcon(
    //   'warning',
    //   localeInfo[lang]?.message?.['request.error.login.request.error.network'],
    //   resUrl,
    // );
    // throw error;
    return {
      code: 1,
      success: false,
      msg: '网络错误，请稍后再试！',
    };
  }
  const flag = thowErrorUrl.find((item) => {
    return error.response.url?.indexOf(item) !== -1;
  });
  if (flag) {
    return {
      code: 1,
      success: false,
      msg: resMessage,
    };
  }
  if (resStatus === 400 && resMessage) {
    openNotificationWithIcon('error', resMessage, resUrl);
    // throw error;
    return {
      code: 1,
      success: false,
      msg: resMessage,
    };
  }

  const msg = resMessage || error.data?.code;
  // ||
  // localeInfo[lang]?.message?.[`request.error.login.request.error.${resStatus || 'unknown'}`];

  if (!flag) {
    openNotificationWithIcon('error', msg);
  }
  return {
    code: 1,
    success: false,
    msg,
    innerCode: error.data?.innerCode || '',
  };
  // throw error
};

const request = extend({
  credentials: 'include', //默认请求是否携带cookie
  timeout: 300000,
  errorHandler,
});

// 拦截请求
request.interceptors.request.use((url: any, options: any) => {
  const urlPathArr = url?.split('/') || [];
  const newOptions = {
    ...options,
    // headers: {
    //   Authorization: getLocalStorage('sdiSuper-token') ? `Bearer ${getLocalStorage('sdiSuper-token')}` : null,
    //   // 'tenant-id': 1, //后端要求header携带的字段、写死
    // },
  };
  newOptions.headers.Authorization = getLocalStorage('cdnControl-token')
    ? `Bearer ${getLocalStorage('cdnControl-token')}`
    : null;
  // 获取本地时区添加到请求头中（监控时区是utc+0）
  newOptions.headers.zoneId =
    urlPathArr && urlPathArr[2] === 'monitor'
      ? 'UTC'
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
  //---
  const _Timestamp = window.parseInt(new Date().getTime() as any, 10);
  const _SignParams = randomUUID(12);
  const md5_key = CryptoJS.SHA256(
    'lightcdn.com' + _Timestamp + _SignParams + 'EXOMtkPRM66MoKJX6KJWxoCIIngGZLqY',
  );
  const _Sign = md5_key.toString(CryptoJS.enc.Base64);
  newOptions.headers['X-Browser-Sign'] = _Sign;
  newOptions.headers['X-Browser-Timestamp'] = _Timestamp;
  newOptions.headers['X-Browser-SignParams'] = _SignParams;
  return {
    url,
    options: { ...newOptions },
  };
});
// 拦截响应
request.interceptors.response.use(async (response: any) => {
  let data = {};
  if (downStreamFiles.some((v: string) => response.url.includes(v))) {
    data = { data: await response?.blob() };
  } else {
    await response
      ?.clone()
      ?.text()
      ?.then(async (res: any) => {
        // 判断返回是否为空，为空则默认返回{}，不做处理
        if (res) {
          data = (await response?.clone()?.json()) || {};
        }
      });
  }
  // } else {
  //   data = (await response?.clone()?.json()) || {};
  // }
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202 ||
    response.status === 304
  ) {
    // 成功情况处理解构data
    return { success: true, ...data };
  }
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw { response, data };
});

// 提示框
function openNotificationWithIcon(type: any, description: any, key?: string) {
  const _key = key || description;
  //先清再展示，要不然有异常
  notification.destroy();
  notification.open({
    type,
    key: _key,
    message: description,
  });
}

export default request;
