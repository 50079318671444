import { useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { notification, Spin } from 'antd';
import { useModel, history, FormattedMessage, useLocation } from 'umi';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useFomatter } from '@/hooks';
import RechargeModal from '@/pages/billing/components/RechargeModal';
import GuidePop from '@/pages/common/GuidePop';
import { getPostpaidFreeCheck_api } from '@/api/account';
import './style.less';

import chat from '@/assets/images/icon/chat.png';
const loginPath = [
  '/user/login',
  '/user/register',
  '/user/forget',
  '/user/reset',
  '/user/updateAccount',
  '/user/accountDisabled',
];

const BasicLayout: React.FC = ({ children }: any) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const { payResult, fetch, failReason, setPayResult, setFailReason } = useModel(
    'usePay',
    (model: any) => ({
      payResult: model.payResult,
      fetch: model.getPaymentTransactionId,
      failReason: model.failReason,
      setPayResult: model.setPayResult,
      setFailReason: model.setFailReason,
    }),
  );
  const { trafficData, getTrafficInfo, hasMonthPlan } = initialState || {};
  const [isNoShow, setIsNoShow] = useState(true);
  const [rechargeVisible, setRechargeVisible] = useState(false); //充值弹窗
  const [tipShow, setTipShow] = useState(true); //提示弹窗展示
  const [isPostTip, setIsPostTip] = useState(false); //后付费欠费暂停服务提示展示
  const location: any = useLocation();
  const { formatter } = useFomatter();
  moment.locale('en', {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'MMM YYYY',
      LL: 'DD MMM YYYY',
      LLL: 'D MMM YYYY, HH:mm',
      LLLL: 'dddd D MMM YYYY HH:mm',
    },
  });

  // 是否可以领取免费的额度
  const { run: getPostpaidFreeCheck, data: freeCheck } = useRequest(getPostpaidFreeCheck_api, {
    manual: true,
    initialData: { haveBalance: true, haveFree: false, drawTime: '', expireTime: '' },
    formatResult: (res) => res?.data || {},
  });

  // 刷新页面
  const handleRefresh = async () => {
    if (getTrafficInfo) {
      sessionStorage.removeItem('payState');
      setPayResult('pending');
      setFailReason('');
      const _data = await getTrafficInfo();
      setInitialState({
        ...initialState,
        trafficData: _data,
        hasMonthPlan: _data?.monthPlanInfo?.hasService ? true : hasMonthPlan,
      });
    }
  };

  useEffect(() => {
    if (trafficData) {
      //通過trafficData?.isExceededTrafficUsageBufferDomain判断是否超额，并且
      //得加个trafficData?.trafficRemain?.traffic字段判断，有可能用户超额了，但是还能买额外流量用，得一起看总流量
      //v1.2.0新判断逻辑：使用超量&&没有余额，显示提示
      if (trafficData?.monthPlanInfo?.hasService) {
        if (
          trafficData?.isExceededTrafficUsageBufferDomain &&
          !trafficData?.trafficRemain?.traffic &&
          (trafficData?.accountVo?.balance || 0) <= 0
        ) {
          //超额
          setIsNoShow(false);
        } else {
          setIsNoShow(true);
        }
      } else {
        //没有购买月度计划的才查询免费接口
        getPostpaidFreeCheck();
        //!trafficData?.monthPlanInfo?.hasService
        if (trafficData?.accountVo?.balance <= 0) {
          if (trafficData?.accountVo?.useBalance > 0) {
            //场景一：后付费使用，小于等于0，并且有消耗，出提示
            setIsPostTip(true);
          } else {
            if (!initialState?.sysSuspend) {
              //增加场景三的条件
              setIsPostTip(false);
            }
          }
          //  else if (trafficData?.accountVo?.useBalance === 0 && trafficData.allSuspended) {
          //   //场景二：免费试用的，未超量，充值了10元，手动扣款退了10元，此时余额==0，资源暂停，但没有显示后付余额不足提示。
          //   isPostTip = true;
          // }
        } else {
          setIsPostTip(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trafficData]);
  useEffect(() => {
    if (trafficData?.accountVo?.balance <= 0 && !trafficData?.monthPlanInfo?.hasService) {
      //v1.2.2
      if (freeCheck.haveFree && trafficData?.accountVo?.useBalance <= 0) {
        //场景三：免费试用 + 已过期 + 余额<=0 + 没有月度计划 + 有资源系统暂停
        if (initialState?.sysSuspend && moment(freeCheck.expireTime).isBefore(moment())) {
          setIsPostTip(true);
        } else {
          setIsPostTip(false);
        }
      }
    }
  }, [initialState?.sysSuspend]);

  useEffect(() => {
    // 获取支付id
    fetch(location?.query || {}, initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.currentUser]);

  useEffect(() => {
    // 根据支付结果提示
    const payState = sessionStorage?.getItem('payState');
    if (!payState) {
      return;
    }
    if (payResult === 'pending') {
      notification.open({
        message: formatter({ id: 'billing.PayResult.handleCheck.message' }),
        key: payState,
        duration: null,
        icon: <Spin />,
      });
    }
    if (payResult === 'success') {
      notification.close(payState);
      notification.success({
        message: formatter({ id: 'billing.PayResult.success.message' }),
      });
      setTimeout(() => {
        handleRefresh();
        //20230719产品要求流程顺序从cdn列表 - 套餐选择页 - 购买成功后 - cdn创建页，中间有跳出则清除缓存
        const _stepValue = sessionStorage.getItem('LIGHTCDN_CREATE_STEP');
        if (_stepValue && _stepValue === 'success') {
          history.push('/cdnResources/create');
        }
      }, 2000);
    }
    if (payResult === 'fail') {
      notification.close(payState);
      if (failReason === 'timeout') {
        notification.error({
          message: formatter({ id: 'billing.PayResult.timeout.message' }),
        });
      } else {
        notification.error({
          message: formatter({ id: 'billing.PayResult.fail.message' }),
        });
      }
      setTimeout(() => {
        handleRefresh();
      }, 2000);
    }
  }, [payResult]);
  return (
    <div
      className={`layLoytContent-box ${
        initialState?.guideType ? `m_guideType_${initialState.guideType}` : ''
      }`}
    >
      {/* 以上样式类名与src\pages\common\GuidePop\style.less样式结合 */}
      {!loginPath.includes(history.location.pathname) && tipShow ? (
        <>
          {!isNoShow || initialState?.resourceSuspend || isPostTip ? (
            <div className="CompatibleWindow">
              <img src={chat} alt="tip" />
              {!isNoShow || initialState?.resourceSuspend ? (
                <div className={'CompatibleWindow-text'}>
                  {/* 未购买通知 > 服务已过期，超管暂停通知 > CDN资源已暂停通知， */}
                  {/* v1.2.0隐藏没有月度计划 */}
                  {!isNoShow || initialState?.resourceSuspend ? (
                    <>
                      <FormattedMessage
                        id={
                          initialState?.resourceSuspend
                            ? 'menu.layout.pause.message'
                            : !isNoShow
                            ? 'menu.layout.message'
                            : 'menu.null.message'
                        }
                      />
                      {/* 两个会同时存在 */}
                      {!isNoShow && !initialState?.resourceSuspend ? (
                        <a
                          style={{ textDecoration: 'underline' }}
                          onClick={() => {
                            history.push('/billing?type=UPGRADE');
                          }}
                        >
                          <FormattedMessage id={'menu.layout.message1'} />
                        </a>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : (
                <div className={'CompatibleWindow-text'}>
                  {/* 余额小于0出提示 */}
                  <FormattedMessage id={'menu.layout.balance.message1'} />
                  <a
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      setRechargeVisible(true);
                    }}
                  >
                    <FormattedMessage id={'menu.layout.balance.message2'} />
                  </a>
                </div>
              )}
              <CloseCircleOutlined
                style={{
                  fontSize: 18,
                  color: '#ff7100',
                }}
                className="CompatibleWindow-closeIcon"
                onClick={() => {
                  setTipShow(false);
                }}
                width={30}
                height={30}
              />
            </div>
          ) : null}
        </>
      ) : null}
      {children}
      {/* 充值弹窗 */}
      {rechargeVisible ? (
        <RechargeModal
          visible={rechargeVisible}
          onCallback={(b: boolean) => {
            console.log(b);
            setRechargeVisible(false);
          }}
        />
      ) : null}
      {/* 引导页遮罩层 */}
      <GuidePop />
    </div>
  );
};

export default BasicLayout;
