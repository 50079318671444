export default {
  'reset.changePassword.message.rule': 'Use 8-20 characters',
  'reset.changePassword.message.error': 'Please input correct password!',
  'reset.changePassword.rule.title': 'We recommend:',
  'reset.changePassword.rule.text1': 'one letter',
  'reset.changePassword.rule.text2': 'one number',
  'reset.changePassword.rule.text3': 'one special character',
  'reset.changePassword.rule.text4': '8-20 characters',
  'reset.changePassword.rule.weak': 'Password weak',
  'reset.changePassword.message.strong': 'Password strong',
  'reset.changePassword.message': 'Please input your password!',
  'reset.validateFields.message': 'Reset succeeded',
  'reset.validatorRePassword.message': 'Passwords are inconsistent',
  'reset.title': 'Reset password',
  'reset.form.password.name': 'Newpassword',
  'reset.form.rePassword.name': 'Re-type password',
  'reset.form.Log_in': 'Log in',
  'reset.FactorAuth.validateFields.success': 'Reset 2-factor authentication successfully',
  'reset.FactorAuth.validateFields.fail': 'Reset 2-factor authentication fail',
};
