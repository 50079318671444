export default {
  'reset.changePassword.message.rule': '使用8-20個字符',
  'reset.changePassword.message.error': '請輸入正確的密碼！',
  'reset.changePassword.rule.title': '我們建議：',
  'reset.changePassword.rule.text1': '一個字母',
  'reset.changePassword.rule.text2': '一個數字',
  'reset.changePassword.rule.text3': '一個特殊字符',
  'reset.changePassword.rule.text4': '8-20個字符',
  'reset.changePassword.rule.weak': '弱密碼',
  'reset.changePassword.message.strong': '強密碼',
  'reset.changePassword.message': '請輸入您的密碼！',
  'reset.validateFields.message': '重置成功',
  'reset.validatorRePassword.message': '密碼錯誤',
  'reset.title': '重置密碼',
  'reset.form.password.name': '新密碼',
  'reset.form.rePassword.name': '再次輸入密碼',
  'reset.form.Log_in': '登錄',
  'reset.FactorAuth.validateFields.success': '重置雙因認證成功',
  'reset.FactorAuth.validateFields.fail': '重置雙因認證失敗',
};
