export default {
  'register.changePassword.message.rule': 'Use 8-20 characters',
  'register.changePassword.message.error': 'Please input correct password!',
  'register.changePassword.rule.title': 'We recommend:',
  'register.changePassword.rule.text1': 'one letter',
  'register.changePassword.rule.text2': 'one number',
  'register.changePassword.rule.text3': 'one special character',
  'register.changePassword.rule.text4': '8-20 characters',
  'register.changePassword.rule.title2': 'Use at least:',
  'register.changePassword.rule.text21': 'one number',
  'register.changePassword.rule.text22': 'one letter',
  'register.changePassword.rule.text23': '8-20 characters',
  'register.changePassword.rule.weak': 'Password weak',
  'register.changePassword.message.strong': 'Password strong',
  'register.changePassword.message': 'Please input your password!',
  'register.modified.message': 'Password modified successfully',
  'register.validateFields.message': 'registered successfully',
  'register.okHandle.message.logging': 'Logging in...',
  'register.okHandle.message.success': 'Log in succeeded',
  'register.okHandle.message.error': 'Login failed',
  'register.validatorEmail.message.success': 'Email Address already exists',
  'register.validatorEmail.message.failed': 'Email Address Check failed',
  'register.validatorEmail.message': 'Please input correct Email Address',
  'register.validatorPhone.message.rule': 'Use 6-11 characters',
  'register.validatorPhone.message': 'Please input correct phone',
  'register.validatorRePassword.message': 'Passwords are inconsistent',
  'register.saveCount.message': 'Email sent successfully',
  'register.Modal.content.title': 'Please verify your e-mail.',
  'register.Modal.content.code1': 'You can resend the verification code in',
  'register.Modal.content.code2': 'seconds',
  'register.Modal.content.code': 'Send verification code →',
  'register.Modal.content.okText': 'Start free trial!',
  'register.Modal.content.cancelText': 'cancel',
  'register.verificationCode': 'Verification code',
  'register.verificationCode.message': 'Please input verification code',
  'register.step1': 'Pay as you go，minimum investment',
  'register.step2': 'Fast configuration with 3 minutes',
  'register.step3': 'Acceleration covers global areas',
  'register.step4': 'Lower price than CDN77',
  'register.form.fullName.name': 'Full Name',
  'register.form.fullName.message': 'Please input your Full Name!',
  'register.form.email.name': 'Email',
  'register.form.email.message': 'Please input your Email Address!',
  'register.form.companyName.name1': 'Company Name',
  'register.form.companyName.name2': 'optional',
  'register.form.Phone.name': 'Phone',
  'register.form.country.message': 'Please select your Country!',
  'register.form.mobile.message': 'Please input your Phone!',
  'register.form.password.name': 'Password',
  'register.form.rePassword.name': 'Re-type password',
  'register.form.rePassword.message': 'Please input your password!',
  'register.form.Sign_up': 'Sign up',
  'register.Modal.okText': 'Start free trial!',
  'register.Modal.cancelText': 'cancel',
  'register.form.tip1': "By clicking Sign Up, I agree to LightCDN's ",
  'register.form.tip2': 'terms, ',
  'register.form.tip3': 'privacy policy.',
  'register.form.code': 'Email verification code',
  'register.form.codeButton': 'Get verification code',
  'register.form.code.message': 'Please input Email verification code',
  'register.form.code.rule': 'Please input correct Email verification code',
  'register.form.Back_to_login': '← Back to login',
  'register.text1': 'Speed up your business with LightCDN',
  'register.text2': 'Immediate access to all LightCDN features',
  'register.Invitor': 'Invitor',
  'register.Invitor.message': 'Invalid code',
};
