// 无权限语句
export default {
  'noAccess.SafetyWaf.text':
    'Web Application Firewall (WAF) monitors, filters or blocks the traffic to and from a web application. A WAF inspects every HTML, HTTPS, SOAP and XML-RPC data packet. This firewall enables preventing attacks such as cross-site scripting (XSS), SQL injection, session hijacking, and buffer overflows through customizable web security rules.',
  'noAccess.SafetyWaf.more1': 'You can learn more about WAF by reviewing ',
  'noAccess.SafetyWaf.more2': 'the documentation.',

  'noAccess.Analytics.text':
    'We support you in checking the visit status of your clients, which includes where your visitors come from, latency, transmission rate, request volume, and traffic. This will help you better understand the operation of your site and customer structure.',

  'noAccess.Nginx.text':
    'Supports limiting the rate according to a single file, or setting a cache key to store and search for requests in the cache. This is often applied in the field of network security. For example, slowing down violent password cracking attacks, crawling web pages by crawlers, and preventing DDOS attacks.',
  'noAccess.Nginx.more1': 'You can learn more about Nginx settings by reviewing ',

  'noAccess.cacheRules.text1':
    'Rules support you to customize CDN edge server behavior from multiple dimensions such as IP, cookies, headers, etc., such as how CDN will manage caching.',
  'noAccess.cacheRules.text2':
    'This creates a lot of flexibility and adaptability for different platform websites to integrate with CDN without much hassle.',
  'noAccess.cacheRules.more1': 'You can learn more about Rules by reviewing ',

  'noAccess.redictRules.text1':
    'Rules support you to customize CDN edge server behavior from multiple dimensions such as IP, cookies, headers, etc., such as how CDN will manage redirects.',
  'noAccess.redictRules.text2':
    'This creates a lot of flexibility and adaptability for different platform websites to integrate with CDN without much hassle.',
  'noAccess.redictRules.more1': 'You can learn more about Rules by reviewing ',

  'noAccess.modifyHeader.text1':
    'Rules support you to customize CDN edge server behavior from multiple dimensions such as IP, cookies, headers, etc., such as how CDN will manage header.',
  'noAccess.modifyHeader.text2':
    'This creates a lot of flexibility and adaptability for different platform websites to integrate with CDN without much hassle.',
  'noAccess.modifyHeader.more1': 'You can learn more about Rules by reviewing ',

  'noAccess.originRules.text1':
    'Rules support you to customize CDN edge server behavior from multiple dimensions such as IP, cookies, headers, etc., such as how CDN will manage origin.',
  'noAccess.originRules.text2':
    'This creates a lot of flexibility and adaptability for different platform websites to integrate with CDN without much hassle.',
  'noAccess.originRules.more1': 'You can learn more about Rules by reviewing ',

  'noAccess.TopFile.text1':
    'The top fiiles will display the file path with high access, and you can purge according to the file path.',
  'noAccess.TopFile.text2':
    'This will help you understand the normal level of your business, and if there are abnormal requests or floods, you will have a clear understanding and find the problem. We also provide a threshold entry to prompt you for anomalies and grasp risks in a timely manner.',
  'noAccess.TopFile.more1': 'You can learn more about Top files by reviewing ',
};
