import { get } from '../request';
import { APP_URL } from '../url_config';
// 查看流量监控
export async function getCdnTrafficMonitor_api(params: any) {
  return get(`${APP_URL}monitor/v1/traffic`, params);
}
// 获取用户CDN套餐总流量信息(原post请求，现只更改请求方式，名称不修改)
export async function postCdnTrafficInfo_api() {
  return get(`${APP_URL}cdn-user-traffic/v1`);
}
// 查看流量监控主页视图
export async function getCdnTrafficOverView_api(params: any) {
  return get(`${APP_URL}monitor/v1/traffic/overView`, params);
}
