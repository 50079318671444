export default {
  'menu.login': 'login',
  'menu.forget': 'forget',
  'menu.reset': 'reset',
  'menu.register': 'register',
  'menu.updateAccount': 'updateAccount',
  'menu.AccountDisabled': 'AccountDisabled',
  'menu.Home': 'Home',
  'menu.CDNResources': 'CDN Resources',
  'menu.SSLCerificates': 'SSL Cerificates',
  'menu.Traffic': 'Traffic',
  'menu.BandWidth': 'BandWidth',
  'menu.Requests': 'Requests',
  'menu.StatusCodes': 'Status Codes',
  'menu.TopFiles': 'Top Files',
  'menu.TopReferrers': 'Top Referrers',
  'menu.AccountSettings': 'Account Settings',
  'menu.Billing': 'Billing',
  'menu.UpgadeAccount': 'Upgade Account',
  'menu.LogOut': 'Log Out',
  'menu.null.message': '',
  'menu.LogOut.message': 'Log out successfully',
  'menu.layout.message':
    'Your service has ended and CDN resources have been suspended. Please choose to ',
  'menu.layout.message1': 'upgrade your account.',
  'menu.layout.notBuy.message':
    "You don't have a monthly plan yet, go ahead and make a purchase. →",
  'menu.layout.pause.message':
    'Your CDN resources have been suspended due to a complaint. Please contact our customer service or email to resolve the issue.',
  'menu.layout.balance.message1':
    'Insufficient balance, CDN resources have been paused. Please choose to ',
  'menu.layout.balance.message2': 'recharge your account.',
  'menu.layout.accountDisabled.text':
    'Your account has been disabled. If you have any requirements, please contact customer service.',
  'menu.Usage.Usage': 'Usage',
  'menu.Usage.Overage': 'Overage',
  'menu.Usage.Total': 'Total',
  'menu.Usage.Remaining': 'Remaining',
  'menu.Usage.UsedPer': '{percentage}% Used',
  'menu.Usage.UsedPro': '{percentage} Used / Pro',
  'menu.Usage.UsedFree': '{percentage} Used / Free',
  'menu.Usage.Suspension': 'Suspension',
  'menu.Usage.AccountBalance': 'Account balance {balance}',
  'menu.Upgrade': 'Upgrade',
  'menu.Usage.balance': 'Balance',
  'menu.Usage.Equal': 'Equal to',
  'menu.Usage.Overdue': 'Overdue',
  'menu.404.subTitle': 'Sorry, the page you visited does not exist.',
  'menu.404.BackHome': 'Back Home',
  'menu.Support': 'Support',
  'menu.language': 'language',
  'menu.copy.success': 'Copy succeeded',
  'menu.editCard.editText': 'Edit',
  'menu.editCard.cancelText': 'Cancel',
  'menu.editCard.saveText': 'Save changes',
  'menu.list.showTotal': 'Total {totalNumber} items',
  'menu.NoDataImg.text': 'No data to show',
  'menu.CDNResourcesCreate': 'CDN Resources Create',
  'menu.CDNResourcesDetail': 'CDN Resources Detail',
  'menu.SslCerificatesCreate': 'SSL Cerificates Create',
  'menu.SslCerificatesDetail': 'SSL Cerificates Detail',
  'menu.PackageSelection': 'Package Selection',
  'menu.Workorder': 'Customized Monthly Plan',
  'menu.NoAccess.button': "Let's get started",
  'menu.notifications.title': 'Notifications',
  'menu.notifications.newPop': 'New pop',
  'menu.notifications.newVersion': 'New version',
  'menu.notifications.orOther': 'Or other communication',
  'menu.notifications.markedRead': 'All marked as read',
  'menu.notifications.readAll': 'Read all notifications',
  'menu.notifications.ViewMore': 'View more',
  // end
  'menu.notifications.planExpired.title': 'The monthly plan has expired',
  'menu.notifications.planExpired.text':
    'In order to continue accelerating your content, please renew your monthly plan.',
  'menu.notifications.planExpired.btn': 'Upgrade account',
  'menu.notifications.balance.title': 'Insufficient balance',
  'menu.notifications.balance.text': 'Please recharge in a timely manner.',
  'menu.notifications.balance.btn': 'Go to recharge',
  'menu.notifications.Inform.title': 'New Inform',
};
