export default {
  'reset.changePassword.message.rule': '使用8-20个字符',
  'reset.changePassword.message.error': '请输入正确的密码！',
  'reset.changePassword.rule.title': '我们建议：',
  'reset.changePassword.rule.text1': '一个字母',
  'reset.changePassword.rule.text2': '一个数字',
  'reset.changePassword.rule.text3': '一个特殊字符',
  'reset.changePassword.rule.text4': '8-20个字符',
  'reset.changePassword.rule.weak': '弱密码',
  'reset.changePassword.message.strong': '强密码',
  'reset.changePassword.message': '请输入您的密码！',
  'reset.validateFields.message': '重置成功',
  'reset.validatorRePassword.message': '密码错误',
  'reset.title': '重置密码',
  'reset.form.password.name': '新密码',
  'reset.form.rePassword.name': '再次输入密码',
  'reset.form.Log_in': '登录',
  'reset.FactorAuth.validateFields.success': '重置双因认证成功',
  'reset.FactorAuth.validateFields.fail': '重置双因认证失败',
};
