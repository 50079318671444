export default {
  'workorder.title': '定製月度計劃',
  'workorder.help.title': '我們如何幫助你？',
  'workorder.help.text1': '為你的業務找到最好的技術方案',
  'workorder.help.text2': '獲取自定義報價',
  'workorder.help.text3': '自定義免費試用（持續時間和流量）',
  'workorder.help.text4': '與任何其他CDN相關的問題',
  'workorder.contact.title': '如何聯系我們？',
  'workorder.contact.text1': 'support@lightcdn.com',
  'workorder.contact.text2': 'www.lightcdn.com',
  'workorder.information.title': '為了讓我們更了解你，請填入一下信息。',
  'workorder.Basic.title': '你的基礎信息',
  'workorder.Business.title': '你的業務信息',
  'workorder.Basic.type.name': '你的業務類型',
  'workorder.Basic.type.Company': '公司',
  'workorder.Basic.type.Personal': '個人',
  'workorder.Basic.nickname.name': '你的名字',
  'workorder.Basic.nickname.message': '請輸入你的名字',
  'workorder.Basic.companyName.name': '你的公司（非必填）',
  'workorder.Basic.companyName.message': '請輸入你的公司',
  'workorder.Basic.email.name': '你的商務郵箱',
  'workorder.Basic.email.message': '請輸入你的商務郵箱',
  'workorder.Business.industry.name': '你的產業',
  'workorder.Business.industry.message': '請輸入你的產業',
  'workorder.Business.projectName.name': '項目名稱/URL（非必填）',
  'workorder.Business.projectName.message': '請輸入項目名稱/URL',
  'workorder.Business.estimate.name': '每月預估流量',
  'workorder.Business.estimate.message': '請輸入你的產業',
  'workorder.Business.description.name': '需求描述',
  'workorder.Business.description.placeholder':
    '請描述您對CDN的要求，例如帶寬速度和服務分發（即您需要加速的地區可以針對國家，越具體越好）。如果您有其他要求，可以再解釋一遍。',
  'workorder.Business.description.message': '請輸入需求描述',
  'workorder.information.button': '提交申請',
  'workorder.modal.text': '我們將在24小時內評估您的情況，並以電子郵件的形式與您同步。',
};
