import { get, post } from '../request';
import { SYSTEM_NOTICE_URL, NOTICE_URL } from '../url_config';
// 官网登录页查询消息列表
export async function getSystemNotice_api(params: any) {
  return get(`${SYSTEM_NOTICE_URL}/${params.stationCode}`, params);
}
// 查询消息列表
export async function getNoticeList_api(params?: any) {
  return get(`${NOTICE_URL}`, params);
}
// 标记已读
export async function getNoticeRead_api(params: any) {
  return post(`${NOTICE_URL}/read`, params);
}
