import { get, post } from '../request';
import { APP_URL, ORDER_URL } from '../url_config';
/**
 * prod
 */
// 创建产品订单
export async function postCreateOrder_api(params: any) {
  return post(`${APP_URL}prod/v1/create-order`, params);
}
// 同步交易记录
export async function getSyncPayment(id: any) {
  return get(`/service/v1/payment/transactions/sync/${id}`);
}
/**
 * 创建升级产品订单
 * @array monthPlanUUIDS  需要变更的月度计划id列表
 * @string orderType  订单类型
 * @string resourceType  资源类型
 * @string resourceUUID  资源id
 * @number differencePaymentAmt  已支付价格
 * @number totalPaymentAmt  需支付价格
 * @number oriPaymentAmt  未用上
 * @string prePareOrderUUId  获取价格時候得到的uuid
 */
export async function postProdUpgradeCreateOrder_api(params: any) {
  return post(`${APP_URL}prod/upgrade/create-order`, params);
}
/**
 * order
 */
// 支付订单
export async function postPaymentOrder_api(params: any) {
  return post(`${ORDER_URL}v1/order/payment`, params);
}
// 支付零元订单
export async function postPaymentZeroOrder_api(params?: any) {
  return post(`${ORDER_URL}v1/order/payment-zero`, params);
}
// 获取交易记录id
export async function getPaymentTransactionId_api(orderUUID: string, params?: any) {
  return get(`${ORDER_URL}v1/order/${orderUUID}`, params);
}
// 获取订单列表
export async function getOrderList(params: any) {
  return get(`${ORDER_URL}v1/order/list`, params);
}
// 创建支付订单 - 充值
export async function postOrder_api(params?: any) {
  return post(`${ORDER_URL}v1/order`, params);
}
// 获取充值订单列表
export async function getOrderRechargeList(params: any) {
  return get(`${ORDER_URL}v1/recharge-order/list`, params);
}
// 使用余额支付
export async function postPaymentBalance_api(params: any) {
  return post(`${ORDER_URL}v1/order/payment-balance`, params);
}
