export default {
  'workorder.title': 'Customized Monthly Plan',
  'workorder.help.title': 'How can we help you?',
  'workorder.help.text1': 'Find the best technical setup for your use case',
  'workorder.help.text2': 'Get a custom quote',
  'workorder.help.text3': 'Customize your free trial (duration & traffic volume)',
  'workorder.help.text4': 'With any other CDN related questions',
  'workorder.contact.title': 'How can you contact us?',
  'workorder.contact.text1': 'support@lightcdn.com',
  'workorder.contact.text2': 'www.lightcdn.com',
  'workorder.information.title':
    'Please fill in the following information to let us know you better.',
  'workorder.Basic.title': 'Your Basic Information',
  'workorder.Business.title': 'Your Business Information',
  'workorder.Basic.type.name': 'Your business type',
  'workorder.Basic.type.Company': 'Company',
  'workorder.Basic.type.Personal': 'Personal',
  'workorder.Basic.nickname.name': 'Your name',
  'workorder.Basic.nickname.message': 'Please enter your name',
  'workorder.Basic.companyName.name': 'Your company ( optional )',
  'workorder.Basic.companyName.message': 'Please enter your company ( optional )',
  'workorder.Basic.email.name': 'Your business email',
  'workorder.Basic.email.message': 'Please enter your business email',
  'workorder.Business.industry.name': 'Your industry',
  'workorder.Business.industry.message': 'Please enter your industry',
  'workorder.Business.projectName.name': 'Project name/URL( optional )',
  'workorder.Business.projectName.message': 'Please enter project name/URL( optional )',
  'workorder.Business.estimate.name': 'Monthly traffic estimate',
  'workorder.Business.estimate.message': 'Please select Your industry',
  'workorder.Business.description.name': 'Requirements description',
  'workorder.Business.description.placeholder':
    'Please describe your requirements for CDN, such as bandwidth speed and service distribution (that is, the regions you need to accelerate can be specific to the country, the more specific the better.). If you have other requirements, you can explain it again.',
  'workorder.Business.description.message': 'Please enter requirements description',
  'workorder.information.button': 'Send Message',
  'workorder.modal.text':
    'We will assess your situation within 24 hours, and will synchronize with you in the form of email.',
};
