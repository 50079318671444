import login from './zh-CN/login';
import home from './zh-CN/home';
import forget from './zh-CN/forget';
import register from './zh-CN/register';
import menu from './zh-CN/menu';
import reset from './zh-CN/reset';
import cdnResources from './zh-CN/cdnResources';
import sslCerificates from './zh-CN/sslCerificates';
import bandWidth from './zh-CN/bandWidth';
import traffic from './zh-CN/traffic';
import requests from './zh-CN/requests';
import statusCodes from './zh-CN/statusCodes';
import accountSettings from './zh-CN/accountSettings';
import billing from './zh-CN/billing';
import upgadeAccount from './zh-CN/upgadeAccount';
import packageSelection from './zh-CN/packageSelection';
import workorder from './zh-CN/workorder';
import giftCode from './zh-CN/giftCode';
import topFiles from './zh-CN/topFiles';
import noAccess from './zh-CN/noAccess';
import guide from './zh-CN/guide';

export default {
  ...menu,
  ...home,
  ...forget,
  ...login,
  ...register,
  ...reset,
  ...cdnResources,
  ...sslCerificates,
  ...bandWidth,
  ...traffic,
  ...requests,
  ...statusCodes,
  ...accountSettings,
  ...billing,
  ...upgadeAccount,
  ...packageSelection,
  ...workorder,
  ...giftCode,
  ...topFiles,
  ...noAccess,
  ...guide,
};
