export default {
  'packageSelection.title': 'Upgrade',
  'packageSelection.planList.free': 'Free',
  'packageSelection.planList.free.title': 'Free Monthly Plan',
  'packageSelection.planList.free.nextText': 'For personal non professional site',
  'packageSelection.planList.free.support': 'Help documentation',
  'packageSelection.planList.free.perMonth': '{numUnit} per month',
  'packageSelection.planList.free.price': 'Repurchase every month',
  'packageSelection.planList.free.config': 'Available international nodes',
  'packageSelection.planList.free.buttonText': 'Select',
  'packageSelection.planList.free.Modal.text': "It's not time to buy, please wait for it to open.",
  'packageSelection.planList.free.extraCore': '',
  'packageSelection.planList.free.corefeatures.p1': 'Fast and easy to use CDN',
  'packageSelection.planList.free.corefeatures.p2': 'Free SSL certificate',
  'packageSelection.planList.free.corefeatures.p3': '100GB traffic per month',
  'packageSelection.planList.free.corefeatures.p4': 'Basic data and analysis of your visitors',
  'packageSelection.planList.free.corefeatures.p5': 'Internet access to mainland China',
  'packageSelection.planList.free.corefeatures.p6': 'Perfect safety protection policy',
  'packageSelection.planList.free.corefeatures.p7': 'Customizable 8 core international nodes',
  'packageSelection.planList.free.corefeatures.p8': 'No contractual agreement',
  'packageSelection.planList.pro.title': 'Pro Monthly Plan',
  'packageSelection.planList.pro.nextText': 'Non commercial professional website',
  'packageSelection.planList.pro.support': 'Chat, support email',
  'packageSelection.planList.pro.perMonth': 'Low as {low} per month',
  // 'packageSelection.planList.pro.perMonth': '2023 Special Promo! A 500G plan open to all nodes!',
  'packageSelection.planList.pro.price': 'Unit price as low as {unitPrice}/GB',
  'packageSelection.planList.pro.config': 'All POPs, including rare POPs opened subsequently',
  'packageSelection.planList.pro.buttonText': 'Select',
  'packageSelection.planList.pro.extraCore': 'Everything in free，and：',
  'packageSelection.planList.pro.corefeatures.p1': 'All nodes, including rare edge nodes',
  'packageSelection.planList.pro.corefeatures.p2':
    '{min}~{max} per month, unit price as low as {unitPrice}',
  'packageSelection.planList.pro.corefeatures.p3': 'Fast and easy to use WAF features',
  'packageSelection.planList.pro.corefeatures.p5': 'Unlimited HTTP cache rule(coming soon)',
  'packageSelection.planList.remind.title': 'Customized Monthly Plan',
  'packageSelection.planList.remind.nextText': 'Professional and commercial websites',
  'packageSelection.planList.remind.support':
    'Exclusive customer service 1-to-1, solution engineer support, ',
  'packageSelection.planList.remind.support1': 'provide testing of paid nodes',
  'packageSelection.planList.remind.perMonth': 'Demand of more than 50T per month',
  'packageSelection.planList.remind.price': 'More affordable unit price',
  'packageSelection.planList.remind.config': '',
  'packageSelection.planList.remind.buttonText': 'Apply for offer',
  'packageSelection.planList.remind.disabledText': 'Please wait for processing',
  'packageSelection.planList.remind.Modal.ok': 'OK',
  'packageSelection.planList.remind.Modal.text':
    'We have received your request. After the function is opened, we will notify you by email.',
  'packageSelection.planList.remind.extraCore': 'Everything in pro，and：',
  'packageSelection.planList.remind.corefeatures.p1': 'Support team sharing',
  'packageSelection.planList.remind.corefeatures.p2': 'The most cost-effective solution',
  'packageSelection.planList.remind.corefeatures.p3': 'Access to non contractual services',
  'packageSelection.planList.Support': 'Support',
  'packageSelection.planList.CoreFeatures': 'Core features',
  'packageSelection.help1': 'If you need to test payment nodes, please ',
  'packageSelection.help2': 'contact us ',
  'packageSelection.help3': 'and we will be happy to serve you.',
  //v1.2.0
  'packageSelection.planList.title1': 'Pay as you go',
  'packageSelection.planList.title2': 'Monthly plan',
  'packageSelection.planList.title3': 'All-in-one solution',
  'packageSelection.planList.title1.text': 'Hourly settlement and on-demand acceleration',
  'packageSelection.planList.title2.text':
    '30-day limited time plan with a more affordable unit price',
  'packageSelection.planList.title3.text': 'Customized solutions based on business needs',
  'packageSelection.planList.Tier': 'Tier',
  'packageSelection.planList.international.nodes': 'international nodes',
  'packageSelection.planList.core.nodes': 'core international nodes',
  'packageSelection.planList.Other': 'Other',
  'packageSelection.planList.nodes.text1':
    'All nodes are suitable for customers with stable business needs in Africa, the Middle East, Southeast Asia, and other regions.',
  'packageSelection.planList.nodes.text2':
    'Backbone network nodes covering five major regions. Meet international visiting needs.',
  'packageSelection.planList.nodes.text3': 'Add new nodes as needed',
  'packageSelection.planList.Support.help': 'Help documentation， support email',
  'packageSelection.planList.Support.help1':
    'Exclusive customer service 1-to-1, solution engineer support',
  'packageSelection.planList.Recharge': 'Account recharge',
  'packageSelection.planList.button2': 'Get a offer',
  'packageSelection.planList.button4': 'Get free credit!',
  'packageSelection.planList.button5': 'Redeemed {date}',
  'packageSelection.planList.Operation.successful': 'Operation successful',
  'packageSelection.planList.noFree.p':
    'There is currently a monthly plan and no free quota can be claimed.',
  'packageSelection.planList.day': '-day',
  'packageSelection.planList.billingDetails.p1': 'Please go to the ',
  'packageSelection.planList.billingDetails.p2': 'billing details',
  'packageSelection.planList.billingDetails.p3': ' to fill in, then you can get your free limit!',
  'packageSelection.planList.Successfully': 'Successfully redeemed!!',
  'packageSelection.planList.CreateCDN': 'Create new CDN resources',
};
