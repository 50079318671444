export default {
  'accountSettings.title': '帳戶設置',
  'accountSettings.tabTitle1': '基本設置',
  'accountSettings.tabTitle2': '團隊成員',
  'accountSettings.tabTitle3': '雙因驗證',
  'accountSettings.general.factor': '雙因驗證',
  'accountSettings.general.handleSave.message': '修改成功',
  'accountSettings.general.validatorPhone.rule': '使用6-11字符',
  'accountSettings.general.validatorPhone.message': '請輸入正確的電話',
  'accountSettings.general.title': '賬號信息',
  'accountSettings.general.Edit': '編輯',
  'accountSettings.general.Cancel': '取消',
  'accountSettings.general.Save': '保存修改',
  'accountSettings.general.Form.email.title': '郵箱地址',
  'accountSettings.general.Form.email.message': '請輸入您的郵箱地址！',
  'accountSettings.general.change': '更改',
  'accountSettings.general.Form.fullname.title': '全名',
  'accountSettings.general.Form.fullname.message': '請輸入您的全名！',
  'accountSettings.general.Form.phone.title': '手機號碼',
  'accountSettings.general.Form.country.message': '請選擇您的國家！',
  'accountSettings.general.Form.phone.message': '請填入您的手機號！',
  'accountSettings.general.Form.companyName.title1': '公司名稱',
  'accountSettings.general.Form.companyName.title2': '（非必填）',
  'accountSettings.general.Form.password.title': '密碼',
  'accountSettings.general.Form.password.message': '請輸入您的密碼！',
  'accountSettings.general.Form.rePassword.title': '再次輸入密碼',
  'accountSettings.general.ChangePassword': '修改密碼',
  'accountSettings.general.handleChangeEmail.message': '郵件發送成功',
  'accountSettings.general.validatorPassword.message': '錯誤的密碼',
  'accountSettings.general.validatorEmail.rule': '該郵箱已存在',
  'accountSettings.general.validatorEmail.message': '請輸入正確的郵箱地址',
  'accountSettings.general.Modal.title1': '更改郵箱地址',
  'accountSettings.general.Modal.okText': '更改郵箱地址',
  'accountSettings.general.Modal.p0': '請輸入密碼以驗證身份。',
  'accountSettings.general.Modal.p1':
    '如果您更改了郵箱，那麽您的賬號也會一並更改。請輸入您的密碼以驗證身份。',
  'accountSettings.general.Modal.title2': '更改郵箱地址',
  'accountSettings.general.Modal.p2':
    '帳戶信息身份驗證成功。我們將向新的電子郵件地址發送確認郵件。請點擊電子郵件中的鏈接將其激活。',
  'accountSettings.general.Modal.email.title': '新郵箱地址',
  'accountSettings.general.validatorRePassword.message': '密碼不一致',
  'accountSettings.FactorAuth.enableText': '啟用雙因驗證',
  'accountSettings.FactorAuth.disableText': '禁用雙因驗證',
  'accountSettings.FactorAuth.disableTip1': '您確定要禁用雙因驗證嗎？',
  'accountSettings.FactorAuth.disableTip2': '請完成以下步驟關閉。',
  'accountSettings.FactorAuth.Status': '狀態',
  'accountSettings.FactorAuth.Status.text':
    '雙重身份驗證是一種安全機製，旨在防止他人訪問或使用您的帳戶，即使他們知道您的密碼。它要求用戶使用特定設備或其他可識別的方法來驗證其身份。此機製通過添加額外的驗證步驟來提高帳戶安全性。',
  'accountSettings.APIKey.text1': '您可以通過以下方式使用',
  'accountSettings.APIKey.text2': '安全地更新您的 LightCDN 資源。',
  'accountSettings.APIKey.Reset.title': '重置 API 訪問密鑰',
  'accountSettings.APIKey.Reset.text':
    '請確認是否要重置 API 訪問密鑰。確認後，舊密鑰將失效並生成新密鑰。您確定要重置嗎？',
  'accountSettings.FactorAuth.enable.step1.1': '在您的手機（',
  'accountSettings.FactorAuth.enable.step1.Iphone': 'Iphone',
  'accountSettings.FactorAuth.enable.step1.Android': 'Android',
  'accountSettings.FactorAuth.enable.step1.2':
    '）上下載 Google Authenticator 應用並使用它來掃描以下二維碼。',
  'accountSettings.FactorAuth.enable.step1.p1': '無法掃描代碼？',
  'accountSettings.FactorAuth.enable.step1.p2': '您可以在手機上手動填寫以下密鑰到應用程序中。',
  'accountSettings.FactorAuth.enable.step2': '填寫身份驗證應用程序中的 6 位代碼來驗證配置。',
  'accountSettings.FactorAuth.enable.step3':
    '請保存這些驗證碼。如果您的手機出現任何問題，您可以使用備用驗證碼來幫助重置 Google Authenticator。',
  'accountSettings.FactorAuth.enable.success': '開啟成功',
  'accountSettings.FactorAuth.disable.success': '關閉成功',
};
