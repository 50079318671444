export default {
  'giftCode-title': 'Promo code',
  'giftCode-tab-1': 'Redeem new promo code',
  'giftCode-tab-2': 'View redeemed promo codes',
  'giftCode-tab-1-1': 'Redeem',
  'giftCode-tab-2-1': 'View',
  'giftCode-RedeemCode-code-placeholder': 'Please enter promo code',
  'giftCode-RedeemCode-validatorCode-msg1': 'Invalid promo code.',
  'giftCode-RedeemCode-validatorCode-msg2': 'You have received it.',
  'giftCode-RedeemCode-Cancel': 'Cancel',
  'giftCode-RedeemCode-Redeem': 'Redeem',
  'giftCode-RedeemCode-result-success-1': 'Successfully redeemed the promo code.',
  'giftCode-RedeemCode-result-success-2': 'Successfully received {num}.',
  'giftCode-RedeemCode-result-success-2-tip':
    'If the monthly plan is interrupted, the complimentary traffic will automatically become invalid. Please renew the fee in a timely manner.',
  'giftCode-RedeemCode-result-fail-1': 'Failed',
  'giftCode-RedeemCode-result-fail-2':
    'We have detected that you currently do not have a monthly paln in use. Please purchase a monthly plan as soon as possible to redeem free traffic',
  'giftCode-RedeemCode-code-extra-1': "By clicking 'Redeem', you agree to the ",
  'giftCode-RedeemCode-code-extra-2': 'terms and conditions ',
  'giftCode-RedeemCode-code-extra-3': 'of the promo code (if applicable)',
  'giftCode-RedeemTable-name-1': 'Expires on {date}',
  'giftCode-RedeemTable-name-2': 'Used on {date}',
  'giftCode-RedeemTable-status-1': 'Get Offer',
  'giftCode-RedeemTable-status-1-1': 'Get Offer',
  'giftCode-RedeemTable-status-2': 'Used',
  'giftCode-RedeemTable-status-3': 'Expired',
  'giftCode-RedeemTable-status-4': 'Lock',
  'giftCode-select-NotSatisfied': 'Not satisfied',
};
